import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import Pagination from "../components/Pagination";
import { getAllCourses } from "../services/adminServices/AdminServices"
import { Backdrop, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";


function  AdminApprovedCourses() {
  const [location, setLocation] = useState(false);

  const handleModel = () => {
    setLocation(!location);
  };

  const fetchCourses = async (token) => {
    try {
      const response = await getAllCourses(token);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };
  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          console.log(courses.data.courses);
  
          // Filter out courses where status is "published"
          const publishedCourses = courses.data.courses.filter(course => course.status === "published");
  
          // Set the filtered courses
          setCourses(publishedCourses);
          
          setOpen(false);
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }
  }, [token]);
  


  return (
    <Fragment>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />Loading...
      </Backdrop>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex px-4 pb-0 pt-4">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Approved Courses List
                      </h4>
                      <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select>
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0">Sr.</th>
                              <th className="border-0" scope="col">
                                Name
                              </th>

                              <th className="border-0" scope="col">
                                Status
                              </th>
                              <th className="border-0" scope="col">
                                Field
                              </th>
                              <th scope="col" className="text-right border-0">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {courses.map((value, index) => (
                              <tr key={index}>
                                <td
                                //  className="product-thumbnail text-start ps-0"
                                 >
                                  <Link
                                    to={`/admin-approvedCourse/${value._id}`}
                                    // className="video-bttn small-icon"
                                  >
                                    {/* <img
                                      src={`assets/images/${value.imageUrl}`}
                                      alt="product"
                                      className="w125 d-inline-block p-0 bg-greylight rounded-lg overflow-hidden"
                                    /> */}
                                    {index + 1}
                                  </Link>
                                </td>

                                <td>
                                  <b>{value.title}</b>
                                </td>

                                <td>
                                  <span
                                    className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 alert-primary`}
                                  >
                                    {value.status}
                                  </span>
                                </td>
                                <td>
                                  <b>{value.domain_of_study}</b>
                                </td>
                                <td className="product-remove text-right">
                                  <Link to={`/admin-approvedCourse/${value._id}`}>
                                    <i className="ti-eye mr-2 font-xs text-warning-500"></i>
                                  </Link>
                                  <Button
                                    className="bg-transparent border-0"
                                    onClick={() => {
                                      handleModel();
                                    }}
                                  >
                                    <i className="ti-trash  font-xs text-danger"></i>
                                  </Button>
                                  <Modal
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={location}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleModel();
                                      }}
                                      className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                    ></Button>
                                    <Modal.Body className="text-center p-4">
                                      <i className="ti-info-alt text-warning display4-size"></i>
                                      <p className="text-grey-500 font-xsss mt-3 mb-4">
                                        Are you sure you want to delete project?
                                      </p>

                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                      >
                                        Yes, delete!
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                      >
                                        No, cancle!
                                      </Button>
                                    </Modal.Body>
                                  </Modal>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AdminApprovedCourses;
