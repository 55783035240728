import axios from "axios";

export default axios.create({

    // Dev Enivironment
    baseURL: "https://api.seekhoo.online/api",

    // Production Environment
    // baseURL: "/api",

    
    // baseURL: "https://backend-spring.herokuapp.com/api",
    headers: {
        "Content-Type": "application/json"
    }
})
