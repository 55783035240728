import React, { Fragment, useEffect, useState } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Subscribe from "../components/Subscribe";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import Myclass from "../components/Myclass";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css"; //Allows for server-side rendering.
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { reviewByCourse } from "../services/statistics/statistics";

import UserDialog from "../components/DialogBox";
import { truncateTitle } from "../utils/utilityMethods";
import { getFeaturedLiveCoursesForAUser } from "../services/userServices/FeaturedServices";
import { useSelector } from "react-redux";
import { getAllFeaturedCourses } from "../services/public/PublicServices";
import { getCategories } from "../services/categoryServices/categoryServices.js";
import SearchBar from "../components/searchBar.tsx";
import CourseCard from "../components/courseCard.js";

const latestList = [
  {
    imageUrl: "course-4.jpg",
    title: "Fundamentals for Scrum Master and Agile Projects ",
    price: "670",
    tag: "Python",
    lesson: "32 ",
    status: "alert-warning text-warning",
  },
  {
    imageUrl: "course-2.jpg",
    title: "Automate the Boring Stuff with Python Programming",
    price: "760",
    tag: "Bootstrap",
    lesson: "14 ",
    status: "alert-primary text-primary",
  },
  {
    imageUrl: "course-1.jpg",
    title: "The Data Science Course Complete Data Science ",
    price: "370",
    tag: "Develop",
    lesson: "23 ",
    status: "alert-danger text-danger",
  },
  {
    imageUrl: "course-3.jpg",
    title: "Complete Python Bootcamp From Zero to Hero in Python ",
    price: "2400",
    tag: "Python",
    lesson: "32 ",
    status: "alert-warning text-warning",
  },
  {
    imageUrl: "course-1.jpg",
    title: "Complete Python Bootcamp From Zero to Hero in Python ",
    price: "40",
    tag: "Desinger",
    lesson: "24 ",
    status: "alert-danger text-danger",
  },
];
const mainSlider = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  centerMode: false,
  variableWidth: false,
};
const sliderList = [
  {
    imageUrl: "student-course-banner.jpg",
    title: "The Complete Guide React Vue Developer",
    des: "ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque. Vestibulum ut sem laoreet, feugiat tellus at, hendrerit arcu.",
    tag: "React",
    rating: "56 ratings",
  },
  {
    imageUrl: "student-course-banner.jpg",
    title: "Complete Python Bootcamp From Zero",
    des: "ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque. Vestibulum ut sem laoreet, feugiat tellus at, hendrerit arcu.",
    tag: "React",
    rating: "45 ratings",
  },
  {
    imageUrl: "student-course-banner.jpg",
    title: "Design and Advanced Methods Workshop Tutorial",
    des: "ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque. Vestibulum ut sem laoreet, feugiat tellus at, hendrerit arcu.",
    tag: "React",
    rating: "22 ratings",
  },
];
// const popularList = [
//   {
//     imageUrl: "course-1.jpg",
//     title: "Complete Python Bootcamp From Zero to Hero in Python ",
//     price: "2400",
//     tag: "Python",
//     lesson: "32 ",
//     status: "alert-warning text-warning",
//   },
//   {
//     imageUrl: "course-4.jpg",
//     title: "Complete Python Bootcamp From Zero to Hero in Python ",
//     price: "40",
//     tag: "Desinger",
//     lesson: "24 ",
//     status: "alert-danger text-danger",
//   },
//   {
//     imageUrl: "course-3.jpg",
//     title: "Java Programming Masterclass for Developers",
//     price: "60",
//     tag: "Bootstrap",
//     lesson: "14 ",
//     status: "alert-success text-success",
//   },
//   {
//     imageUrl: "course-2.jpg",
//     title: "The Data Science Course Complete Data Science ",
//     price: "370",
//     tag: "Develop",
//     lesson: "23 ",
//     status: "alert-danger text-danger",
//   },
//   {
//     imageUrl: "course-3.jpg",
//     title: "Complete Python Bootcamp From Zero to Hero in Python ",
//     price: "450",
//     tag: "Desinger",
//     lesson: "24 ",
//     status: "alert-danger text-danger",
//   },
// ];

const bgList = [
  "#fcf1eb",

  "#fff9eb",

  "#e5f4fb",

  "#dcf4f8",

  "#fcf1eb",

  "#fff9eb",

  "#e5f4fb",

  "#fcf1eb",

  "#fcf1eb",

  "#fff9eb",
];

const getRandomBg = () => {
  return bgList[Math.floor(Math.random() * bgList.length)];
};

export const Default = () => {
  const navigate = useNavigate();
  const [courseSearchQuery, setCourseSearchQuery] = useState("");

  const categorysettings = {
    arrows: true,
    dots: false,
    infinite: true, // Enable infinite loop
    speed: 300,
    slidesToShow: 7, // Number of slides to show at once
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Autoplay speed in milliseconds
    cssEase: "linear", // Smooth linear transition
    pauseOnHover: false, // Continue autoplay on hover
  };
  // const popularSlider = {
  //   arrows: false,
  //   dots: false,
  //   infinite: true,
  //   speed: 300,
  //   centerMode: false,
  //   slidesToShow: 1, // Display one slide at a time
  //   slidesToScroll: 1, // Scroll one slide at a time
  //   variableWidth: false, // Disable variable width
  // };
  const latestSlider = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    centerMode: false,
    variableWidth: true,
  };
  // const memberSlider = {
  //   arrows: false,
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   centerMode: false,
  //   variableWidth: false
  // };

  const [courses, setCourses] = useState([]);
  const [liveCourses, setLiveCourses] = useState([]);

  const fetchCourses = async () => {
    try {
      await getAllFeaturedCourses()
        .then((response) => {
          setCourses(response.data.featuredCourses);

          attachRating(response.data.featuredCourses);
          // console.log(response.data.courses);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const attachRating = (courses) => {
    courses.map((course) => {
      reviewByCourse(course._id).then((response) => {
        const avgRating =
          response.data.reduce((acc, review) => acc + review.rating, 0) /
            response.data.length || 0;

        setCourses((prevCourses) => {
          return prevCourses.map((prevCourse) => {
            if (prevCourse._id === course._id) {
              return {
                ...prevCourse,
                rating: avgRating,
              };
            }
            return prevCourse;
          });
        });
      });
      return course;
    });
  };

  const sortCoursesByRating = (courses) => {
    const courseSortedByRating = courses.sort((a, b) => b.rating - a.rating);
    return courseSortedByRating;
  };

  const sortCoursesByCreatedAt = (courses) => {
    const courseSortedByCreatedAt = courses.sort(
      (a, b) => b.createdAt - a.createdAt
    );
    return courseSortedByCreatedAt;
  };

  const [courseCategories, setCourseCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 200) {
        setCourseCategories(response.data);
      } else {
        console.log("Error in fetching categories");
        alert("Error in fetching categories");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchCourses();
    fetchLiveCourses();
  }, []);

  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const fetchLiveCourses = async () => {
    try {
      await getFeaturedLiveCoursesForAUser(token)
        .then((response) => {
          setLiveCourses(response.data);
          // console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const user = useSelector((state) => state.user.loggedIn);
  const options = {
    items: 3,
    nav: true,
    rewind: true,
    autoplay: true,
  };
  return (
    <Fragment>
      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader />

          <UserDialog
            role="user"
            message="Get ready to embark on a journey of learning and discovery!"
          />

          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div
                    className="card rounded-xxl p-lg--5 border-0 bg-no-repeat bg-image-contain banner-wrap"
                    style={{
                      backgroundImage: `url("student-main-image.png")`,
                    }}
                  >
                    <div className="card-body p-4">
                      <h2 className="display3-size fw-400 display2-md-size sm-mt-7 sm-pt-10">
                        Find a perfect{" "}
                        <b className="d-lg-block">Online Course</b>
                      </h2>
                      <h4 className="text-grey-500 font-xssss fw-500 ml-1 lh-24">
                        For only course you need to learn web development
                      </h4>
                      <SearchBar />
                      <h4 className="text-grey-500 font-xssss fw-500 ml-1 lh-24">
                        <b className="text-grey-800 text-dark">
                          Popular Search :
                        </b>
                        Designer, Developer, PHP, HTML, CSS, SCSS
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pt-2">
                  <h2 className="fw-400 font-lg">
                    Explore <b>Categories</b>
                    <a href="/" className="float-right">
                      <i className="feather-edit text-grey-500 font-xs"></i>
                    </a>
                  </h2>
                </div>
                <div className="col-lg-12 mt-3">
                  <Slider {...categorysettings}>
                    {courseCategories.length > 0 &&
                      courseCategories.map((value, index) => (
                        <div
                          key={index}
                          className="card cat-card-hover mr-3 w140 border-0 p-0 text-center"
                          onClick={() => {}}
                          style={{ minHeight: "200px" }}
                        >
                          <Link to={`/default-search/ /${value.name}`}>
                            <div
                              className="card-body p-3 ml-0 rounded-lg"
                              style={{
                                background: `${getRandomBg()}`,
                                minHeight: "190px",
                                maxHeight: "190px",
                                maxWidth: "190px",
                              }}
                            >
                              <a
                                href={`/default-search/ /${value.name}`}
                                className="btn-xl bg-white"
                              >
                                <img
                                  src={value.imageURL}
                                  alt="icon"
                                  className=" btn-xl"
                                  style={{ objectFit: "contain" }}
                                />
                              </a>

                              <h4 className="fw-600 font-xsss mt-3 mb-0">
                                {value.name}
                              </h4>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </Slider>
                </div>
                {/* new section */}

                <div className="col-lg-12 mb-0" style={{ marginTop: "30px" }}>
                  <Slider {...mainSlider}>
                    {sliderList.map((value, index) => (
                      <div
                        className="card course-slider rounded-lg border-0 banner-wrap overflow-hidden"
                        key={index}
                      >
                        <div
                          className="video-wrap"
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          <img
                            src={`assets/images/${value.imageUrl}`}
                            alt="banner"
                            style={{
                              width: "100%",
                              display: "block",
                              margin: "0 auto",
                            }}
                          />
                        </div>

                        <div className="card-body position-absolute align-items-center d-flex h-100 top-0">
                          <div className="card-content pr-lg-5">
                            <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-warning d-inline-block text-warning mr-1 mb-2">
                              {value.tag}
                            </span>
                            <h2 className="display1-size display1-sm-size fw-700 lh-3 text-white ">
                              {value.title}
                            </h2>
                            <p className="d-none d-xl-block font-xssss fw-500 text-grey-300 lh-30 mt-0 mr-5 mb-0">
                              {value.des}
                            </p>
                            <div className="star d-block w-100 text-left mt-2">
                              <img
                                src="assets/images/star.png"
                                alt="star"
                                className="w15 float-left"
                              />
                              <img
                                src="assets/images/star.png"
                                alt="star"
                                className="w15 float-left"
                              />
                              <img
                                src="assets/images/star.png"
                                alt="star"
                                className="w15 float-left"
                              />
                              <img
                                src="assets/images/star.png"
                                alt="star"
                                className="w15 float-left"
                              />
                              <img
                                src="assets/images/star-disable.png"
                                alt="star"
                                className="w15 float-left mr-2"
                              />
                            </div>
                            <p className="review-link d-none d-lg-block font-xssss fw-600 text-grey-100 lh-3 mb-1">
                              {value.rating}
                            </p>
                            <div className="clearfix"></div>
                            <button
                              onClick={() => {
                                navigate("/default-search/ / ");
                              }}
                              className="btn btn-primary"
                            >
                              Get Course
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
                <section className="section new-course">
                  <div className="container">
                    <div className="section-header aos">
                      <div className="section-sub-head">
                        <span>What’s New</span>
                        <h2>Featured Courses</h2>
                      </div>
                      <div className="all-btn all-category d-flex align-items-center">
                        <button
                          onClick={() => {
                            navigate("/default-search/All/Select Category/");
                          }}
                          className="btn btn-primary"
                        >
                          All Courses
                        </button>
                      </div>
                    </div>
                    <div className="section-text aos">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Eget aenean accumsan bibendum gravida maecenas augue
                        elementum et neque. Suspendisse imperdiet.
                      </p>
                    </div>
                    <div className="course-feature">
                      <div className="row">
                        {sortCoursesByRating(courses).map((value, index) => (
                          <CourseCard key={index} value={value} />
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
                <section className="section new-course">
                  <div className="container">
                    <div className="section-header aos">
                      <div className="section-sub-head">
                        <span>What’s Latest</span>
                        <h2>Latest Classes</h2>
                      </div>
                      <div className="all-btn all-category d-flex align-items-center">
                        <button
                          onClick={() => {
                            navigate("/default-search/All/Select Category/");
                          }}
                          className="btn btn-primary"
                        >
                          All Classes
                        </button>
                      </div>
                    </div>
                    <div className="section-text aos">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Eget aenean accumsan bibendum gravida maecenas augue
                        elementum et neque. Suspendisse imperdiet.
                      </p>
                    </div>
                    <div className="course-feature">
                      <div className="row">
                        {sortCoursesByCreatedAt(courses).map((value, index) => (
                          <CourseCard key={index} value={value} />
                        ))}
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section new-course">
                  <div className="container">
                    <div className="section-header aos">
                      <div className="section-sub-head">
                        <span>What’s Latest</span>
                        <h2>Live Classes</h2>
                      </div>
                      {/* <div className="all-btn all-category d-flex align-items-center">
                        <a href="course-list.html" className="btn btn-primary">
                          All Classes
                        </a>
                      </div> */}
                    </div>
                    <div className="section-text aos">
                      <p className="mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Eget aenean accumsan bibendum gravida maecenas augue
                        elementum et neque. Suspendisse imperdiet.
                      </p>
                    </div>
                    <div className="col-lg-12 mt-3 ">
                      {user && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            flexWrap: "wrap",
                          }}
                        >
                          {liveCourses.length === 0 && (
                            <div className="text-center">
                              No live courses for your followed instructors
                            </div>
                          )}
                          {liveCourses.map((value, index) => (
                            <div
                              id="live-courses"
                              style={{ maxWidth: "fit-content" }}
                              key={index}
                            >
                              <div className="card w200 d-block border-0 shadow-xss rounded-lg overflow-hidden mb-4 mr-2">
                                <div
                                  className="card-body position-relative h100 bg-gradiant-bottom bg-image-cover bg-image-center"
                                  style={{
                                    backgroundImage: `url(${value.thumbnail})`,
                                  }}
                                ></div>
                                <Link to={`/default-live-stream/${value._id}`}>
                                  <div className="card-body d-block w-100 pl-4 pr-4 pb-4 text-center">
                                    <figure className="avatar ml-auto mr-auto mb-0 mt--6 position-relative w75 z-index-1">
                                      <img
                                        src={`assets/images/course-4.jpg`}
                                        alt="avater"
                                        className="float-right p-1 bg-white rounded-circle w-100"
                                      />
                                    </figure>
                                    <div className="clearfix"></div>
                                    <h4 className="fw-700 font-xsss mt-3 mb-1">
                                      {value.title}
                                    </h4>
                                    <Link
                                      to={`/default-live-stream/${value._id}`}
                                    >
                                      <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                                        {value.instructor}
                                      </p>
                                    </Link>
                                    <span className="live-tag mt-2 mb-3 bg-danger p-2 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3">
                                      LIVE
                                    </span>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </section>

                {/* <div className="col-lg-12 pt-0 mb-3">
                  <h2 className="fw-400 font-lg d-block">
                    Live <b> Classes</b>
                    <a href="/" className="float-right">
                      <i className="feather-edit text-grey-500 font-xs"></i>
                    </a>
                  </h2>
                </div> */}
              </div>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                <Profile />
                <Myclass />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default Default;
