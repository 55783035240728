import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  const navClass = `${isOpen ? " show" : ""}`;
  const { divClass, color = "light" } = props;
  let colorClass = color === "dark" ? "text-white" : "";
  return (
    <div className={`header-wrapper pt-3 pb-3 shadow-xss ${divClass}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-9 navbar pt-0 pb-0">
            <Link to="/">
              <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                Seekho.pk
                <span className="d-block font-xsssss ls-1 text-grey-500 open-font ">
                  Online Learning Course
                </span>
              </h1>
            </Link>
            <button className="navbar-toggler" onClick={toggleOpen}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${navClass}`}
              id="navbarNavDropdown"
            >
              <Navbar
                expand="lg"
                className="dropdown-navbar slide-navmenu nav-menu"
              >
                <Navbar id="basic-navbar-nav" className="w-100 d-block">
                  <Nav className={`${colorClass}`}>
                    <NavDropdown title="Categories" id="basic-nav-dropdown">
                      <NavDropdown.Item href="/home-2">
                        Home One
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/home-3">
                        Home Two
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/home-4">
                        Home Three
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/home-5">
                        Home Four
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/home-6">
                        Home Five
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="My Classes" id="basic-nav-dropdown">
                      <NavDropdown.Item href="/about">About</NavDropdown.Item>
                      <NavDropdown.Item href="/contact">
                        Contact
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/contact-2">
                        Contact 2
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/notfound">404</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown.Item
                      href="/contact"
                      style={{ marginRight: "12px" }}
                    >
                      Seekho Business
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/teacher-carousel">
                      Teach on Seekho
                    </NavDropdown.Item>
                    {/* <NavDropdown.Item href="/contact">
                        Seekho Business
                      </NavDropdown.Item> */}
                  </Nav>
                </Navbar>
              </Navbar>
            </div>
          </div>
          <div className="col-lg-3 text-right d-none d-lg-block">
            <Link
              to="/navigation"
              data-toggle="modal"
              data-target="#ModalCart"
              className={`float-right text-center mt-1 ml-4 text-grey-800 position-relative ${colorClass}`}
            >
              <i className="ti-arrow-circle-right font-lg"></i>
              <span className="font-xssss fw-500 d-block lh-1">Nav</span>
            </Link>
            <Link
              to="/coming"
              data-toggle="modal"
              data-target="#ModalCart"
              className={`float-right text-center mt-1 ml-4 text-grey-800 position-relative ${colorClass}`}
            >
              <i className="ti-shopping-cart font-lg"></i>
              <span className="font-xssss fw-500 d-block lh-1">Cart</span>
              <span className="icon-count bg-current">3</span>
            </Link>
            <Link
              to="course-grid-3"
              className={`float-right text-center mt-1 ml-4 text-grey-800 position-relative ${colorClass}`}
            >
              <i className="ti-heart font-lg"></i>
              <span className="font-xssss fw-500 d-block lh-1">Saved</span>
              <span className="icon-count bg-current">2</span>
            </Link>
            <Link
              to="/register"
              className={`float-right d-none d-lg-block text-center mt-1 ml-4 text-grey-800 ${colorClass}`}
            >
              <i className="ti-user font-lg"></i>
              <span className="font-xssss fw-500 d-block lh-1">Account</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
