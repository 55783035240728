import React from "react";
import { useState } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";

import AdminTopnav from "../components/AdminTopnav.js";
import Adminfooter from "../components/Adminfooter.js";
import Teachersidebar from "../components/Teachersidebar.js";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";

function TeacherDiscountVoucher() {
  TeacherProtection();
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showSecondModal, setShowSecondModal] = useState(false); // New state for the second modal

  const handleClose = () => setShowModal(false);
  const handleClose2 = () => setShowSecondModal(false);

  const handleShow = () => setShowModal(true);
  const handleSelectOption = (option) => setSelectedOption(option);

  const handleNext = () => {
    setShowModal(false);
    setShowSecondModal(true); // Open the second modal
  };

  return (
    <div id="wrapper">
      <Teachersidebar />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <AdminTopnav />

          {/* Main Content Here */}
          <div className="container-fluid p-5 bg-white border-0 mt-0 rounded-10  shadow-xs overflow-hidden ">
            {/* Heading */}
            <div className="d-flex justify-content-between align-items-center mb-4 ">
              <h1 className="fw-bold" style={{ color: "#34c38f" }}>
                Coupons
              </h1>
              <button
                className="btn btn-md"
                style={{ backgroundColor: "#34c38f", color: "white" }}
                onClick={handleShow}
              >
                <i className="fa fa-plus me-2"></i> Add New Coupon
              </button>
            </div>

            {/* Placeholder Area */}
            <div
              className="text-center"
              style={{
                minHeight: "70vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#f8f9fa",
                borderRadius: "15px",
              }}
            >
              <i
                className="far fa-file-alt fa-4x mb-3"
                style={{ color: "#adb5bd" }}
              ></i>
              <p
                className="mb-3"
                style={{ fontSize: "1.25rem", color: "#6c757d" }}
              >
                You have not created any coupons yet
              </p>
              <p style={{ fontSize: "1rem", color: "#6c757d" }}>
                You can start creating your first coupon by clicking on "Add New
                Coupon".
              </p>
              <button
                className="btn btn-md"
                style={{ backgroundColor: "#34c38f", color: "white" }}
              >
                <i className="fa fa-plus me-2"></i> Add New Coupon
              </button>
            </div>
          </div>

          <Adminfooter />
        </div>
      </div>
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton className="bg-light">
          <Modal.Title
            className="w-100 text-center"
            style={{ fontWeight: "bold", fontSize: "1.25rem" }}
          >
            What type of coupon do you want to create?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light d-flex justify-content-center align-items-center">
          <div className="d-flex flex-column flex-md-row justify-content-around w-100">
            <div
              className={`m-2 p-3 option ${
                selectedOption === "multiple" ? "selected" : ""
              }`}
              onClick={() => handleSelectOption("multiple")}
              style={{
                border:
                  selectedOption === "multiple"
                    ? "2px solid #34c38f"
                    : "1px solid #ced4da",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="i1.png"
                alt="Multiple Use"
                className="img-fluid"
                style={{ maxHeight: "300px" }}
              />
              <h5 className="mt-3">Multiple Use</h5>
              <p style={{ fontSize: "0.8rem" }}>
                Create a coupon with a code of your choice that can be used as
                many times as you want.
              </p>
            </div>
            <div
              className={`m-2 p-3 option ${
                selectedOption === "bulk" ? "selected" : ""
              }`}
              onClick={() => handleSelectOption("bulk")}
              style={{
                border:
                  selectedOption === "bulk"
                    ? "2px solid #34c38f"
                    : "1px solid #ced4da",
                borderRadius: "8px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <img
                src="i2.png"
                alt="Bulk Single Use"
                className="img-fluid"
                style={{ maxHeight: "300px" }}
              />
              <h5 className="mt-3">Bulk Single Use</h5>
              <p style={{ fontSize: "0.8rem" }}>
                Generate a CSV file with a list of unique one-time-use coupons.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bg-light">
          <Button variant="success" onClick={handleNext} className="fw-bold">
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSecondModal}
        onHide={handleClose}
        centered
        className="custom-modal2"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            Add a new product coupon
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="align-items-center">
              <Form.Label column sm={3}>
                Coupon Type
              </Form.Label>
              <Col sm={9}>
                <Row>
                  <Col sm={6}>
                    <Form.Check
                      type="radio"
                      label="Percentage"
                      name="couponType"
                      id="percentage"
                    />
                    <Form.Control type="text" placeholder="e.g. 50%" />
                  </Col>
                  <Col sm={6}>
                    <Form.Check
                      type="radio"
                      label="Amount"
                      name="couponType"
                      id="amount"
                    />
                    <Form.Control type="text" placeholder="e.g. 10" />
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center">
              <Form.Label column sm={3}>
                Pricing Plan
              </Form.Label>
              <Col sm={9}>
                <Form.Control as="select">
                  <option>Select Pricing Plan</option>
                  {/* Add other options here */}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center">
              <Form.Label column sm={3}>
                Coupon Code
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="For usage e.g. HALFOFF"
                  maxLength="40"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center">
              <Form.Label column sm={3}>
                Internal Name
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="For internal reference e.g. Early Bird Sale"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center">
              <Form.Label column sm={3}>
                Expires
              </Form.Label>
              <Col sm={4}>
                <Form.Control type="date" />
              </Col>
              <Col sm={5}>
                <Form.Control type="time" />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center">
              <Form.Label column sm={3}>
                Number Available
              </Form.Label>
              <Col sm={9}>
                <Form.Control type="number" placeholder="10000" />
              </Col>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleClose2}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default TeacherDiscountVoucher;
