import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    adminData: null,
    loggedIn: false, 
    
  },
  reducers: {
    getuser(state, action) {
      state.teacherData = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.loggedIn = true;
    },
    logoutUser: (state) => {
      state.user = null;
      state.loggedIn = false;
    },
  }
});

export const { getuser, setUser, logoutUser } = adminSlice.actions;
export default adminSlice.reducer;
