import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';

const CountdownTimer = ({ targetDate , setCheckStatus}) => {
  const calculateTimeLeft = () => {
    // Convert target date to milliseconds
    const targetTime = new Date(targetDate).getTime();
    
    // Get current date in Pakistan Standard Time (UTC +5)
    const now = new Date(new Date().getTime() + 5 * 3600 * 1000);
    
    // Calculate time difference
    const difference = targetTime - now.getTime();
    
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    setCheckStatus(timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0 || !(Object.keys(timeLeft).length) )
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTimeLeft = () => {
    const { days, hours, minutes, seconds } = timeLeft;
    return `The live class is about to start in ${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
  };

  return (
    <div>
      {Object.keys(timeLeft).length ? (

        <div className='flex flex-row justify-center items-center gap-4'>
          <div className='btn btn-primary h-8 w-16 p-2 mr-2 '> {timeLeft.days} Days</div>
          <div className='btn btn-primary h-8 w-16 p-2 mr-2 '> {timeLeft.hours} Hours</div>
          <div className='btn btn-primary h-8 w-16 p-2 mr-2 '> {timeLeft.minutes} Minutes</div>
          <div className='btn btn-primary h-8 w-16 p-2 mr-2 '> {timeLeft.seconds} Seconds</div>
        </div>
      ) : (
        <div className='flex flex-row justify-center items-center gap-4'>
          <div className='btn btn-primary h-8 w-16 p-2 mr-2 '> Countdown Completed</div>
          
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;




// import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography';

// const CountdownTimer = ({ targetDate }) => {
//   const calculateTimeLeft = () => {
//     // Convert target date to milliseconds
//     const targetTime = new Date(targetDate).getTime();
    
//     // Get current date in Pakistan Standard Time (UTC +5)
//     const now = new Date(new Date().getTime() + 5 * 3600 * 1000);
    
//     // Calculate time difference
//     const difference = targetTime - now.getTime();
    
//     let timeLeft = {};

//     if (difference > 0) {
//       timeLeft = {
//         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         minutes: Math.floor((difference / 1000 / 60) % 60),
//         seconds: Math.floor((difference / 1000) % 60)
//       };
//     }

//     return  timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearTimeout(timer);
//   });

//   const timerComponents = [];

//   Object.keys(timeLeft).forEach((interval) => {
//     if (!timeLeft[interval]) {
//       return;
//     }

//     timerComponents.push(
//       <Typography variant="h6" component="span" key={interval}>
//         {timeLeft[interval]} {interval}{' '}
//       </Typography>
//     );
//   });

//   return (
//     <div>
//       {timerComponents.length ? (
//         timerComponents
//       ) : (
//         <Typography variant="h6" component="span">Countdown completed!</Typography>
//       )}
//     </div>
//   );
// };

// export default CountdownTimer;
