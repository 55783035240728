import React from "react";

const Navigation = () => {
  return (
    <div className="main-wrap">
      <div className="row">
        <div
          className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
          style={{
            backgroundImage: `url("/assets/images/log.jpg")`,
          }}
        ></div>

        <div
          className="col-xl-7 vh-lg-100 align-items-center d-flex rounded-lg overflow-hidden"
          style={{ backgroundColor: "#e8f1fa" }}
        >
          <div
            className="card shadow-none border-0 ml-auto mr-auto login-card"
            style={{ backgroundColor: "#e8f1fa" }}
          >
            <div className="card-body rounded-0 text-left">
              <div className="col-sm-12 p-0 text-left">
                <div className="form-group mb-1">
                  <a
                    href="/login"
                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                  >
                    Student
                  </a>
                </div>
                <div className="form-group mb-1">
                  <a
                    href="/login-teacher"
                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                  >
                    Teacher
                  </a>
                </div>
                {/* Admin should interact from other url i-e "/admin" */}
                {/* <div className="form-group mb-1">
                  <a
                    href="/admin"
                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 "
                  >
                    Admin
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
