// src/pages/Teacher.js
import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { getEarnings } from "../services/teacherServices/TeacherServices.js";
import Teachersidebar from "../components/Teachersidebar.js";
import TeacherTopnav from "../components/TeacherTopNav.js";
import Adminfooter from "../components/Adminfooter.js";
import UserDialog from "../components/DialogBox.js";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";
import httpClient from "../services/http-common.js";

function Teacher() {
  TeacherProtection();
  const [teacherData, setTeacherData] = useState({});

  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");

  const [withdrawalData, setWithdrawalData] = useState({
    currency: "",
    country: "",
    status: "Pending",
    bankName: "",
    bankAccountNumber: "",
    bankBranch: "",
    routingNumber: "",
    swiftCode: "",
    iban: "",
    recipientAddress: "",
    bankAddress: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWithdrawalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchEarnings = async () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const response = await getEarnings(token);
    console.log(response);

    if (response.status === 200) {
      setTeacherData(response.data);
      console.log(response.data);
    } else {
      console.log("Error");
    }
  };
  const createPayout = async () => {
    event.preventDefault();

    // if (!stripe || !elements) {
    //   console.error("Stripe.js has not loaded");
    //   return;
    // }

    // const cardElement = elements.getElement(CardElement);
    // console.log(cardElement);
    // if (!cardElement) {
    //   console.error("Card Element not found");
    //   return;
    // }

    // const { error, paymentMethod } = await stripe.createPaymentMethod({
    //   type: "card",
    //   card: cardElement,
    //   billing_details: {
    //     email,
    //   },
    // });
    // console.log(paymentMethod);

    if (false) {
      console.error(error);
    } else {
      try {
        // const { error, token } = await stripe.createToken(cardElement);
        console.log(withdrawalData);
        const response = await httpClient.post(
          "/stripe/create-payout",
          {
            paymentMethodId: "12515166176372727",
            amountPKR: withdrawAmount,
            withdrawalData: withdrawalData,
          },
          {
            headers: {
              "x-auth-token": JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        if (response.status === 200) {
          console.log("Payout created successfully");
          setWithdrawalData({
            currency: "",
            country: "",
            status: "Pending",
            bankName: "",
            bankAccountNumber: "",
            bankBranch: "",
            routingNumber: "",
            swiftCode: "",
            iban: "",
            recipientAddress: "",
            bankAddress: "",
          });
          fetchEarnings();
        } else {
          console.error("Error creating payout");
          window.alert("Error creating payout");
        }
      } catch (error) {
        console.error("Server Error:", error);
      }
    }
  };
  useEffect(() => {
    fetchEarnings();
  }, []);

  const handlePayoutSubmit = async (event) => {
    await createPayout(event, email, stripe, elements, withdrawAmount);
  };

  return (
    <Fragment>
      <UserDialog
        role="teacher"
        message="Get ready to embark on a journey of teaching and growing!"
      />
      <div id="wrapper">
        <Teachersidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner">
                        <h6>MONTHLY REVENUE</h6>
                        <h4 className="instructor-text-success">
                          PKR {teacherData.earningThisMonth}
                        </h4>
                        <p>Earning this month</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner">
                        <h6>STUDENTS ENROLLMENTS</h6>
                        <h4 className="instructor-text-info">
                          {teacherData.totalEnrollments}
                        </h4>
                        <p>All Time Enrollments</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex">
                  <div className="card instructor-card w-100">
                    <div className="card-body">
                      <div className="instructor-inner">
                        <h6>TOTAL REVENUE</h6>
                        <h4 className="instructor-text-success">
                          PKR {teacherData.totalEarnings}
                        </h4>
                        <p>All Time Earnings</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card instructor-card">
                    <div className="card-header">
                      <h4>Earnings</h4>
                    </div>
                    <div
                      className="row"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div className="col-md-6 d-flex">
                        <div className="card instructor-card w-100">
                          <div className="card-body">
                            <div className="instructor-inner">
                              <h6>EARNINGS AVAILABLE TO WITHDRAW</h6>
                              <h4 className="instructor-text-success">
                                PKR {teacherData.totalEarningsInstructor && teacherData.totalEarningsInstructor.toFixed(3)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 d-flex">
                        <div className="card instructor-card w-100">
                          <div className="card-body">
                            <div className="instructor-inner">
                              <h6>EARNINGS WITHDRAWN</h6>
                              <h4 className="instructor-text-success">
                                PKR {teacherData.totalEarningsWithdrawn && teacherData.totalEarningsWithdrawn.toFixed(3)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row p-4">
                      {/* <Button
                        className="text-white col-md-3  p-2 pl-4 pr-4"
                        style={{
                          backgroundColor: "#09ac2c",
                          borderColor: "#09ac2c",
                        }}
                      >
                        Withdraw
                      </Button> */}

                      <div className="col-md-12 mt-4">
                        <form
                          onSubmit={handlePayoutSubmit}
                          className="flex-row flex d-flex col-md-12"
                          style={{ flexWrap: "wrap" }}
                        >
                          <div className="col-md-4 p-2">
                            <label>Amount (PKR):</label>
                            <input
                              type="number"
                              placeholder="Enter Amount in PKR"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              onChange={(e) =>
                                setWithdrawAmount(e.target.value)
                              }
                              required
                            />
                          </div>
                          <div className="col-md-4 p-2">
                            <label>Email:</label>
                            <input
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Email"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              required
                            />
                          </div>
                          {/* <CardElement /> */}

                          <div className="col-md-4 p-2">
                            <label>Local Currency:</label>
                            <input
                              type="text"
                              name="currency"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              value={withdrawalData.currency}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <div className="col-md-4 p-2">
                            <label>Country:</label>
                            <select
                              name="country"
                              value={withdrawalData.country}
                              onChange={handleChange}
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              required
                            >
                              <option value="">Select Country</option>
                              <option value="pakistan">Pakistan</option>
                              <option value=" ">Other</option>
                            </select>
                          </div>
                          {withdrawalData.country && (
                            <>
                              <div className="col-md-4 p-2">
                                <label>Bank Name:</label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="bankName"
                                  value={withdrawalData.bankName}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-4 p-2">
                                <label>Bank Account Number:</label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="bankAccountNumber"
                                  value={withdrawalData.bankAccountNumber}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              <div className="col-md-4 p-2">
                                <label>Bank Branch Name:</label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="bankBranch"
                                  value={withdrawalData.bankBranch}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="col-md-4 p-2">
                                <label>
                                  Routing Number (Sort code, IFSC code, or ABA
                                  number):
                                </label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="routingNumber"
                                  value={withdrawalData.routingNumber}
                                  onChange={handleChange}
                                  required
                                />
                              </div>
                              {withdrawalData.country !== "pakistan" && (
                                <>
                                  <div className="col-md-4 p-2">
                                    <label>Country:</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="country"
                                      value={withdrawalData.country}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>SWIFT Code (or BIC):</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="swiftCode"
                                      value={withdrawalData.swiftCode}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>
                                      IBAN (International Bank Account Number):
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="iban"
                                      value={withdrawalData.iban}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>Recipient Address:</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="recipientAddress"
                                      value={withdrawalData.recipientAddress}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>Bank Address:</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="bankAddress"
                                      value={withdrawalData.bankAddress}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          <Button
                            className="text-white col-md-12 p-2 pl-4 pr-4"
                            style={{
                              backgroundColor: "#09ac2c",
                              borderColor: "#09ac2c",
                            }}
                            type="submit"
                            disabled={
                              withdrawAmount >
                                teacherData.totalEarningsInstructor ||
                              withdrawAmount <= 0
                            }
                          >
                            Create Payout Request
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Best Selling Courses</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          <table className="table table-nowrap">
                            <thead>
                              <tr>
                                <th>COURSES</th>
                                <th>SALES</th>
                                <th>AMOUNT</th>
                              </tr>
                            </thead>
                            <tbody>
                              {teacherData.topCoursesSorted &&
                                teacherData.topCoursesSorted.map((course) => {
                                  return (
                                    <tr key={course.course._id}>
                                      <td>
                                        <div className="sell-table-group d-flex align-items-center">
                                          <div className="sell-group-img">
                                            <a href="course-details.html">
                                              <img
                                                src={
                                                  course.course.thumbnail ||
                                                  "assets/img/course/course-15.jpg"
                                                }
                                                className="img-fluid"
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                          <div className="sell-tabel-info">
                                            <p>
                                              <a href="course-details.html">
                                                {course.course.title}
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>{course.enrollments}</td>
                                      <td>PKR {course.totalEarningsCourse}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="settings-widget">
                    <div className="settings-inner-blk p-0">
                      <div className="sell-course-head comman-space">
                        <h3>Widhrawal Request</h3>
                      </div>
                      <div className="comman-space pb-0">
                        <div className="settings-tickets-blk course-instruct-blk table-responsive">
                          <table className="table table-nowrap">
                            <thead>
                              <tr>
                                <th className="text-center">Amount</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Local Currency</th>
                              </tr>
                            </thead>
                            <tbody>
                              {teacherData.withdrawalrequests &&
                                teacherData.withdrawalrequests.map(
                                  (withdraw) => {
                                    return (
                                      <tr key={withdraw._id}>
                                        <td className="text-center">PKR {withdraw.amount}</td>
                                        <td className="text-center">{withdraw.status.toUpperCase()}</td>
                                        <td className="text-center">{new Date(withdraw.createdAt).toLocaleDateString()}</td>
                                        <td className="text-center">{withdraw.currency}</td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Teacher;
