import httpClient from "../http-common";

export const updatePassword = (currentPassword, newPassword, userType) => {
  console.log("currentPassword", currentPassword);
  let token = JSON.parse(localStorage.getItem("token"));
  console.log("token", token);
  return httpClient.post(
    `credentials/passwordChange`,
    {
      currentPassword: currentPassword,
      newPassword: newPassword,
      userType: userType,
    },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getAccountInfo = (userType) => {
  let token = JSON.parse(localStorage.getItem("token"));
  return httpClient.post(
    `credentials/getUserByType`,
    {
      userType: userType,
    },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};


export const updateAccountInfo = (data, userType) => {
  let token = JSON.parse(localStorage.getItem("token"));
  return httpClient.post(
    `credentials/updateAccountInfo`,
    {
      ...data,
      userType: userType,
    },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
}


export const getTeacherById = (id) => {
  return httpClient.get(`credentials/teacherByID/${id}`,{
    headers: {
      "x-auth-token": JSON.parse(localStorage.getItem("token")),
    },
  });
}