import React, { Fragment } from "react";

import Teachersidebar from "../components/Teachersidebar";
import TeacherTopnav from "../components/TeacherTopNav";
import Teacherfooter from "../components/Teacherfooter";
import "./certificate.css";
import { TeacherProtection } from "../CustomHooks/TeacherProtection";

function Certificate() {
  TeacherProtection();
  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="row bg-white">
              <div className="col-md-3 d-flex align-items-start justify-content-center p-3 mt-3">
                <button
                  className="btn btn-md"
                  style={{ backgroundColor: "#34c38f", color: "white" }}
                >
                  Add New Coupon
                </button>
              </div>

              <div className="col-md-9 mt-4">
                <div className="container pm-certificate-container">
                  <div className="outer-border"></div>
                  {/* <div className="inner-border"></div> */}

                  <div className="pm-certificate-border col-xs-12">
                    <div className="row pm-certificate-header heading-c">
                      <div className="pm-certificate-title cursive col-xs-12 text-center">
                        <h2 className="title-c">Seekho.pk</h2>
                      </div>
                    </div>

                    <div
                      className="row pm-certificate-body"
                      style={{ marginTop: "-45px" }}
                    >
                      <div className="pm-certificate-block">
                        <div className="col-xs-12">
                          <div className="row middle-c">
                            <div className="col-xs-2"></div>
                            <div className="pm-certificate-name underline margin-0 col-xs-8 text-center">
                              <span className="pm-name-text bold">
                                Subhan Shahzad{" "}
                              </span>
                            </div>
                            <div className="col-xs-2"></div>
                          </div>
                        </div>

                        <div className="col-xs-12">
                          <div className="row middle-c">
                            <div className="col-xs-2"></div>
                            <div className="pm-earned col-xs-8 text-center">
                              <span className="pm-earned-text padding-0 block cursive">
                                has earned
                              </span>
                              <span className="pm-credits-text block bold sans">
                                PD175: 1.0 Credit Hours
                              </span>
                            </div>
                            <div className="col-xs-2"></div>
                            <div className="col-xs-12"></div>
                          </div>
                        </div>

                        <div className="col-xs-12">
                          <div className="row middle-c">
                            <div className="col-xs-2"></div>
                            <div className="pm-course-title col-xs-8 text-center">
                              <span className="pm-earned-text block cursive">
                                while completing the training course entitled
                              </span>
                            </div>
                            <div className="col-xs-2"></div>
                          </div>
                        </div>

                        <div className="col-xs-12">
                          <div className="row middle-c">
                            <div className="col-xs-2"></div>
                            <div className="pm-course-title underline col-xs-8 text-center">
                              <span className="pm-credits-text block bold sans">
                                MERN Stack: Beginner to Expert, All in one
                                course
                              </span>
                            </div>
                            <div className="col-xs-2"></div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xs-12">
                        <div className="row">
                          <div
                            className="pm-certificate-footer"
                            style={{
                              display: "flex",
                              flexWrap: "initial",
                              marginTop: "220px",
                            }}
                          >
                            <div
                              className="col-xs-4 pm-certified col-xs-4 text-center"
                              style={{
                                marginLeft: "-130px",
                              }}
                            >
                              <span className="pm-credits-text block sans">
                                Gaurav City School District
                              </span>
                              <span className="pm-empty-space block underline"></span>
                              <span className="bold block">
                                Crystal Benton Instructional <br />
                                Specialist II, Staff Development
                              </span>
                            </div>
                            <div className="col-xs-4"></div>
                            <div
                              className="col-xs-4 pm-certified col-xs-4 text-center"
                              style={{
                                marginLeft: "320px",
                              }}
                            >
                              <span className="pm-credits-text block sans">
                                Date Completed
                              </span>
                              <span className="pm-empty-space block underline"></span>
                              <span className="bold block">DOB: </span>
                              <span className="bold block">
                                Social Security # (last 4 digits)
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Teacherfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Certificate;
