import React, { Fragment, useEffect, useState } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Darkmode from "../components/Darkmode";
import Subscribe from "../components/Subscribe";
import { Link } from "react-router-dom";
import {
  getAccountInfo,
  updateAccountInfo,
} from "../services/credentials/credentialsChange";
import Teachersidebar from "../components/Teachersidebar.js";
import TeacherTopnav from "../components/TeacherTopNav.js";

const TeacherAccountInfo = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact_number: "",
    country: "",
    address: "",
    city: "",
    postalCode: "",
    description: "",
    userType: "teacher", // or "teacher"
    domain_of_study: "", // specific to teacher
    years_of_experience: "", // specific to teacher
    github: "",
    linkedin: "",
    youtube: "",
  });

  const fetchAccountInfo = async () => {
    const response = await getAccountInfo(formData.userType);

    if (response.status === 200) {
      const data = response.data;
      setFormData({
        ...formData,
        name: data.name,
        email: data.email,
        contact_number: data.contact_number,
        country: data.country,
        address: data.address,
        city: data.city,
        postalCode: data.postalCode,
        description: data.description,
        domain_of_study: data.domain_of_study,
        years_of_experience: data.years_of_experience,
        github: data.github,
        linkedin: data.linkedin,
        youtube: data.youtube,
      });
    } else {
      console.log("Error in fetching account info");
    }
  };

  const updateAccountInfoFunc = async () => {
    const response = await updateAccountInfo(formData, formData.userType);
    if (response.status === 200) {
      console.log("Account info updated successfully");
    } else {
      console.log("Error in updating account info");
    }
  };

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  return (
    <Fragment>
      <div className="main-wrapper">
        <Teachersidebar />

        <div className="main-content">
          <TeacherTopnav />

          <div className="middle-sidebar-bottom bg-lightblue theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div
                    className="card-body p-4 w-100  border-0 d-flex rounded-lg"
                    style={{
                      backgroundColor: "#00CC52",
                    }}
                  >
                    <Link to="/teacher-setting" className="d-inline-block mt-2">
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>

                    <h4 className="font-xs text-white fw-600 ml-4 mb-0 mt-2">
                      Account Details
                    </h4>
                  </div>
                  <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                    <div className="row justify-content-center">
                      <div className="col-lg-4 text-center">
                        <figure className="avatar ml-auto mr-auto mb-0 mt-2 w100">
                          <img
                            src="https://via.placeholder.com/150x150.png"
                            alt="avater"
                            className="shadow-sm rounded-lg w-100"
                          />
                        </figure>
                        <h2 className="fw-700 font-sm text-grey-900 mt-3">
                          {formData.name}
                        </h2>
                        <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">
                          {formData.country}
                        </h4>
                      </div>
                    </div>

                    <form action="#">
                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.name}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              E-mail
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.email}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  email: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Contact Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.contact_number}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  contact_number: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Country
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.country}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  country: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.address}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  address: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              City
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.city}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  city: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Postal Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.postalCode}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  postalCode: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Domain of Study
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.domain_of_study}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  domain_of_study: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Years of Experience
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.years_of_experience}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  years_of_experience: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Github
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.github}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  github: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              linkedIn
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.linkedin}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  linkedin: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Youtube
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData.youtube}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  youtube: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>

                        {/* <div className="col-lg-12 mb-3">
                          <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                              <div className="form-group mb-0 w-100">
                                <input
                                  type="file"
                                  name="file"
                                  id="file"
                                  className="input-file"
                                />
                                <label
                                  htmlFor="file"
                                  className="rounded-lg text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                >
                                  <i className="ti-cloud-down large-icon mr-3 d-block"></i>
                                  <span className="js-fileName">
                                    Drag and drop or click to replace
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-lg-12 mb-3">
                          <label className="mont-font fw-600 font-xsss">
                            Description
                          </label>
                          <textarea
                            className="form-control mb-0 p-3 bg-greylight lh-16"
                            rows="5"
                            placeholder="Write your message..."
                            value={formData.description}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                description: e.target.value,
                              });
                            }}
                          ></textarea>
                        </div>

                        <div className="col-lg-12">
                          <button
                            className=" text-center text-white font-xsss fw-600 p-3 w175 rounded-lg d-inline-block"
                            onClick={updateAccountInfoFunc}
                            style={{
                              backgroundColor: "#00CC52",
                              border: "none",
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                <Profile />
                <Darkmode />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default TeacherAccountInfo;
