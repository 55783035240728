import React, { Fragment, useEffect, useState } from "react";
import { getFeaturedLiveCoursesForAUser } from "../services/userServices/FeaturedServices";
import { Link } from "react-router-dom";

const classList = [
  {
    imageUrl: "user.png",
    title: "Advanced Python Sass",
    per: "87",
    status: "bg-warning",
  },
  {
    imageUrl: "user.png",
    title: "Bootstrap SASS CSS ",
    per: "96",
    status: "bg-success",
  },
  {
    imageUrl: "user.png",
    title: "Basic JAVA",
    per: "95",
    status: "bg-primary",
  },
  {
    imageUrl: "user.png",
    title: "React JS",
    per: "55",
    status: "bg-warning",
  },
];

const Myclass = () => {
  const [liveCourses, setLiveCourses] = useState([]);
  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const fetchLiveCourses = async () => {
    try {
      await getFeaturedLiveCoursesForAUser(token)
        .then((response) => {
          setLiveCourses(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchLiveCourses();
  }, []);
  return (
    <div className="card theme-light-bg overflow-hidden rounded-xxl border-0 mb-3">
      <div className="card-body d-flex justify-content-between align-items-end pl-4 pr-4 pt-4 pb-3">
        <h4 className="fw-700 font-xsss">My Class</h4>
        <a href="/" className="position-absolute right-0 mr-4">
          <i className="ti-more-alt text-grey-500 font-xs"></i>
        </a>
      </div>
      {liveCourses.length > 0 &&
        liveCourses.map((value, index) => (
          <div
            className="card-body pl-4 pr-4 pt-0 pb-3 border-0 w-100 d-block"
            key={index}
          >
            <div className="row">
              <div className="col-3 p-0">
                <Link
                  to={`/default-live-stream/${value._id}`}
                  className="btn-round-lg rounded-lg bg-lightblue ml-3 border-0"
                >
                  <img
                    src={`../assets/images/course-4.jpg`}
                    alt="icon"
                    className="p-1 w-100"
                  />
                </Link>
              </div>
              <div className="col-9 pr-3">
                <Link to={`/default-live-stream/${value._id}`}>
                  <h4 className="font-xs d-block fw-700 mt-2">
                    {value.title}
                    <span className="live-tag ml-2  bg-danger p-1 z-index-1 rounded-lg text-white font-xsssss text-uppersace fw-700 ls-3">
                      LIVE
                    </span>
                  </h4>{" "}
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default Myclass;
