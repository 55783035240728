import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { truncateTitle } from "../utils/utilityMethods";
import ReactStars from "react-rating-stars-component";
import { reviewByCourse } from "../services/statistics/statistics";

export default function CourseCard(props) {
  const { value } = props;
  const { bought } = props;
  const { teacher } = props;
  const { homepage } = props;
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    reviewByCourse(value._id).then((response) => {
      setReviews(response.data);
      const avgRating =
        response.data.reduce((acc, review) => acc + review.rating, 0) /
          response.data.length || 0;
      setAverageRating(avgRating);
    });
  }, [value._id]);

  return (
    <div className="col-lg-4 col-md-6 d-flex">
      <div className="course-box d-flex aos">
        <div className="product">
          <div className="product-img">
            <div className="video-bttn position-relative d-block">
              <img
                className="img-fluid"
                style={{
                  height: "200px",
                  width: "300px",
                }}
                alt=""
                src={`${value.thumbnail}`}
              />
            </div>
            {/* <div className="price" >
              <h3>PKR {value.price}</h3>
            </div> */}
            <div
              className="price"
              style={{
                scale: "0.75",
                right: "0",
                bottom: "0",
              }}
            >
              <h3>PKR {value.price}</h3>
            </div>
          </div>
          <div className="product-content">
            <div className="course-group d-flex">
              <div className="course-group-img d-flex">
                <div className="course-name">
                  <h4>{truncateTitle(value.domain_of_study)}</h4>
                </div>
              </div>
              <div className="course-share d-flex align-items-center justify-content-center">
                <a href="#">
                  <i className="fa-regular fa-heart"></i>
                </a>
              </div>
            </div>
            <h3 className="title instructor-text">
              {!bought && !teacher ? (
                <Link
                  to={`/course/${value._id}`}
                  className="text-dark text-grey-900 capitalize"
                >
                  {value.title.slice(0, 20) + "..."}
                </Link>
              ) : !teacher ? (
                <Link
                  to={`/stream-course/${value._id}`}
                  className="text-dark text-grey-900 capitalize"
                >
                  {value.title.slice(0, 20) + "..."}
                </Link>
              ) : teacher && bought ? (
                <Link
                  to={`/teacher-stream-course/${value._id}`}
                  className="text-dark text-grey-900 capitalize"
                >
                  {value.title.slice(0, 20) + "..."}
                </Link>
              ) : (
                <div></div>
              )}
            </h3>
            <div className="course-info d-flex align-items-center">
              {/* <div className="rating-img d-flex align-items-center">
              <img src="assets/img/icon/icon-01.svg" alt="" />
              <p>12+ Lesson</p>
            </div>
            <div className="course-view d-flex align-items-center">
              <img src="assets/img/icon/icon-02.svg" alt="" />
              <p>9hr 30min</p>
            </div> */}
            </div>
            <div className="rating">
              {averageRating !== 0 && (
                <ReactStars
                  count={5}
                  value={averageRating.toFixed(1)}
                  size={24}
                  activeColor="#ffd700"
                  isHalf={true}
                  edit={false}
                />
              )}
              {averageRating == 0 && (
                <ReactStars
                  count={5}
                  value={averageRating.toFixed(1)}
                  size={24}
                  activeColor="#ffd700"
                  isHalf={true}
                  edit={false}
                />
              )}
              <span className="d-inline-block average-rating">
                <span>{averageRating.toFixed(1)}</span> ({reviews.length})
              </span>
            </div>
            {!bought && (
              <div className="all-btn all-category d-flex align-items-center">
                {!props.homePage ? <Link to={`/course/${value._id}`} className="btn btn-primary">
                  BUY NOW
                </Link> : <Link to={`/login`} className="btn btn-primary">
                  BUY NOW
                </Link>}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
