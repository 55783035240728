import React, { useState, Fragment, useEffect } from "react";
// import { Modal, Button } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import Pagination from "../components/Pagination";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { formatDate } from "../utils/formatDate";

import {
  getAllApprovedInstructors,
  getWithdrawalRequests,
  updateWithdrawalRequest,
} from "../services/adminServices/AdminServices";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { Plus, PlusCircleIcon, Trash, Trash2, X } from "lucide-react";
import {
  addCategory,
  deleteCategory,
  getCategories,
} from "../services/categoryServices/categoryServices";
function AdminCategory() {
  //   const [location, setLocation] = useState(false);
  const [open, setOpen] = useState(false);
  const [detailedViewID, setDetailedViewID] = useState("");

  const [cateforyData, setCategoryData] = useState({
    name: "",
    description: "",
    imageURL: "",
  });

  const [courseCategories, setCourseCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 200) {
        setCourseCategories(response.data);
        console.log(response.data);
      } else {
        console.log("Error in fetching categories");
        alert("Error in fetching categories");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Admin | Seekho Instructors ";

    fetchCategories();
  }, []);

  //   const handleModel = () => {
  //     setLocation(!location);
  //   };
  return (
    <Fragment>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div
                className="d-flex flex-row justify-center items-center"
                style={{ justifyContent: "center", alignItems: "start" }}
              >
                {detailedViewID !== "" && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      //   width: "500px",
                      //   height: "500px",
                      zIndex: "10",
                      padding: "20px",
                    }}
                    className="col-md-9 h-full"
                  >
                    <div
                      className="d-flex flex-row col-md-12"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Categories</div>
                      <div
                        className="curson-pointer text-danger"
                        onClick={() => {
                          setDetailedViewID("");
                        }}
                      >
                        <X />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-4">
                        <form
                          className="flex-row flex d-flex col-md-12"
                          style={{ flexWrap: "wrap" }}
                        >
                          <div className="col-md-4 p-2">
                            <label>Name:</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="Enter Name of Category"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              value={cateforyData.name}
                              onChange={(e) => {
                                setCategoryData({
                                  ...cateforyData,
                                  name: e.target.value,
                                });
                              }}
                              required
                            />
                          </div>

                          <div className="col-md-4 p-2">
                            <label>Description:</label>
                            <input
                              type="text"
                              name="description"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              onChange={(e) => {
                                setCategoryData({
                                  ...cateforyData,
                                  description: e.target.value,
                                });
                              }}
                              placeholder="Enter Description"
                              value={cateforyData.description}
                              required
                            />
                          </div>
                          <div className="col-md-4 p-2">
                            <label>imageURL:</label>
                            <input
                              type="text"
                              name="imageURL"
                              placeholder="Enter Image URL"
                              onChange={(e) => {
                                setCategoryData({
                                  ...cateforyData,
                                  imageURL: e.target.value,
                                });
                              }}
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              value={cateforyData.imageURL}
                              required
                            />
                          </div>

                          <Button
                            className="text-white col-md-12 p-2 pl-4 pr-4"
                            style={{
                              backgroundColor: "#09ac2c",
                              borderColor: "#09ac2c",
                            }}
                            onClick={async () => {
                              const token = JSON.parse(
                                localStorage.getItem("token")
                              );
                              console.log(cateforyData);
                              const response = await addCategory(
                                cateforyData.name,
                                cateforyData.description,
                                cateforyData.imageURL
                              );
                              console.log(response);
                              if (response.status === 200) {
                                fetchCategories();
                                setDetailedViewID("");
                              } else {
                                console.log("Error Creating Category");
                                alert("Error Creating Category");
                              }
                            }}
                          >
                            Create Category
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div
                      className="card-body d-flex p-4 pb-0 justify-between"
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Categories
                      </h4>
                      <PlusCircleIcon
                        onClick={() => {
                          if (detailedViewID === "") {
                            setDetailedViewID("add");
                          } else {
                            setDetailedViewID("");
                          }
                        }}
                      />
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0" scope="col">
                                Name
                              </th>
                              <th className="border-0" scope="col">
                                Description
                              </th>
                              <th className="border-0" scope="col">
                                Created At
                              </th>
                              <th className="border-0" scope="col">
                                Image URL
                              </th>

                              <th scope="col" className="text-end border-0">
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {courseCategories.length > 0 &&
                              courseCategories.map((value) => (
                                <tr key={value?._id}>
                                  <td>
                                    <b> {value?.name}</b>
                                  </td>
                                  <td>{value.description}</td>

                                  <td>
                                    <b>
                                      {new Date(
                                        value.createdAt
                                      ).toLocaleDateString()}
                                    </b>
                                  </td>

                                  <td>
                                    <span>{value.imageURL}</span>
                                  </td>

                                  <td className="product-remove text-end">
                                    <button
                                      className="bg-transparent border-0"
                                      onClick={async () => {
                                        courseCategories.splice(
                                          courseCategories.indexOf(value),
                                          1
                                        );

                                        setCourseCategories([...courseCategories]);
                                        

                                        await deleteCategory(value._id);
                                      }}
                                    >
                                      <i className="me-1 font-xs text-danger">
                                        <Trash2 />
                                      </i>
                                    </button>
                                    {/* <Button
                                        className="bg-transparent border-0"
                                        onClick={() => {
                                          handleModel();
                                        }}
                                      >
                                        <i className="ti-trash  font-xs text-danger"></i>
                                      </Button> */}
                                    {/* <Modal
                                        size="sm"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        show={location}
                                      >
                                        <Button
                                          onClick={() => {
                                            handleModel();
                                          }}
                                          className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                        ></Button>
                                        <Modal.Body className="text-center p-4">
                                          <i className="ti-info-alt text-warning display4-size"></i>
                                          <p className="text-grey-500 font-xsss mt-3 mb-4">
                                            Are you sure you want to delete product?{" "}
                                          </p>
    
                                          <Button
                                            onClick={() => {
                                              handleModel();
                                            }}
                                            className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                          >
                                            Yes, delete!{" "}
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              handleModel();
                                            }}
                                            className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                          >
                                            No, cancle!
                                          </Button>
                                        </Modal.Body>
                                      </Modal> */}
                                  </td>
                                </tr>
                              ))}
                            {courseCategories.length === 0 ? (
                              <tr>
                                <td>No Applicant</td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default AdminCategory;
