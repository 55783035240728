import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Badge, Button, Modal, Ratio } from "react-bootstrap";

// Services
import {
  deletePartOfModule,
  deleteModule,
} from "../../services/teacherServices/TeacherServices";
import {
  setCurrentModuleID,
  setModuleData,
  removeModuleById,
} from "../../Redux/courseSlice";

const ProgressComponent = (props) => {
  const modules = useSelector((state) => state.course.moduleData);
  const currentModuleID = useSelector((state) => state.course.currentModuleID);
  const [expandedModule, setExpandedModule] = useState(null);
  const [currentModuleIDs, setCurrentModuleIDs] = useState(currentModuleID);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [title, setTitle] = useState(true);
  const [videoURL, setVideoURL] = useState(true);

  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const handleChange = (moduleID) => (event, isExpanded) => {
    setExpandedModule(isExpanded ? moduleID : null);
    setCurrentModuleIDs(moduleID);
    dispatch(setCurrentModuleID(moduleID));
    // console.log(moduleID)
  };
  const dispatch = useDispatch();

  const handleDeletePartOfModule = (partIDe) => async () => {
    const moduleID = currentModuleIDs;
    const partID = partIDe;
    setLoading(true);
    try {
      const response = await deletePartOfModule(token, moduleID, partID);
      console.log(response.data.module);
      dispatch(setModuleData(response.data.module));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const openModal = async (title, video) => {
    setFullscreen(false);
    setTitle(title);
    setVideoURL(video);
    setShow(true);
  };

  function closeModal() {
    setShow(false);
  }

  const handleDeleteModule = (moduleID) => async () => {
    setLoading(true);
    try {
      const response = await deleteModule(token, moduleID);
      console.log(response.data);
      dispatch(removeModuleById(moduleID));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const renderParts = (module) => {
    if (module.parts.length === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body1">
            At least one item should be added in the initialized module.
          </Typography>
          <Button
            onClick={handleDeleteModule(module._id)}
            className="bg-danger opacity-75"
          >
            <DeleteIcon /> Delete this module
          </Button>
        </div>
      );
    }
    return module.parts.map((part, index) => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {part.type === "video" && (
          <div
            key={part._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#4CAF50",
            }}
          >
            <Typography
              variant="h6"
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "normal",
              }}
            >
              <OndemandVideoIcon
                style={{ marginRight: "8px", marginLeft: "8px" }}
              />
              {part.title}
            </Typography>
            <Button
              onClick={() => openModal(part?.title, part?.video?.url)}
              // target="_blank"
              // rel="noreferrer"
              style={{ marginLeft: "20px" }}
            >
              Preview
            </Button>
          </div>
        )}
        {part.type === "assignment" && (
          <div
            key={part._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <AssignmentIcon style={{ marginRight: "8px" }} />
              {part?.assignment?.heading}
            </Typography>
          </div>
        )}

        {part.type === "quiz" && (
          <div
            key={part._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <QuizIcon style={{ marginRight: "8px" }} />
              {part.title}
            </Typography>
          </div>
        )}

        <Button
          onClick={handleDeletePartOfModule(part._id)}
          className="bg-danger opacity-75"
        >
          <DeleteIcon />
        </Button>
      </div>
    ));
  };

  return (
    <fieldset
      className="field-card "
      style={{
        width: "1000px",
      }}
    >
      <div className="add-course-info">
        <div className="add-course-inner-header">
          <h4
            style={{
              color: "#4CAF50",
            }}
          >
            Curriculum
          </h4>
        </div>
        {/* <div className="add-course-section">
                      <a href="javascript:void(0);" className="btn">
                        Add Section
                      </a>
                    </div> */}
        <div className="add-course-form">
          {modules.map((module, index) => (
            <div key={module._id} className="curriculum-grid">
              <div className="curriculum-head">
                <p>{module.title}</p>
                {!props.preview && (
                  <button
                    onClick={() => {
                      dispatch(setCurrentModuleID(module._id));

                      props.handleContentButtonClick(module._id);
                      props.handleLectureAdd();
                    }}
                    className="btn"
                    style={{
                      backgroundColor: "#4CAF50",
                      color: "#ffffff",
                      borderColor: "#4CAF50",
                    }}
                  >
                    Add Video Lecture
                  </button>
                )}
              </div>
              <div className="curriculum-info">
                <div id="accordion">
                  {module.parts.map((part, index) => {
                    return (
                      <div key={index} className="faq-grid">
                        <div className="faq-header">
                          <a
                            className="collapsed faq-collapse"
                            data-bs-toggle="collapse"
                            href={"#collapseOne" + part._id}
                          >
                            <i className="fas fa-align-justify"></i>
                            {part.title}
                          </a>
                          {!props.preview && (
                            <div className="faq-right">
                              <button
                                className="me-0 border-0 bg-transparent"
                                onClick={handleDeletePartOfModule(part._id)}
                              >
                                <i className="far fa-trash-can"></i>
                              </button>
                            </div>
                          )}
                        </div>
                        <div
                          id={"collapseOne" + part._id}
                          className="collapse"
                          data-bs-parent="#accordion"
                        >
                          <div className="faq-body">
                            <div className="add-article-btns">
                              <a
                                href="javascript:void(0);"
                                className="btn"
                                onClick={() => {
                                  openModal(part?.title, part?.video?.url);
                                }}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="widget-btn">
            <a className="btn btn-black prev_btn">Previous</a>
            <a className="btn btn-info-light next_btn">Continue</a>
          </div> */}
      </div>
      {show && (
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={closeModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{
            position: "absolute",
            top: "20%",
            left: "20%",
            width: "70%",
            height: "70%",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ minWidth: "100%", minHeight: "100%" }}>
              <Ratio aspectRatio="16x9">
                <video src={videoURL} controls />
              </Ratio>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </fieldset>
  );
};
{
  /* {modules.map((module, index) => (
        <div key={module._id} className="mb-2">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
            Loading...
          </Backdrop>
          <Accordion
            expanded={
              currentModuleID === module._id || expandedModule === module._id
            }
            onChange={handleChange(module._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${module._id}-content`}
              id={`${module._id}-header`}
              style={{
                // backgroundColor:
                //   currentModuleID === module._id ||
                //   expandedModule === module._id
                //     ? "#7F27FF"
                //     : "#10d876",
                color: "#ffffff",
                backgroundColor: "#4CAF50",
              }}
            >
              <div style={{ display: "flex", backgroundColor: "#4CAF50" }}>
                <Typography
                  className="card-title"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  {module.title}
                  {currentModuleIDs === module._id && (
                    <Badge bg="warning ml-4" > Selected</Badge>
                  )}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
              {renderParts(module)}
            </AccordionDetails>
          </Accordion>
        </div>
      ))} */
}

{
  /* {show && (
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={closeModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{
            position: "absolute",
            top: "20%",
            left: "20%",
            width: "70%",
            height: "70%",
          }}
        >
          <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
          <Modal.Body>
          <div style={{ minWidth: "100%", minHeight: "100%" }}>
            <Ratio aspectRatio="16x9">
              <video src={videoURL} controls />
            </Ratio>
          </div>
          </Modal.Body>
        </Modal>
      )} */
}

export default ProgressComponent;
