import httpClient from "../http-common";

// // Notification Services

export const getNotifications = (token, id) => {
  return httpClient.get(`/applicants/notification/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const updateNotificationRead = (token, userId, notificationId) => {
  return httpClient.post(
    `/applicants/notificationUpdateRead`,
    { notificationId, userId },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

// // Withdrawal Services

export const getWithdrawalRequests = (token) => {
  return httpClient.get(`/applicants/withdrawalRequests`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const updateWithdrawalRequest = (token, withdrawalId, status) => {
  return httpClient.post(
    `/applicants/updateWithdrawalRequest`,
    { withdrawalId, status },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

// // AuthorizeUser
export const getAllApplicants = (token) => {
  return httpClient.get(`/applicants/inactive-applicants`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

// // AuthorizeUser
export const getAllApprovedInstructors = (token) => {
  return httpClient.get(`/applicants/all-approved-applicants`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

// // AuthorizeUser
export const getRejectedApplicants = (token) => {
  return httpClient.get(`/applicants/all-rejected-applicants`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

// // delete Applicant
export const deleteApplicantAdmin = (token, id) => {
  return httpClient.delete(`/applicants/deleteApplicant/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getApplicantByID = (token, id) => {
  return httpClient.get(`/applicants/applicantbyID/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const approveApplicant = (token, id, data) => {
  return httpClient.put(`/applicants/approveApplicant/${id}`, data, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const disApproveApplicant = (token, id) => {
  return httpClient.put(
    `/applicants/disApproveApplicant/${id}`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getAllPendingCourse = (token) => {
  return httpClient.get(`/admin/all-courses/pending`, {
    headers: {
      "x-auth-token": token,
    },
  });
};
export const getCourseByID = (token, id) => {
  return httpClient.get(`/admin/course/${id}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const approveCourseByAdmin = (token, id) => {
  return httpClient.put(
    `/admin/approve-course/${id}`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const disapproveCourseByAdmin = (token, id) => {
  return httpClient.put(
    `/admin/disapprove-course/${id}`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getAllCourses = (token, id) => {
  return httpClient.get(`admin/all-courses`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getAllLiveCourses = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return httpClient.get(`/live-course/get-all-live-course`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const inActivateLiveCourse = (id, token) => {
  return httpClient.put(
    `/live-course/inactivate-live-course/${id}`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const ActivateLiveCourse = (id, token) => {
  return httpClient.put(
    `/live-course/activate-live-course/${id}`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};
