import httpClient from "../http-common";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51PP1CEP0NkqBczGf8ffkZlJcdMCbC2SpCP1okHDpaqV5pEIfnNBUaeDgDfEMTxYfP1WfMYyPtddu5qpTUpqrZxf2009lc1SG3U"
);
import { Elements } from '@stripe/react-stripe-js';


export const StripeContext = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export const createPayment = async (items, token) => {
  const stripe = await stripePromise;

  //   item.name ||
  //   !item.description ||
  //   !item.price ||
  //   !item.studentID ||
  //   !item.courseID ||
  //   !item.teacherID
  //console.log(items);
  const session = await httpClient.post(
    "/stripe/create-payment-intent",
    { items },
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );

  const response = session.data
  //console.log(response)

  

  //console.log("session", session.status);
  const sessionJson = session.data;
  //console.log("sessionJson", session);
  try {
    if (stripe === null) throw new Error("Stripe is not loaded");
    await stripe.redirectToCheckout({
      sessionId: sessionJson.sessionId,
    });
  } catch (error) {
    console.error("Front Error:", error);
    // Handle errors
  }

  return session
};

export const createPayout = async (event, email, stripe, elements, amountPKR , widhrawalData) => {
  event.preventDefault();

  if (!stripe || !elements) {
    console.error("Stripe.js has not loaded");
    return;
  }

  const cardElement = elements.getElement(CardElement);

  if (!cardElement) {
    console.error("Card Element not found");
    return;
  }

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
    billing_details: {
      email,
    },
  });

  if (error) {
    console.error(error);
  } else {
    try {
      const response = await httpClient.post(
        "/create-payout",
        {
          paymentMethodId: paymentMethod.id,
          amountPKR: amountPKR,
          widhrawalData: widhrawalData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Payout created successfully");
      } else {
        console.error("Error creating payout");
      }
    } catch (error) {
      console.error("Server Error:", error);
    }
  }
};