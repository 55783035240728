import httpClient from "../../http-common";


// Create a new course
export const uploadLiveCourse = (token, formData) => {
    return httpClient.post(`/live-course/upload-live-course`, formData, {
        headers: {
            'x-auth-token': token,
        }
    });
};

export const updateLiveCourse = (token, id, data) => {
    return httpClient.put(`/live-course/update-live-course/${id}`, data, {
        headers: {
            'x-auth-token': token,
        }
    });
};

export const getLiveCoursesForTeacherID = (token) => {
    return httpClient.get(`/live-course/get-my-live-course`, {
        headers: {
            'x-auth-token': token,
        }
    });
};

export const getLiveCoursesByTeacherID = (token) => {
    return httpClient.get(`/live-course/upload-live-course/:instructorId`, {
        headers: {
            'x-auth-token': token,
        }
    });
};

export const deleteLiveCourse = (token,id) => {
    return httpClient.delete(`/live-course/delete-live-course/${id}`, {
        headers: {
            'x-auth-token': token,
        }
    });
};


export const uploadImage = (token, formData) => {
    return httpClient.post(`/images/postImages`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
};

export const getLiveCourseByID = (id) => {
    return httpClient.get(`/live-course/get-live-course/${id}`);
};