import React, { Fragment, useEffect, useState } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Myclass from "../components/Myclass";
import Subscribe from "../components/Subscribe";
import { getAllInstructors } from "../services/public/PublicServices";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { searchTeachers } from "../services/teacherServices/TeacherServices.js";
import { Link } from "react-router-dom";
import {
  FollowInstructor,
  GetFolowingOfUSer,
} from "../services/userServices/FollowingServices.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import SearchBar from "../components/searchBar.tsx";
import SearchBarTeacher from "../components/searchBarTeachers.tsx";

const Defaultchannel = () => {
  const [instructors, setInstructors] = useState([]);
  const [followings, setFollowiings] = useState([]);
  const [iterator, setIterator] = useState(false);


  useEffect(() => {
    //fetchInstructors();
  }, [iterator]);

const changeIterator = () => {
  setIterator(!iterator);
}
  useEffect(() => {
    try {
      checkIfFollowingInstructor();
    } catch (error) {
      console.log(error);
    }

  }, [iterator]);
  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [open, setOpen] = useState(false);

  const checkIfFollowingInstructor = async () => {
    if (user) {
      setOpen(true)
      await GetFolowingOfUSer(user._id)
        .then((response) => {
          //  [{"_id":"65d873d759ee8ddff82396e5","name":"Zahid Asif"}]
          setFollowiings(response.data);

          setOpen(false)
          // console.log(isFollowingInstructor);

        })
        .catch((error) => {
          console.error(error);

          setOpen(false)
        });
    }
  }
  const checker = (id) => {
    let check = false;
    for (const value of followings) {
      if (value._id === id) {
        check = true;
        console.log(check);
        return check; 
      }
    }
    // console.log(check);
    return check;
  };

  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const handleFollowInstructor = (instructorID) => {
    setOpen(true)
    FollowInstructor(token, instructorID)
      .then((response) => {
        console.log(response.data);
        setOpen(false)
        changeIterator()
      })
      .catch((error) => {
        console.error(error);
        setOpen(false)
      });
  };


  // const fetchInstructors = async () => {
  //   try {
  //     console.log("fetching instructors")
  //     const response = await searchTeachers("Computer Science");
  //     setInstructors(response.data);
  //     console.log(response.data)
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>
      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader />

          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <SearchBarTeacher setTeacherListProp={setInstructors} />
              <div className="row">
                {instructors.map((value, index) => (
                  <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
                    <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
                      <Link
                        to={`/default-author-profile/${value._id}`}
                        className="position-absolute right-0 mr-4 top-0 mt-3"
                      >
                        <i className="ti-more text-grey-500 font-xs"></i>
                      </Link>
                      <Link
                        to={`/default-author-profile/${value._id}`}
                        className="btn-round-xxxl rounded-full bg-lightblue ml-auto mr-auto"
                      >
                        <img
                          src={`assets/images/user.png`}
                          alt="."
                          className="p-1 w-100"
                        />
                        <AccountCircleIcon />
                      </Link>
                      <h4 className="fw-700 font-xs mt-4">{value.name}</h4>
                      <p className="fw-500 font-xssss text-grey-500 mt-3">
                        {value.domain_of_study}
                      </p>
                      <div className="clearfix"></div>
                      {value.tag1 ? (
                        <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-success d-inline-block text-success mb-1 mr-1">
                          {value.tag1}
                        </span>
                      ) : (
                        ""
                      )}
                      {value.tag2 ? (
                        <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 bg-lightblue d-inline-block text-grey-800 mb-1 mr-1">
                          {value.tag2}
                        </span>
                      ) : (
                        ""
                      )}
                      {value.tag3 ? (
                        <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-info d-inline-block text-info mb-1">
                          {value.tag3}
                        </span>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={() => {
                          handleFollowInstructor(
                            value?._id
                          );
                        }}
                        disabled={open}
                      >
                        <span className="font-xssss fw-700 text-light ">
                          {checker(value._id) ? "Following" : "Follow"}
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                <Profile />
                <Myclass />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default Defaultchannel;
