import React, { Fragment, useEffect, useState } from "react";
import { getCategories } from "../services/categoryServices/categoryServices.js";
import { Link, useNavigate } from "react-router-dom";

export default function SearchBar(props?: {
  searchPagePublic?: any;
  onClicked?: Function;
  public?: boolean;
  deafultCategory?: string;
  defaultSearchQuery?: string;
}) {
  const [courseSearchQuery, setCourseSearchQuery] = useState(
    props?.defaultSearchQuery || "All Courses"
  );
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState(
    props?.deafultCategory || "Select Category"
  );
  const navigate = useNavigate();
  const [courseCategories, setCourseCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 200) {
        setCourseCategories(response.data);
      } else {
        console.log("Error in fetching categories");
        alert("Error in fetching categories");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  if (props?.public && props?.public == true) {
    return (
      <div className="form-inner">
        <div className="input-group">
          <i className="fa-solid fa-magnifying-glass search-icon"></i>
          <input
            type="email"
            className="form-control"
            placeholder="Search School, Online eductional centers, etc"
            value={courseSearchQuery}
            onChange={(e) => setCourseSearchQuery(e.target.value)}
          />
          <span className="drop-detail">
            <select
              className="form-select select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {courseCategories.map((category: any) => {
                return <option value={category.name}>{category.name}</option>;
              })}
            </select>
          </span>
          <button
            className="btn btn-primary sub-btn"
            onClick={() => {
              navigate(
                "/default-public-search/" +
                  (courseSearchQuery == "All Courses"
                    ? "All"
                    : courseSearchQuery) +
                  "/" +
                  category
              );
            }}
            type="submit"
          >
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="form-group mt-lg-4 p-3 border-light border p-2 bg-white rounded-lg ">
        <div className="row">
          <div className="col-md-5">
            <div className="form-group icon-input mb-0">
              <i className="ti-search font-xs text-grey-400"></i>
              <input
                type="text"
                className="style1-input bg-transparent border-0 pl-5 font-xsss mb-0 text-grey-500 fw-500"
                placeholder="Search online courses.."
                value={courseSearchQuery}
                onChange={(e) => setCourseSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group icon-input mb-0">
              <i className="ti-package font-xs text-grey-400"></i>
              {/* <select className="style1-select bg-transparent border-0 pl-5">
              <option value="Bootstrap">Bootstrap</option>
              <option value="HTML">HTML</option>
              <option value="Jquery">Jquery</option>
              <option value="Sass">Sass</option>
              <option value="React">React</option>
            </select> */}

              <input
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="style1-select bg-transparent border-0 pl-5"
                placeholder="Category"
                disabled
              />
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="w-4 text-grey-500 h-fit bg-transparent p-2 pl-0 pb-0 border-0  border-black border-solid"
              >
                <option
                  className="text-black bg-gray-50 p-8 w-12 text-xs border-4  border-black border-solid active:bg-black focus:bg-black hover:bg-black"
                  value=""
                >
                  Select Category
                </option>
                {courseCategories.map((category: any) => {
                  return (
                    <option
                      className="text-black bg-gray-50 p-8 w-12 text-xs border-4  border-black border-solid active:bg-black focus:bg-black hover:bg-black"
                      value={category.name}
                    >
                      {category.name}
                    </option>
                  );
                })}
                <br className="text-black" />
              </select>
            </div>
          </div>
          <div className="col-md-3">
            {props?.public === false ? (
              <Link
                to={
                  (!props.searchPagePublic  ? "/default-search/" : "/default-public-search/") +
                  (courseSearchQuery == "All Courses"
                    ? "All"
                    : courseSearchQuery) +
                  "/" +
                  category
                }
                onClick={async () => {
                  if (props?.onClicked) {
                    await props.onClicked();
                  }
                }}
                className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase "
              >
                Search
              </Link>
            ) : (
              <Link
                to={"/default-search/" + courseSearchQuery + "/" + category}
                onClick={async () => {
                  if (props?.onClicked) {
                    await props.onClicked();
                  }
                }}
                className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase "
              >
                Search
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

{
  /* <div className="form-inner">
  <div className="input-group">
    <i className="fa-solid fa-magnifying-glass search-icon"></i>
    <input
      type="email"
      className="form-control"
      placeholder="Search School, Online eductional centers, etc"
    />
    <span className="drop-detail">
      <select className="form-select select">
        <option>Category</option>
        <option>Angular</option>
        <option>Node Js</option>
        <option>React</option>
        <option>Python</option>
      </select>
    </span>
    <button className="btn btn-primary sub-btn" type="submit">
      <i className="fas fa-arrow-right"></i>
    </button>
  </div>
</div>; */
}
