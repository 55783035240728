import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext.tsx";
export function TeacherProtection() {
  const navigate = useNavigate();
  const { isAuthenticated, login, logout } = useAuth();


  useEffect(() => {
    console.log("TeacherProtection", isAuthenticated);
    if (isAuthenticated !== true) {
      if (isAuthenticated === false) {
        navigate("/login-teacher");
      } else {
        // we'll write advanced nT logic here but for now will be consider not loggedIn
        navigate("/login-teacher");
      }
    }
  }, [isAuthenticated]);
  return null;
}
