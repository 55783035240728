import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./authContext.tsx";
import { authorizeUser } from "../services/userServices/UserServices";
import { setUser } from "../Redux/userSlice";
import { useDispatch } from "react-redux";
import { authorizeTeacher } from "../services/teacherServices/TeacherServices.js";
interface PrivateRouteProps {
  element: React.ComponentType<any>;
  [key: string]: any;
  userRole: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element: Component,
  userRole,
  ...rest
}) => {
  var { isAuthenticated, login, logout } = useAuth();
  const dispatch = useDispatch();

  var token = localStorage.getItem("token");

  if (!isAuthenticated && token) {
    let cleanStr: string = token.replace(/"/g, "");
    token = cleanStr;

    console.log("PrivateRoute: token  found in localStorage", token, "useraa");

    if (userRole == "user") {
      console.log("userRole: ", userRole);
      authorizeUser(token, userRole)
        .then((res) => {
          // console.log(res.data)
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("showPopup", JSON.parse("true"));
          dispatch(setUser(res.data));
          isAuthenticated = true;
          console.log("heresss");
          login(res.data);
          return <Component {...rest} />;
        })
        .catch((err) => {
          console.log(err);
          try {
            localStorage.removeItem("token");
          } catch (error) {}
          logout();

          return <Navigate to="/login" />;
        });
    } else if (userRole == "admin") {
      authorizeUser(token, "admin")
        .then((res) => {
          // console.log(res.data)
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("showPopup", JSON.parse("true"));
          dispatch(setUser(res.data));
          isAuthenticated = true;

          login(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (userRole == "teacher") {
      authorizeTeacher(token)
        .then((res) => {
          console.log(res.data);
          localStorage.setItem("user", JSON.stringify(res.data));
          localStorage.setItem("showPopup", JSON.parse("true"));
          login(res.data);
          dispatch(setUser(res.data));
          isAuthenticated = true;
          console.log("heresssTeacher");

          return <Component {...rest} />;
        })
        .catch((err) => {
          console.log(err);
          try {
            localStorage.removeItem("token");
          } catch (error) {}
          logout();

          return <Navigate to="/login-teacher" />;
        });
    }
  } else {
    return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
  }
};

export default PrivateRoute;
