export const truncateTitle = (title) => {
    if (title.length <= 17) {
        return title;
    } else {
        return title.substring(0, 17) + '...';
    }
};


export const truncateText = (title) => {
    if (title.length <= 37) {
        return title;
    } else {
        return title.substring(0, 37) + '...';
    }
};