import React, { Fragment, useEffect, useState } from "react";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import { getLiveCourseByID } from "../services/courseServices/liveCourseServices/liveCourseServices";

import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Image, ListGroup, Ratio } from "react-bootstrap";
import {
  inActivateLiveCourse,
  ActivateLiveCourse,
} from "../services/adminServices/AdminServices";
import { DateForma, getTimeWithAMPM } from "../utils/formatDate";

function AdminDetailLiveCourse() {
  const params = useParams();
  const fetchCourses = async (token) => {
    try {
      // console.log(params.id)
      const response = await getLiveCourseByID(courseID);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };
  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const [courseID] = useState(params.id);
  const [courses, setCourses] = useState([]);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          //   console.log(courses.data.liveCourse);
          setCourses(courses.data.liveCourse);
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setOpen(false);
        });
    }
  }, [token]);

  //   const handleAccept = async () => {
  //     try {
  //       await approveCourseByAdmin(token, courseID)
  //         .then(response => {
  //           console.log(response)
  //           navigate("/admin-projectslist")
  //         })
  //         .catch(error => {
  //           console.log(error)
  //         })

  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }

  const handleInActivateCourse = async () => {
    try {
      await inActivateLiveCourse(courseID, token)
        .then((response) => {
          // console.log(response)
          navigate("/admin-live-courses");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivateCourse = async () => {
    try {
      await ActivateLiveCourse(courseID, token)
        .then((response) => {
          // console.log(response)
          navigate("/admin-live-courses");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 d-flex mb-4">
                  <h2 className="text-grey-900 font-md fw-700">
                    Live Course Detail View
                  </h2>
                  <i className="ml-auto feather-command font-lg text-grey-500"></i>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Select the action after reviewing the live course.
                      </h4>
                      <div className="d-flex justify-content-between align-items-center">
                        {/* <button className="btn btn-success"
                        onClick={handleAccept}
                        >Accept</button> */}
                        {courses?.isActive ? (
                          <button
                            className="btn btn-danger"
                            onClick={handleInActivateCourse}
                          >
                            Inactivate this Course
                          </button>
                        ) : (
                          <button
                            className="btn btn-success"
                            onClick={handleActivateCourse}
                          >
                            Activate this Course
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Course Information
                      </h4>
                      <form
                        className="contact_form"
                        name="contact_form"
                        action="#"
                        method="post"
                        noValidate=""
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb30">
                              <label className="form-label">
                                Project Title
                              </label>
                              <p className="form-text">
                                <b>{courses?.title}</b>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb30">
                              <label className="form-label">Instructor</label>
                              <p className="form-text">
                                {courses?.instructor?.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb30">
                              <label className="form-label">Category</label>
                              <p className="form-text">
                                {courses.domain_of_study}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Project Description
                              </label>
                              <p className="form-text">{courses.description}</p>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-label">Course Price</label>
                              <p className="form-text">{courses.price}</p>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="card border-0 p-4 ">
                    <h4 className="font-xss text-grey-800 mb-1 mt-0 fw-700">
                      Thumbnail Image
                    </h4>

                    {courses.thumbnail && (
                      <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md-12">
                          {/* {courses.thumbnail && ( */}
                          <>
                            <div
                              style={{ width: 660, height: 450 }}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Ratio aspectRatio="16x9">
                                <Image
                                  src={courses.thumbnail}
                                  alt="Thumbnail Preview"
                                  fluid
                                  style={{
                                    marginTop: "10px",
                                    border: "1px solid #ced4da",
                                  }}
                                />
                              </Ratio>
                            </div>
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card border-0 p-4 mt-3 rounded-10 admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-1 mt-0 fw-700">
                        Lectures Information
                      </h4>
                    </div>
                    <div>
                      {courses.lectures &&
                        courses.lectures.map((lecture, index) => (
                          <Card key={index} className="mb-4 shadow">
                            <Card.Body>
                              <Card.Title className="h3 mb-3">
                                {lecture.title}
                              </Card.Title>
                              <Card.Text className="text-muted">
                                {lecture.description}
                              </Card.Text>
                            </Card.Body>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                              }}
                            >
                              <ListGroup variant="flush">
                                <ListGroup.Item className="font-italic">
                                  Start Date:{" "}
                                  {DateForma(lecture.liveTime.startTime)}
                                </ListGroup.Item>
                                <ListGroup.Item className="font-italic">
                                  Start Time:{" "}
                                  {getTimeWithAMPM(lecture.liveTime.startTime)}
                                </ListGroup.Item>
                              </ListGroup>
                            </div>
                          </Card>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AdminDetailLiveCourse;
