import React, { useState, Fragment, useEffect } from "react";
// import { Modal, Button } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import Pagination from "../components/Pagination";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { formatDate } from "../utils/formatDate";

import {
  getAllApprovedInstructors,
  getWithdrawalRequests,
  updateWithdrawalRequest,
} from "../services/adminServices/AdminServices";
import { Link } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { X } from "lucide-react";
function AdminWidhdrawal() {
  //   const [location, setLocation] = useState(false);
  const [withdrawList, setWithdrawList] = useState([]);
  const [open, setOpen] = useState(false);
  const [detailedViewID, setDetailedViewID] = useState("");
  const [singleWidthdrawalData, setSingleWidthdrawalData] = useState({});
  const [status, setStatus] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Admin | Seekho Instructors ";

    getWithdrawalRequestsC();
  }, []);

  const getWithdrawalRequestsC = async () => {
    try {
      setOpen(true);
      const token = JSON.parse(localStorage.getItem("token"));
      const res = await getWithdrawalRequests(token);
      setWithdrawList(res.data.withdrawalRequests);
      setOpen(false);
      //  console.log(customerList.length)
    } catch (err) {
      console.log(err);
      setOpen(false);
    }
  };

  //   const handleModel = () => {
  //     setLocation(!location);
  //   };
  return (
    <Fragment>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div
                className="d-flex flex-row justify-center items-center"
                style={{ justifyContent: "center", alignItems: "start" }}
              >
                {detailedViewID !== "" && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      //   width: "500px",
                      //   height: "500px",
                      zIndex: "10",
                      padding: "20px",
                    }}
                    className="col-md-9 h-full"
                  >
                    <div
                      className="d-flex flex-row col-md-12"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Withdraw Details</div>
                      <div
                        className="curson-pointer text-danger"
                        onClick={() => {
                          setDetailedViewID("");
                          setSingleWidthdrawalData({});
                          setStatus("");
                        }}
                      >
                        <X />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-4">
                        <form
                          className="flex-row flex d-flex col-md-12"
                          style={{ flexWrap: "wrap" }}
                        >
                          <div className="col-md-4 p-2">
                            <label>Amount (PKR):</label>
                            <input
                              type="number"
                              placeholder="Enter Amount in PKR"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              value={singleWidthdrawalData.amount}
                              required
                            />
                          </div>

                          <div className="col-md-4 p-2">
                            <label>Local Currency:</label>
                            <input
                              type="text"
                              name="currency"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              value={singleWidthdrawalData.currency}
                              required
                            />
                          </div>
                          <div className="col-md-4 p-2">
                            <label>Country:</label>
                            <input
                              type="text"
                              name="country"
                              className="form-control text-black p-2 pl-4 pr-4 bg-white"
                              value={singleWidthdrawalData.country.toUpperCase()}
                              required
                            />
                          </div>
                          {singleWidthdrawalData.country && (
                            <>
                              <div className="col-md-4 p-2">
                                <label>Bank Name:</label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="bankName"
                                  value={singleWidthdrawalData.bankName}
                                  required
                                />
                              </div>
                              <div className="col-md-4 p-2">
                                <label>Bank Account Number:</label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="bankAccountNumber"
                                  value={
                                    singleWidthdrawalData.bankAccountNumber
                                  }
                                  required
                                />
                              </div>
                              <div className="col-md-4 p-2">
                                <label>Bank Branch Name:</label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="bankBranch"
                                  value={singleWidthdrawalData.bankBranch}
                                />
                              </div>
                              <div className="col-md-4 p-2">
                                <label>
                                  Routing Number (Sort code, IFSC code, or ABA
                                  number):
                                </label>
                                <input
                                  type="text"
                                  className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                  name="routingNumber"
                                  value={singleWidthdrawalData.routingNumber}
                                  required
                                />
                              </div>
                              {singleWidthdrawalData.country !== "pakistan" && (
                                <>
                                  <div className="col-md-4 p-2">
                                    <label>Country:</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="country"
                                      value={singleWidthdrawalData.country}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>SWIFT Code (or BIC):</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="swiftCode"
                                      value={singleWidthdrawalData.swiftCode}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>
                                      IBAN (International Bank Account Number):
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="iban"
                                      value={singleWidthdrawalData.iban}
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>Recipient Address:</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="recipientAddress"
                                      value={
                                        singleWidthdrawalData.recipientAddress
                                      }
                                    />
                                  </div>
                                  <div className="col-md-4 p-2">
                                    <label>Bank Address:</label>
                                    <input
                                      type="text"
                                      className="form-control text-black p-2 pl-4 pr-4 bg-white"
                                      name="bankAddress"
                                      value={singleWidthdrawalData.bankAddress}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}

                          <div className="col-md-4 p-2">
                            <label>Status:</label>
                            <select
                              name="status"
                              value={status}
                              onChange={(e) => {
                                setStatus(e.target.value);
                              }}
                              className="form-select text-black p-2 pl-4 pr-4 bg-white"
                            >
                              <option value="Pending">Pending</option>
                              <option value="Approved">Approved</option>
                              {/* <option value="Rejected">Rejected</option> */}
                            </select>
                          </div>

                          <Button
                            className="text-white col-md-12 p-2 pl-4 pr-4"
                            style={{
                              backgroundColor: "#09ac2c",
                              borderColor: "#09ac2c",
                            }}
                            disabled={singleWidthdrawalData.status === status}
                            onClick={async () => {
                              const token = JSON.parse(
                                localStorage.getItem("token")
                              );
                              const response = await updateWithdrawalRequest(
                                token,
                                singleWidthdrawalData._id,
                                status
                              );
                              if (response.status === 200) {
                                getWithdrawalRequestsC();
                                setDetailedViewID("");
                                setSingleWidthdrawalData({});
                                setStatus("");
                              } else {
                                console.error(
                                  "Error updating withdrawal request"
                                );
                                alert("Error updating withdrawal request");
                              }
                            }}
                          >
                            Update Payout Status
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex p-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Withdrawal Requests
                      </h4>
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0"></th>
                              <th className="border-0" scope="col">
                                Amount PKR
                              </th>
                              <th className="border-0" scope="col">
                                Status
                              </th>
                              <th className="border-0" scope="col">
                                Subbmitted At
                              </th>
                              <th className="border-0" scope="col">
                                Country
                              </th>

                              <th scope="col" className="text-end border-0">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {withdrawList.length > 0 &&
                              withdrawList.map((value) => (
                                <tr key={value?._id}>
                                  <td className="product-thumbnail text-start pl-0">
                                    {/* <img
                                        src={`assets/images/${value.imageUrl}`}
                                        alt="product"
                                        className="w45 d-inline-block"
                                      /> */}
                                    <AccountCircleIcon />
                                  </td>

                                  <td>
                                    <b>PKR {value?.amount}</b>
                                  </td>
                                  <td>
                                    <span
                                      className={`badge rounded-pill font-xsssss fw-700 pl-3 pr-3 lh-24 text-uppercase rounded-3 ls-2 ${
                                        value.status == "Pending"
                                          ? "alert-warning"
                                          : value.status == "Approved"
                                          ? "alert-success"
                                          : "alert-danger"
                                      } `}
                                    >
                                      {value.status}
                                    </span>
                                  </td>

                                  <td>
                                    <b>
                                      {new Date(
                                        value.createdAt
                                      ).toLocaleDateString()}
                                    </b>
                                  </td>

                                  <td>
                                    <span>{value.country.toUpperCase()}</span>
                                  </td>

                                  <td className="product-remove text-end">
                                    <button
                                      className="bg-transparent border-0"
                                      onClick={() => {
                                        if (detailedViewID === value._id) {
                                          setDetailedViewID("");
                                          setSingleWidthdrawalData({});
                                          setStatus("");
                                        } else {
                                          setDetailedViewID(value._id);
                                          setSingleWidthdrawalData(value);
                                          setStatus(value.status);
                                        }
                                      }}
                                    >
                                      <i className="me-1 font-xs text-grey-500">
                                        <RemoveRedEyeIcon />
                                      </i>
                                    </button>
                                    {/* <Button
                                        className="bg-transparent border-0"
                                        onClick={() => {
                                          handleModel();
                                        }}
                                      >
                                        <i className="ti-trash  font-xs text-danger"></i>
                                      </Button> */}
                                    {/* <Modal
                                        size="sm"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        show={location}
                                      >
                                        <Button
                                          onClick={() => {
                                            handleModel();
                                          }}
                                          className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                        ></Button>
                                        <Modal.Body className="text-center p-4">
                                          <i className="ti-info-alt text-warning display4-size"></i>
                                          <p className="text-grey-500 font-xsss mt-3 mb-4">
                                            Are you sure you want to delete product?{" "}
                                          </p>
    
                                          <Button
                                            onClick={() => {
                                              handleModel();
                                            }}
                                            className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                          >
                                            Yes, delete!{" "}
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              handleModel();
                                            }}
                                            className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                          >
                                            No, cancle!
                                          </Button>
                                        </Modal.Body>
                                      </Modal> */}
                                  </td>
                                </tr>
                              ))}
                            {withdrawList.length === 0 ? (
                              <tr>
                                <td>No Applicant</td>
                              </tr>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default AdminWidhdrawal;
