
// example course object{
//   "_id": "662206da56ff33190c192035",
//   "title": "Game Development Crash Course",
//   "description": "Jump into the exciting world of game development with our intensive crash course designed to take you from novice to game creator in just a few weeks. This course covers everything you need to know to start building engaging and interactive video games across various platforms.",
//   "instructor": {
//       "_id": "6622046556ff33190c191fcf",
//       "name": "Mohsin Mehdi",
//       "email": "mohsinm@gmail.com"
//   },
//   "isActive": false,
//   "status": "published",
//   "thumbnail": "http://res.cloudinary.com/dkvyeo2zf/image/upload/v1713506281/e8jbgnj1yzuhmdaeq5qw.png",
//   "domain_of_study": "Programming and Development",
//   "price": 2000,
//   "createdAt": "2024-04-19T05:53:30.176Z",
//   "updatedAt": "2024-04-19T05:58:03.662Z",
//   "__v": 0,
//   "score": 13
// }

import React, { Fragment, useState, useEffect } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Myclass from "../components/Myclass";
import Subscribe from "../components/Subscribe";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { searchCourses } from "../services/courseServices/courseServices";
import SearchBar from "../components/searchBar.tsx";
import CourseCard  from "../components/courseCard.js";


const Defaultsearch = () => {
  const [courseList, setCourseList] = useState([]);
  const [sortCriteria, setSortCriteria] = useState('relevance');

  const { query, category } = useParams();

  const fetchCourses = async () => {
    try {
      const response = await searchCourses(query, category);
      let sortedCourses = response.data;

      if (sortCriteria === 'priceAsc') {
        sortedCourses = sortedCourses.sort((a, b) => a.price - b.price);
      } else if (sortCriteria === 'priceDesc') {
        sortedCourses = sortedCourses.sort((a, b) => b.price - a.price);
      } else if (sortCriteria === 'createdDateAsc') {
        sortedCourses = sortedCourses.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      } else if (sortCriteria === 'createdDateDesc') {
        sortedCourses = sortedCourses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      } else if (sortCriteria === 'updatedDateAsc') {
        sortedCourses = sortedCourses.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
      } else if (sortCriteria === 'updatedDateDesc') {
        sortedCourses = sortedCourses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      }

      setCourseList(sortedCourses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [query, category, sortCriteria]);

  return (
    <Fragment>
      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader /> 

          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div
                    className="card rounded-xxl p-lg--5 border-0 bg-no-repeat"
                    style={{ backgroundColor: `#e4f7fe` }}
                  >
                    <div className="card-body w-100 p-4">
                      <div className="form-group mt-3 p-3 border-light border p-2 bg-white rounded-lg">
                        <SearchBar onClicked={fetchCourses} deafultCategory={category} defaultSearchQuery={query}/>
                      </div>
                      <h4 className="text-grey-500 font-xssss fw-500 ml-1 lh-24">
                        <b className="text-grey-800 text-dark">
                          Popular Search :
                        </b>
                        {" "}Web Development, Data Science, Machine Learning, Python
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pt-2 mb-4">
                  <h2 className="fw-400 font-lg">
                    Search <b>Results</b>
                    <span className="fw-500 ml-2 text-grey-500 font-xssss">
                      ( {courseList.length} course(s) found )
                    </span>
                    <a href="/" className="float-right">
                      <i className="feather-edit text-grey-500 font-xs"></i>
                    </a>
                  </h2>
                  <div className="form-group">
                    <label htmlFor="sort" className="mr-2">Sort by:</label>
                    <select 
                      id="sort"
                      className="custom-select w-auto"
                      value={sortCriteria}
                      onChange={(e) => setSortCriteria(e.target.value)}
                    >
                      <option value="relevance">Relevance</option>
                      <option value="priceAsc">Price: Low to High</option>
                      <option value="priceDesc">Price: High to Low</option>
                      <option value="createdDateAsc">Created Date: Oldest First</option>
                      <option value="createdDateDesc">Created Date: Newest First</option>
                      <option value="updatedDateAsc">Updated Date: Oldest First</option>
                      <option value="updatedDateDesc">Updated Date: Newest First</option>
                    </select>
                  </div>
                </div>
                {courseList.length > 0 &&
                  courseList.map((value, index) => (
                    // Start Single Demo
                    <CourseCard key={index} value={value} />

                  ))}
              </div>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                <Profile />
                <Myclass />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default Defaultsearch;
