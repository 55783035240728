import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import teacherReducer from "./teacherApplicationReducer";
import courseReducer from "./courseSlice";
import adminReducer from "./adminSlice";
import clickedValuesReducer from "./clickedValuesReducer";

const rootReducer = combineReducers({
  user: userReducer,
  teacher: teacherReducer,
  clickedValues: clickedValuesReducer,
  admin: adminReducer,
  course:courseReducer,
  // Add other reducers here if needed
});

export default rootReducer;