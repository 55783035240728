import httpClient from "../http-common";

 
// Get featured courses
export const getAllFeaturedCourses = () => {
    return httpClient.get(`/enrollment/my-courses/featured-courses`, {
    });
}

// Get featured courses by id
export const getFeaturedCoursesByID = (id) => {
    return httpClient.get(`/enrollment/course/${id}`, {
    });
}

// Get featured courses by id
export const getAllInstructors = () => {
    return httpClient.get(`/applicants/all-applicants`, {
    });
} 