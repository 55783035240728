import React, { useState } from "react";
import "./carousel.css";
import { Alert, Button, Spinner } from "react-bootstrap";

import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";
import { teacherApplicant } from "../services/teacherServices/TeacherServices";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectClickedValues } from "../Redux/selectors";

function Applytoteach() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([
    {
      id: 2,
      image: "1.jpg",
      illustration: "ill2.png",
      name: "Do you want to teach @ SEEKHO?",
      quiz: {
        options: ["Yes"],
      },
    },
    {
      id: 1,
      image: "2.jpg",
      illustration: "ill3.png",
      name: "Have you done any online job before?",
      quiz: {
        options: ["Yes", "No"],
      },
    },
    {
      id: 1,
      image: "1.jpg",
      illustration: "ill4.png",
      name: "How many years of experience do you have?",
      quiz: {
        options: ["0 to 5 years", "6 to 10 years", "10+ years"],
      },
    },
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = (selectedOption) => {
    dispatch({ type: "ADD_CLICKED_VALUE", payload: selectedOption });
    setCurrentStep((prevStep) => prevStep + 1);
    setItems((prevItems) => [...prevItems.slice(1), prevItems[0]]);
  };

  // const handlePrev = () => {
  //   setCurrentStep((prevStep) => prevStep - 1);
  //   setItems((prevItems) => [
  //     prevItems[prevItems.length - 1],
  //     ...prevItems.slice(0, -1),
  //   ]);
  // };

  return (
    <div>
      <Link
        to="/"
        className="d-inline-block mt-2 "
        style={{
          position: "absolute",
          top: "100px",
          left: "100px",
          zIndex: 100,
        }}
      >
        <i className="ti-arrow-left font-sm text-black"></i>
      </Link>
      {currentStep <= 2 ? (
        <div
          className="container-c d-flex just d-flex justify-content-center align-items-center "
          style={{ width: "97%", height: "98%", marginLeft: "-2%" }}
        >
          <div
            id="slide d-flex just d-flex justify-content-center align-items-center "
            style={{}}
          >
            {items.map((item, index) => (
              <div
                key={index}
                className={`item ${
                  index > 1 ? "small-item" : ""
                } d-flex justify-content-center align-items-center p-2`}
                style={{
                  backgroundImage: `url('${item.image}')`,
                  width: "55%",
                  height: "100%",
                }}
              >
                {index > 1 && (
                  <div
                    className="center-illustration p-2 "
                    style={{ paddingBottom: "100%" }}
                  >
                    <img
                      src={item.illustration}
                      alt="Illustration"
                      className="illus"
                      style={{ minWidth: "1000%", minHeight: "1000%" }}
                    />
                  </div>
                )}
                <div className="content ">
                  <div className="name">{item.name}</div>
                  <div className="quiz-container">
                    <div className="quiz-option">
                      {item.quiz.options.map((option, optionIndex) => (
                        <button
                          key={optionIndex}
                          onClick={() => handleNext(option)}
                        >
                          <i className="fas fa-solid fa-a"></i>
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="buttons">
          <button id="prev" onClick={handlePrev}>
            <i className="fas fa-angle-left"><ArrowBackIcon /></i>
          </button>
          <button id="next" onClick={handleNext}>
            <i className="fas fa-angle-right"><ArrowForwardIcon /></i>
          </button>
        </div> */}
        </div>
      ) : (
        <></>
      )}

      {/* Conditionally render the form component after the third question */}
      {currentStep > 2 && <FormComponent />}
    </div>
  );
}

// Define your form component here
const FormComponent = () => {
  // let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState({
    visible: false,
    message: "",
  });
  const [success, setSuccess] = useState({
    visible: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const clickedValues = useSelector(selectClickedValues);
  const [additionalData, setAdditionalData] = useState({
    github: "",
    linkedin: "",
    youtube: "",
    securityQuestion: "",
    securityAnswer: "",
  });

  // console.log(clickedValues);
  const onSubmit = async (data) => {
    const mergedData = {
      ...data, // Spread the data object
      years_of_experience: clickedValues[1], // Add the clickedValues array
      ...additionalData, // Spread the additionalData object
    };
    // console.log(mergedData);
    // console.log(data);
    setLoading(true);
    try {
      await teacherApplicant(mergedData)
        .then((res) => {
          setTimeout(() => {
            setSuccess({
              visible: true,
              message: res.data.msg,
            });
            setError({
              visible: false,
              message: "",
            });
            setLoading(false);
          }, 2500);
        })
        .catch((err) => {
          setTimeout(() => {
            // console.log(err.response.data.errors[0].msg);
            setError({
              visible: true,
              message: err.response.data.errors[0].msg,
            });
            setLoading(false);
          }, 1500);
        });
    } catch (err) {
      setError({
        visible: true,
        message: "Server Error",
      });
      setLoading(false);
    }
  };
  return (
    <div className="container px-3 py-4">
      <div className="row">
        <div className="col-lg-12 d-flex mb-4">
          <h2 className="text-grey-900 font-md fw-700">
            Teach at SEEKHO Application
          </h2>
          <i className="ml-auto feather-command font-lg text-grey-500"></i>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 ">
          <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
            <div className="card-body d-block">
              <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                Professional Information
              </h4>
              {error.visible && <Alert variant="danger">{error.message}</Alert>}
              {success.visible && (
                <Alert variant="success">{success.message}</Alert>
              )}
              <form
                className="contact_form"
                name="contact_form"
                action="#"
                method="post"
                noValidate=""
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Your Name
                      </label>
                      <input
                        name="form_name"
                        type="text"
                        placeholder="Please Enter Your name *"
                        {...register("name", { required: "Name is required" })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Email
                      </label>
                      <input
                        name="form_type"
                        type="text"
                        placeholder="Email"
                        {...register("email_contact", {
                          required: "Please include a valid email address",
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Please include a valid email address",
                          },
                        })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.email_contact ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.email?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Field of Study (e.g. Mathematics, Physics, etc.)
                      </label>
                      <input
                        name="form_type"
                        type="text"
                        placeholder="Domain of Study"
                        {...register("domain_of_study", {
                          required: "Fill out the field",
                        })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.domain_of_study?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Contact Number
                      </label>
                      <input
                        name="form_type"
                        type="text"
                        placeholder="Phone Number"
                        {...register("contact_number", {
                          required: "Fill out the field",
                        })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.contact_number?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Country
                      </label>
                      <input
                        name="form_type"
                        type="text"
                        placeholder="Country"
                        {...register("country", {
                          required: "Fill out the field",
                        })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.country?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        City
                      </label>
                      <input
                        name="form_type"
                        type="text"
                        placeholder="City"
                        {...register("address", {
                          required: "Fill out the field",
                        })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.address?.message}
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="product_sku" className="form-label">
                        Cover Letter
                      </label>
                      <textarea
                        name="form_message"
                        rows="6"
                        placeholder="Write down a cover letter providing how you have you been beneficial for education. *"
                        {...register("cover_letter", {
                          required: "Fill out the field",
                        })}
                        className={`form-control h150 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      ></textarea>
                      <span className="text-danger">
                        {errors.cover_letter?.message}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Resume / Portfolio (URL)
                      </label>
                      <input
                        name="form_type"
                        type="text"
                        placeholder="Resume / Portfolio (URL) *"
                        {...register("resume", {
                          required: "Fill out the field",
                        })}
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.resume?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Github
                      </label>
                      <input
                        name="github"
                        type="text"
                        placeholder="Github URL"
                        value={additionalData.github}
                        onChange={(e) =>
                          setAdditionalData({
                            ...additionalData,
                            github: e.target.value,
                          })
                        }
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        linkedIn
                      </label>
                      <input
                        name="linkedin"
                        type="text"
                        placeholder="Linkedin URL"
                        value={additionalData.linkedin}
                        onChange={(e) =>
                          setAdditionalData({
                            ...additionalData,
                            linkedin: e.target.value,
                          })
                        }
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Youtube
                      </label>
                      <input
                        name="youtube"
                        type="text"
                        placeholder="Youtube URL"
                        value={additionalData.youtube}
                        onChange={(e) =>
                          setAdditionalData({
                            ...additionalData,
                            youtube: e.target.value,
                          })
                        }
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Security Question
                      </label>
                      <input
                        name="securityQuestion"
                        type="text"
                        placeholder="Security Question"
                        value={additionalData.securityQuestion}
                        onChange={(e) =>
                          setAdditionalData({
                            ...additionalData,
                            securityQuestion: e.target.value,
                          })
                        }
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb30">
                      <label htmlFor="product_sku" className="form-label">
                        Security Answer
                      </label>
                      <input
                        name="securityAnswer"
                        type="text"
                        placeholder="Security Answer"
                        value={additionalData.securityAnswer}
                        onChange={(e) =>
                          setAdditionalData({
                            ...additionalData,
                            securityAnswer: e.target.value,
                          })
                        }
                        className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                          errors.name ? "border-danger" : ""
                        }`}
                      />
                      <span className="text-danger">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb30">
                      <Button
                        disabled={loading || success.visible}
                        type="submit"
                        className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          "Submit Application"
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applytoteach;
