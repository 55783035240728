import httpClient from "../http-common";



export const getFeaturedLiveCoursesForAUser = (token) => { 
  return httpClient.get(`/featured/live-courses`, {
    headers: {
      'x-auth-token': token
    },
  });
};

export const getLiveCourseById = (id) => {
  return httpClient.get(`/live-course/get-live-course/${id}`, {
  });
};

