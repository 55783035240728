import React, { Fragment, useState, useEffect } from "react";
import Chart from "react-apexcharts";

import Adminsidebar from "../components/Adminsidebar.js";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import UserDialog from "../components/DialogBox.js";
import { getAdminStats } from "../services/statistics/statistics.js";

const orderList = [
  {
    id: "0901",
    name: "Marvin McKinney",
    email: "marvin@example.com",
    total: "$9.00",
    color: "warning",
    status: "Pending",
    date: "03.12.2020",
  },
  {
    id: "2323",
    name: "Leslie Alexander",
    email: "leslie@example.com",
    total: "$46.61",
    color: "warning",
    status: "Pending",
    date: "21.02.2020",
  },
  {
    id: "1233",
    name: "Esther Howard",
    email: "esther@example.com",
    total: "$12.00",
    color: "danger",
    status: "Canceled",
    date: "03.07.2020",
  },
  {
    id: "1233",
    name: "Esther Howard",
    email: "esther@example.com",
    total: "$12.00",
    color: "danger",
    status: "Canceled",
    date: "03.07.2020",
  },
  {
    id: "2323",
    name: "Jenny Wilson",
    email: "jenny@example.com",
    total: "$589.99",
    color: "success",
    status: "Received",
    date: "22.05.2020",
  },
];



const Admin = () => {
  const [iconList, setIconList] = useState([
    {
      name: 0,
      count: "Total Courses",
      status: "warning",
      icon: "feather-hard-drive",
      des: "Total Courses in the system",
    },
    {
      name: 0,
      count: "Published Courses",
      status: "success",
      icon: "feather-box",
      des: "Courses that are approved and published",
    },
    {
      name: 0,
      count: "Approved Teachers",
      status: "info",
      icon: "feather-award",
      des: "Teachers that are skilled and approved",
    },
    {
      name: 0 + " PKR",
      count: "Total Sales",
      status: "secondary",
      icon: "feather-flag",
      des: "Includes sale of recorded and live courses",
    },
    {
      name: 0,
      count: "Active Customers",
      status: "info",
      icon: "feather-award",
      des: "20% Increase from Last Week",
    },
    {
      name: 0,
      count: "Unpublished Courses",
      status: "danger",
      icon: "feather-box",
      des: "Courses that are not approved yet",
    },
    {
      name: 0,
      count: "Recorded Courses",
      status: "success",
      icon: "feather-box",
      des: "Courses that are approved and published",
    },
    {
      name: 0,
      count: "Live Courses",
      status: "success",
      icon: "feather-box",
      des: "Courses that are approved and published",
    },
  ]);

  const [pieChart, setPieChart] = useState({
    series: [44, 55],
    options: {
      labels: ["A: Recorded courses", "B: Live courses"],
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
            stacked: true,
            toolbar: {
              show: false,
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  });

  const [lineChart, setLineChart] = useState({
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    series: [
      {
        name: "Recorded Course Sales",
        data: [], // This will be filled with sales data
      },
    ],
    options: {
      chart: {
        height: 100,
        type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        columnWidth: "40%",
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
    },
  });
 
  const fetchAdminStats = async () => {
    try {
      const response = await getAdminStats();
      if (response.data) {
        const data = response.data.data;
        // Use the data to update the state as needed
        console.log(data);
        const salesPerMonth = new Array(12).fill(0);
        data.enrolledCoursesDateandCount.forEach((course) => {
          const month = new Date(course.course.createdAt).getMonth(); // Get month from date
          salesPerMonth[month] += course.course.price; // Add the price to the corresponding month
        });
        setIconList([
          {
            name: data.totalCourses,
            count: "Total Courses",
            status: "warning",
            icon: "feather-hard-drive",
            des: "Total Courses in the system",
          },
          {
            name: data.publishedCourses,
            count: "Published Courses",
            status: "success",
            icon: "feather-box",
            des: "Courses that are approved and published",
          },
          {
            name: data.approvedTeachersCount,
            count: "Approved Teachers",
            status: "info",
            icon: "feather-award",
            des: "Teachers that are skilled and approved",
          },
          {
            name: data.totalSales + " PKR",
            count: "Total Sales",
            status: "secondary",
            icon: "feather-flag",
            des: "Includes sale of recorded and live courses",
          },
          {
            name: data.userDateandCount.length,
            count: "Active Customers",
            status: "info",
            icon: "feather-award",
            des: "20% Increase from Last Week",
          },
          {
            name: data.coursesDateandCount.length - data.publishedCourses,
            count: "Unpublished Courses",
            status: "danger",
            icon: "feather-box",
            des: "Courses that are not approved yet",
          },
          {
            name: data.coursesDateandCount.length,
            count: "Recorded Courses",
            status: "success",
            icon: "feather-box",
            des: "Courses that are approved and published",
          },
          {
            name: data.liveCoursesDateandCount.length,
            count: "Live Courses",
            status: "success",
            icon: "feather-box",
            des: "Courses that are approved and published",
          },
        ]);

        setPieChart({
          series: [
            data.coursesDateandCount.length,
            data.liveCoursesDateandCount.length,
            
          ],
          options: {
            labels: ["A: Recorded courses", "B: Live courses"],
            chart: {
              type: "donut",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "bottom",
                  },
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                },
              },
            ],
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
          },
        });
        setLineChart((prevState) => ({
          ...prevState,
          series: [
            {
              name: "Recorded Course Sales",
              data: salesPerMonth,
            },
          ],
        }));
      }
    } catch (error) {
      console.error("Error while fetching admin stats:", error);
    }
  };

  useEffect(() => {
    fetchAdminStats();
  }, []);

  return (
    <Fragment>
      <UserDialog
        role="admin"
        message="Welcome to the admin panel | Manage Processes | Seamlessly"
      />
      <div id="wrapper">
        <Adminsidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />
            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 d-flex mb-4">
                  <h2 className="text-grey-900 font-md fw-700">Overview</h2>
                  {/* <select
                    className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                    aria-label="Default select example"
                  >
                    <option>Sort by latest</option>
                    <option defaultValue="1">Sort by popularity</option>
                    <option defaultValue="2">Sort by price: low to high</option>
                    <option defaultValue="3">Sort by price: high to low</option>
                  </select> */}
                </div>
              </div>

              <div className="row">
                {iconList.map((value, index) => (
                  <div key={index} className="col-lg-3">
                    <div
                      className={`card mb-4 border-0 pt-4 pb-4 text-center alert-${value.status} align-items-center rounded-10`}
                    >
                      <i
                        className={`psor text-white btn-round-md font-xs ${value.icon} bg-${value.status}`}
                      ></i>
                      <h3 className="fw-700 font-xl text-grey-900 mt-2 ls-3 mb-0">
                        {value.name}
                      </h3>
                      <span className="font-xssss ls-0 text-grey-900 fw-700 mt-0">
                        {value.count}
                      </span>
                      <span className="mt-1 text-grey-500 font-xsssss fw-500">
                        {value.des}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="card border-0 p-4 mt-2 rounded-10">
                    <div className="card-body d-flex">
                      <h4 className="font-xss text-grey-800 mb-4 mt-3 fw-700">
                        Sales Statistics (PKR /Month)
                      </h4>
                      <h5 className="ml-auto mr-3 mt-2 text-grey-600 font-xssss fw-700">
                        
                      </h5>
                      <h5 className="mt-2 text-grey-600 font-xssss fw-700">
                        <span className="btn-round-xss bg-info mr-1"></span>
                        Recorded courses sale
                      </h5>
                    </div>
                    <Chart
                      options={lineChart.options}
                      series={lineChart.series}
                      type="bar"
                      width="100%"
                      height="350"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex pt-4 pb-0">
                      <h4 className="font-xss text-grey-800 mb-4 mt-3 fw-700">
                        Popular Products
                      </h4>
                      <a
                        href="/#"
                        className="ml-auto text-grey-500 fw-600 font-xssss mt-3"
                      >
                        See all
                      </a>
                    </div>
                    <div className="card-body p-0 table-responsive-sm">
                      <table className="table table-admin mb-0">
                        <thead className="bg-greylight rounded-10 ovh">
                          <tr>
                            <th className="border-0"></th>
                            <th className="border-0" scope="col">
                              Name
                            </th>
                            <th className="border-0" scope="col">
                              Email
                            </th>
                            <th className="border-0" scope="col">
                              Total
                            </th>
                            <th className="border-0" scope="col">
                              Status
                            </th>
                            <th className="border-0" scope="col">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.map((value, index) => (
                            <tr key={index}>
                              <td className="product-thumbnail text-start pl-0">
                                <img
                                  src="https://via.placeholder.com/50x50.png"
                                  alt="Product Thumbnail"
                                />
                              </td>
                              <td>
                                <b>{value.name}</b>
                              </td>
                              <td>{value.email}</td>
                              <td>
                                <b>{value.total}</b>
                              </td>
                              <td>
                                <span
                                  className={`btn-round-xs alert-${value.color}`}
                                ></span>
                                <b>{value.status}</b>
                              </td>
                              <td>{value.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-lg-12">
                  <div className="card border-0 p-4 mt-2 rounded-10">
                    <div className="card-body d-flex">
                      <h4 className="font-xss text-grey-800 mb-4 mt-3 fw-700">
                        Courses Statistics
                      </h4>
                      {/* <select className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent">
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">Sort by price: low to high</option>
                        <option defaultValue="3">Sort by price: high to low</option>
                      </select> */}
                    </div>
                    <Chart
                      options={pieChart.options}
                      series={pieChart.series}
                      type="donut"
                      width="100%"
                      height="350"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="card border-0 p-4 mt-2 rounded-10">
                    <div className="card-body d-flex">
                      <h4 className="font-xss text-grey-800 mb-4 mt-3 fw-700">
                        User Statistics
                      </h4>
                      <select className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent">
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price: low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price: high to low
                        </option>
                      </select>
                    </div>
                    <div className="card-body p-0 table-responsive-sm">
                      <table className="table table-admin mb-0">
                        <thead className="bg-greylight rounded-10 ovh">
                          <tr>
                            <th className="border-0"></th>
                            <th className="border-0" scope="col">
                              Name
                            </th>
                            <th className="border-0" scope="col">
                              Email
                            </th>
                            <th className="border-0" scope="col">
                              Total
                            </th>
                            <th className="border-0" scope="col">
                              Status
                            </th>
                            <th className="border-0" scope="col">
                              Date
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.map((value, index) => (
                            <tr key={index}>
                              <td className="product-thumbnail text-start pl-0">
                                <img
                                  src="https://via.placeholder.com/50x50.png"
                                  alt="Product Thumbnail"
                                />
                              </td>
                              <td>
                                <b>{value.name}</b>
                              </td>
                              <td>{value.email}</td>
                              <td>
                                <b>{value.total}</b>
                              </td>
                              <td>
                                <span
                                  className={`btn-round-xs alert-${value.color}`}
                                ></span>
                                <b>{value.status}</b>
                              </td>
                              <td>{value.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="card border-0 p-4 mt-2 rounded-10">
                    <div className="card-body d-flex">
                      <h4 className="font-xss text-grey-800 mb-4 mt-3 fw-700">
                        Recent Activities
                      </h4>
                      <select className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent">
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price: low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price: high to low
                        </option>
                      </select>
                    </div>
                    <Chart
                      options={pieChart.options}
                      series={pieChart.series}
                      type="donut"
                      width="100%"
                      height="350"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <Adminfooter />
        </div>
      </div>
    </Fragment>
  );
};

export default Admin;
