import React, { useState } from "react";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIcon from "@mui/icons-material/Assignment";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Button, Modal, Ratio } from "react-bootstrap";

const ProgressComponentRO = (props) => {
  const modules = props.modules || useSelector((state) => state.course.moduleData);
  const currentModuleID = useSelector((state) => state.course.currentModuleID);
  const [expandedModule, setExpandedModule] = useState(null);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [title, setTitle] = useState(true);
  const [videoURL, setVideoURL] = useState(true);
  const handleChange = (moduleID) => (event, isExpanded) => {
    setExpandedModule(isExpanded ? moduleID : null);
  };

  const openModal = async (title, video) => {
    setFullscreen(true);
    setTitle(title);
    setVideoURL(video);
    setShow(true);
  };

  function closeModal() {
    setShow(false);
  }
  const renderParts = (module) => {
    if (module.parts.length === 0) {
      return (
        <Typography variant="body1">
          At least one item should be added in the initialized module.
        </Typography>
      );
    }
    return module.parts.map((part, index) => (
      <div>
        {part.type === "video" && (
          <div
            key={part._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <OndemandVideoIcon style={{ marginRight: "8px" }} />
              {part.title}
            </Typography>
            <Button
              onClick={() => openModal(part?.title, part?.video?.url)}
              // target="_blank"
              // rel="noreferrer"
              // style={{ textDecoration: "underline" }}
              style={{ marginLeft: "20px" }}
            >
              Preview
            </Button>
          </div>
        )}
        {part.type === "assignment" && (
          <div
            key={part._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <AssignmentIcon style={{ marginRight: "8px" }} />
              {part?.assignment?.heading}
            </Typography>
          </div>
        )}

        {part.type === "quiz" && (
          <div
            key={part._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <QuizIcon style={{ marginRight: "8px" }} />
              {part.title}
            </Typography>
          </div>
        )}
      </div>
    ));
  };

  return (
    <>
      {modules.map((module, index) => (
        <div key={module._id} className="mb-2">
          <Accordion
            expanded={
              currentModuleID === module._id || expandedModule === module._id
            }
            onChange={handleChange(module._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${module._id}-content`}
              id={`${module._id}-header`}
              style={{
                backgroundColor: "#10d876",
                color: "#ffffff",
              }}
            >
              <Typography
                className="card-title"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                {module.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
              {renderParts(module)}
            </AccordionDetails>
          </Accordion>
        </div>
      ))}

      {show && (
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={closeModal}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{
            position: "absolute",
            top: "50%",
            left: "20%",
            padding:"100px",
            width: "900px", 
            height: "550px"
          }}
        >
          {/* <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header> */}
          {/* <Modal.Body> */}
          <div style={{ width: "800px", height: "450px" }}>
            <Ratio aspectRatio="16x9">
              <video src={videoURL} controls />
            </Ratio>
          </div>
          {/* </Modal.Body> */}
        </Modal>
      )}
    </>
  );
};

export default ProgressComponentRO;
