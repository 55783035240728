import React, { useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

const Teachersidebar = () => {
  useEffect(() => {
    var elements = document.querySelectorAll(".has-droupdown > a");
    const handleClick = (e) => {
      var next = e.currentTarget.nextElementSibling;
      if (next) {
        next.classList.toggle("active");
        e.currentTarget.classList.toggle("open");
      }
    };

    elements.forEach((element) => {
      element.addEventListener("click", handleClick);
    });

    // Cleanup the event listener
    return () => {
      elements.forEach((element) => {
        element.removeEventListener("click", handleClick);
      });
    };
  }, []);

  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark scroll-bar"
      id="accordionSidebar"
    >
      <div
        className="sidebar-brand d-flex align-items-start justify-content-start"
        href="/admin"
      >
        <span className="d-inline-block fredoka-font ls-3 fw-600 text-success font-xl logo-text mb-0 text-capitalize lh-1">
          Seekho.pk
        </span>
        <button className="ms-auto mt-2 d-lg-none" id="sidebarClose">
          <i className="ti-close text-white font-sm text-grey-100 "></i>
        </button>
      </div>

      <div className="sidebar-heading">Pages</div>

      <li className="nav-item">
        <NavLink className="navi-link" to="/teacher">
          <i className="feather-pie-chart mr-2"></i>
          <span>Dashboard</span>
        </NavLink>
      </li>
      <li className="has-droupdown nav-item">
        <NavLink to="/teacher-create" className="navi-link">
          <i className="feather-video mr-2"></i>
          <span>Create Recorded Course</span>
        </NavLink>
        {/* <ul className="submenu">
          <li className="nav-item">
            <NavLink className="navi-link">Edit course</NavLink>
          </li>

          <li className="nav-item">
            <NavLink className="navi-link" to="/teacher-course-plan">
              Course plans
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="navi-link" to="/teacher-discount-voucher">
              Discount vouchers
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="navi-link" to="/teacher-watch-course">
              Watch course
            </NavLink>
          </li>
        </ul> */}
      </li>

      <li className="nav-item">
        <NavLink className="navi-link" to="/teacher-create-live-course">
          <i className="feather-camera mr-2"></i>
          <span>Create Live Course</span>
        </NavLink>
      </li>

      <li className="has-droupdown nav-item">
        <Link to="/teacher-course-list" className="navi-link">
          <i className="feather-video mr-2 "></i>
          <span>Courses</span>
        </Link>
      </li>
      <li className="has-droupdown nav-item">
        <NavLink to="/teacher-earnings" className="navi-link">
          <i className="feather-dollar-sign mr-2 "></i>
          <span>Earnings</span>
        </NavLink>
      </li>
      <li className="has-droupdown nav-item">
        <Link to="/teacher-enrollments" className="navi-link">
          <i className="feather-slack mr-2"></i>
          <span>Enrollments</span>
        </Link>
      </li>
      <li className="has-droupdown nav-item">
        <Link to="/teacher-my-courses" className="navi-link">
          <i className="feather-slack mr-2"></i>
          <span>Manage Courses</span>
        </Link>
      </li>
      <li className="has-droupdown nav-item">
        <Link to="/teacher-my-live-courses" className="navi-link">
          <i className="feather-camera mr-2"></i>
          <span>My Live Courses</span>
        </Link>
      </li>
      {/* <li className="has-droupdown nav-item">
        <Link to="/teacher-certificates-list" className="navi-link">
          <i className="feather-shopping-bag mr-2"></i>
          <span>Certificates</span>
        </Link>
      </li> */}

      <li className="nav-item">
        <NavLink className="navi-link" to="/teacher-reviews">
          <i className="feather-message-circle mr-2"></i>
          <span>Review</span>
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink className="navi-link" to="/teacher-setting">
          <i className="feather-message-circle mr-2"></i>
          <span>Settings</span>
        </NavLink>
      </li>
      {/* <li className="nav-item">
        <NavLink className="navi-link" to="/teacher-chat">
          <i className="feather-message-square mr-2"></i>
          <span>Chat</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="navi-link" to="/teacher-email">
          <i className="feather-mail mr-2"></i>
          <span>Email</span>
        </NavLink>
      </li>

      <div className="sidebar-heading">Authentication</div>

      <li className="nav-item">
        <NavLink className="navi-link" to="/login-teacher">
          <i className="feather-award mr-2"></i>
          <span>Login Teacher</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="navi-link" to="/register-teacher">
          <i className="feather-user mr-2"></i>
          <span>Register Teacher</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="navi-link" to="/forgot">
          <i className="feather-lock mr-2"></i>
          <span>Forgot Password</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="navi-link" to="/notfound">
          <i className="feather-log-out mr-2"></i>
          <span>Verify Password</span>
        </NavLink>
      </li>

      <div className="mt-3"></div>

      <div className="sidebar-card d-lg-flex mt-auto p-4">
        <p className="text-center mb-3 font-xsss text-grey-100 fw-500">
          Become a <b>pro</b> <br /> Get more <b>features</b>
        </p>
        <NavLink
          to="/admin"
          className="bg-success text-white rounded-xl btn-cart w125 d-inline-block text-center font-xsssss p-3 fw-700 ls-3"
        >
          ANALYTICS
        </NavLink>
      </div> */}
    </ul>
  );
};

export default Teachersidebar;
