import React, { Fragment, useEffect, useState } from "react";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import { getCourseByID } from "../services/adminServices/AdminServices";

import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { approveCourseByAdmin, disapproveCourseByAdmin } from "../services/adminServices/AdminServices"
import ProgressComponentRO from "../components/ViewOnlyComponents/ProgressComponentRO";
import { useDispatch } from "react-redux";
import { setCourse, setModuleData, setCurrentModuleID } from "../Redux/courseSlice";
import { Alert, Image, Ratio } from "react-bootstrap";

function Adminprojectsadd() {

  const params = useParams();
  const fetchCourses = async (token) => {
    try {
      // console.log(params.id)
      const response = await getCourseByID(token, courseID);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };
  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const [courseID] = useState(params.id);
  const [courses, setCourses] = useState([]);
  const [modules, setModules] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({ isVisible: false, message: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          setCourses(courses.data.course);
          setOpen(false);
          setModules(courses.data.modules);
          console.log(modules.length)
          dispatch(setCourse(courses.data.course));
          for (let i = courses.data.modules.length - 1; i >= 0; i--) {
            dispatch(setModuleData(courses.data.modules[0]));
          }
          dispatch(setModuleData(courses.data.modules));

          setModules(courses.data.modules);

          dispatch(setCurrentModuleID(courses.data.modules[0]._id));
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }
  }, [token]);

  const handleAccept = async () => {
    try {
      await approveCourseByAdmin(token, courseID)
        .then(response => {
          console.log(response)
          navigate("/admin-approvedCourse-list")
          setError({ isVisible: false, message: "" });
        })
        .catch(error => {
          console.log(error)
          setError({ isVisible: true, message: `Error Approving Course | ${error.response.data.errors[0].msg}` });
        })

    } catch (error) {
      console.log(error)
      setError({ isVisible: true, message: " Server Error Approving Course" });
    }
  }

  const handleReject = async () => {
    try {
      await disapproveCourseByAdmin(token, courseID)
        .then(response => {
          console.log(response)
          setError({ isVisible: false, message: "" });
          navigate("/admin-projectslist")
        })
        .catch(error => {
          console.log(error)
          setError({ isVisible: true, message: `Error Rejecting Course | ${error.response.data.errors[0].msg}` });
        })

    } catch (error) {
      console.log(error)
      setError({ isVisible: true, message: `Server Error Rejecting Course` });
    }
  }



  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 d-flex mb-4">
                  <h2 className="text-grey-900 font-md fw-700">Course Application Detail View</h2>
                  {
                    error.isVisible && (
                      <Alert variant="warning" className="ml-auto">
                        {error.message}
                      </Alert>
                    )
                  }

                  <i className="ml-auto feather-command font-lg text-grey-500"></i>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">Select the action after reviewing the course</h4>
                      <div className="d-flex justify-content-between align-items-center">
                        <button className="btn btn-success"
                          onClick={handleAccept}
                        >Approve Course</button>
                        <button className="btn btn-danger"
                          onClick={handleReject}
                        >Reject Application</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Course Information
                      </h4>
                      <form
                        className="contact_form"
                        name="contact_form"
                        action="#"
                        method="post"
                        noValidate=""
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group mb30">
                              <label className="form-label">
                                Project Title
                              </label>
                              <p className="form-text">
                                <b>
                                  {courses.title}
                                </b>
                              </p>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group mb30">
                              <label className="form-label">Instructor</label>
                              <p className="form-text">{courses.instructor}</p>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-group mb30">
                              <label className="form-label">Category</label>
                              <p className="form-text">{courses.domain_of_study}</p>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Project Description
                              </label>
                              <p className="form-text">
                                {courses.description}
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-label">
                                Course Price
                              </label>
                              <p className="form-text">
                              PKR  {courses.price}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Course Thumbnail
                              </label>
                              {courses.thumbnail ? (
                                <div style={{ width: 660, height: 400, margin: "0 auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <Ratio aspectRatio="16x9">
                                    <Image
                                      src={courses.thumbnail}
                                      alt="Thumbnail Preview"
                                      fluid
                                      style={{ marginTop: "10px", border: "1px solid #ced4da" }}
                                    />
                                  </Ratio>
                                </div>

                              ) : (
                                <p className="form-text" >No thumbnail to this course</p>
                              )}
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                <h2 className="font-xss text-grey-800 mb-4 mt-0 fw-700">Modules List: -</h2>
                              </label>
                              <ProgressComponentRO modules={modules}/>

                              {/* {modules?.map((module, index) => (
                                <div key={index} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                                  <h2 className="font-xss text-grey-800 mb-4 mt-0 fw-700">{index + 1}. Title: {module.title}</h2>
                                  <h3 className="font-xss text-grey-800 mb-4 mt-0 fw-700">Description:</h3>
                                  <p className="font-xss text-grey">
                                    {module.description}
                                  </p>
                                  {module?.video?.url && (
                                    <a href={module.video.url} target="_blank" rel="noreferrer">
                                      <Button>Preview Video Content</Button>
                                    </a>
                                  )}
                                </div>
                              ))} */}
                            </div>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Adminprojectsadd;
