export const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const now = new Date();

  const seconds = Math.floor((now - date) / 1000);

  // Define time intervals in seconds
  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
  };

  // Calculate the difference in each time interval
  let difference;
  for (const [interval, secondsInterval] of Object.entries(intervals)) {
    difference = Math.floor(seconds / secondsInterval);
    if (difference >= 1) {
      if (difference > 1) {
        return `${difference} ${interval}s ago`;
      } else {
        return `${difference} ${interval} ago`;
      }
    }
  }

  // If the time difference is less than a minute, show "Just now"
  return 'Just now';
};


export const calculateTimeLeft = (targetDateTime) => {
  const targetDate = new Date(targetDateTime);
  const currentDate = new Date();
  
  // Adjust current date by subtracting 5 hours
  currentDate.setHours(currentDate.getHours() );
  
  const timeDifference = targetDate.getTime() - currentDate.getTime();
  
  if (timeDifference > 0) {
    const secondsLeft = Math.floor(timeDifference / 1000);
    
    const years = Math.floor(secondsLeft / (3600 * 24 * 365));
    if (years > 0) return `${years} year${years > 1 ? 's' : ''} left`;
    
    const days = Math.floor(secondsLeft / (3600 * 24));
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} left`;
    
    const hours = Math.floor(secondsLeft / 3600);
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} left`;
    
    const minutes = Math.floor(secondsLeft / 60);
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} left`;
    
    return `${secondsLeft} second${secondsLeft > 1 ? 's' : ''} left`;
  } else {
    return 'The specified datetime has already passed.';
  }
}


export const DateForma = (dateTime) => {
  const date = new Date(dateTime);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}


export const getTimeWithAMPM = (dateTime) => {
  const date = new Date(dateTime);
  date.setHours(date.getHours() - 5); // Subtract 5 hours
  
  const hours = date.getHours();
  const minutes = date.getMinutes();
  
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const hourIn12Format = hours % 12 || 12;

  const formattedTime = `${hourIn12Format} ${minutes.toString().padStart(2, '0')} ${ampm}`;
  
  return formattedTime;
}
