import { createSlice } from "@reduxjs/toolkit";

const teacherSlice = createSlice({
  name: 'teacher_application',
  initialState: {
    teacherData: null,
    loggedIn: false, 
    moduleData: [],
    
  },
  reducers: {
    getuser(state, action) {
      state.teacherData = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.loggedIn = true;
    },
    logoutUser: (state) => {
      state.user = null;
      state.loggedIn = false;
    },
    getModuleData(state, action) {
      state.moduleData = action.payload;
    },
    // nullateModuleData(state, action) {
    //   state.moduleData = [];
    // },
    setModuleData(state, action) {
      // Check if action.payload is an array
      if (Array.isArray(action.payload)) {
          // Merge previous module data with new module data
          state.moduleData = [...state.moduleData, ...action.payload];
      } else {
          // If action.payload is not an array, simply append it to the existing state.moduleData
          state.moduleData = [...state.moduleData, action.payload];
      }
  },
  }
});

export const { getuser, setUser, logoutUser, setModuleData,getModuleData } = teacherSlice.actions;
export default teacherSlice.reducer;
