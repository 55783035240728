const initialState = {
    clickedValues: []
  };
  
  const clickedValuesReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_CLICKED_VALUE':
        return {
          ...state,
          clickedValues: [...state.clickedValues, action.payload]
        };
      default:
        return state;
    }
  };
  
  export default clickedValuesReducer;
  export const selectClickedValues = state => state.clickedValues;