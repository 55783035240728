import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { truncateTitle } from "../utils/utilityMethods";
import ReactStars from "react-rating-stars-component";
import { reviewByInstructor } from "../services/statistics/statistics";

import Pagination from "../components/Pagination";
import Teachersidebar from "../components/Teachersidebar";
import TeacherTopnav from "../components/TeacherTopNav";
import Teacherfooter from "../components/Teacherfooter";
import { TeacherProtection } from "../CustomHooks/TeacherProtection";

const reviewList = [
  {
    studentName: "Alice Johnson",
    courseName: "Mobile Product Design",
    reviewText: "Incredibly insightful with practical design tips.",
    rating: 4.5,
  },
  {
    studentName: "Mark Robinson",
    courseName: "HTML Developer",
    reviewText: "A comprehensive journey through HTML.",
    rating: 4,
  },
  {
    studentName: "Sophia Turner",
    courseName: "Advanced CSS and Sass",
    reviewText: "Transformed my approach to modern web design.",
    rating: 5,
  },
  {
    studentName: "Lucas Morgan",
    courseName: "Modern React with Redux",
    reviewText: "Detailed explanations and great project-based learning.",
    rating: 4.8,
  },
  {
    studentName: "Emma Watson",
    courseName: "Node JS",
    reviewText: "Node.js was demystified, and I feel much more confident now!",
    rating: 4.2,
  },
  {
    studentName: "Noah Anderson",
    courseName: "Python for Data Science",
    reviewText: "A perfect blend of theory and practical examples.",
    rating: 4.7,
  },
  {
    studentName: "Olivia Smith",
    courseName: "Digital Marketing Essentials",
    reviewText: "Loved the real-world marketing strategies that were taught.",
    rating: 4.3,
  },
  {
    studentName: "James Johnson",
    courseName: "Blockchain Basics",
    reviewText:
      "Excellent intro to blockchain technology and its applications.",
    rating: 4.6,
  },
  {
    studentName: "Mia Williams",
    courseName: "Introduction to AI",
    reviewText:
      "Artificial Intelligence is no longer a mystery to me, thanks to this course.",
    rating: 5,
  },
  {
    studentName: "William Brown",
    courseName: "UX/UI Design Fundamentals",
    reviewText:
      "The UX/UI principles learned here have been game-changers for my designs.",
    rating: 4.9,
  },
];

function TeacherReviews() {
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    reviewByInstructor(user._id).then((response) => {
      setReviews(response.data);
      const avgRating =
        response.data.reduce((acc, review) => acc + review.rating, 0) /
          response.data.length || 0;
      setAverageRating(avgRating);
      console.log(response.data);
    });
  }, []);

  TeacherProtection();
  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex px-4 pt-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Review List
                      </h4>
                      {/* <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select> */}
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-teacher mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0">Student</th>
                              <th className="border-0">Review</th>
                              <th className="border-0">Rating</th>
                              <th className="border-0">Course</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reviews.map((review, index) => (
                              <tr key={index}>
                                <td className="product-thumbnail text-start pl-0">
                                  <b style={{ fontSize: "0.9rem" }}>
                                    {review.user.name}
                                  </b>
                                </td>
                                <td>
                                  <p className="fw-500 font-xssss review-text">
                                    "{review.comment}"
                                  </p>
                                </td>
                                <td>
                                  <div className="star-rating">
                                    {
                                      <ReactStars
                                        count={5}
                                        value={review.rating.toFixed(1)}
                                        size={24}
                                        activeColor="#ffd700"
                                        isHalf={true}
                                        edit={false}
                                      />
                                    }
                                  </div>
                                </td>
                                <td>
                                  <b className="course-name">
                                    {review.course.title}
                                  </b>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <Teacherfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TeacherReviews;
