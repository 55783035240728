import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

// import Adminsidebar from "../components/Adminsidebar";
// import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import Pagination from "../components/Pagination";
import Teachersidebar from "../components/Teachersidebar";
// import Slider from "react-slick";

import TeacherTopnav from "../components/TeacherTopNav";

// Services
import { getAllCourses } from "../services/teacherServices/TeacherServices";
import { useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { nullateData } from "../Redux/courseSlice.jsx";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";
import CourseCard from "../components/courseCard.js";

function TeacherCourseList() {
  TeacherProtection();
  const [courses, setCourses] = React.useState([]);
  const [token] = React.useState(JSON.parse(localStorage.getItem("token")));
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          console.log(courses.data.courses);
          setCourses(courses.data.courses);
          setOpen(false);
          dispatch(nullateData());
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }
  }, []);

  const fetchCourses = async (token) => {
    try {
      const response = await getAllCourses(token);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };

  const truncateTitle = (title) => {
    if (title.length <= 17) {
      return title;
    } else {
      return title.substring(0, 17) + "...";
    }
  };

  // const popularSlider = {
  //   arrows: false,
  //   dots: false,
  //   infinite: true,
  //   speed: 300,
  //   centerMode: false,
  //   variableWidth: true,
  // };
  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>

      <div id="wrapper">
        <Teachersidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="">
                    <div className="">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Courses List
                      </h4>
                      {/* <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select> */}
                    </div>
                    <div className="card-body p-4">
                      <div className="col-lg-12 mt-3 d-flex flex-row flex-wrap">
                        {courses.map((value, index) => (

                          <CourseCard key={index} value={value} bought={true} teacher={true} />
                          // <div
                          //   className="card course-card w250 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-3 mb-4"
                          //   key={value._id}
                          // >
                          //   <div className="card-image w-100 mb-3">
                          //     <Link
                          //       to={`/default-course-one/${value._id}`}
                          //       className="video-bttn position-relative d-block"
                          //     >
                          //       <img
                          //         src={`${value.thumbnail}`}
                          //         alt="course"
                          //         className="w-100"
                          //       />
                          //     </Link>
                          //   </div>
                          //   <div className="card-body pt-0">
                          //     <span
                          //       className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 ${value.status}`}
                          //     >
                          //         {truncateTitle(value.domain_of_study)}
                          //     </span>
                          //     <span className="font-xss fw-700 pl-3 pr-3 ls-2 lh-32 d-inline-block text-success float-right">
                          //       <span className="font-xsssss">PKR</span>
                          //       {value.price}
                          //     </span>
                          //     <h4 className="fw-700 font-xss mt-3 lh-28 mt-0">
                          //       <Link
                          //         to={`/default-course-one/${value._id}`}
                          //         className="text-dark text-grey-900"
                          //       >
                          //         {(value.title)}
                          //       </Link>
                          //     </h4>
                          //     {/* <h6 className="font-xssss text-grey-500 fw-600 ml-0 mt-2">
                          //       {value.lesson} Lesson
                          //     </h6> */}
                          //     {/* <ul className="memberlist mt-3 mb-2 ml-0 d-block">
                          //       <li>
                          //         <a href="/">
                          //           <img
                          //             src="assets/images/user.png"
                          //             alt="avater"
                          //             className="w30 d-inline-block rounded-circle"
                          //           />
                          //         </a>
                          //       </li>
                          //       <li>
                          //         <a href="/">
                          //           <img
                          //             src="assets/images/user.png"
                          //             alt="avater"
                          //             className="w30 d-inline-block rounded-circle"
                          //           />
                          //         </a>
                          //       </li>
                          //       <li>
                          //         <a href="/">
                          //           <img
                          //             src="assets/images/user.png"
                          //             alt="avater"
                          //             className="w30 d-inline-block"
                          //           />
                          //         </a>
                          //       </li>
                          //       <li>
                          //         <a href="/">
                          //           <img
                          //             src="assets/images/user.png"
                          //             alt="avater"
                          //             className="w30 d-inline-block rounded-circle"
                          //           />
                          //         </a>
                          //       </li>
                          //       <li className="last-member">
                          //         <a
                          //           href="/"
                          //           className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center"
                          //         >
                          //           +2
                          //         </a>
                          //       </li>
                          //       <li className="pl-4 w-auto">
                          //         <a
                          //           href="/"
                          //           className="fw-500 text-grey-500 font-xssss"
                          //         >
                          //           Student apply
                          //         </a>
                          //       </li>
                          //     </ul> */}
                          //   </div>
                          // </div>
                          /* <div className="col-lg-4 col-md-6 d-flex">
                            <div className="course-box d-flex aos">
                              <div className="product">
                                <div className="product-img">
                                  <Link
                                    to={`/course/${value._id}`}
                                    className="video-bttn position-relative d-block"
                                  >
                                    <img
                                      className="img-fluid"
                                      alt=""
                                      src={`${value.thumbnail}`}
                                    />
                                  </Link>
                                  <div className="price">
                                    <h3>PKR {value.price}</h3>
                                  </div>
                                </div>
                                <div className="product-content">
                                  <div className="course-group d-flex">
                                    <div className="course-group-img d-flex">
                                      <div className="course-name"></div>
                                    </div>
                                    <div className="course-share d-flex align-items-center justify-content-center">
                                      <a href="#">
                                        <i className="fa-regular fa-heart"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <h3 className="title instructor-text">
                                    <Link
                                      to={`/course/${value._id}`}
                                      className="text-dark text-grey-900 capitalize"
                                    >
                                      {value.title}
                                    </Link>
                                  </h3>
                                  <div className="course-info d-flex align-items-center">
                                    <div className="rating-img d-flex align-items-center">
                                      <img
                                        src="assets/img/icon/icon-01.svg"
                                        alt=""
                                      />
                                      <p> {value.lesson} </p>
                                    </div>
                                  </div>

                                  <div className="all-btn all-category d-flex justify-content-center align-items-center">
                                    <a
                                      href="checkout.html"
                                      className="btn btn-primary"
                                    >
                                      {value.domain_of_study}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */
                        ))}
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TeacherCourseList;
