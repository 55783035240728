import httpClient from "../http-common";


// Enroll in a course
export const FollowInstructor = (token,instructorID) => {
  return httpClient.post(`/followings/follow/${instructorID}`, {},{
    headers: {
      'x-auth-token': token
    },
  });
};

export const GetFollowerListInstructor = (studentID) => {
  return httpClient.get(`/followings/instructors/${studentID}/followers`);
};

export const GetFolowingOfUSer = (id) => {
  return httpClient.get(`/followings/students/${id}/following`);
};

