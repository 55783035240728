import React, { Fragment, useEffect, useState } from "react";
import Teachersidebar from "../components/Teachersidebar";
import TeacherTopnav from "../components/TeacherTopNav";
import Teacherfooter from "../components/Teacherfooter";
import {
  getCompleteCourse,
  publishCourse,
} from "../services/courseServices/courseServices";
import { editCourse } from "../services/teacherServices/TeacherServices.js";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import {
  setCourse,
  setModuleData,
  setCurrentModuleID,
} from "../Redux/courseSlice.jsx";
import ProgressComponent from "../components/ModuleComponents/ProgressComponent.jsx";
import { Backdrop, CircularProgress } from "@mui/material";
import Alert from "react-bootstrap/Alert";
import AddModuleForm from "../TestComponent.jsx";
import ThumbnailEdit from "../components/ModuleComponents/ThumbnailEdit.jsx";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";

function TeacherCourseEditForm() {
  TeacherProtection();
  const params = useParams();
  const dispatch = useDispatch();

  const fetchCourses = async (token) => {
    try {
      const response = await getCompleteCourse(token, courseID);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };

  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [courseID] = useState(params.id);
  const [token] = useState(JSON.parse(localStorage.getItem("token")));

  useEffect(() => {
    window.scrollTo(0, 0);
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          setCourses(courses.data);
          setOpen(false);
          localStorage.setItem("courseID", courses.data.course._id);
          // console.log(courses.data.modules);
          dispatch(setCourse(courses.data.course));
          for (let i = courses.data.modules.length - 1; i >= 0; i--) {
            dispatch(setModuleData(courses.data.modules[i]));
          }
          dispatch(setCurrentModuleID(courses.data.modules[0]._id));
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }
  }, [token]);

  // State for input fields
  const [publishButonRES, setpublishButonRES] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [courseStatus, setCourseStatus] = useState("");
  const [price, setPrice] = useState("");
  const [thubnail, setThubmnail] = useState(null);
  const [error, setError] = useState({
    message: "",
    visible: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    visible: false,
  });

  // Handler for saving changes
  const handleLogChanges = async () => {
    const data = { title, category, description, price, courseID };
    try {
      await editCourse(token, data)
        .then((response) => {
          console.log(response.data);
          setSuccess({
            message: "Course information has been updated successfully",
            visible: true,
          });
          setError({
            message: "",
            visible: false,
          });
        })
        .catch((error) => {
          console.log(error);
          setSuccess({
            message: "",
            visible: false,
          });
          setError({
            message: `Error updating course ${error.response.data.errors[0].msg}`,
            visible: true,
          });
        });
    } catch (error) {
      console.log(error);
      setSuccess({
        message: "",
        visible: false,
      });
      setError({
        message: "Server Error updating course",
        visible: true,
      });
    }
  };

  useEffect(() => {
    if (courses.course) {
      setTitle(courses.course.title);
      setCategory(courses.course.domain_of_study);
      setDescription(courses.course.description);
      setCourseStatus(courses.course.status);
      setPrice(courses.course.price);
      setThubmnail(courses.course.thumbnail);
    }
  }, [courses]);

  const HandlepublishCourse = async () => {
    try {
      setOpen(true);
      await publishCourse(token, courseID)
        .then((response) => {
          console.log(response.data);

          setSuccess({
            message: ` ${response.data.msg}`,
            visible: true,
          });
          setError({
            message: "",
            visible: false,
          });
          setOpen(false);
          setpublishButonRES(true);
        })
        .catch((error) => {
          console.log(error);
          setSuccess({
            message: "",
            visible: false,
          });
          setError({
            message: `Error publishing course ${error.response.data.errors[0].msg}`,
            visible: true,
          });
          setOpen(false);
        });
    } catch (error) {
      console.log(error);
      setSuccess({
        message: "",
        visible: false,
      });
      setError({
        message: "Server Error publishing course",
        visible: true,
      });
      setOpen(false);
    }
  };

  const styles3 = {
    submitButton: {
      marginTop: "20px",
      textAlign: "center",
    },
    submitButtonButton: {
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "10px 20px",
      cursor: "pointer",
    },
    submitButtonButtonHover: {
      backgroundColor: "#45a049",
    },
  };

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>
      <div id="wrapper">
        <Teachersidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 d-flex mb-4">
                  <h2 className="text-grey-900 font-md fw-700">Edit Course </h2>
                  {courseStatus === "draft" && (
                    <div className="col-lg-6 d-flex mb-4">
                      <Button
                        onClick={HandlepublishCourse}
                        disabled={open || publishButonRES}
                        type="submit"
                        style={styles3.submitButtonButton}
                      >
                        Publish Course
                      </Button>
                    </div>
                  )}
                  {courseStatus === "pending" && (
                    <div className="col-lg-6 d-flex mb-4">
                      <Alert variant="warning">
                        This course verification is pending. Stay tuned we'll
                        notify you.{" "}
                      </Alert>
                    </div>
                  )}
                  {courseStatus === "published" && (
                    <div className="col-lg-6 d-flex mb-4">
                      <Alert variant="warning">
                        Your course is live. Make sure you apply changes
                        according to Seekho Policies
                      </Alert>
                    </div>
                  )}
                  {courseStatus === "disapproved" && (
                    <div className="col-lg-6 d-flex mb-4">
                      <Alert variant="danger">
                        This course was rejected by seekho team.
                      </Alert>
                    </div>
                  )}
                  <i className="ml-auto feather-command font-lg text-grey-500"></i>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="card border-0 px-4 pt-4 mt-2 rounded-lg admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-4 mt-0 fw-700">
                        Course Information
                      </h4>
                      {error.visible && (
                        <div className="row">
                          <div className="col-md-12">
                            <Alert variant="danger">{error.message}</Alert>
                          </div>
                        </div>
                      )}
                      {success.visible && (
                        <div className="row">
                          <div className="col-md-12">
                            <Alert variant="success">{success.message}</Alert>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label">
                              Title
                            </label>
                            <input
                              name="title"
                              className="form-control form_control"
                              type="text"
                              placeholder="Property Title *"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb30">
                            <label htmlFor="product_sku" className="form-label">
                              Category
                            </label>
                            <input
                              name="category"
                              className="form-control form_control"
                              type="text"
                              placeholder="Type"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="product_sku" className="form-label">
                              Description
                              <small className="form-text text-muted">
                                {description.length} / 1200 characters
                              </small>
                            </label>
                            <textarea
                              name="description"
                              className="form-control h30"
                              rows="3"
                              placeholder="Course Description *"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              maxLength={1200} // Set the maximum length to 300 characters
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="product_sku" className="form-label">
                            Course Status
                          </label>
                          <input
                            name="courseStatus"
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            disabled={true}
                            value={courseStatus}
                            onChange={(e) => setCourseStatus(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="product_size" className="form-label">
                            Price (PKR)
                          </label>
                          <input
                            name="price"
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            value={price}
                            onChange={(e) => {
                              const input = e.target.value;
                              // Check if input is numerical
                              if (/^\d*$/.test(input)) {
                                setPrice(input);
                              }
                            }}
                          />
                        </div>
                        <div className="col-lg-12 d-flex mb-4 justify-content-center">
                          <Button
                            disabled={open || success.visible}
                            onClick={handleLogChanges}
                            style={styles3.submitButtonButton}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 p-4 mt-3 rounded-10 admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-1 mt-0 fw-700">
                        Thumbnail Image
                      </h4>

                      <div className="row">
                        <div className="col-md-12">
                          <ThumbnailEdit title={title} thumbnail={thubnail} courseID={courseID} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 p-4 mt-3 rounded-10 admin-form">
                    <div className="card-body d-block">
                      <h4 className="font-xss text-grey-800 mb-1 mt-0 fw-700">
                        Modules Information
                      </h4>

                      <div className="row">
                        <div className="col-md-12">
                          <AddModuleForm showProgress={true} />
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-md-12">
                          <ProgressComponent />
                        </div>
                      </div> */}
                      {/* <div className="col-lg-6 d-flex mb-4 justify-content-center">
                          <Button
                            onClick={handleLogChanges}
                            className="text-white-900 font-md fw-700"
                          >
                            Save Changes
                          </Button>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Teacherfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TeacherCourseEditForm;
