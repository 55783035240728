import React, { useState, Fragment, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import Pagination from "../components/Pagination";
import Teachersidebar from "../components/Teachersidebar";
import TeacherTopnav from "../components/TeacherTopNav";
import Teacherfooter from "../components/Teacherfooter";
import { Link } from "react-router-dom";
import { deleteCourse, getAllCourses } from "../services/teacherServices/TeacherServices";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";


import {
  nullateData
} from "../Redux/courseSlice.jsx";
import { formatDate } from "../utils/formatDate.js";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";

function TeacherCourses() {
  TeacherProtection();
  const [location, setLocation] = useState(false);

  const handleModel = (id) => {
    setLocation(!location);
    setCourseIdToDelete(id)
  };

  const [courseIdToDelete, setCourseIdToDelete] = useState("");

  const handleDeleteModule = async  () => {
    setOpen(true)
    try {
      // console.log(courseIdToDelete);
      setLocation(!location);
      await deleteCourse(token, courseIdToDelete)
      .then((response) => { 
        // console.log(response.data);
        setOpen(false);
        setFetcher(fetcher+1)
      })
      .catch((error) => {
        console.error(error.response.data.errors[0].msg);
        setOpen(false);
      });
  
    } catch (error) {
      console.log(error)
    }

  };

  const dispatch = useDispatch();

  const fetchCourses = async (token) => {
    try {
      const response = await getAllCourses(token);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };
  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [fetcher,setFetcher] = useState(0)

  useEffect(() => {
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          // console.log(courses.data.courses);
          setCourses(courses.data.courses);
          setOpen(false);
          dispatch(nullateData());
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });

    }

  }, [fetcher]);
  const sortedData = courses.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>
      <div id="wrapper">
        <Teachersidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex p-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        My Courses List
                      </h4>
                      {/* <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select> */}
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0">Sr.</th>
                              <th className="border-0" scope="col">
                                Course Title
                              </th>

                              {/* <th className="border-0" scope="col">
                                Course
                              </th> */}
                              <th className="border-0" scope="col">
                                Status
                              </th>
                              <th className="border-0" scope="col">
                                Last Update
                              </th>
                              <th scope="col" className="text-end border-0">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {sortedData.map((value, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <b>{value.title}</b>
                                </td>
                                {/* <td>{value.courseName}</td> */}
                                <td>
                                  <span
                                    className={`badge rounded-pill font-xsssss fw-700 pr-3 pl-3 lh-24 text-uppercase rounded-3 ls-2 alert-primary`}
                                  >
                                    {value.status}
                                  </span>
                                </td>
                                <td>{formatDate(value.updatedAt)}</td>
                                <td className="product-remove text-end">
                                  <Link
                                    to={`/teacher-course-edit-form/${value._id}`}
                                  >
                                    <i className="feather-edit me-1 font-xs text-grey-500"></i>
                                  </Link>
                                  {
                                    (value.status === "draft" || value.status === "disapproved") && (
                                      <Button
                                      className="bg-transparent border-0"
                                      onClick={() => {
                                        handleModel(value._id);
                                      }}
                                    >
                                      <i className="ti-trash  font-xs text-danger"></i>
                                    </Button>
                                    )
                                  }
                                  <Modal
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={location}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleModel();
                                      }}
                                      className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                    ></Button>
                                    <Modal.Body className="text-center p-4">
                                      <i className="ti-info-alt text-warning display4-size"></i>
                                      <p className="text-grey-500 font-xsss mt-3 mb-4">
                                        Are you sure you want to delete course?
                                      </p>

                                      <Button
                                        onClick={() => {
                                          handleDeleteModule();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                      >
                                        Yes, delete!
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                      >
                                        No, cancle!
                                      </Button>
                                    </Modal.Body>
                                  </Modal>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <Teacherfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default TeacherCourses;
