import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setUser } from "../Redux/userSlice";
import { useAuth } from "../context/authContext.tsx";
import {
  signInUser,
  authorizeUser,
} from "../services/userServices/UserServices";
import {
  getSecurityQuestionUser,
  changePasswordUser,
} from "../services/userServices/UserServices";

const ForgotPassword = () => {
  var { isAuthenticated, login, logout } = useAuth();
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [securityQuestion, setSecurityQuestion] = useState("");
  const [securityAnswer, setSecurityAnswer] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    const response = await changePasswordUser(
      email,
      securityQuestion,
      securityAnswer,
      newPassword
    );
    alert("Password Changed Successfully");
    window.location.href = "/login";
  };

  const fetchSecurityQuestion = async (email) => {
    try {
      const response = await getSecurityQuestionUser(email);
      setSecurityQuestion(response.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="main-wrap">
      <div className="row">
        <div
          className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
          style={{
            backgroundImage: `url("/assets/images/log.jpg")`,
          }}
        ></div>

        <div
          className="col-xl-7 vh-lg-100 align-items-center d-flex rounded-lg overflow-hidden"
          style={{ backgroundColor: "#e8f1fa" }}
        >
          <div
            className="card shadow-none border-0 ml-auto mr-auto login-card"
            style={{ backgroundColor: "#e8f1fa" }}
          >
            <div className="card-body rounded-0 text-left">
              <h2 className="fw-700 display1-size display2-md-size mb-3">
                Forgot Password
              </h2>

              <div className="form-group icon-input mb-3">
                <i className="font-sm ti-email text-grey-500 pr-0"></i>
                <input
                  type="text"
                  className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid black",
                  }}
                />
                {/* <span className="text-danger">{errors.email?.message}</span> */}
              </div>
              <Button
                disabled={!email}
                onClick={() => {
                  fetchSecurityQuestion(email);
                }}
                className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0 mt-2"
              >
                Get Security Question
              </Button>

              {error.visible && <Alert variant="danger">{error.message}</Alert>}
              <form onSubmit={handleSubmit} className="mt-4">
                {securityQuestion && (
                  <label className="text-grey-500 font-xsss fw-600">
                    Security Question: {securityQuestion}
                  </label>
                )}
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                  <input
                    type="text"
                    className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600`}
                    value={securityAnswer}
                    onChange={(e) => setSecurityAnswer(e.target.value)}
                    placeholder="Security Answer"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    }}
                  />
                  {/* <span className="text-danger">{errors.email?.message}</span> */}
                </div>
                <div className="form-group icon-input mb-3">
                  <input
                    type="Password"
                    className={`style2-input pl-5 form-control text-grey-900 font-xss ls-3`}
                    placeholder="New Password"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {/* <span className="text-danger">
                    {errors.password?.message}
                  </span> */}
                  <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                </div>
                <div className="form-group icon-input mb-3">
                  <input
                    type="Password"
                    className={`style2-input pl-5 form-control text-grey-900 font-xss ls-3`}
                    placeholder="Confirm New Password"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    }}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {/* <span className="text-danger">
                    {errors.password?.message}
                  </span> */}
                  <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                </div>

                <Button
                  disabled={newPassword != confirmPassword}
                  type="submit"
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                >
                  Change Password
                </Button>
              </form>

              <div className="col-sm-12 p-0 text-left">
                <div className="form-group mb-1">
                  {/* Your existing HTML code here */}
                </div>
                <h6 className="text-grey-500 font-xssss fw-500 mt-0 mb-0 lh-32">
                  Remember Your Password{" "}
                  <Link to="/login" className="fw-700 ml-1">
                    Login
                  </Link>
                </h6>
              </div>
              {/* <div className="col-sm-12 p-0 text-center mt-2">
                <h6 className="mb-0 d-inline-block fw-500 font-xssss text-grey-500 mb-3">
                  Or, Sign in with your social account{" "}
                </h6>
                <div className="form-group mb-1">
                  <a
                    href="/login-google"
                    className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"
                  >
                    <img
                      src="https://via.placeholder.com/50x50.png"
                      alt="icon"
                      className="ml-2 w40 mb-1 mr-5"
                    />{" "}
                    Sign in with Google
                  </a>
                </div>
                <div className="form-group mb-1">
                  <a
                    href="/login-facebook"
                    className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0 "
                  >
                    <img
                      src="https://via.placeholder.com/50x50.png"
                      alt="icon"
                      className="ml-2 w40 mb-1 mr-5"
                    />{" "}
                    Sign in with Facebook
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
