// example course object{
//   "_id": "662206da56ff33190c192035",
//   "title": "Game Development Crash Course",
//   "description": "Jump into the exciting world of game development with our intensive crash course designed to take you from novice to game creator in just a few weeks. This course covers everything you need to know to start building engaging and interactive video games across various platforms.",
//   "instructor": {
//       "_id": "6622046556ff33190c191fcf",
//       "name": "Mohsin Mehdi",
//       "email": "mohsinm@gmail.com"
//   },
//   "isActive": false,
//   "status": "published",
//   "thumbnail": "http://res.cloudinary.com/dkvyeo2zf/image/upload/v1713506281/e8jbgnj1yzuhmdaeq5qw.png",
//   "domain_of_study": "Programming and Development",
//   "price": 2000,
//   "createdAt": "2024-04-19T05:53:30.176Z",
//   "updatedAt": "2024-04-19T05:58:03.662Z",
//   "__v": 0,
//   "score": 13
// }

import React, { Fragment, useState, useEffect } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Myclass from "../components/Myclass";
import Subscribe from "../components/Subscribe";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { searchCourses } from "../services/courseServices/courseServices";
import SearchBar from "../components/searchBar.tsx";
import CourseCard from "../components/courseCard.js";

const Defaultsearch = () => {
  const [courseList, setCourseList] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("relevance");

  const { query, category } = useParams();

  const fetchCourses = async () => {
    try {
      const response = await searchCourses(query, category);
      let sortedCourses = response.data;

      if (sortCriteria === "priceAsc") {
        sortedCourses = sortedCourses.sort((a, b) => a.price - b.price);
      } else if (sortCriteria === "priceDesc") {
        sortedCourses = sortedCourses.sort((a, b) => b.price - a.price);
      } else if (sortCriteria === "createdDateAsc") {
        sortedCourses = sortedCourses.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      } else if (sortCriteria === "createdDateDesc") {
        sortedCourses = sortedCourses.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      } else if (sortCriteria === "updatedDateAsc") {
        sortedCourses = sortedCourses.sort(
          (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
        );
      } else if (sortCriteria === "updatedDateDesc") {
        sortedCourses = sortedCourses.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
      }

      setCourseList(sortedCourses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [query, category, sortCriteria]);

  return (
    <Fragment>
      <div className="main-wrapper">
        <header className="header">
          <div className="header-fixed">
            <nav className="navbar navbar-expand-lg header-nav scroll-sticky">
              <div className="container">
                <div className="navbar-header">
                  <a id="mobile_btn" href="javascript:void(0);">
                    <span className="bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </a>
                  <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                    Seekho.pk
                    <span className="d-block font-xsssss ls-1 text-grey-500 open-font ">
                      Online Learning Course
                    </span>
                  </h1>
                </div>
                <div className="main-menu-wrapper">
                  <div className="menu-header">
                    <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                      Seekho.pk
                      <span className="d-block font-xsssss ls-1 text-grey-500 open-font ">
                        Online Learning Course
                      </span>
                    </h1>
                    <a
                      id="menu_close"
                      className="menu-close"
                      href="javascript:void(0);"
                    >
                      <i className="fas fa-times"></i>
                    </a>
                  </div>
                  <ul className="main-nav">
                    <li className="active">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="">
                      <Link to="/teacher-carousel">Teach on Seeko</Link>
                    </li>

                    <li className="has-submenu">
                      <Link to="/default-public-search/All/All">
                        All Courses
                      </Link>
                      {/* <ul className="submenu">
                        <li>
                          <a href="blog-list.html">Blog List</a>
                        </li>
                        <li>
                          <a href="blog-grid.html">Blog Grid</a>
                        </li>
                        <li>
                          <a href="blog-masonry.html">Blog Masonry</a>
                        </li>
                        <li>
                          <a href="blog-modern.html">Blog Modern</a>
                        </li>
                        <li>
                          <a href="blog-details.html">Blog Details</a>
                        </li>
                      </ul> */}
                    </li>
                    <li className="login-link">
                      <a href="login.html">Login / Signup</a>
                    </li>
                  </ul>
                </div>
                <ul className="nav header-navbar-rht">
                  <li className="nav-item">
                    <Link className="nav-link header-sign" to="/login">
                      Student
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link header-sign" to="/login-teacher">
                      Teacher
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <div className="main-content " style={{
          marginTop: "100px"
        }}>
          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <div
                    className="card rounded-xxl p-lg--5 border-0 bg-no-repeat"
                    style={{ backgroundColor: `#e4f7fe` }}
                  >
                    <div className="card-body w-100 p-4">
                      <div className="form-group mt-3 p-3 border-light border p-2 bg-white rounded-lg">
                        <SearchBar
                          onClicked={fetchCourses}
                          public={false}
                          searchPagePublic={true}
                        />
                      </div>
                      <h4 className="text-grey-500 font-xssss fw-500 ml-1 lh-24">
                        <b className="text-grey-800 text-dark">
                          Popular Search :
                        </b>{" "}
                        Web Development, Data Science, Machine Learning, Python
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 pt-2 mb-4">
                  <h2 className="fw-400 font-lg">
                    Search <b>Results</b>
                    <span className="fw-500 ml-2 text-grey-500 font-xssss">
                      ( {courseList.length} course(s) found )
                    </span>
                    <a href="/" className="float-right">
                      <i className="feather-edit text-grey-500 font-xs"></i>
                    </a>
                  </h2>
                  <div className="form-group">
                    <label htmlFor="sort" className="mr-2">
                      Sort by:
                    </label>
                    <select
                      id="sort"
                      className="custom-select w-auto"
                      value={sortCriteria}
                      onChange={(e) => setSortCriteria(e.target.value)}
                    >
                      <option value="relevance">Relevance</option>
                      <option value="priceAsc">Price: Low to High</option>
                      <option value="priceDesc">Price: High to Low</option>
                      <option value="createdDateAsc">
                        Created Date: Oldest First
                      </option>
                      <option value="createdDateDesc">
                        Created Date: Newest First
                      </option>
                      <option value="updatedDateAsc">
                        Updated Date: Oldest First
                      </option>
                      <option value="updatedDateDesc">
                        Updated Date: Newest First
                      </option>
                    </select>
                  </div>
                </div>
                {courseList.length > 0 &&
                  courseList.map((value, index) => (
                    // Start Single Demo
                    <CourseCard key={index} value={value} homePage={true} />
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
        <footer className="footer">
          <div className="footer-top aos">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-widget footer-about">
                    <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                      Seekho.pk
                    </h1>
                    <div className="footer-about-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut consequat mauris Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Ut consequat mauris
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">For Instructor</h2>
                    <ul>
                      <li>
                        <a href="/login-teacher">Login</a>
                      </li>
                      <li>
                        <a href="/teacher-carousel">Teach on Seekho</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">For Student</h2>
                    <ul>
                      <li>
                        <a href="/login">Login</a>
                      </li>
                      <li>
                        <a href="/register">Register</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="footer-widget footer-contact">
                    <h2 className="footer-title">News letter</h2>
                    <div className="news-letter">
                      <form>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email address"
                          name="email"
                        />
                      </form>
                    </div>
                    <div className="footer-contact-info">
                      <div className="footer-address">
                        <img
                          src="assets/img/icon/icon-20.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p> Pakisthan</p>
                      </div>
                      <p>
                        <img
                          src="assets/img/icon/icon-19.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <a
                          href="cdn-cgi/l/email-protection.html"
                          className="__cf_email__"
                        >
                          contact@seekho.com
                        </a>
                      </p>
                      <p className="mb-0">
                        <img
                          src="assets/img/icon/icon-21.svg"
                          alt=""
                          className="img-fluid"
                        />
                        +92 12345-67890
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container">
              <div className="copyright">
                <div className="row">
                  <div className="col-md-6">
                    <div className="privacy-policy">
                      <ul>
                        <li>
                          <a href="term-condition.html">Terms</a>
                        </li>
                        <li>
                          <a href="privacy-policy.html">Privacy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="copyright-text">
                      <p className="mb-0">&copy; Seekho.pk</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  );
};

export default Defaultsearch;
