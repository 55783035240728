// UserDialog.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const UserDialog = ({message, role }) => {
  const [show, setShow] = useState(false);
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = () => {
      const userDetailsStr = localStorage.getItem("user");
      const showPopUp = JSON.parse(localStorage.getItem("showPopup"));

      if (userDetailsStr && showPopUp) {
        const userDetails = JSON.parse(userDetailsStr);
        setUserDetails(userDetails);
        // Check if the user's role matches the expected role
        if (userDetails?.role === getExpectedRole(role)) {
          setShow(true);
        }

        localStorage.setItem("showPopup", JSON.parse(false));

      }
    };

    fetchUserDetails();
  }, [role]);

  const handleClose = () => setShow(false);

  // Function to get the expected role ID based on the role prop
  const getExpectedRole = (role) => {
    switch (role) {
      case "user":
        return "65d1efbf3c2a238750902a07";
      case "admin":
        return "65d1efbf3c2a238750902a08";
      case "teacher":
        return "65d1efbf3c2a238750902a09";
      default:
        return "";
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>seekho.pk</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Welcome, {userDetails?.name}!</p>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} size="lg">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserDialog;
