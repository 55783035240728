import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import {
  signInUser,
  authorizeUser,
} from "../services/userServices/UserServices";

import { useDispatch } from "react-redux";
import { setUser } from "../Redux/adminSlice";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    setLoading(true);
    // Make API call to authenticate user
    // Replace this with your actual API call
    try {
      setLoading(true);
      await signInUser(data)
        .then((res) => {
          setLoading(true);
          // console.log(res.data.token)
          // localStorage.setItem("token", (res.data.token));
          localStorage.setItem("token", JSON.stringify(res.data.token));
          // console.log(res.data.msg)
          isAuthorized(res.data.token);
        })
        .catch((err) => {
          // console.log(err.response.data.errors)
          console.log(err.response.data.errors[0].msg);
          setError({
            visible: true,
            message: err.response.data.errors[0].msg,
          });
          setLoading(false);
        });
    } catch (err) {
      setError({
        visible: true,
        message: "Server Error",
      });
      setLoading(false);
    }
  };

  const isAuthorized = async (token) => {
    await authorizeUser(token, "admin")
      .then((res) => {
        // console.log(res.data)
        localStorage.setItem("user", JSON.stringify(res.data));
        dispatch(setUser(res.data));
        setTimeout(() => {
          navigate("/admin");
        }, 2500);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError({
          visible: true,
          message: err.response.data.errors[0].msg,
        });
      });
  };

  return (
    <div className="main-wrap">
      <div className="row">
        <div
          className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
          style={{
            backgroundImage: `url("/assets/images/log.jpg")`,
          }}
        ></div>
        <Link
          to="/"
          className="d-inline-block mt-2 "
          style={{ position: "absolute", top: "100px", left: "100px" }}
        >
          <i className="ti-arrow-left font-sm text-black"></i>
        </Link>
        <div
          className="col-xl-7 vh-lg-100 align-items-center d-flex rounded-lg overflow-hidden"
          style={{ backgroundColor: "#e8f1fa" }}
        >
          <div
            className="card shadow-none border-0 ml-auto mr-auto login-card"
            style={{ backgroundColor: "#e8f1fa" }}
          >
            <div className="card-body rounded-0 text-left">
              <h2 className="fw-700 display1-size display2-md-size mb-3">
                Admin - Login
              </h2>
              {error.visible && <Alert variant="danger">{error.message}</Alert>}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group icon-input mb-3">
                  <i className="font-sm ti-email text-grey-500 pr-0"></i>
                  <input
                    type="text"
                    className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                      errors.email ? "border-danger" : ""
                    }`}
                    placeholder="Email Address"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    }}
                    {...register("email", {
                      required: "Please include a valid email address",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Please include a valid email address",
                      },
                    })}
                  />
                  <span className="text-danger">{errors.email?.message}</span>
                </div>
                <div className="form-group icon-input mb-1">
                  <input
                    type="Password"
                    className={`style2-input pl-5 form-control text-grey-900 font-xss ls-3 ${
                      errors.password ? "border-danger" : ""
                    }`}
                    placeholder="Password"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    }}
                    {...register("password", {
                      required: "Please enter a password",
                    })}
                  />
                  <span className="text-danger">
                    {errors.password?.message}
                  </span>
                  <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                </div>
                <div className="form-check text-left mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input mt-2"
                    id="exampleCheck1"
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid black",
                    }}
                  />
                  <label
                    className="form-check-label font-xssss text-grey-500"
                    htmlFor="exampleCheck1"
                  >
                    Remember me
                  </label>
                  <a
                    href="/forgot"
                    className="fw-600 font-xssss text-grey-700 mt-1 float-right"
                  >
                    Forgot your Password?
                  </a>
                </div>

                <Button
                  disabled={loading}
                  type="submit"
                  className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    "Login"
                  )}
                </Button>
              </form>

              <div className="col-sm-12 p-0 text-left">
                <div className="form-group mb-1">
                  {/* Your existing HTML code here */}
                </div>
                <h6 className="text-grey-500 font-xssss fw-500 mt-0 mb-0 lh-32">
                  Don't have an account{" "}
                  <Link to="/register-admin" className="fw-700 ml-1">
                    Register
                  </Link>
                </h6>
              </div>
              {/* <div className="col-sm-12 p-0 text-center mt-2">
                <h6 className="mb-0 d-inline-block fw-500 font-xssss text-grey-500 mb-3">
                  Or, Sign in with your social account{" "}
                </h6>
                <div className="form-group mb-1">
                  <a
                    href="/login-google"
                    className="form-control text-left style2-input text-white fw-600 bg-facebook border-0 p-0 mb-2"
                  >
                    <img
                      src="https://via.placeholder.com/50x50.png"
                      alt="icon"
                      className="ml-2 w40 mb-1 mr-5"
                    />{" "}
                    Sign in with Google
                  </a>
                </div>
                <div className="form-group mb-1">
                  <a
                    href="/login-facebook"
                    className="form-control text-left style2-input text-white fw-600 bg-twiiter border-0 p-0 "
                  >
                    <img
                      src="https://via.placeholder.com/50x50.png"
                      alt="icon"
                      className="ml-2 w40 mb-1 mr-5"
                    />{" "}
                    Sign in with Facebook
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
