import React, { useEffect, useState } from "react";
import Darkbutton from "../components/Darkbutton";
import Sidebartoggle from "../components/Sidebartoggle";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../Redux/adminSlice";
import { Button } from "react-bootstrap";
import { useAuth } from "../context/authContext.tsx";
import "react-owl-carousel2/lib/styles.css"; //Allows for server-side rendering.
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { updateNotificationRead } from "../services/adminServices/AdminServices.js";
function AdminTopnav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInAdmin = useSelector((state) => state.admin.loggedIn);
  const adminData = useSelector((state) => state.admin);
  const { logout, isAuthenticated } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [user, setUser] = useState({
    loggedIn: false,
    user: {
      name: "No User",
      domain_of_study: "Not Logged In",
    },
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleLogout = async () => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("user");
    await dispatch(logoutUser());
    await setTimeout(async () => {
      window.location.href = "/";
    }, 1000);
  };

  useEffect(() => {
    if (isAuthenticated) {
      const userData = JSON.parse(localStorage.getItem("user"));

      const sortedNotifications = userData.notification.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setNotifications(sortedNotifications);
      setUser({
        loggedIn: true,
        user: {
          _id: userData._id,
          name: userData.name,
          domain_of_study: userData.domain_of_study,
        },
      });
    }
    // console.log(teacherData.user.name);
  }, [loggedInAdmin, adminData]);

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow-xs ">
      <Sidebartoggle />

      <div className="form-group mb-0 icon-input d-none d-lg-block ml-auto mr-2">
        {/* <i className="feather-search font-sm text-grey-400"></i>
        <input
          type="text"
          placeholder="Start typing to search.."
          className="lh-38 pt-2 pb-2 pl-5 pr-3 font-xssss fw-500 rounded-xl posr"
        /> */}
      </div>

      <ul className="navbar-nav">
        <li className="nav-item dropdown no-arrow d-sm-none">
          <a
            className="navi-link dropdown-toggle"
            href="/"
            id="searchDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="feather-search mt-n1"></i>
          </a>

          <div
            className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown"
          >
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="feather-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        <li className="nav-item dropdown no-arrow mx-1">
          <a
            className="navi-link dropdown-toggle"
            href="#"
            id="alertsDropdown"
            role="button"
            onClick={toggleDropdown}
            aria-haspopup="true"
            aria-expanded={dropdownOpen}
          >
            <i className="feather-bell"></i>
            <span className="badge badge-danger badge-counter">
              {
                notifications.filter(
                  (notification) => notification.isRead === false
                ).length
              }
            </span>
          </a>

          {dropdownOpen && (
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in show  p-4"
              style={{
                width: "250px",
                height: "400px",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                scrollbarColor: "#f1f1f1 #ffffff",
              }}
            >
              <h4 className="fw-700 font-xs mb-4">Notification</h4>
              {notifications.length > 0 &&
                notifications.map((notification) => {
                  return (
                    <div
                      className="card bg-transparent-card w-100 border-0 pl-5 mb-3  "
                      style={{
                        cursor: "pointer",
                        backgroundColor: notification.isRead
                          ? "#f1f1f1"
                          : "#ffffff",
                        borderRadius: "10px",
                      }}
                      onClick={async () => {
                        await updateNotificationRead(
                          JSON.parse(localStorage.getItem("token")),
                          user.user._id,
                          notification._id
                        );

                        notifications.map((notification) => {
                          if (notification._id === notification._id) {
                            notification.isRead = true;
                          }
                        });

                        navigate(notification.redirectLink);
                      }}
                    >
                      <img
                        src="assets/images/user.png"
                        alt="user"
                        className="w40 position-absolute left-0 p-1"
                        style={{
                          borderRadius: "50%",
                          border: "2px solid #f1f1f1",
                        }}
                      />

                      <h5 className="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block  p-2">
                        {notification.name}
                        <span className="text-grey-400 font-xsssss fw-600 float-right mt-1">
                          {new Date(
                            notification.createdAt
                          ).toLocaleDateString()}
                        </span>
                      </h5>
                      <h6 className="text-grey-500 fw-500 font-xssss lh-4  p-2">
                        {notification.description}
                      </h6>
                    </div>
                  );
                })}
            </div>
          )}
        </li>

        {/* <li className="nav-item dropdown no-arrow mx-1">
          <a
            className="navi-link dropdown-toggle"
            href="/"
            id="messagesDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="feather-mail"></i>

            <span className="badge badge-danger badge-counter">7</span>
          </a>
        </li> */}

        <Darkbutton />

        <li className="nav-item dropdown no-arrow">
          {isAuthenticated ? (
            <div
              className="navi-link dropdown-toggle"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="../assets/images/user.png"
                alt="user"
                className="w45 mt-n1 rounded-circle"
              />

              <span className="d-none d-lg-inline text-grey-600 fw-700 ml-2 mr-2 font-xssss">
                {user.user.name}
                <i className="font-xsssss d-block text-grey-500 fw-500">
                  Administrator
                </i>
              </span>
              <Button
                onClick={() => {
                  localStorage.removeItem("token");
                  localStorage.removeItem("user");
                  window.location.href = "/login-admin";
                }}
              >
                <i className="ms-3 feather-log-out d-none d-lg-block"></i>
              </Button>
            </div>
          ) : (
            <a
              className="navi-link dropdown-toggle"
              href="/navigation"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src="assets/images/user.png"
                alt="user"
                className="w45 mt-n1 rounded-circle"
              />

              <span className="d-none d-lg-inline text-grey-600 fw-700 ml-2 mr-2 font-xssss">
                No User
                <i className="font-xsssss d-block text-grey-500 fw-500">
                  No Login
                </i>
              </span>
              <i className="ms-3 feather-log-out d-none d-lg-block"></i>
            </a>
          )}
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <a className="dropdown-item" href="/">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-grey-900"></i>
              Profile
            </a>
            <a className="dropdown-item" href="/">
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
              Settings
            </a>
            <a className="dropdown-item" href="/">
              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
              Activity Log
            </a>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              href="/"
              data-toggle="modal"
              data-target="#logoutModal"
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw ml-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default AdminTopnav;
