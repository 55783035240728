import httpClient from "../http-common";


// Enroll in a course
export const enrollCourse = (token,courseID) => {
  return httpClient.post(`/enrollment/enroll-course/${courseID}`, {},{
    headers: {
      'x-auth-token': token
    },
  });
};

export const getEnrolledCourses = (token) => {
  return httpClient.get(`/enrollment/my-courses`, {
    headers: {
      'x-auth-token': token
    },
  });
};

export const getDetailEnrolledCourses = (token,id) => {
  return httpClient.get(`/enrollment/my-courses/course/${id}`, {
    headers: {
      'x-auth-token': token
    },
  });
};

export const getDetailEnrolledCoursesTeacher = (token,id) => {
  return httpClient.get(`/enrollment/my-courses-teacher/course/${id}`, {
    headers: {
      'x-auth-token': token
    },
  });
};
