import React, { Fragment } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Subscribe from "../components/Subscribe";
import { Accordion } from "react-bootstrap";
import ReactPlayer from "react-player";
import Myclass from "../components/Myclass";
import { toPng } from "html-to-image";
import ReactStars from "react-rating-stars-component";

import {
  setCourse,
  setModuleData,
  setCurrentModuleID,
} from "../Redux/courseSlice.jsx";

import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { getDetailEnrolledCourses } from "../services/userServices/EnrollmentServices.js";
import {
  FollowInstructor,
  GetFolowingOfUSer,
} from "../services/userServices/FollowingServices.jsx";

import { addReviewToCourse } from "../services/statistics/statistics.js";
export const StreamCoursePage = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const fetchCourses = async (token) => {
    try {
      const response = await getDetailEnrolledCourses(token, courseID);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };

  const [showCertificate, setShowCertificate] = useState(false);
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [courseID] = useState(params.id);
  const [token] = useState(JSON.parse(localStorage.getItem("token")));
  const currentCourse = useSelector((state) => state.course);
  const [currentVideo, setCurrentVideo] = useState(
    currentCourse.moduleData[0]?.parts[0]?.video.url
  );

  const [fetchData, setFetchData] = useState({});

  const [activeIndex, setActiveIndex] = useState(0); // State variable to store active accordion index
  const changeVideo = (videoUrl) => {
    setCurrentVideo(videoUrl);
    window.scrollTo(0, 0);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the active index
  };

  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [isFollowingInstructor, setIsFollowingInstructor] = useState(false);

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleSubmit = async () => {
    console.log(`Rating: ${rating}, Review: ${review}`);

    try {
      const response = await addReviewToCourse(
        courseID,
        rating,
        review,
        courses.enrolledCourse.instructor._id
      );

      if (response.status === 200) {
        console.log("Review added successfully");

        // Reset the form fields
        setRating(0);
        setReview("");
      } else {
        console.log("Error adding review");
        alert("Error adding review");
      }
    } catch (error) {
      alert("You have already reviewed this course.");
    }
  };

  const generateCertificateBool = () => {
    // Check if the certificate flag is already set in localStorage
    const bool = localStorage.getItem("showCertificate" + courseID);

    if (bool === "true") {
      return true;
    }

    // Calculate the total number of video parts in the course
    const totalParts = currentCourse.moduleData.reduce((acc, item) => {
      return acc + item.parts.length;
    }, 0);


    // Check if the length of videoEndedList matches total parts and all videos are ended
    const allVideosEnded =
      videoEndedList.length === totalParts &&
      videoEndedList.every((item) => item.ended === true);



    // If all videos are ended, set the certificate flag in localStorage
    if (allVideosEnded && totalParts > 0) {
      localStorage.setItem("showCertificate" + courseID, "true");
      return true;
    }

    return false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (token) {
      setOpen(true);
      fetchCourses(token)
        .then((courses) => {
          setCourses(courses.data);
          setOpen(false);
          dispatch(setCourse(courses.data.course));
          console.log("courses.data", courses.data.enrolledCourse.course.title);
          setFetchData(courses.data.enrolledCourse);
          setCurrentVideo(courses.data.modules[0]?.parts[0]?.video.url);

          for (let i = 0; i <= courses.data.modules.length; i++) {
            dispatch(setModuleData(courses.data.modules[i]));
          }
          // dispatch(setCurrentModuleID(courses.data.modules[0]._id));
          // setCurrentVideo(currentCourse.moduleData[0]?.parts[0]?.video.url)
        })
        .catch((error) => {
          console.error(error);
          setOpen(false);
        });
    }
  }, []);

  useEffect(() => {
    try {
      checkIfFollowingInstructor();
    } catch (error) {
      console.log(error);
    }
  }, [user, courses]);

  const checkIfFollowingInstructor = async () => {
    if (user) {
      await GetFolowingOfUSer(user._id)
        .then((response) => {
          //  [{"_id":"65d873d759ee8ddff82396e5","name":"Zahid Asif"}]
          console.log(response.data);
          const isInstructorIdExist = response.data.some(
            (item) => item._id === courses?.enrolledCourse?.instructor?._id
          );
          setIsFollowingInstructor(isInstructorIdExist);
          console.log(isFollowingInstructor);
        })
        .catch((error) => {
          console.error(error);
          setIsFollowingInstructor(true);
        });
    }
  };

  const handleFollowInstructor = (instructorID) => {
    FollowInstructor(token, instructorID)
      .then((response) => {
        console.log(response.data);
        setIsFollowingInstructor(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [videoEndedList, setVideoEndedList] = useState([
    //{ videoUrl: "https://www.youtube.com/watch?v=ysz5S6PUM-U", ended: false },
  ]);
  const [videoDurationList, setVideoDurationList] = useState([
    //{ videoUrl: "https://www.youtube.com/watch?v=ysz5S6PUM-U", duration: 0 },
  ]);
  const handleVideoEnd = () => {
    console.log("video ended");
    setVideoEndedList([
      ...videoEndedList,
      { videoUrl: currentVideo, ended: true },
    ]);
  };
  const handleVideoDuration = (duration) => {
    console.log("video duration", duration);
    setVideoDurationList([
      ...videoDurationList,
      { videoUrl: currentVideo, duration },
    ]);
  };

  const certificateRef = useRef(null);

  const handleDownloadImage = async () => {
    if (certificateRef.current) {
      const dataUrl = await toPng(certificateRef.current);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "certificate.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>

      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader />

          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="row">
                {showCertificate && (
                  <div>
                    <button
                      className="btn btn-primary rounded-pill"
                      style={{ width: "50%", marginBottom: "2px" }}
                      onClick={handleDownloadImage}
                    >
                      Download Certificate
                    </button>
                    <div
                      className="container pm-certificate-container"
                      ref={certificateRef}
                      style={{ position: "absolute", zIndex: "9999" }}
                    >
                      <div className="outer-border"></div>

                      <div className="inner-border"></div>

                      <div className="pm-certificate-border col-xs-12">
                        <div className="row pm-certificate-header heading-c">
                          <div className="pm-certificate-title cursive col-xs-12 text-center">
                            <h2 className="title-c">Seekho.pk</h2>
                          </div>
                        </div>

                        <div
                          className="row pm-certificate-body"
                          style={{ marginTop: "-45px" }}
                        >
                          <div className="pm-certificate-block">
                            <div className="col-xs-12">
                              <div className="row middle-c">
                                <div className="col-xs-2"></div>
                                <div className="pm-certificate-name underline margin-0 col-xs-8 text-center">
                                  <span className="pm-name-text bold">
                                    {user.name}{" "}
                                  </span>
                                </div>
                                <div className="col-xs-2"></div>
                              </div>
                            </div>

                            {/* <div className="col-xs-12">
                            <div className="row middle-c">
                              <div className="col-xs-2"></div>
                              <div className="pm-earned col-xs-8 text-center">
                                <span className="pm-earned-text padding-0 block cursive">
                                  has earned
                                </span>
                                <span className="pm-credits-text block bold sans">
                                  PD175: 1.0 Credit Hours
                                </span>
                              </div>
                              <div className="col-xs-2"></div>
                              <div className="col-xs-12"></div>
                            </div>
                          </div> */}

                            <div className="col-xs-12">
                              <div className="row middle-c">
                                <div className="col-xs-2"></div>
                                <div className="pm-course-title col-xs-8 text-center">
                                  <span className="pm-earned-text block cursive">
                                    Has completed the training course entitled
                                  </span>
                                </div>
                                <div className="col-xs-2"></div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row middle-c">
                                <div className="col-xs-2"></div>
                                <div className="pm-course-title underline col-xs-8 text-center">
                                  <span className="pm-credits-text block bold sans">
                                    {fetchData.course.title}
                                  </span>
                                </div>
                                <div className="col-xs-2"></div>
                              </div>
                            </div>

                            <div className="col-xs-12">
                              <div className="row middle-c">
                                <div className="col-xs-2"></div>
                                <div className="pm-certificate-name underline margin-0 col-xs-8 text-center">
                                  <span className="pm-name-text bold">
                                    By {fetchData.instructor.name}{" "}
                                  </span>
                                </div>
                                <div className="col-xs-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-xl-8 col-xxl-9">
                  <div className="card border-0 mb-0 rounded-lg overflow-hidden">
                    <ReactPlayer
                      controls="true"
                      width="100%"
                      height="auto"
                      className="react-player"
                      onEnded={handleVideoEnd}
                      onDuration={handleVideoDuration}
                      playing
                      // light={`assets/images/${value.videoimage}`}
                      url={currentVideo}
                    />
                  </div>
                  <div className="card d-block border-0 rounded-lg overflow-hidden dark-bg-transparent bg-transparent mt-4 pb-3">
                    <div className="row">
                      <div className="col-10">
                        <h2 className="fw-700 font-md d-block lh-4 mb-2">
                          {courses?.enrolledCourse?.course?.title}
                        </h2>
                      </div>
                      <div className="col-2">
                        {generateCertificateBool() && (
                          <button
                            className="btn btn-primary rounded-pill"
                            onClick={() => setShowCertificate(!showCertificate)}
                          >
                            <span className="font-xssss fw-700 text-light ">
                              Generate Certificate
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                    <span className="font-xssss fw-700 text-grey-900 d-inline-block ml-0 text-dark">
                      {courses?.enrolledCourse?.instructor?.name}
                    </span>{" "}
                    <button
                      className="btn btn-primary rounded-pill"
                      onClick={() => {
                        handleFollowInstructor(
                          courses?.enrolledCourse?.instructor?._id
                        );
                      }}
                      disabled={open || isFollowingInstructor}
                    >
                      <span className="font-xssss fw-700 text-light ">
                        {isFollowingInstructor ? "Following" : "Follow"}
                      </span>
                    </button>
                    <span className="dot ml-2 mr-2 d-inline-block btn-round-xss bg-grey"></span>
                    <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                      {courses?.enrolledCourse?.course?.domain_of_study}
                    </span>
                    {/* <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                        Design
                      </span>
                      <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                        Developer
                      </span>
                      <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                        HTML5
                      </span>
                      <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                        Jquery
                      </span> */}
                    <span className="dot ml-2 mr-2 d-inline-block btn-round-xss bg-grey"></span>
                    {/* <span className="font-xssss fw-700 text-primary d-inline-block ml-0 ">
                        Follow Instructor
                      </span> */}
                  </div>
                  {currentCourse.moduleData.map((value, index) => (
                    <div className="card d-block border-0 rounded-lg overflow-hidden mt-3">
                      <Accordion
                        activeKey={activeIndex === index ? "0" : null} // Set activeKey based on state variable
                        onSelect={() => toggleAccordion(index)} // Call toggleAccordion function when accordion is clicked
                        className="accordian mb-0 accordian-course"
                      >
                        <Accordion.Item
                          eventKey="0"
                          className="accordion-item border-0 mb-0 shadow-xss rounded-sm bg-white"
                        >
                          <Accordion.Header>{value?.title}</Accordion.Header>
                          {value?.parts.map((part, index) => {
                            return (
                              <Accordion.Body>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      part.video.url === currentVideo
                                        ? "#D1E7DD"
                                        : "transparent",
                                    color:
                                      part.video.url === currentVideo
                                        ? "white"
                                        : "initial",
                                    fontWeight:
                                      part.video.url === currentVideo
                                        ? "bold"
                                        : "initial",
                                  }}
                                  className="card-body d-flex p-2"
                                  onClick={() => changeVideo(part.video.url)}
                                >
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={videoEndedList.some(
                                      (item) =>
                                        item.videoUrl === part.video.url &&
                                        item.ended
                                    )}
                                    // onChange={() => {
                                    //   videoEndedList.some(
                                    //     (item) =>
                                    //       item.videoUrl === part.video.url &&
                                    //       item.ended
                                    //   )
                                    //     ? setVideoEndedList(
                                    //         videoEndedList.filter(
                                    //           (item) =>
                                    //             item.videoUrl !== part.video.url
                                    //         )
                                    //       )
                                    //     : setVideoEndedList([
                                    //         ...videoEndedList,
                                    //         { videoUrl: part.video.url, ended: true },
                                    //       ]);
                                    // }}
                                  />

                                  <span className="bg-transparent btn-round-xs rounded-lg font-xssss text-grey-700 fw-600">
                                    {index + 1}
                                  </span>
                                  <span className="font-xssss fw-500 text-grey-700 ml-2">
                                    {part.title}
                                  </span>
                                  <span className="ml-auto font-xssss fw-500 text-grey-700">
                                    {videoDurationList.find(
                                      (item) => item.videoUrl === part.video.url
                                    )?.duration > 0
                                      ? `${Math.floor(
                                          videoDurationList.find(
                                            (item) =>
                                              item.videoUrl === part.video.url
                                          )?.duration / 60
                                        )}:${Math.floor(
                                          videoDurationList.find(
                                            (item) =>
                                              item.videoUrl === part.video.url
                                          )?.duration % 60
                                        )}`
                                      : "00:00"}
                                  </span>
                                </div>
                              </Accordion.Body>
                            );
                          })}
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
                  {/* <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4">
                    <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                      Description
                    </h2>
                    <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 pl-2">
                      
                    </p>
                  </div> */}

                  <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 alert-success">
                    <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 text-success mb-4">
                      Description
                    </h2>
                    <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-30 position-relative lh-24">
                      <i className="ti-check font-xssss btn-round-xs bg-success text-white position-absolute left-0 top-5"></i>
                      {courses?.enrolledCourse?.course?.description}
                    </h4>
                    {/* <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-30 position-relative lh-24">
                      <i className="ti-check font-xssss btn-round-xs bg-success text-white position-absolute left-0 top-5"></i>
                      After completing this course you'll be confident to create
                      any subtle to complex animation that will turn any project
                      a professional work and surely you'll become an awesome
                      developer and designer
                    </h4>
                    <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-30 position-relative lh-24">
                      <i className="ti-check font-xssss btn-round-xs bg-success text-white position-absolute left-0 top-5"></i>
                      Create awesome animated splash screens for any Excel
                      project such as animation with password access to a work
                      book, loading animation.
                    </h4>
                    <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-30 position-relative lh-24">
                      <i className="ti-check font-xssss btn-round-xs bg-success text-white position-absolute left-0 top-5"></i>
                      After completing this course you'll be confident to create
                      any subtle to complex animation that will turn any project
                      a professional work and surely you'll become an awesome
                      developer and designer
                    </h4>
                    <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-30 position-relative lh-24">
                      <i className="ti-check font-xssss btn-round-xs bg-success text-white position-absolute left-0 top-5"></i>
                      Create awesome animated splash screens for any Excel
                      project such as animation with password access to a work
                      book, loading animation.
                    </h4>
                    <h4 className="font-xssss fw-600 text-grey-600 mb-3 pl-30 position-relative lh-24">
                      <i className="ti-check font-xssss btn-round-xs bg-success text-white position-absolute left-0 top-5"></i>
                      After completing this course you'll be confident to create
                      any subtle to complex animation that will turn any project
                      a professional work and surely you'll become an awesome
                      developer and designer
                    </h4> */}
                  </div>

                  <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 mb-5">
                    <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                      Requirements
                    </h2>
                    <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 pl-2">
                      After creating more than a dozen courses on Microsoft
                      Access databases and programming in VBA, many students
                      have contacted me with discussions on specific problems
                      and scenarios. From these discussions.
                    </p>
                  </div>
                  <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4 mb-5">
                    <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                      Add Review
                    </h2>
                    <div className="form-group mb-2">
                      <ReactStars
                        count={5}
                        onChange={handleRatingChange}
                        size={24}
                        activeColor="#ffd700"
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your review"
                      value={review}
                      onChange={(e) => setReview(e.target.value)}
                    />
                    <button
                      className="btn btn-primary rounded-pill mt-2"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                <Profile />
                <Myclass />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default StreamCoursePage;
