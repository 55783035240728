import React, { useState, useEffect, Fragment } from "react";
import Adminfooter from "../components/Adminfooter.js";
import Teachersidebar from "../components/Teachersidebar.js";
import LiveCourseIcon from "./1home.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { getCategories } from "../services/categoryServices/categoryServices.js";

import {
  uploadLiveCourse,
  uploadImage,
} from "../services/courseServices/liveCourseServices/liveCourseServices";
import { useNavigate } from "react-router-dom";
import { Form, Ratio, Image, Spinner } from "react-bootstrap";
import TeacherTopnav from "../components/TeacherTopNav.js";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";

function Teachercreatelive() {
  TeacherProtection();
  const handleNextClick = () => {
    if (currentSection < 4) {
      setCurrentSection(currentSection + 1);
    }
  };
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (currentSection > 1) {
      setCurrentSection(currentSection - 1);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const buttonWidth = "700px";
  const courseIcons = {
    recorded: LiveCourseIcon,
  };

  const imageStyle = {
    marginRight: "20px",
  };

  const selectedBadgeStyle = {
    display: "block",
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "green",
    color: "white",
    borderTopRightRadius: "13px",
    borderBottomLeftRadius: "13px",
    padding: "5px 10px",
    fontSize: "0.8rem",
  };

  const cardContentStyle = {
    flexGrow: 1,
    textAlign: "left",
  };

  const courseButtonStyle = {
    display: "flex",
    alignItems: "flex-start",
    padding: "15px",
    margin: "10px 0",
    border: "1px solid #ccc",
    borderRadius: "13px",
    width: buttonWidth,
    cursor: "pointer",
  };

  const [currentSection, setCurrentSection] = useState(1);
  const [uploadingThumbnail, setUploadingThumbnail] = useState({
    isUploading: false,
    message: "",
  });
  const [expanded, setExpanded] = useState(false);
  const [numberOfLectures, setNumberOfLectures] = useState(0);
  const [lectures, setLectures] = useState([]);
  const [error, setError] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [thumbnailUploaded, setThumbnailUploaded] = useState(null);

  const [token] = useState(JSON.parse(localStorage.getItem("token")));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [courseCategories, setCourseCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 200) {
        setCourseCategories(response.data);
      } else {
        console.log("Error in fetching categories");
        alert("Error in fetching categories");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCategories();
    if (error) {
      setLectures([]); // Clear lectures if there is an error
    } else {
      const newLectures = [];
      for (let i = 1; i <= numberOfLectures; i++) {
        newLectures.push(`lecture${i}`);
      }
      setLectures(newLectures);
    }
  }, [numberOfLectures, error]);

  const handleThumbnailUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (!file) {
      console.error("No file selected.");
      return;
    }
    setUploadingThumbnail({ isUploading: true });

    const formData = new FormData();
    formData.append("thumbnail", file);

    try {
      // Display preview of the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
      };
      reader.onerror = (error) => {
        console.error("File reading error:", error);
      };
      reader.readAsDataURL(file);

      const response = await uploadImage(token, formData);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.imageUrl
      ) {
        const imageUrl = response.data.data.imageUrl;
        setThumbnailUploaded(imageUrl);
        // console.log(thumbnailUploaded)
        // console.log(JSON.stringify(imageUrl))
        setUploadingThumbnail({ isUploading: false });
      } else {
        console.error("Invalid response format:", response);
        setUploadingThumbnail({ isUploading: false });
      }
    } catch (error) {
      console.error("Upload error:", error);
      setUploadingThumbnail({ isUploading: false });
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLectureChange = (event) => {
    const value = event.target.value;
    const numValue = parseInt(value, 10);

    if (value === "" || isNaN(numValue) || numValue < 0 || numValue > 20) {
      setError("Please enter a number between 0 and 20.");
      setNumberOfLectures(0); // Reset numberOfLectures when invalid
    } else {
      setError("");
      setNumberOfLectures(numValue);
    }
  };

  const renderForm = (lectureNumber) => (
    <div>
      <div className="form-group">
        <label className="mont-font fw-600 font-xsss">Chapter Title</label>
        <input
          type="text"
          className="form-control"
          placeholder={`Chapter ${lectureNumber} Title`}
          {...register(`lecture${lectureNumber}Title`, {
            required: `Chapter ${lectureNumber} Title is required`,
          })}
        />
        {errors[`lecture${lectureNumber}Title`] && (
          <span className="text-danger">
            {errors[`lecture${lectureNumber}Title`].message}
          </span>
        )}
      </div>

      <div className="form-group">
        <label className="mont-font fw-600 font-xsss">
          Brief Introduction of Chapter
        </label>
        <textarea
          className="form-control mb-0 p-3 bg-white lh-16"
          rows="3"
          placeholder="Brief introduction..."
          {...register(`lecture${lectureNumber}Description`, {
            required: `Chapter ${lectureNumber} Description is required`,
          })}
        ></textarea>
        {errors[`lecture${lectureNumber}Description`] && (
          <span className="text-danger">
            {errors[`lecture${lectureNumber}Description`].message}
          </span>
        )}
      </div>
    </div>
  );

  const [lectureSchedules, setLectureSchedules] = useState([]);

  useEffect(() => {
    setLectureSchedules(
      Array.from({ length: numberOfLectures }, () => ({
        durationHours: 1,
        durationMinutes: 0,
        date: "",
        startTime: "",
        endTime: "",
      }))
    );
  }, [numberOfLectures]);

  const handleScheduleChange = (index, field, value) => {
    const updatedSchedules = [...lectureSchedules];
    updatedSchedules[index][field] = value;
    setLectureSchedules(updatedSchedules);

    if (
      field === "durationHours" ||
      field === "durationMinutes" ||
      field === "startTime"
    ) {
      // Pass the new value along with other necessary fields to calculateEndTime
      calculateEndTime(index, {
        ...updatedSchedules[index],
        [field]: value,
      });
    }
  };

  const calculateEndTime = (index, schedule) => {
    const { durationHours, durationMinutes, startTime } = schedule;
    if (startTime) {
      const [hours, minutes] = startTime.split(":").map(Number);
      const endHours = hours + durationHours;
      const endMinutes = minutes + durationMinutes;
      const finalEndHours = endMinutes >= 60 ? endHours + 1 : endHours;
      const finalEndMinutes = endMinutes % 60;

      lectureSchedules[index].endTime = `${finalEndHours
        .toString()
        .padStart(2, "0")}:${finalEndMinutes.toString().padStart(2, "0")}`;
      setLectureSchedules([...lectureSchedules]);
    }
  };

  const renderLectureScheduling = () => {
    const index = 0;
    return (
      <Accordion
        key={index}
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
        style={{ backgroundColor: "#FFFFFF", color: "#22100d" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          style={{ backgroundColor: "#FFFFFF", color: "#22100d" }}
        >
          <Typography
            // className="card-title"
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          >
            Course Schedule
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: "#ffffff" }}>
          <div
            className="form-group"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ width: "calc(50% - 10px)" }}>
              <label className="mont-font fw-600 font-xsss">
                Duration Hours
              </label>
              <select
                className="form-control"
                {...register(`lecture${index + 1}DurationHours`, {
                  required: `Lecture ${index + 1} Duration Hours is required`,
                })}
              >
                {[...Array(61).keys()].map((hour) => (
                  <option key={hour} value={hour}>
                    {hour}
                  </option>
                ))}
              </select>
              {errors[`lecture${index + 1}DurationHours`] && (
                <span className="text-danger">
                  {errors[`lecture${index + 1}DurationHours`].message}
                </span>
              )}
            </div>

            <div style={{ width: "calc(50% - 10px)" }}>
              <label className="mont-font fw-600 font-xsss">
                Duration Minutes
              </label>
              <select
                className="form-control"
                {...register(`lecture${index + 1}DurationMinutes`, {
                  required: `Lecture ${index + 1} Duration Minutes is required`,
                })}
              >
                {[...Array(61).keys()].map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
              {errors[`lecture${index + 1}DurationMinutes`] && (
                <span className="text-danger">
                  {errors[`lecture${index + 1}DurationMinutes`].message}
                </span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label className="mont-font fw-600 font-xsss">Date</label>
            <input
              type="date"
              className="form-control"
              min={getCurrentDate()}
              {...register(`lecture${index + 1}Date`, {
                required: `Lecture ${index + 1} Date is required`,
              })}
            />
            {errors[`lecture${index + 1}Date`] && (
              <span className="text-danger">
                {errors[`lecture${index + 1}Date`].message}
              </span>
            )}
          </div>
          <div style={{ marginRight: "10px", width: "calc(50% - 10px)" }}>
            <label className="mont-font fw-600 font-xsss">Start Time</label>
            <input
              type="time"
              className="form-control"
              {...register(`lecture${index + 1}StartTime`, {
                required: `Lecture ${index + 1} Start Time is required`,
              })}
              onChange={(e) => {
                handleScheduleChange(index, "startTime", e.target.value);
                calculateEndTime(index, {
                  ...lectureSchedules[index],
                  startTime: e.target.value,
                });
              }}
            />
            {errors[`lecture${index + 1}StartTime`] && (
              <span className="text-danger">
                {errors[`lecture${index + 1}StartTime`].message}
              </span>
            )}
          </div>
          {/* <div style={{ width: "calc(50% - 10px)" }}>
            <label className="mont-font fw-600 font-xsss">End Time</label>
            <input
              type="time"
              className="form-control"
              value={schedule.endTime}
              disabled
            />
          </div> */}
        </AccordionDetails>
      </Accordion>
    );
  };

  const onSubmit = async (data) => {
    handleNextClick();
  };

  const onPublish = async (formData) => {
    const { title, description, domain_of_study } = formData;
    const lectures = [];

    // Loop through lecture data
    for (let i = 1; formData[`lecture${i}Title`]; i++) {
      const lecture = {
        title: formData[`lecture${i}Title`],
        description: formData[`lecture${i}Description`],
        liveTime: {
          startTime:
            formData[`lecture${i}Date`] +
            "T" +
            formData[`lecture${i}StartTime`] +
            ":00Z",
          endTime: "", // You can calculate endTime if needed
        },
        duration:
          parseInt(formData[`lecture${i}DurationHours`]) * 60 +
          parseInt(formData[`lecture${i}DurationMinutes`]),
        attendees: [], // You can populate this field if needed
      };
      lectures.push(lecture);
    }

    // Additional fields if needed
    const materials = formData.materials ? formData.materials.split(",") : [];
    let thumbnail = thumbnailUploaded;
    const isActive = true; // Example value, replace with actual value

    const data = {
      title,
      description,
      price: 49.99, // Example value, replace with actual price
      domain_of_study,
      lectures,
      materials,
      thumbnail,
      isActive,
    };

    // console.log(data);
    await uploadLiveCourse(token, data)
      .then((response) => {
        // console.log(response);
        navigate("/teacher-my-live-courses");
      })
      .catch((error) => {
        console.log(error);
      });

    // handleNextClick();
  };

  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />
            <div className="container-fluid px-4 py-4">
              {/* Attractive and unique heading and subheading */}
              {/* {currentSection === 1 && (
                <div className="text-center mb-4">
                  <h2
                    style={{
                      fontSize: "2.0rem",
                      fontWeight: "bold",
                      color: "#4a4a4a",
                    }}
                  >
                    Hi! Welcome Zafar Ahmed
                  </h2>
                </div>
              )} */}
            </div>
            <div
              className="card-body d-block p-5 pb-4 text-center"
              style={{ marginTop: "-50px" }}
            >
              <div
                className="timeline-steps aos-init aos-animate"
                data-aos="fade-up"
              >
                <div
                  className={`timeline-step ${
                    currentSection === 1 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Lesson and Videos
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 2 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Scheduling and Finishing
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid px-4 py-4">
              <div
                className="d-flex flex-column align-items-center"
                style={{
                  marginTop: "-30px",
                  maxHeight: "100%",
                  overflowY: "hidden",
                }}
              >
                {currentSection === 1 && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ margin: "5px" }}>
                      <div
                        className="card position-relative"
                        style={courseButtonStyle}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={courseIcons.recorded}
                            alt="Recorded Course"
                            width="48"
                            height="48"
                            style={imageStyle}
                          />
                          <div style={cardContentStyle}>
                            <h5
                              className="card-title text-success"
                              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              Live Course
                            </h5>
                            <p
                              className="card-text"
                              style={{ fontSize: "0.9rem", marginTop: "5px" }}
                            >
                              Schedule and Launch your live course.
                            </p>
                          </div>
                        </div>
                        <div style={selectedBadgeStyle}>Selected</div>
                      </div>

                      <div className="col-lg-30 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss">
                            Live Series Title
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            {...register("title", {
                              required: "Title must be included",
                            })}
                          />
                          {errors.title && (
                            <span className="text-danger">
                              {errors.title.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-30 ">
                        <div className="form-group">
                          <Form.Group controlId="formFileSm" className="mb-3">
                            <Form.Label className="add-course-label">
                              Select Thumbnail Image:
                            </Form.Label>
                            <Form.Control
                              className="relative-form"
                              type="file"
                              accept="image/*"
                              // {...register("thumbnail", {
                              //   required: "Thumnail must be included.",
                              // })}
                              // value={thumbnailUploaded}
                              onChange={handleThumbnailUpload}
                            />
                          </Form.Group>

                          {uploadingThumbnail.isUploading && (
                            <div className="d-flex flex-column align-items-center">
                              Uploading Thumbnail
                              <Spinner animation="border" role="status" />
                            </div>
                          )}

                          {previewImage ? (
                            <>
                              <br />
                              <br />
                              <h2 className="mont-font fw-600 font-lg">
                                Preview Thumbnail
                              </h2>

                              <div style={{ width: 660, height: 450 }}>
                                <Ratio aspectRatio="16x9">
                                  <Image
                                    src={previewImage}
                                    alt="Thumbnail Preview"
                                    fluid
                                    style={{
                                      marginTop: "10px",
                                      border: "1px solid #ced4da",
                                    }}
                                  />
                                </Ratio>
                                <br />

                                {/* <div
                                    className="d-flex flex-column align-items-center"
                                  // style={{ marginTop: "-30px" }}
                                  >
                                    <Button
                                  className="btn-success"
                                  onClick={handleSaveThumbnail}
                                    >
                                      Select Thumbnail
                                    </Button>
                                  </div> */}
                              </div>
                            </>
                          ) : (
                            <>
                              <div class="form-group">
                                <div class="add-image-box">
                                  <a href="javascript:void(0);">
                                    <i class="far fa-image"></i>
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-30 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss">
                            Select Category
                          </label>
                          <select
                            className="form-control"
                            {...register("domain_of_study", {
                              required: "Please select a category",
                            })}
                          >
                            <option value="">Select</option>
                            {courseCategories.length > 0 &&
                              courseCategories.map((category) => (
                                <option value={category.name}>
                                  {category.name}
                                </option>
                              ))}
                          </select>
                          {errors.domain_of_study && (
                            <span className="text-danger">
                              {errors.domain_of_study.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-30 mb-3">
                        <label className="mont-font fw-600 font-xsss">
                          Briefly explain your live session & why is it
                          important?
                        </label>
                        <textarea
                          className="form-control mb-0 p-3 bg-white lh-16"
                          rows="5"
                          placeholder="Write your message..."
                          {...register("description", {
                            required:
                              "Please enter description about the course",
                          })}
                        ></textarea>
                        {errors.description && (
                          <span className="text-danger">
                            {errors.description.message}
                          </span>
                        )}
                      </div>
                      <p style={{ fontSize: "0.9rem", marginBottom: "5px" }}>
                        Enter Number of Chapters
                      </p>

                      <TextField
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        onChange={handleLectureChange}
                        error={!!error}
                        helperText={error}
                        style={{ width: "100%", marginBottom: "20px" }}
                      />

                      {lectures.map((lecture, index) => (
                        <Accordion
                          key={lecture}
                          expanded={expanded === lecture}
                          onChange={handleChange(lecture)}
                          style={{ marginBottom: "10px" }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${lecture}-content`}
                            id={`${lecture}-header`}
                            style={{
                              backgroundColor: "#ffffff",
                              color: "#22100d",
                            }} // Style for accordion header
                          >
                            <Typography
                              className="card-title "
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                              }}
                            >
                              Chapter {index + 1}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            {" "}
                            {/* Style for accordion details */}
                            {renderForm(index + 1)}
                          </AccordionDetails>
                        </Accordion>
                      ))}
                      {currentSection < 2 && (
                        <div
                          className="widget-btn"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            type="submit"
                            className="btn btn-info-light next_btn" // Add the same class as the Link
                            style={{ width: "100px" }}
                            disabled={uploadingThumbnail.isUploading}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                )}

                {/* Content for other sections */}
                {currentSection === 2 && (
                  <div>
                    <form onSubmit={handleSubmit(onPublish)}>
                      <div>
                        {renderLectureScheduling()}
                        <div
                          className="widget-btn"
                          style={{ display: "flex", gap: "40px" }}
                        >
                          <button
                            onClick={handleBackClick}
                            className="btn btn-black"
                          >
                            Back
                          </button>
                          <button
                            className="btn btn-info-light next_btn"
                            type="submit"
                          >
                            Publish
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Teachercreatelive;
