import "../main.scss";
import React, { Fragment, useState, useEffect } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import Profile from "../components/Profile";
import Myclass from "../components/Myclass";
import Subscribe from "../components/Subscribe";
import { Link } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "react-owl-carousel2/lib/styles.css"; //Allows for server-side rendering.
import "owl.carousel/dist/assets/owl.carousel.css";
import { truncateTitle } from "../utils/utilityMethods";

import "owl.carousel/dist/assets/owl.theme.default.css";
import { getTeacherById } from "../services/credentials/credentialsChange";
import { Github } from "lucide-react";
import CourseCard from "../components/courseCard";


const Defaultauthorprofile = () => {
  const { id } = useParams();
  const [author, setAuthor] = useState({});
  const [courseList, setCourseList] = useState([]);
  const getAuthor = async () => {
    try {
      const response = await getTeacherById(id);
      console.log(response.data);
      setAuthor(response.data.teacher);

      const publishedCourses = response.data.courses.filter(
        (course) => course.status == "published"
      );

      setCourseList(publishedCourses);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getAuthor();
  }, [id]);

  return (
    <Fragment>
      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader />

          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-3">
                <div
                  className="card-body position-relative h200 bg-gradiant-bottom bg-image-cover bg-image-center"
                  style={{
                    backgroundImage: `url("../../assets/images/back.jpg")`,
                  }}
                ></div>
                <div className="card-body d-block w-100 pl-lg-5 pl-4 pr-4">
                  <figure className="avatar ml-0 mb-0 mt--6 position-relative w90 z-index-1">
                    <img
                      src="../../assets/images/user.png"
                      alt="avater"
                      className="float-right p-1 bg-white rounded-circle w-100 rounded-circle"
                    />
                  </figure>
                  <div className="clearfix"></div>
                  <div className="row">
                    <div className="col-xl-6 md-mb-2">
                      <h4 className="fw-700 font-xs mt-3 mb-1">
                        {author.name}
                        <i className="ti-check font-xssss btn-round-xs bg-success text-white ml-1"></i>
                      </h4>
                      <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-0">
                        {author.email}
                      </span>
                      <span className="dot ml-2 mr-2 d-inline-block btn-round-xss bg-grey"></span>
                      <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                        {author.domain_of_study}
                      </span>
                    </div>
                    <div className="col-xl-3 md-mb-2">
                      <ul className="d-flex align-items-center mt-2 float-left">
                        {author.youtube && (
                          <li className="mr-2">
                            <a
                              href={author.youtube}
                              className="btn-round-md bg-youtube"
                              target="_blank"
                            >
                              <i className="font-xs ti-youtube text-white"></i>
                            </a>
                          </li>
                        )}

                        {author.linkedin && (
                          <li className="mr-2">
                            <a
                              href={author.linkedin}
                              className="btn-round-md bg-linkedin"
                              target="_blank"
                            >
                              <i className="font-xs ti-linkedin text-white"></i>
                            </a>
                          </li>
                        )}
                        {author.github && (
                          <li className="mr-2">
                            <a
                              href={author.github}
                              className="btn-round-md bg-black"
                              target="_blank"
                            >
                              <Github size={20} color="white" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="col-xl-3 md-mb-2 pl-xl-4"></div>

                    <div className="col-11">
                      <h4 className="text-grey-800 font-xsss mt-4 fw-700">
                        About me
                      </h4>
                      <p className="font-xssss fw-600 lh-28 text-grey-500 mb-0 pl-0">
                        {author.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="video"
                id="uncontrolled-tab-example"
                className="mb-3 nav nav-tabs profile xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0 bg-white shadow-xss rounded-lg"
              >
                <Tab eventKey="video" title="Courses">
                  <div className="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-lg-4 p-2">
                    <div className="card-body mb-lg-3 pb-0">
                      <h2 className="fw-400 font-lg d-block">
                        My <b>Courses</b>
                        <a href="/default-user-profile" className="float-right">
                          <i className="feather-edit text-grey-500 font-xs"></i>
                        </a>
                      </h2>
                    </div>
                    <div className="card-body pb-0">
                      <div className="row">
                        {courseList.map((value, index) => (
                          <CourseCard key={index} value={value} />
                        ))}
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                <Profile />
                <Myclass />
                <Subscribe />
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default Defaultauthorprofile;
