import React, { Fragment, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import Adminfooter from "../components/Adminfooter.js";
import Teachersidebar from "../components/Teachersidebar.js";
import UserDialog from "../components/DialogBox.js";
import TeacherTopnav from "../components/TeacherTopNav.js";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";
import { getTeacherStats } from "../services/statistics/statistics.js";
// Define icons and descriptions for teacher-specific statistics
const popularList = [
  {
    imageUrl: "course.png",
    title: "Complete Python Bootcamp From Zero to Hero in Python ",
    price: "2400",
    tag: "Python",
    lesson: "32 ",
    status: "alert-warning text-warning",
  },
  {
    imageUrl: "course.png",
    title: "Complete Python Bootcamp From Zero to Hero in Python ",
    price: "40",
    tag: "Desinger",
    lesson: "24 ",
    status: "alert-danger text-danger",
  },
  {
    imageUrl: "course.png",
    title: "Java Programming Masterclass for Developers",
    price: "60",
    tag: "Bootstrap",
    lesson: "14 ",
    status: "alert-success text-success",
  },
]; 

// Pie Chart for Grade Distribution

function Teacher() {
  const [iconList, setIconList] = useState([
    {
      name: "0",
      count: "Pre-recorded Courses",
      status: "info",
      icon: "feather-video",
      des: "Number of active courses",
    },
    {
      name: "0",
      count: "Live Courses",
      status: "info",
      icon: "feather-mic",
      des: "Number of live courses",
    },
    {
      name: "0",
      count: "Completed Courses",
      status: "success",
      icon: "feather-check-square",
      des: "Total completed courses ",
    },
    {
      name: "0",
      count: "Published Recorded Courses",
      status: "success",
      icon: "feather-check-square",
      des: "Total published courses",
    },
    // ... other items as needed
  ]);
  const [lineChart, setLineChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      fill: {
        colors: ["#34c38f", "#556ee6"],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
    },
    series: [
      {
        name: "Pre-recorded courses",
        data: [0, 2, 0, 0, 0, 0, 3, 0, 0, 0, 0, 0],
      },
      // {
      //   name: "Live courses",
      //   data: [0, 0, 0, 4, 0, 0, 0, 0, 5, 0, 0, 0],
      // },
    ],
  });
  const [pieChart, setPieChart] = useState({
    options: {
      labels: ["A: Recorded courses", "B: Live courses"],
      colors: ["#f46a6a", "#34c38f"],
      legend: {
        position: "bottom",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [2, 7], // Sample data for grade distribution
  });
  TeacherProtection();

  const handleImageClick = () => {
    // Replace "/other-page" with the actual URL you want to redirect to
    window.location.href = "./teacher-course-list";
  };

  const updateCourseCounts = (courses, liveCourses) => {
    const prerecordedCounts = new Array(12).fill(0);
    const liveCounts = new Array(12).fill(0);

    courses.forEach((course) => {
      const month = new Date(course.course.createdAt).getUTCMonth(); // Get month (0-11)
      prerecordedCounts[month]++;
    });

    liveCourses.forEach((course) => {
      const month = new Date(course.date).getUTCMonth(); // Get month (0-11)
      liveCounts[month]++;
    });

    setLineChart((prevLineChart) => ({
      ...prevLineChart,
      series: [
        { ...prevLineChart.series[0], data: prerecordedCounts },
        // { ...prevLineChart.series[1], data: liveCounts },
      ],
    }));
  };
  const fetchTeacherStats = async () => {
    await getTeacherStats().then((data) => {

      const statData = data.data.data;
      // console.log("statData", statData);
      // iconList[0].count = data.data.totalCourses;
      // iconList[1].count = data.data.publishedCourses;
      // iconList[2].count = data.data.completedLiveCourses;
      // iconList[3].count = data.data.upcomingLectures;

      setIconList([
        {
          name: statData.coursesDateandCount.length,
          count: "Pre-recorded Courses",
          status: "info",
          icon: "feather-video",
          des: "Number of active courses",
        },
        {
          name: statData.liveCoursesDateandCount.length,
          count: "Live Courses",
          status: "info",
          icon: "feather-mic",
          des: "Number of live courses",
        },
        {
          name: statData.completedLiveCourses,
          count: "Completed Live Courses",
          status: "success",
          icon: "feather-check-square",
          des: "Number of live courses completed ",
        },
        {
          name: statData.publishedCourses || 0,
          count: "Published Recorded Courses",
          status: "success",
          icon: "feather-check-square",
          des: "Total published courses",
        },
        // ... other items as needed
      ]);

      updateCourseCounts(statData.enrolledCoursesDateandCount, statData.liveCoursesDateandCount);

      setPieChart({
        options: {
          labels: ["A: Recorded courses", "B: Live courses"],
          colors: ["#f46a6a", "#34c38f"],
          legend: {
            position: "bottom",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        series: [
          statData.coursesDateandCount.length || 0,
          statData.liveCoursesDateandCount.length || 0,
        ], // Sample data for grade distribution
      });
    });
  };

  useEffect(() => {
    fetchTeacherStats();
  }, []);
  return (
    <Fragment>
      <UserDialog
        role="teacher"
        message="Get ready to embark on a journey of teaching and growing!"
      />
      <div id="wrapper">
        <Teachersidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            {/* Content Here */}
            <div className="container-fluid px-4 py-4">
              {/* Icon Statistics Row */}
              <div className="row mb-4">
                {iconList &&
                  iconList.map((value, index) => (
                    <div key={index} className="col-xl-3 col-md-6 mb-4">
                      <div
                        className={`card shadow h-100 py-2 alert-${value.status}`}
                      >
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div
                                className={`text-xs font-weight-bold text-${value.status} text-uppercase mb-1`}
                                style={{
                                  minHeight: "50px", // Ensure that there is space for one or two lines of text
                                }}
                              >
                                {value.count}
                              </div>
                              <div className="h6 mb-0 font-weight-bold text-gray-800">
                                {value.name}
                              </div>
                              <div className="text-xs text-gray-500">
                                {value.des}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i
                                className={`psor text-white btn-round-md font-xs ${value.icon} bg-${value.status}`}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* Line Chart for Student Performance Trends */}
              <div className="row">
                <div className="col-xl-8">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Course Sales Statistics
                      </h6>
                    </div>
                    <div className="card-body">
                      <Chart
                        options={lineChart.options}
                        series={lineChart.series}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                </div>

                {/* Pie Chart for Grade Distribution */}
                <div className="col-xl-4">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Courses Distribution
                      </h6>
                    </div>
                    <div className="card-body">
                      <Chart
                        options={pieChart.options}
                        series={pieChart.series}
                        type="donut"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="container px-3 py-4">
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="card border-0 mt-2 rounded-10">
                      <div className="card-body d-flex pt-4 px-4 pb-0">
                        <h4 className="font-xss text-grey-800 mt-3 fw-700">
                          Recorded Courses List
                        </h4>
                      </div>
                      <div className="card-body p-4">
                        <div className="col-lg-12 my-3 d-flex flex-row flex-wrap">
                          {popularList.map((value, index) => (
                            <div className="col-lg-4 col-md-6 d-flex">
                              <div className="course-box d-flex aos">
                                <div className="product">
                                  <div className="product-img">
                                    <Link
                                      to={`/course/${value._id}`}
                                      className="video-bttn position-relative d-block"
                                    >
                                      <img
                                        className="img-fluid"
                                        alt=""
                                        src={`assets/images/${value.imageUrl}`}
                                      />
                                    </Link>
                                    <div className="price">
                                      <h3>{value.tag}</h3>
                                    </div>
                                  </div>
                                  <div className="product-content">
                                    <div className="course-group d-flex">
                                      <div className="course-group-img d-flex">
                                        <div className="course-name">
                                          <h4></h4>
                                        </div>
                                      </div>
                                      <div className="course-share d-flex align-items-center justify-content-center">
                                        <a href="#">
                                          <i className="fa-regular fa-heart"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <h3 className="title instructor-text">
                                      <Link
                                        to={`/course/${value._id}`}
                                        className="text-dark text-grey-900 capitalize"
                                      >
                                        {value.title}
                                      </Link>
                                    </h3>
                                    <div className="course-info d-flex align-items-center">
                                      <div className="rating-img d-flex align-items-center">
                                        <img
                                          src="assets/img/icon/icon-01.svg"
                                          alt=""
                                        />
                                        <p> {value.tag} </p>
                                      </div>
                                    </div>

                                    <div className="all-btn all-category d-flex align-items-center">
                                      <a
                                        href="checkout.html"
                                        className="btn btn-primary"
                                      >
                                        PKR {value.price}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                          <ul className="nav header-navbar-rht">
                            <li className="nav-item">
                              <Link
                                className="nav-link header-sign"
                                to="/teacher-course-list"
                              >
                                See More
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container px-3 py-4">
                <div className="row">
                  <div className="col-lg-12 mt-4">
                    <div className="card border-0 mt-2 rounded-10">
                      <div className="card-body d-flex pt-4 px-4 pb-0">
                        <h4 className="font-xss text-grey-800 mt-3 fw-700">
                          Live Courses List
                        </h4>
                      </div>
                      <div className="card-body p-4">
                        <div className="col-lg-12 mt-3 d-flex flex-row flex-wrap">
                          {popularList.map((value, index) => (
                            <div className="col-lg-4 col-md-6 d-flex">
                              <div className="course-box d-flex aos">
                                <div className="product">
                                  <div className="product-img">
                                    <Link
                                      to={`/course/${value._id}`}
                                      className="video-bttn position-relative d-block"
                                    >
                                      <img
                                        className="img-fluid"
                                        alt=""
                                        src={`assets/images/${value.imageUrl}`}
                                      />
                                    </Link>
                                    <div className="price">
                                      <h3>{value.tag}</h3>
                                    </div>
                                  </div>
                                  <div className="product-content">
                                    <div className="course-group d-flex">
                                      <div className="course-group-img d-flex">
                                        <div className="course-name">
                                          <h4></h4>
                                        </div>
                                      </div>
                                      <div className="course-share d-flex align-items-center justify-content-center">
                                        <a href="#">
                                          <i className="fa-regular fa-heart"></i>
                                        </a>
                                      </div>
                                    </div>
                                    <h3 className="title instructor-text">
                                      <Link
                                        to={`/course/${value._id}`}
                                        className="text-dark text-grey-900 capitalize"
                                      >
                                        {value.title}
                                      </Link>
                                    </h3>
                                    <div className="course-info d-flex align-items-center">
                                      <div className="rating-img d-flex align-items-center">
                                        <img
                                          src="assets/img/icon/icon-01.svg"
                                          alt=""
                                        />
                                        <p> {value.lesson} Lessons </p>
                                      </div>
                                    </div>

                                    <div className="all-btn all-category d-flex align-items-center">
                                      <a
                                        href="checkout.html"
                                        className="btn btn-primary"
                                      >
                                        PKR {value.price}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-4">
                          <ul className="nav header-navbar-rht">
                            <li className="nav-item">
                              <Link
                                className="nav-link header-sign"
                                to="/teacher-course-list"
                              >
                                See More
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Teacher;
