import { createSlice } from "@reduxjs/toolkit";

const courseSlice = createSlice({
  name: "course_application",
  initialState: {
    courseData: null,
    moduleData: [],
    currentModuleID: null,
    isModuleDataNotEmpty: false, // Default value set to false
  },
  reducers: {
    getCourse(state, action) {
      state.courseData = action.payload;
    },
    setCourse(state, action) {
      state.courseData = action.payload;
    },
    setCurrentModuleID(state, action) {
        state.currentModuleID = action.payload;
      },
      nullateData(state) {
        state.courseData = null;
        state.moduleData = [];
        state.currentModuleID =  null;
      },
    nullCourseAndModule: (state) => {
      state.courseData = null;
      state.loggedIn = false;
    },
    nullateModule: (state) => {
      state.moduleData = [];
    },
    getModuleData(state, action) {
      state.moduleData = action.payload;
    },
    removeModuleById(state, action) {
      const moduleId = action.payload;
      state.moduleData = state.moduleData.filter(
        (module) => module._id !== moduleId
      );
    },
    setIsModuleDataNotEmpty(state) {
      // Check if moduleData array is not empty and currentModuleID is not null
      state.isModuleDataNotEmpty = state.moduleData.length > 0 ;
    },

    setModuleData(state, action) {
      // Check if action.payload is an array
      if (Array.isArray(action.payload)) {
        // Merge previous module data with new module data
        state.moduleData = state.moduleData.map((existingModule) => {
          const matchingIndex = action.payload.findIndex(
            (newModule) => newModule._id === existingModule._id
          );
          if (matchingIndex !== -1) {
            // Replace existing module with the new one
            return action.payload[matchingIndex];
          }
          return existingModule;
        });
      } else {
        // If action.payload is not an array, simply append it to the existing state.moduleData
        const existingIndex = state.moduleData.findIndex(
          (existingModule) =>
            existingModule._id === action.payload._id
        );
        if (existingIndex !== -1) {
          // Replace existing module with the new one
          state.moduleData[existingIndex] = action.payload;
        } else {
          state.moduleData = [...state.moduleData, action.payload];
        }
      }
    },
  },
});

export const {
  getCourse,
  setCourse,
  nullCourseAndModule,
  setModuleData,
  getModuleData,
  setCurrentModuleID,
  nullateData,
  removeModuleById,
  setIsModuleDataNotEmpty,
  nullateModule
} = courseSlice.actions;
export default courseSlice.reducer;
