import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./combineReducer"; // Assuming you have a combined reducer
// import userReducer from "./userSlice"; // Import user reducer if exists
// import teacherReducer from "./teacherApplicationReducer"; // Import teacher reducer

const store = configureStore({
  reducer: rootReducer, // Assuming you have a combined reducer
  // No need to include individual reducers here
});

export default store;