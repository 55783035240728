import { createSlice } from "@reduxjs/toolkit";

const userslice = createSlice({
  name: "user",

  initialState: {
    userData: null,
    loggedIn: false,
    addContactBox: false,
  },

  reducers: {
    getuser(state, action) {
      state.userData = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.loggedIn = true;
    },
    logoutUser: (state) => {
      state.user = null;
      state.loggedIn = false;
    },
  },
});

export const { getuser, setUser, logoutUser, setcontactBox } =
  userslice.actions;
export default userslice.reducer;
