import httpClient from "../http-common";


// // AuthorizeUser
export const getAllEnrolledUsers = (token) => {
    return httpClient.get(`/enrollment/instructor/courses`, {
        headers: {
            'x-auth-token': token
        },
    });
};




