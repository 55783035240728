import React from "react";
import ReactDOM from "react-dom";

import "./main.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/forgotPasswor.jsx";
import Coming from "./pages/Coming";
import Coursesgridthree from "./pages/Coursegridthree";
import Default from "./pages/Default";
import Admin from "./pages/Admin";
import Defaultfollower from "./pages/Defaultfollower";
import Defaultchannel from "./pages/Defaultchannel";
import Defaultlive from "./pages/Defaultlive";
import Defaultuserprofile from "./pages/Defaultuserprofile";
import Defaultauthorprofile from "./pages/Defaultauthorprofile";
import Defaultsettings from "./pages/Defaultsettings";
import Defaultanalytics from "./pages/Defaultanalytics";
import Chat from "./pages/Chat";
import Defaultsearch from "./pages/Defaultsearch";
import Adminchat from "./pages/Adminchat";
import Admincustomer from "./pages/Admincustomer";
import Admincustomerview from "./pages/Admincustomerview";
import Adminemail from "./pages/Adminemail";
import Adminorder from "./pages/Adminorder";
import Adminorderview from "./pages/Adminorderview";
import Adminproductadd from "./pages/Adminproductadd";
import Adminproductgrid from "./pages/Adminproductgrid";
import Adminproductlist from "./pages/Adminproductlist";
import Adminreview from "./pages/Adminreview";
import Adminvenderlist from "./pages/Adminvenderlist";
import Adminvenderview from "./pages/Adminvenderview";
import Accountinfo from "./pages/Accountinfo";
import Contactinfo from "./pages/Contactinfo";
import Social from "./pages/Social";
import Payment from "./pages/Payment";
import Password from "./pages/Password";
import Defaultprojects from "./pages/Defaultprojects";
import Adminprojects from "./pages/Adminprojects";
import Adminprojectsgrid from "./pages/Adminprojectsgrid";
import Adminprojectsadd from "./pages/Adminprojectsadd";
import Defaultcourseone from "./pages/Defaultcourseone";
import Teacher from "./pages/Teacher";
import Teachercreate from "./pages/Teachercreate";
import Teachercreatelive from "./pages/TeacherCreateLiveCourse";
import TeacherWatchCourse from "./pages/TeacherWatchCourse";
import Teachercourseplan from "./pages/Teachercourseplan";
import Teachereditcourse from "./pages/Teachereditcourse";
import Teacherdiscountvoucher from "./pages/Teacherdiscountvoucher";
import Teacherenrollments from "./pages/Teacherenrollments";
import Teacherstudentadd from "./pages/Teacherstudentadd";
import TeacherCourseList from "./pages/TeacherCourseList";
import Teachercertificates from "./pages/Teachercertificates";
import Certificate from "./pages/Certificate";
import TeacherReviews from "./pages/TeacherReviews";
import Teacherchat from "./pages/Teacherchat";
import Teacheremail from "./pages/Teacheremail";
import TeacherEarnings from "./pages/TeacherEarnings";
import Applytoteach from "./pages/Applytoteach";
import Navigation from "./pages/Navigation";
import AdminLogin from "./pages/AdminLogin";
import TeacherLogin from "./pages/TeacherLogin";
// import AdminRegister from "./pages/AdminRegister";
// import TeacherRegister from "./pages/TeacherRegister";
import { NotFoundPage } from "./pages/NotFoundPage";
import { HomePage } from "./pages/newHome";

import { Provider } from "react-redux";
import store from "./Redux/Store";
// import ExampleComponent from "./components/testComponent/ExampleComponent";
import TeacherCourses from "./pages/TeacherCourses";
import TeacherCourseEditForm from "./pages/TeacherCourseEditForm";
import AdminApprovedCourses from "./pages/AdminApprovedCourses";
import AdminApprovedCourseDetail from "./pages/AdminApprovedCourseDetail";
// import AddModuleForm from "./TestComponent";
import TeacherLiveCourses from "./pages/TeacherLiveCourses";
import LiveCourseDetailView from "./pages/LiveCourseDetailView";
import AdminLiveCourses from "./pages/AdminLiveCourses";
import AdminDetailLiveCourse from "./pages/AdminDetailLiveCourse";
import CoursePreview from "./pages/CoursePreview.jsx";
import CourseTeacherPreview from "./pages/Course-Teacher-Preview.jsx";
import StreamCoursePage from "./pages/StreamCoursePage";
import AdminApprovedApplicants from "./pages/AdminApprovedApplicants";
import AdminRejectedApplicnats from "./pages/AdminRejectedApplicants";
import Room from "./videoChatComponents/Room.tsx";
// import TestingMdal from "./TestingMdal";
import TeacherSettings from "./pages/TeacherSettings";
import { AuthProvider } from "./context/authContext.tsx";
import PrivateRoute from "./context/PrivateRoute.tsx";
import DefaultPublicSearch from "./pages/defaultPublicSearch.js";
import TeacherPassword from "./pages/TeacherPassword.js";
import TeacherAccountInfo from "./pages/TeacherAccountInfo.js";
import { StripeContext } from "./services/userServices/stripe.js";
import AdminWidhdrawal from "./pages/AdminWithdrawal.js";
import AdminCategory from "./pages/AdminCategory.js";
import TeacherStreamCourse from "./pages/TeacherStreamCourse.jsx";
import ForgotPasswordTeacher from "./pages/forgotPasswordTeacher.jsx";
import AdminEnrollment from "./pages/AdminEnrollment.js";

const Root: React.FC = () => {
  return (
    <StripeContext>
      <Provider store={store}>
        <AuthProvider>
          <BrowserRouter basename={"/"}>
            <Routes>
              <Route
                path="/default-public-search/:query/:category"
                element={<DefaultPublicSearch />}
              />
              <Route path="/" element={<HomePage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/coming" element={<Coming />} />
              <Route path="/forgot-password-user" element={<ForgotPassword />} />
              <Route path="/forgot-password-teacher" element={<ForgotPasswordTeacher />} />
              <Route
                path="/Default"
                element={<PrivateRoute element={Default} userRole="user" />}
              />
              <Route
                path="/Default-follower"
                element={
                  <PrivateRoute element={Defaultfollower} userRole="user" />
                }
              />
              <Route
                path="/Default-channel"
                element={
                  <PrivateRoute element={Defaultchannel} userRole="user" />
                }
              />
              <Route
                path="/Default-analytics"
                element={
                  <PrivateRoute element={Defaultanalytics} userRole="user" />
                }
              />

              <Route
                path="/message"
                element={<PrivateRoute element={Chat} userRole="user" />}
              />
              <Route
                path="/default-search/:query/:category"
                element={
                  <PrivateRoute element={Defaultsearch} userRole="user" />
                }
              />
              <Route
                path="/Default-live-stream/:id"
                element={<PrivateRoute element={Defaultlive} userRole="user" />}
              />
              <Route
                path="/Default-user-profile"
                element={
                  <PrivateRoute element={Defaultuserprofile} userRole="user" />
                }
              />
              <Route
                path="/Default-author-profile/:id"
                element={
                  <PrivateRoute
                    element={Defaultauthorprofile}
                    userRole="user"
                  />
                }
              />
              <Route
                path="/Default-settings"
                element={
                  <PrivateRoute element={Defaultsettings} userRole="user" />
                }
              />
              <Route
                path="/course-grid-3"
                element={
                  <PrivateRoute element={Coursesgridthree} userRole="user" />
                }
              />
              <Route
                path="/admin"
                element={<PrivateRoute element={Admin} userRole="admin" />}
              />
              <Route path="/login-admin" element={<AdminLogin />} />
              {/* <Route path="/register-admin" element={<AdminRegister />} /> */}
              <Route
                path="/admin-chat"
                element={<PrivateRoute element={Adminchat} userRole="admin" />}
              />
              <Route
                path="/admin-customer"
                element={
                  <PrivateRoute element={Admincustomer} userRole="admin" />
                }
              />
              <Route
                path="/admin-customerview/:id"
                element={
                  <PrivateRoute element={Admincustomerview} userRole="admin" />
                }
              />
              <Route
                path="/admin-approved-applicants"
                element={
                  <PrivateRoute
                    element={AdminApprovedApplicants}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/admin-withdrawal-requests"
                element={
                  <PrivateRoute
                    element={AdminWidhdrawal}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/admin-categories"
                element={
                  <PrivateRoute
                    element={AdminCategory}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/admin-rejected-applicants"
                element={
                  <PrivateRoute
                    element={AdminRejectedApplicnats}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/admin-email"
                element={<PrivateRoute element={Adminemail} userRole="admin" />}
              />
              <Route
                path="/admin-enrollment"
                element={<PrivateRoute element={AdminEnrollment} userRole="admin" />}
              />
              <Route
                path="/admin-order"
                element={<PrivateRoute element={Adminorder} userRole="admin" />}
              />
              <Route
                path="/admin-orderview"
                element={
                  <PrivateRoute element={Adminorderview} userRole="admin" />
                }
              />
              <Route
                path="/admin-productadd"
                element={
                  <PrivateRoute element={Adminproductadd} userRole="admin" />
                }
              />
              <Route
                path="/admin-productgrid"
                element={
                  <PrivateRoute element={Adminproductgrid} userRole="admin" />
                }
              />
              <Route
                path="/admin-productlist"
                element={
                  <PrivateRoute element={Adminproductlist} userRole="admin" />
                }
              />
              <Route
                path="/admin-review"
                element={
                  <PrivateRoute element={Adminreview} userRole="admin" />
                }
              />
              <Route
                path="/admin-venderlist"
                element={
                  <PrivateRoute element={Adminvenderlist} userRole="admin" />
                }
              />
              <Route
                path="/admin-venderview"
                element={
                  <PrivateRoute element={Adminvenderview} userRole="admin" />
                }
              />
              <Route
                path="/account-information"
                element={<PrivateRoute element={Accountinfo} userRole="user" />}
              />
              <Route
                path="/contact-information"
                element={
                  <PrivateRoute element={Contactinfo} userRole="admin" />
                }
              />
              <Route
                path="/social"
                element={<PrivateRoute element={Social} userRole="any" />}
              />
              <Route
                path="/payment"
                element={<PrivateRoute element={Payment} userRole="any" />}
              />
              <Route
                path="/password"
                element={<PrivateRoute element={Password} userRole="user" />}
              />
              <Route
                path="/default-projects"
                element={
                  <PrivateRoute element={Defaultprojects} userRole="admin" />
                }
              />
              <Route
                path="/admin-projectslist"
                element={
                  <PrivateRoute element={Adminprojects} userRole="admin" />
                }
              />
              <Route
                path="/admin-approvedCourse-list"
                element={
                  <PrivateRoute
                    element={AdminApprovedCourses}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/admin-live-courses"
                element={
                  <PrivateRoute element={AdminLiveCourses} userRole="admin" />
                }
              />
              <Route
                path="/admin-projectsgrid"
                element={
                  <PrivateRoute element={Adminprojectsgrid} userRole="admin" />
                }
              />
              <Route
                path="/admin-projectsadd/:id"
                element={
                  <PrivateRoute element={Adminprojectsadd} userRole="admin" />
                }
              />
              <Route
                path="/admin-approvedCourse/:id"
                element={
                  <PrivateRoute
                    element={AdminApprovedCourseDetail}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/admin-approved-live-Course/:id"
                element={
                  <PrivateRoute
                    element={AdminDetailLiveCourse}
                    userRole="admin"
                  />
                }
              />
              <Route
                path="/default-course-one/:id"
                element={
                  <PrivateRoute element={Defaultcourseone} userRole="user" />
                }
              />
              <Route
                path="/stream-course/:id"
                element={
                  <PrivateRoute element={StreamCoursePage} userRole="user" />
                }
              />
              <Route
                path="/course/:id"
                element={
                  <PrivateRoute element={CoursePreview} userRole="user" />
                }
              />
              <Route
                path="/course-teacher/:id"
                element={
                  <PrivateRoute
                    element={CourseTeacherPreview}
                    userRole="teacher"
                  />
                }
              />
              <Route path="/login-teacher" element={<TeacherLogin />} />
              {/* <Route path="/register-teacher" element={<TeacherRegister />} /> */}
              <Route
                path="/teacher"
                element={<PrivateRoute element={Teacher} userRole="teacher" />}
              />
              <Route
                path="/teacher-create"
                element={
                  <PrivateRoute element={Teachercreate} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-watch-course"
                element={
                  <PrivateRoute
                    element={TeacherWatchCourse}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-account-information"
                element={
                  <PrivateRoute
                    element={TeacherAccountInfo}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-password"
                element={
                  <PrivateRoute element={TeacherPassword} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-course-plan"
                element={
                  <PrivateRoute
                    element={Teachercourseplan}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-edit-course/:selectedCourse"
                element={
                  <PrivateRoute
                    element={Teachereditcourse}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-discount-voucher"
                element={
                  <PrivateRoute
                    element={Teacherdiscountvoucher}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-enrollments"
                element={
                  <PrivateRoute
                    element={Teacherenrollments}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-my-courses"
                element={
                  <PrivateRoute element={TeacherCourses} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-my-live-courses"
                element={
                  <PrivateRoute
                    element={TeacherLiveCourses}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-student-edit-form"
                element={
                  <PrivateRoute
                    element={Teacherstudentadd}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-course-edit-form/:id"
                element={
                  <PrivateRoute
                    element={TeacherCourseEditForm}
                    userRole="teacher"
                  />
                }
              />

              <Route
                path="/teacher-stream-course/:id"
                element={
                  <PrivateRoute
                    element={TeacherStreamCourse}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-live-course-detailedView/:id"
                element={
                  <PrivateRoute
                    element={LiveCourseDetailView}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-course-list"
                element={
                  <PrivateRoute
                    element={TeacherCourseList}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-create-live-course"
                element={
                  <PrivateRoute
                    element={Teachercreatelive}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-setting"
                element={
                  <PrivateRoute element={TeacherSettings} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-earnings"
                element={
                  <PrivateRoute element={TeacherEarnings} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-certificates-list"
                element={
                  <PrivateRoute
                    element={Teachercertificates}
                    userRole="teacher"
                  />
                }
              />
              <Route
                path="/teacher-certificate"
                element={
                  <PrivateRoute element={Certificate} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-reviews"
                element={
                  <PrivateRoute element={TeacherReviews} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-chat"
                element={
                  <PrivateRoute element={Teacherchat} userRole="teacher" />
                }
              />
              <Route
                path="/teacher-email"
                element={
                  <PrivateRoute element={Teacheremail} userRole="teacher" />
                }
              />
              <Route path="/teacher-carousel" element={<Applytoteach />} />
              <Route path="/navigation" element={<Navigation />} />
              <Route path="/room" element={<Room />} />
              <Route path="*" element={<NotFoundPage />} />
              {/* <Route path="/test" element={<ExampleComponent />} /> */}
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </Provider>
    </StripeContext>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
