import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
// import Adminfooter from "../components/Adminfooter.js";
import Teachersidebar from "../components/Teachersidebar.js";
import RecordedCourseIcon from "./1home.png";
import TeacherTopnav from "../components/TeacherTopNav.js";
import { Alert, Button, Spinner } from "react-bootstrap";

// Services APIs
import {
  editCourse,
  uploadCourse,
} from "../services/courseServices/courseServices";

import { nullateModule, setCourse } from "../Redux/courseSlice.jsx";
import ThumbnailUpload from "../components/ModuleComponents/ThumbnailPreview.js";
import { useNavigate } from "react-router-dom";
import AddModuleForm from "../TestComponent.jsx";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";
import { getCategories } from "../services/categoryServices/categoryServices.js";

function Teachercreate() {
  TeacherProtection();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleNextClick = () => {
    if (currentSection < 4) {
      setCurrentSection(currentSection + 1);
    }
  };

  // const [originalResult, setOriginalResult] = useState(0);
  // const [discountedResult, setDiscountedResult] = useState(0);
  // const [discountedPrice, setDiscountedPrice] = useState("");

  const styles2 = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      marginBottom: "30px",
    },
    column: {
      margin: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    label: {
      marginBottom: "5px",
      fontWeight: "bold",
      color: "#333",
      fontSize: "0.8rem",
    },
    input: {
      marginBottom: "10px",
      padding: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "150px",
    },
    result: {
      color: "#4a90e2",
      fontWeight: "bold",
    },
  };

  const [originalPrice, setOriginalPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [originalResult, setOriginalResult] = useState(0);
  const [discountedResult, setDiscountedResult] = useState(0);

  const handleOriginalPriceChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, ""); // Remove leading zeroes

    if (/^\d*$/.test(value)) {
      setOriginalPrice(value);
      const original = parseFloat(value) || 0;
      setOriginalResult(original);

      const discounted = parseFloat(discountedPrice) || 0;
      if (original > discounted) {
        setDiscountedResult(discounted);
      } else {
        setDiscountedResult(0);
      }
    }
  };

  const handleDiscountedPriceChange = (e) => {
    let value = e.target.value;
    value = value.replace(/^0+/, ""); // Remove leading zeroes

    const original = parseFloat(originalPrice) || 0;

    if (/^\d*$/.test(value) && parseFloat(value) <= original) {
      setDiscountedPrice(value);
      const discounted = parseFloat(value) || 0;
      setDiscountedResult(discounted);
    }
  };

  const handleBackClick = () => {
    if (currentSection > 1) {
      setCurrentSection(currentSection - 1);
    }
  };
  const buttonWidth = "700px";
  const courseIcons = {
    recorded: RecordedCourseIcon,
  };

  const handleDraft = () => {
    navigate("/teacher-my-courses");
  };

  const imageStyle = {
    marginRight: "20px",
  };
  const selectedBadgeStyle = {
    display: "block",
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "green",
    color: "white",
    borderTopRightRadius: "13px",
    borderBottomLeftRadius: "13px",
    padding: "5px 10px",
    fontSize: "0.8rem",
  };

  const cardContentStyle = {
    flexGrow: 1,
    textAlign: "left",
  };
  const courseButtonStyle = {
    display: "flex",
    alignItems: "flex-start",
    padding: "15px",
    margin: "10px 0",
    border: "1px solid #ccc",
    borderRadius: "13px",
    width: buttonWidth,
    cursor: "pointer",
  };

  const [currentSection, setCurrentSection] = useState(1);

  const [user, setUser] = useState({
    loggedIn: false,
    user: {
      name: "No User",
      domain_of_study: "Not Logged In",
    },
  });

  const loggedInTeacher = useSelector((state) => state.teacher.loggedIn);
  console.log(loggedInTeacher);
  const teacherData = useSelector((state) => state.teacher);

  const [courseCategories, setCourseCategories] = useState([]);
  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 200) {
        setCourseCategories(response.data);
      } else {
        console.log("Error in fetching categories");
        alert("Error in fetching categories");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loggedInTeacher) {
      fetchCategories();
      setUser({
        loggedIn: true,
        user: {
          name: teacherData.user.name,
          domain_of_study: teacherData.user.domain_of_study,
        },
      });
    }
    // console.log(teacherData.user.name);
  }, [loggedInTeacher, teacherData]);

  // Poorana Maaaal

  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />
            <div className="container-fluid px-4 py-4">
              {/* Attractive and unique heading and subheading */}
              {currentSection === 1 && (
                <div className="text-center mb-4">
                  <h2
                    style={{
                      fontSize: "2.0rem",
                      fontWeight: "bold",
                      color: "#4a4a4a",
                    }}
                  >
                    Hi! Welcome {user.user.name}
                  </h2>
                </div>
              )}
            </div>
            <div
              className="card-body d-block p-5 pb-4 text-center"
              style={{ marginTop: "-50px" }}
            >
              <div
                className="timeline-steps aos-init aos-animate"
                data-aos="fade-up"
              >
                <div
                  className={`timeline-step ${
                    currentSection === 1 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Basic Details
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 2 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Pricing & Finalizing
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 3 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Lesson & Videos
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 4 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      One Last Thing
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid px-4 py-4">
              <div
                className="d-flex flex-column align-items-center "
                style={{ marginTop: "-30px" }}
              >
                {currentSection === 1 && <BasicDetails />}

                {/* Content for other sections */}
                {currentSection === 3 && (
                  <div className="bg-white">
                    <div class="widget-btn ">
                      <div></div>
                      <button
                        onClick={handleDraft}
                        type="submit"
                        class="btn btn-success"
                        style={{
                          backgroundColor: "#4CAF50 ",
                          borderColor: "#4CAF50 ",
                        }}
                      >
                        Save
                      </button>
                    </div>
                    <AddModuleForm showProgress={true} />
                    <div class="widget-btn">
                      <button onClick={handleBackClick} class="btn btn-black">
                        Back
                      </button>
                      <button
                        type="submit"
                        onClick={handleNextClick}
                        class="btn btn-info-light next_btn"
                      >
                        {" "}
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : (
                          "Continue"
                        )}
                      </button>
                    </div>

                    <br />
                    <div></div>
                  </div>
                )}

                {currentSection === 2 && <PriceComponent />}
                {currentSection === 4 && (
                  <div>
                    <div class="widget-btn">
                      <div></div>
                      <button
                        onClick={handleDraft}
                        type="submit"
                        class="btn btn-success"
                        style={{
                          backgroundColor: "#4CAF50 ",
                          borderColor: "#4CAF50 ",
                          height: "max",
                        }}
                      >
                        Save
                      </button>
                    </div>
                    <ThumbnailUpload handleBackClick={handleBackClick} />
                    {/* <div class="widget-btn">
                      <button onClick={handleBackClick} class="btn btn-black">
                        Back
                      </button>
                    </div> */}
                  </div>
                )}
              </div>
            </div>
            {/* <Adminfooter /> */}
          </div>
        </div>
      </div>
    </Fragment>
  );

  function BasicDetails() {
    const dispatch = useDispatch();

    useEffect(() => {
      document.title = "Create Course | Seekho";
      setRelatedValues({ token: JSON.parse(localStorage.getItem("token")) });
    }, []);

    const [relatedValues, setRelatedValues] = useState({
      user: {
        name: "No User",
        domain_of_study: "Not Logged In",
      },
      token: "",
      courseID: "",
    });
    const onSubmit = async (data) => {
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("description", data.description);
        formData.append("domain_of_study", data.domain_of_study);
        await uploadCourse(relatedValues.token, formData)
          .then((res) => {
            setLoading(true);
            localStorage.setItem("courseID", res.data.course._id);
            dispatch(setCourse(res.data));
            if (currentSection < 4) {
              setCurrentSection(currentSection + 1);
            }
            setError({
              visible: false,
              message: "",
            });
            setLoading(false);
          })
          .catch((err) => {
            // console.log(err.response.data.errors)
            console.log(err.response.data.errors[0].msg);
            setError({
              visible: true,
              message: err.response.data.errors[0].msg,
            });
            setLoading(false);
          });
      } catch (err) {
        setError({
          visible: true,
          message: "Server Error",
        });
        setLoading(false);
      }
    };
    return (
      <div>
        <p
          style={{
            fontSize: "0.9rem",
            marginBottom: "5px",
          }}
        >
          Add basic details to create your course
        </p>
        <div className="card position-relative" style={courseButtonStyle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={courseIcons.recorded}
              alt="Recorded Course"
              width="48"
              height="48"
              style={imageStyle}
            />
            <div style={cardContentStyle}>
              <h5
                className="card-title text-success"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  color: "#4CAF50",
                }}
              >
                Recorded Course
              </h5>
              <p
                className="card-text"
                style={{ fontSize: "0.9rem", marginTop: "5px" }}
              >
                Create, launch, and sell your recorded course.
              </p>
            </div>
          </div>
          <div style={selectedBadgeStyle}>Selected</div>
        </div>

        <div>
          {error.visible && <Alert variant="danger">{error.message}</Alert>}
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              maxWidth: "700px",
              marginTop: "30px",
            }}
          >
            <div className="col-lg-30 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("title", { required: "Title must be included" })}
                />
                {errors.title && (
                  <span className="text-danger">{errors.title.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-30 mb-3">
              <div className="form-group">
                <label className="mont-font fw-600 font-xsss">
                  Course Category
                </label>
                <select
                  className="form-control"
                  {...register("domain_of_study", {
                    required: "Please select a category",
                  })}
                >
                  <option value="">Select</option>
                  {courseCategories.length > 0 &&
                    courseCategories.map((category) => (
                      <option value={category.name}>{category.name}</option>
                    ))}
                </select>
                {errors.domain_of_study && (
                  <span className="text-danger">
                    {errors.domain_of_study.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-lg-30 mb-3">
              <label className="mont-font fw-600 font-xsss">
                Briefly explain your course & why is it important?
              </label>
              <textarea
                className="form-control mb-0 p-3 bg-white lh-16"
                rows="5"
                placeholder="Write your message..."
                {...register("description", {
                  required: "Please enter description about the course",
                })}
              ></textarea>
              {errors.description && (
                <span className="text-danger">
                  {errors.description.message}
                </span>
              )}
            </div>
            <div className="form-group text-center mt-4"></div>
            <div class="widget-btn">
              <div></div>
              <button
                type="submit"
                disabled={loading}
                class="btn btn-info-light next_btn"
              >
                {" "}
                {loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Create"
                )}
              </button>
            </div>
            {/* <button
              className="btn btn-success" // Add the same class as the Link
              style={{ width: buttonWidth }}
              // disabled={loading}
             
            >
             
            </button> */}
          </form>
        </div>
      </div>
    );
  }

  function PriceComponent() {
    const dispatch = useDispatch();
    const [originalPrice, setOriginalPrice] = useState("");
    const [discountedPrice, setDiscountedPrice] = useState("");
    const [originalResult, setOriginalResult] = useState(0);
    const [discountedResult, setDiscountedResult] = useState(0);

    const originalPriceRef = useRef(null);
    const discountedPriceRef = useRef(null);

    const handleOriginalPriceChange = (e) => {
      let value = e.target.value;
      value = value.replace(/^0+/, ""); // Remove leading zeroes

      if (/^\d*$/.test(value)) {
        setOriginalPrice(value);
        const original = parseFloat(value) || 0;
        setOriginalResult(original);

        const discounted = parseFloat(discountedPrice) || 0;
        if (original > discounted) {
          setDiscountedResult(discounted);
        } else {
          setDiscountedResult(0);
        }
      }
    };

    const handleDiscountedPriceChange = (e) => {
      let value = e.target.value;
      value = value.replace(/^0+/, ""); // Remove leading zeroes

      const original = parseFloat(originalPrice) || 0;

      if (/^\d*$/.test(value) && parseFloat(value) <= original) {
        setDiscountedPrice(value);
        const discounted = parseFloat(value) || 0;
        setDiscountedResult(discounted);
      }
    };

    const [relatedValues, setRelatedValues] = useState({
      token: "",
      courseID: "",
    });

    dispatch(nullateModule());

    useEffect(() => {
      document.title = "Create Course | Seekho | Pricing";
      setRelatedValues({
        token: JSON.parse(localStorage.getItem("token")),
        courseID: localStorage.getItem("courseID"),
      });
    }, []);

    const onSubmit = async (data, event) => {
      event.preventDefault();
      setLoading(true);
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("courseID", relatedValues.courseID);
        formData.append("price", originalResult - discountedResult);
        console.log(formData);
        await editCourse(relatedValues.token, formData)
          .then((res) => {
            setLoading(true);
            dispatch(setCourse(res.data));
            if (currentSection < 4) {
              setCurrentSection(currentSection + 1);
            }
            console.log(res.data);
            setError({
              visible: false,
              message: "",
            });
            setLoading(false);
          })
          .catch((err) => {
            // console.log(err.response.data.errors)
            console.log(err.response.data.errors[0].msg);
            setError({
              visible: true,
              message: err.response.data.errors[0].msg,
            });
            setLoading(false);
          });
      } catch (err) {
        setError({
          visible: true,
          message: "Server Error",
        });
        setLoading(false);
      }
    };
    return (
      <div>
        <div class="widget-btn">
          <div></div>
          <button
            onClick={handleDraft}
            type="submit"
            class="btn btn-success"
            style={{
              backgroundColor: "#4CAF50 ",
              borderColor: "#4CAF50 ",
            }}
          >
            Save
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {error.visible && <Alert variant="danger">{error.message}</Alert>}
          <div style={styles2.container}>
            <div style={styles2.column}>
              <label style={styles2.label}>Original Price (PKR)</label>
              <input
                ref={originalPriceRef}
                style={styles2.input}
                type="number"
                value={originalPrice}
                onChange={handleOriginalPriceChange}
              />
              <div style={styles2.result}>{originalResult}</div>
            </div>

            <div style={styles2.column}>
              <label style={styles2.label}>Discounted Price (PKR)</label>
              <input
                ref={discountedPriceRef}
                style={styles2.input}
                type="number"
                value={discountedPrice}
                onChange={handleDiscountedPriceChange}
              />
              <div style={styles2.result}>{discountedResult}</div>
            </div>

            <div style={styles2.column}>
              <label style={styles2.label}>Result</label>
              <input
                style={styles2.input}
                type="text"
                value={originalResult - discountedResult}
                readOnly
              />
            </div>
          </div>
          <div class="widget-btn">
            <button onClick={handleBackClick} class="btn btn-black">
              Back
            </button>
            <button
              type="submit"
              disabled={loading}
              class="btn btn-info-light next_btn"
            >
              {" "}
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Continue"
              )}
            </button>
          </div>
          <br />
        </form>
      </div>
    );
  }
}

export default Teachercreate;
