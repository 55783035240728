import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { socket } from "./webRTC/socket.ts";
import { roomNoVar } from "./webRTC/globals.ts";
import { Eraser } from "lucide-react";
import { userAction } from "./webRTC/action.ts";

const WhiteBoard = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [prevCoords, setPrevCoords] = useState({ x: 0, y: 0 });
  const [color, setColor] = useState("black");
  const [drawSize, setDrawSize] = useState(3);

  const draw = (
    newX: number,
    newY: number,
    prevX: number,
    prevY: number,
    color: string | CanvasGradient | CanvasPattern,
    size: number
  ) => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!ctx) return;
    ctx.strokeStyle = color;
    ctx.lineWidth = size;
    ctx.beginPath();
    ctx.moveTo(prevX, prevY);
    ctx.lineTo(newX, newY);
    ctx.stroke();
    ctx.closePath();
  };

  const handleDraw = (data: {
    newX: any;
    newY: any;
    prevX: any;
    prevY: any;
    color: any;
    size: any;
  }) => {
    const { newX, newY, prevX, prevY, color, size } = data;
    draw(newX, newY, prevX, prevY, color, size);
  };

  const handleMouseDown = (e: {
    nativeEvent: { offsetX: any; offsetY: any };
  }) => {
    setIsDrawing(true);
    setPrevCoords({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const handleMouseMove = (e: {
    nativeEvent: { offsetX: any; offsetY: any };
  }) => {
    if (!isDrawing) return;
    const newX = e.nativeEvent.offsetX;
    const newY = e.nativeEvent.offsetY;

    if (userAction === "createRoom") {
      draw(newX, newY, prevCoords.x, prevCoords.y, color, drawSize);
      sendDrawAction(newX, newY, prevCoords.x, prevCoords.y, color, drawSize);
    }

    setPrevCoords({ x: newX, y: newY });
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    socket.send(
      JSON.stringify({
        type: "storeCanvas",
        roomId: roomNoVar,
        canvasData: canvasRef.current?.toDataURL(),
      })
    );
  };

  const sendDrawAction = (
    newX: any,
    newY: any,
    prevX: number,
    prevY: number,
    color: string,
    size: number
  ) => {
    if (userAction !== "createRoom") return;
    socket.send(
      JSON.stringify({
        type: "draw",
        newX,
        newY,
        prevX,
        prevY,
        color,
        size,
      })
    );
  };
  const clearBoard = () => {
    if (userAction === "createRoom") {
      if (
        window.confirm(
          "Are you sure you want to clear the board? This cannot be undone."
        )
      ) {
        const canvas = canvasRef.current as HTMLCanvasElement;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        if (socket && socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ type: "clearBoard" }));
        }
      }
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    canvas.style.width = "100%";
    canvas.style.height = "100%";

    const handleClearBoard = () => {
      const ctx = canvas.getContext("2d");
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
    socket.send(JSON.stringify({ type: "getCanvas", roomId: roomNoVar }));
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "draw") {
        handleDraw(data);
      } else if (data.type === "clearBoard") {
        handleClearBoard();
      } else if (data.type === "getCanvas") {
        const canvas = canvasRef.current as HTMLCanvasElement;
        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        let img = new Image();
        img.onload = start;
        img.src = data.canvas;

        function start() {
          ctx.drawImage(img, 0, 0);
        }
      }
    };
  }, []);

  return (
    <div className="whiteboard-container w-full h-full flex flex-row gap-4 justify-center items-center">
      {userAction == "createRoom" && (
        <div className="flex flex-col justify-center items-center gap-8">
          <button
            className={`w-24 h-8 mr-2 rounded bg-black text-white p-2 ${
              drawSize === 3 ? "border border-2 white" : "border border-0 white"
            }`}
            style={{ borderColor: drawSize === 3 ? "white" : "" }}
            onClick={() => setDrawSize(3)}
          >
            Small
          </button>
          <button
            className={`w-24 h-8 mr-2 rounded bg-black text-white p-2 ${
              drawSize === 6 ? "border border-2 black" : "border border-0 black"
            }`}
            style={{ borderColor: drawSize === 6 ? "black" : "" }}
            onClick={() => setDrawSize(6)}
          >
            Medium
          </button>
          <button
            className={`w-24 h-8 mr-2 rounded bg-black text-white p-2 ${
              drawSize === 10
                ? "border border-2 white"
                : "border border-0 white"
            }`}
            style={{ borderColor: drawSize === 10 ? "white" : "" }}
            onClick={() => setDrawSize(10)}
          >
            Large
          </button>
          <button
            className="w-24 h-8 mr-2 rounded bg-black text-white p-2"
            onClick={() => {
              clearBoard();
            }}
          >
            Clear Board
          </button>
        </div>
      )}
      <canvas
        id="whiteboard"
        className="w-full h-full "
        style={{
          minWidth: "900px",
          minHeight: "600px",
          maxWidth: "900px",
          maxHeight: "600px",
        }}
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseOut={handleMouseUp} 
      ></canvas>
      {userAction == "createRoom" && (
        <div className="colors-cont  flex flex-col justify-center items-center mt-16">
          <div
            className={`black ${
              color == "black" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("black")}
          ></div>
          <div
            className={`red ${
              color == "#e74c3c" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#e74c3c")}
          ></div>
          <div
            className={`yellow ${
              color == "#f1c40f" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#f1c40f")}
          ></div>
          <div
            className={`green ${
              color == "#badc58" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#badc58")}
          ></div>
          <div
            className={`blue ${
              color == "#3498db" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#3498db")}
          ></div>
          <div
            className={`orange ${
              color == "#e67e22" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#e67e22")}
          ></div>
          <div
            className={`purple ${
              color == "#9b59b6" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#9b59b6")}
          ></div>
          <div
            className={`pink ${
              color == "#fd79a8" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#fd79a8")}
          ></div>
          <div
            className={`brown ${
              color == "#834c32" ? "border border-4 white" : ""
            }`}
            onClick={() => setColor("#834c32")}
          ></div>
          <div
            className={`grey ${color == "gray" ? "border border-4 white" : ""}`}
            onClick={() => setColor("gray")}
          ></div>
          <div
            className={`mt-4 ${
              color == "white" ? "text-black" : "text-gray-500"
            }`}
            onClick={() => setColor("white")}
          >
            <Eraser size={24} />
          </div>
        </div>
      )}
    </div>
  );
};

export default WhiteBoard;
