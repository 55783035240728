import React, { useState, Fragment, useEffect } from "react";
import Pagination from "../components/Pagination";
import Teachersidebar from "../components/Teachersidebar";
import TeacherTopnav from "../components/TeacherTopNav";
import Teacherfooter from "../components/Teacherfooter";
import { getAllEnrolledUsers } from "../services/teacherServices/EnrollmentServices";
import { formatDate } from "../utils/formatDate";
import { TeacherProtection } from "../CustomHooks/TeacherProtection";


function Teacherenrollments() {
  TeacherProtection();

  const [enrolledCourses, setEnrolledCourses] = useState([]);

  const [token] = useState(JSON.parse(localStorage.getItem("token")));

  const fetchEnrolledUsers = async () =>{
    try {
      const response = await getAllEnrolledUsers(token);
      // console.log(response.data.enrolledCourses);
      setEnrolledCourses(response.data.enrolledCourses);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    fetchEnrolledUsers();
  },[])
  
  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <TeacherTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex p-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Enrollments List
                      </h4>
                      {/* <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select> */}
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0">ID</th>
                              <th className="border-0" scope="col">
                                Name
                              </th>

                              <th className="border-0" scope="col">
                                Course Title
                              </th>
                              <th className="border-0" scope="col">
                                category
                              </th>
                              <th className="border-0" scope="col">
                                Enrollment Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {enrolledCourses.map((value, index) => (
                              <tr key={index+1}>
                                <td>{index+1}</td>
                                <td>
                                  <b>{value.student.name}</b>
                                </td>
                                <td>{value.course.title}</td>
                                <td>
                                  <span
                                    className={`badge rounded-pill font-xsssss fw-700 pr-3 pl-3 lh-24 text-uppercase rounded-3 ls-2 alert-${value.color}`}
                                  >
                                    {value.course.domain_of_study}
                                  </span>
                                </td>
                                <td>{formatDate(value.date)}</td>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <Teacherfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Teacherenrollments;
