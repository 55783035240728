import httpClient from "../http-common";

export const searchCourses = (query, category) => {
  const result = httpClient.get(`/course/search-courses/${query}/${category}`);
  console.log(result);
  return result;
};

export const getCategories = () => {
  return httpClient.get(`/course/all-courses/categories`);
};

// Create a new course
export const uploadCourse = (token, formData) => {
  return httpClient.post(`/course/upload-course`, formData, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "multipart/form-data",
    },
  });
};

// Edit a course
export const editCourse = (token, formData) => {
  return httpClient.put(`/course/edit-course`, formData, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "multipart/form-data",
    },
  });
};

//  Create a module
export const addNewModule = (token, formData, courseID) => {
  return httpClient.post(`/module/add-a-module/${courseID}`, formData, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addNewPart = (token, formData, moduleID) => {
  return httpClient.put(`/module/insert-new-part/${moduleID}`, formData, {
    headers: {
      "x-auth-token": token,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const publishCourse = (token, courseID) => {
  return httpClient.put(
    `/course/publish-course/${courseID}`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getCompleteCourse = (token, courseID) => {
  return httpClient.get(`/course/draft-course/${courseID}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getAllCourses = () => {
  return httpClient.get(`/course/get-all-courses`, {});
};
