import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import AdminTopnav from "../components/AdminTopnav.js";
import Adminfooter from "../components/Adminfooter.js";
import Teachersidebar from "../components/Teachersidebar.js";
import {
  FaEdit,
  FaTrashAlt,
  FaAngleDown,
  FaTimes,
  FaVideo,
  FaPlay,
  FaFile,
} from "react-icons/fa"; // Import icons from react-icons
import RecordedCourseIcon from "./1home.png";
import LiveCourseIcon from "./1home.png";
import PresaleCourseIcon from "./1home.png";
import { TeacherProtection } from "../CustomHooks/TeacherProtection.jsx";
function Teachereditcourse() {
  TeacherProtection();
  const { selectedCourse } = useParams();
  const [currentSection, setCurrentSection] = useState(1);
  const [sections, setSections] = useState([
    { id: 1, title: "Lecture 1: Introduction" },
  ]);
  const [editingSectionId, setEditingSectionId] = useState(null);
  const [editedSectionText, setEditedSectionText] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedDropdownId, setSelectedDropdownId] = useState(null);
  // New states for description editing
  const [editingDescriptionId, setEditingDescriptionId] = useState(null);
  const [descriptionText, setDescriptionText] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [originalResult, setOriginalResult] = useState(0);
  const [discountedResult, setDiscountedResult] = useState(0);
  // const [error, setError] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [faqList, setFaqList] = useState([]);
  const [newFaq, setNewFaq] = useState({ question: "", answer: "" });

  const handleOriginalPriceChange = (e) => {
    let value = e.target.value;

    // Remove leading zeroes
    value = value.replace(/^0+/, "");

    // Ensure the value is a valid number and not negative
    if (!isNaN(value) && value >= 0) {
      setOriginalPrice(value);
      setOriginalResult(value * 282);

      // If discounted price is valid, update its result
      if (value > discountedPrice) {
        setDiscountedResult(discountedPrice * 282);
      } else {
        setDiscountedResult(0);
      }
    }
  };

  const handleDiscountedPriceChange = (e) => {
    let value = e.target.value;

    // Remove leading zeroes
    value = value.replace(/^0+/, "");

    // Ensure the value is a valid number, not negative, and less than the original price
    if (!isNaN(value) && value >= 0 && value < originalPrice) {
      setDiscountedPrice(value);
      setDiscountedResult(value * 282);
    }
  };
  const handleThumbnailUpload = (e) => {
    // Handle the thumbnail upload logic here and set it to the 'thumbnail' state
    const uploadedThumbnail = e.target.files[0];
    setThumbnail(uploadedThumbnail);
  };

  const handleAddFAQ = () => {
    // Add a new FAQ to the list
    setFaqList([...faqList, newFaq]);
    // Clear the new FAQ fields
    setNewFaq({ question: "", answer: "" });
  };

  const handleNewFaqChange = (field, e) => {
    // Update the new FAQ fields
    setNewFaq({ ...newFaq, [field]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addSection = () => {
    const nextSectionId =
      sections.length === 0 ? 1 : sections[sections.length - 1].id + 1;
    setSections([
      ...sections,
      {
        id: nextSectionId,
        title: `Lecture ${nextSectionId}: New Section`,
        isCurriculumButtonPressed: false,
      },
    ]);
  };

  const deleteSection = (id) => {
    setSections(sections.filter((section) => section.id !== id));
    closeDropdown();
  };

  const startEditing = (id, title) => {
    setEditingSectionId(id);
    setEditedSectionText(title.replace(`Lecture ${id}: `, ""));
  };

  const saveEdit = () => {
    const editedSectionIndex = sections.findIndex(
      (section) => section.id === editingSectionId
    );
    if (editedSectionIndex !== -1) {
      const updatedSections = [...sections];
      updatedSections[
        editedSectionIndex
      ].title = `Lecture ${editingSectionId}: ${editedSectionText}`;
      setSections(updatedSections);
    }
    setEditingSectionId(null);
    setEditedSectionText("");
  };

  const cancelEdit = () => {
    setEditingSectionId(null);
    setEditedSectionText("");
  };

  const toggleDropdown = (id) => {
    if (selectedDropdownId === id) {
      closeDropdown();
    } else {
      setIsDropdownOpen(true);
      setSelectedDropdownId(id);
    }
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setSelectedDropdownId(null);
  };

  const handleCurriculumButtonClick = (id) => {
    const updatedSections = sections.map((section) => {
      if (section.id === id) {
        return {
          ...section,
          isCurriculumButtonPressed: !section.isCurriculumButtonPressed,
        };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const handleContentButtonClick = (id) => {
    setSections(
      sections.map((section) => {
        if (section.id === id) {
          return {
            ...section,
            isContentButtonPressed: !section.isContentButtonPressed,
          };
        }
        return section;
      })
    );
  };
  // Functions for description editing
  const startEditingDescription = (id, description) => {
    setEditingDescriptionId(id);
    setDescriptionText(description);
  };

  const saveDescription = () => {
    const editedSectionIndex = sections.findIndex(
      (section) => section.id === editingDescriptionId
    );
    if (editedSectionIndex !== -1) {
      const updatedSections = [...sections];
      updatedSections[editedSectionIndex].description = descriptionText;
      setSections(updatedSections);
    }
    setEditingDescriptionId(null);
  };

  const cancelEditingDescription = () => {
    setEditingDescriptionId(null);
  };
  const styles3 = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      marginBottom: "30px",
    },
    form: {
      backgroundColor: "#f4f4f4",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "100%",
    },
    backButton: {
      textAlign: "right",
    },
    uploadLabel: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
    faqLabel: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
    faqItem: {
      backgroundColor: "#ffffff",
      border: "1px solid #ddd",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "4px",
    },
    faqQuestion: {
      fontWeight: "bold",
    },
    faqInput: {
      padding: "8px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      marginRight: "10px",
    },
    addFaqButton: {
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "8px 16px",
      cursor: "pointer",
    },
    addFaqButtonHover: {
      backgroundColor: "#45a049",
    },
    submitButton: {
      marginTop: "20px",
      textAlign: "center",
    },
    submitButtonButton: {
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "10px 20px",
      cursor: "pointer",
    },
    submitButtonButtonHover: {
      backgroundColor: "#45a049",
    },
  };
  const styles2 = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      marginBottom: "30px",
    },
    column: {
      margin: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    label: {
      marginBottom: "5px",
      fontWeight: "bold",
      color: "#333",
      fontSize: "0.8rem",
    },
    input: {
      marginBottom: "10px",
      padding: "8px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      width: "150px",
    },
    result: {
      color: "#4a90e2",
      fontWeight: "bold",
    },
  };

  const styles = {
    courseContainer: {
      width: "100%",
    },
    sectionContainer: {
      border: "2px solid #ccc",
      margin: "15px 0",
      backgroundColor: "#f9f9f9",
    },

    sectionContainer1: {
      margin: "15px 10px",
      backgroundColor: "#f9f9f9",
    },

    sectionBlock: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px",
    },

    editContainer: {
      display: "flex",
      alignItems: "center",
    },
    staticText: {
      marginRight: "10px",
    },
    editInput: {
      flex: 1,
      marginRight: "10px",
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    editBtn: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    cancelBtn: {
      backgroundColor: "#ff0000",
      color: "white",
      padding: "5px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    dropdownIcon: {
      fontSize: "20px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    addSectionBtn: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "10px 15px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    icon: {
      marginLeft: "10px",
      cursor: "pointer",
    },
    dropdownButtons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "10px",
      marginLeft: "auto",
    },
    dropdownContent: {
      padding: "10px 15px",
      backgroundColor: "#f9f9f9",
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center align the buttons
      justifyContent: "center",
    },
    dropdownContent1: {
      padding: "10px 15px",
      backgroundColor: "#f9f9f9",
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center align the buttons
      justifyContent: "center",
    },
    button: {
      marginBottom: "0px", // Adds space between buttons
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px", // Adjust padding for smaller size
      border: "1px solid #ddd", // Elegant border
      borderRadius: "5px", // Rounded corners
      cursor: "pointer",
      width: "100%", // Adjust width as per requirement
      textAlign: "center", // Ensure text is centered
    },
    button1: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px", // Adjust padding for smaller size
      border: "1px solid #ddd", // Elegant border
      borderRadius: "5px", // Rounded corners
      cursor: "pointer",
      width: "32%", // Adjust width as per requirement
      textAlign: "center", // Ensure text is centered
    },
    descriptionEditContainer: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
    },
    descriptionTextarea: {
      width: "100%",
      height: "100px",
      marginBottom: "10px",
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      resize: "none",
      fontFamily: "inherit", // Use the same font as the parent container
    },
    descriptionButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    descriptionButton: {
      marginRight: "5px",
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  const contentButtonStyle = {
    ...styles.button,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    margin: "0 10px",
  };

  const buttonWidth = "700px";
  // const IntroductionItem = ({ title, onAdd }) => (
  //   <div className="introduction-item">
  //     <span className="lecture-title">{title}</span>
  //     <button className="add-button" onClick={onAdd}>
  //       + Add
  //     </button>
  //   </div>
  // );

  const courseButtonStyle = {
    display: "flex",
    alignItems: "flex-start",
    padding: "15px",
    margin: "10px 0",
    border: "1px solid #ccc",
    borderRadius: "13px",
    width: buttonWidth,
    cursor: "pointer",
  };

  const selectedBadgeStyle = {
    display: "block",
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "green",
    color: "white",
    borderTopRightRadius: "13px",
    borderBottomLeftRadius: "13px",
    padding: "5px 10px",
    fontSize: "0.8rem",
  };

  const cardContentStyle = {
    flexGrow: 1,
    textAlign: "left",
  };

  const courseIcons = {
    recorded: RecordedCourseIcon,
    live: LiveCourseIcon,
    presale: PresaleCourseIcon,
  };

  const imageStyle = {
    marginRight: "20px",
  };

  const handleNextClick = () => {
    if (currentSection < 4) {
      setCurrentSection(currentSection + 1);
    }
  };

  const handleBackClick = () => {
    if (currentSection > 1) {
      setCurrentSection(currentSection - 1);
    }
  };
  const [currentSubsection, setCurrentSubsection] = useState(1); // Define currentSubsection state

  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />
            <div
              className="card-body d-block p-5 pb-4 text-center"
              style={{ marginTop: "-30px" }}
            >
              <div
                className="timeline-steps aos-init aos-animate"
                data-aos="fade-up"
              >
                <div
                  className={`timeline-step ${
                    currentSection === 1 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Basic Details
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 2 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Lesson & Videos
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 3 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      Pricing & Finalizing
                    </p>
                  </div>
                </div>
                <div
                  className={`timeline-step ${
                    currentSection === 4 ? "active" : ""
                  }`}
                >
                  <div className="timeline-content">
                    <div className="inner-circle"></div>
                    <p className="font-xssss fw-600 text-grey-900 mb-0 mb-lg-0">
                      One Last Thing
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid px-4 py-4">
              <div
                className="d-flex flex-column align-items-center"
                style={{ marginTop: "-30px" }}
              >
                {currentSection === 1 && (
                  <div>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginBottom: "5px",
                      }}
                    >
                      Add basic details to create your course
                    </p>
                    {selectedCourse === "recorded" && (
                      <div
                        className="card position-relative"
                        style={courseButtonStyle}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={courseIcons.recorded}
                            alt="Recorded Course"
                            width="48"
                            height="48"
                            style={imageStyle}
                          />
                          <div style={cardContentStyle}>
                            <h5
                              className="card-title text-success"
                              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              Recorded Course
                            </h5>
                            <p
                              className="card-text"
                              style={{ fontSize: "0.9rem", marginTop: "5px" }}
                            >
                              Create, launch, and sell your recorded course.
                            </p>
                          </div>
                        </div>
                        <div style={selectedBadgeStyle}>Selected</div>
                      </div>
                    )}
                    {selectedCourse === "live" && (
                      <div
                        className="card position-relative"
                        style={courseButtonStyle}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={courseIcons.live}
                            alt="Live Course"
                            width="48"
                            height="48"
                            style={imageStyle}
                          />
                          <div style={cardContentStyle}>
                            <h5
                              className="card-title text-success"
                              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              Live Course
                            </h5>
                            <p
                              className="card-text"
                              style={{ fontSize: "0.9rem", marginTop: "5px" }}
                            >
                              Create, launch, and sell your cohort-based live
                              course.
                            </p>
                          </div>
                        </div>
                        <div style={selectedBadgeStyle}>Selected</div>
                      </div>
                    )}
                    {selectedCourse === "presale" && (
                      <div
                        className="card position-relative"
                        style={courseButtonStyle}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={courseIcons.presale}
                            alt="Presale Course"
                            width="48"
                            height="48"
                            style={imageStyle}
                          />
                          <div style={cardContentStyle}>
                            <h5
                              className="card-title text-success"
                              style={{ fontSize: "1.2rem", fontWeight: "bold" }}
                            >
                              Presale
                            </h5>
                            <p
                              className="card-text"
                              style={{ fontSize: "0.9rem", marginTop: "5px" }}
                            >
                              With a presale page, drive sales to your course
                              before its launch.
                            </p>
                          </div>
                        </div>
                        <div style={selectedBadgeStyle}>Selected</div>
                      </div>
                    )}
                    <div>
                      <form
                        action="#"
                        style={{
                          width: "100%",
                          maxWidth: "700px",
                          marginTop: "30px",
                        }}
                      >
                        <div className="col-lg-30 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Course Title
                            </label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-30 mb-3">
                          <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">
                              Course Category
                            </label>
                            <select className="form-control ">
                              <option value="category1">Category 1</option>
                              <option value="category2">Category 2</option>
                              <option value="category3">Category 3</option>
                              {/* Add more options as needed */}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-30 mb-3">
                          <label className="mont-font fw-600 font-xsss">
                            Briefly explain your course & why is it important?
                          </label>
                          <textarea
                            className="form-control mb-0 p-3 bg-white lh-16"
                            rows="5"
                            placeholder="Write your message..."
                          ></textarea>
                        </div>

                        <div className="col-lg-30 mb-3">
                          <label className="mont-font fw-600 font-xsss">
                            About Course
                          </label>
                          <textarea
                            className="form-control mb-0 p-3 bg-white lh-16"
                            rows="5"
                            placeholder="Write your message..."
                          ></textarea>
                        </div>
                        <div className="form-group text-center mt-4"></div>
                      </form>
                    </div>
                  </div>
                )}

                {/* Content for other sections */}
                {currentSection === 2 && (
                  <div>
                    <div>
                      <div className="d-flex justify-content-between">
                        {/* Button to show Subsection 1 */}
                        <button
                          onClick={() => setCurrentSubsection(1)}
                          className="btn btn-primary"
                        >
                          Curriculum
                        </button>

                        {/* Button to show Subsection 2 */}
                      </div>

                      {/* Content for Subsection 1 */}
                      {currentSubsection === 1 && (
                        <div style={styles.courseContainer}>
                          {sections.map((section) => (
                            <div
                              key={section.id}
                              style={styles.sectionContainer}
                            >
                              <div style={styles.sectionBlock}>
                                <div style={styles.editContainer}>
                                  {editingSectionId === section.id ? (
                                    <>
                                      <span
                                        style={styles.staticText}
                                      >{`Lecture ${section.id}:`}</span>
                                      <input
                                        type="text"
                                        value={editedSectionText}
                                        onChange={(e) =>
                                          setEditedSectionText(e.target.value)
                                        }
                                        style={styles.editInput}
                                      />
                                      <button
                                        style={styles.editBtn}
                                        onClick={saveEdit}
                                      >
                                        Save
                                      </button>
                                      <button
                                        style={styles.cancelBtn}
                                        onClick={cancelEdit}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <h2>{section.title}</h2>
                                  )}
                                </div>
                                <div>
                                  <FaEdit
                                    style={styles.icon}
                                    onClick={() =>
                                      startEditing(section.id, section.title)
                                    }
                                  />
                                  <FaTrashAlt
                                    style={styles.icon}
                                    onClick={() => deleteSection(section.id)}
                                  />
                                  <FaAngleDown
                                    style={styles.dropdownIcon}
                                    onClick={() => toggleDropdown(section.id)}
                                  />
                                </div>
                              </div>
                              {isDropdownOpen &&
                                selectedDropdownId === section.id && (
                                  <div>
                                    {editingDescriptionId === section.id ? (
                                      <div
                                        style={styles.descriptionEditContainer}
                                      >
                                        <textarea
                                          value={descriptionText}
                                          onChange={(e) =>
                                            setDescriptionText(e.target.value)
                                          }
                                          style={styles.descriptionTextarea}
                                          placeholder="Write your message..."
                                        />
                                        <div
                                          style={
                                            styles.descriptionButtonContainer
                                          }
                                        >
                                          <button
                                            style={styles.descriptionButton}
                                            onClick={saveDescription}
                                          >
                                            Save
                                          </button>
                                          <button
                                            style={styles.descriptionButton}
                                            onClick={cancelEditingDescription}
                                          >
                                            Close
                                          </button>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div style={styles.dropdownContent}>
                                          <button
                                            style={styles.button}
                                            onClick={() =>
                                              startEditingDescription(
                                                section.id,
                                                section.description
                                              )
                                            }
                                          >
                                            + Description
                                          </button>
                                          <button style={styles.button}>
                                            + Resources
                                          </button>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}
                              <div style={styles.sectionContainer1}>
                                {section.isCurriculumButtonPressed ? (
                                  <div style={{ position: "relative" }}>
                                    <FaTimes
                                      style={{
                                        position: "absolute",
                                        top: "-15px",
                                        right: "1px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleCurriculumButtonClick(section.id)
                                      }
                                    />
                                    <div
                                      style={{
                                        ...styles.dropdownContent1,
                                        border:
                                          section.isCurriculumButtonPressed
                                            ? "2px solid #ccc"
                                            : "none",
                                      }}
                                      onClick={() =>
                                        handleCurriculumButtonClick(section.id)
                                      }
                                    >
                                      <button style={styles.button}>
                                        Lecture
                                      </button>
                                      <button style={styles.button}>
                                        Quizz
                                      </button>
                                      <button style={styles.button}>
                                        Exercise
                                      </button>
                                      <button style={styles.button}>
                                        Assignment
                                      </button>
                                      <button style={styles.button}>
                                        Practice Test
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div>
                                    <button
                                      style={styles.button1}
                                      onClick={() =>
                                        handleCurriculumButtonClick(section.id)
                                      }
                                    >
                                      + Curriculum Items
                                    </button>
                                  </div>
                                )}
                              </div>
                              {/* Content + Button and Dropdown */}
                              <div style={styles.sectionContainer1}>
                                {section.isContentButtonPressed ? (
                                  <div style={{ position: "relative" }}>
                                    <FaTimes
                                      style={{
                                        position: "absolute",
                                        top: "-15px",
                                        right: "1px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleContentButtonClick(section.id)
                                      }
                                    />
                                    <div
                                      style={{
                                        ...styles.dropdownContent1,
                                        border: section.isContentButtonPressed
                                          ? "2px solid #ccc"
                                          : "none",
                                      }}
                                    >
                                      <button style={contentButtonStyle}>
                                        <FaVideo
                                          style={{ marginRight: "10px" }}
                                        />{" "}
                                        Video
                                      </button>
                                      <button style={contentButtonStyle}>
                                        <FaPlay
                                          style={{ marginRight: "10px" }}
                                        />{" "}
                                        Article
                                      </button>
                                      <button style={contentButtonStyle}>
                                        <FaFile
                                          style={{ marginRight: "10px" }}
                                        />{" "}
                                        Other
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <button
                                    style={styles.button1}
                                    onClick={() =>
                                      handleContentButtonClick(section.id)
                                    }
                                  >
                                    + Content
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                          <button
                            style={styles.addSectionBtn}
                            onClick={addSection}
                          >
                            Add Section
                          </button>
                        </div>
                      )}

                      {/* Content for Subsection 2 */}
                    </div>
                    <div>
                      <button
                        onClick={handleBackClick}
                        style={{ width: buttonWidth }}
                        className="btn btn-secondary"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                )}

                {currentSection === 3 && (
                  <div>
                    <div style={styles2.container}>
                      <div style={styles2.column}>
                        <label style={styles2.label}>
                          Original Price (USD)
                        </label>
                        <input
                          style={styles2.input}
                          type="number"
                          value={originalPrice}
                          onChange={handleOriginalPriceChange}
                        />
                        <div style={styles2.result}>
                          {originalResult.toFixed(2)}
                        </div>
                      </div>

                      <div style={styles2.column}>
                        <label style={styles2.label}>
                          Discounted Price (USD)
                        </label>
                        <input
                          style={styles2.input}
                          type="number"
                          value={discountedPrice}
                          onChange={handleDiscountedPriceChange}
                        />
                        <div style={styles2.result}>
                          {discountedResult.toFixed(2)}
                        </div>
                      </div>

                      <div style={styles2.column}>
                        <label style={styles2.label}>Result</label>
                        <input
                          style={styles2.input}
                          type="text"
                          value={(originalResult - discountedResult).toFixed(2)}
                          readOnly
                        />
                      </div>
                    </div>
                    <button
                      onClick={handleBackClick}
                      style={{ width: buttonWidth }}
                      className="btn btn-secondary"
                    >
                      Back
                    </button>
                  </div>
                )}
                {currentSection === 4 && (
                  <div>
                    <p
                      style={{
                        fontSize: "0.9rem",
                        marginBottom: "5px",
                      }}
                    >
                      Add basic details to create your course
                    </p>

                    <div style={styles2.container}>
                      <div style={styles3.courseCreationContent}>
                        <div style={styles3.thumbnailUpload}>
                          <label style={styles3.uploadLabel}>
                            Upload Thumbnail Image:
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleThumbnailUpload}
                          />
                        </div>
                        <div style={styles3.faqSection}>
                          <label style={styles3.faqLabel}>FAQs:</label>
                          {faqList.map((faq, index) => (
                            <div style={styles3.faqItem} key={index}>
                              <div style={styles3.faqQuestion}>
                                Question: {faq.question}
                              </div>
                              <div>Answer: {faq.answer}</div>
                            </div>
                          ))}
                          <div style={styles3.newFaq}>
                            <input
                              type="text"
                              placeholder="Question"
                              value={newFaq.question}
                              onChange={(e) =>
                                handleNewFaqChange("question", e)
                              }
                              style={styles3.faqInput}
                            />
                            <input
                              type="text"
                              placeholder="Answer"
                              value={newFaq.answer}
                              onChange={(e) => handleNewFaqChange("answer", e)}
                              style={styles3.faqInput}
                            />
                            <button
                              onClick={handleAddFAQ}
                              style={styles3.addFaqButton}
                            >
                              Save FAQ
                            </button>
                          </div>
                        </div>
                        <div style={styles3.submitButton}>
                          <button style={styles3.submitButtonButton}>
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentSection < 4 && (
                  <button
                    onClick={handleNextClick}
                    className="btn btn-success" // Add the same class as the Link
                    style={{ width: buttonWidth }}
                  >
                    Next
                  </button>
                )}
                {currentSection === 4 && (
                  <button style={{ width: buttonWidth }}>Publish</button>
                )}
              </div>
            </div>
            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Teachereditcourse;
