import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri"; // Import eye icons

import {
  authorizeUser,
  registerUser,
} from "../services/userServices/UserServices";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setUser } from "../Redux/userSlice";

const Register = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    visible: false,
    message: "",
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      setPasswordMatchError("Passwords do not match");
    } else {
      setPasswordMatchError(""); // Reset password match error
      // console.log(data);
      setLoading(true);
      try {
        await registerUser(data, "student")
          .then((res) => {
            setLoading(true);
            // console.log(res.data.token)
            // localStorage.setItem("token", (res.data.token));
            localStorage.setItem("token", JSON.stringify(res.data.token));
            // console.log(res.data.msg)
            isAuthorized(res.data.token);
          })
          .catch((err) => {
            // console.log(err.response.data.errors)
            // console.log(err.response.data.errors[0].msg);
            setError({
              visible: true,
              message: err.response.data.errors[0].msg,
            });
            setLoading(false);
          });
      } catch (error) {
        setError({
          visible: true,
          message: "Server Error",
        });
        setLoading(false);
      }
    }
  };

  // here create an async function that is given the token from previous response and returns if the user is authorized or not
  const isAuthorized = async (token) => {
    await authorizeUser(token, "user")
      .then((res) => {
        // console.log(res.data)
        localStorage.setItem("user", JSON.stringify(res.data));
        dispatch(setUser(res.data));
        setTimeout(() => {
          navigate("/login");
        }, 2500);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError({
          visible: true,
          message: err.response.data.errors[0].msg,
        });
      });
  };

  return (
    <>
      <div className="main-wrap">
        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{ backgroundImage: `url("/assets/images/reg.png")` }}
          ></div>
          <Link
            to="/"
            className="d-inline-block mt-2 "
            style={{ position: "absolute", top: "100px", left: "100px" }}
          >
            <i className="ti-arrow-left font-sm text-black"></i>
          </Link>
          <div
            className="col-xl-7 vh-100 align-items-center d-flex rounded-lg overflow-hidden"
            style={{ backgroundColor: "#e8f1fa" }}
          >
            <div className="card shadow-none border-0 ml-auto mr-auto login-card">
              <div
                className="card-body rounded-0 text-left"
                style={{ backgroundColor: "#e8f1fa" }}
              >
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  Create <br />
                  your account
                </h2>
                {error.visible && (
                  <Alert variant="danger">{error.message}</Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-user text-grey-500 pr-0"></i>
                    <input
                      type="text"
                      {...register("name", { required: "Name is required" })}
                      className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                        errors.name ? "border-danger" : ""
                      }`}
                      placeholder="Your Name"
                    />
                    <span className="text-danger">{errors.name?.message}</span>
                  </div>

                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-email text-grey-500 pr-0"></i>
                    <input
                      type="text"
                      {...register("email", {
                        required: "Please include a valid email address",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please include a valid email address",
                        },
                      })}
                      className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                        errors.email ? "border-danger" : ""
                      }`}
                      placeholder="Your Email Address"
                    />
                    <span className="text-danger">{errors.email?.message}</span>
                  </div>

                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      {...register("password", {
                        required: "Please enter a password",
                      })}
                      className={`style2-input pl-5 form-control text-grey-900 font-xss ls-3 ${
                        errors.password ? "border-danger" : ""
                      }`}
                      placeholder="Password"
                    />
                    <span
                      className="password-toggle-icon"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    >
                      {passwordVisible ? <RiEyeOffFill /> : <RiEyeFill />}
                    </span>
                    <span className="text-danger">
                      {errors.password?.message}
                    </span>
                  </div>

                  <div className="form-group icon-input mb-1">
                    <i className="font-sm ti-lock text-grey-500 pr-0"></i>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      {...register("confirmPassword", {
                        required: "Please confirm your password",
                        validate: (value) =>
                          value === watch("password") ||
                          "Passwords do not match",
                      })}
                      className={`style2-input pl-5 form-control text-grey-900 font-xss ls-3 ${
                        errors.confirmPassword ? "border-danger" : ""
                      }`}
                      placeholder="Confirm Password"
                    />
                    <span className="text-danger">
                      {errors.confirmPassword?.message}
                    </span>
                    {passwordMatchError && (
                      <span className="text-danger">{passwordMatchError}</span>
                    )}
                  </div>

                  <div className="form-check text-left mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input mt-2"
                      id="terms"
                    />
                    <label
                      className="form-check-label font-xssss text-grey-500"
                      htmlFor="terms"
                    >
                      By registering you agree to the Terms and Conditions
                    </label>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-question text-grey-500 pr-0"></i>
                    <input
                      type="text"
                      {...register("securityQuestion", {
                        required: "Security Question is required",
                      })}
                      className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                        errors.name ? "border-danger" : ""
                      }`}
                      placeholder="Security Question"
                    />
                    <span className="text-danger">{errors.name?.message}</span>
                  </div>
                  <div className="form-group icon-input mb-3">
                    <i className="font-sm ti-question text-grey-500 pr-0"></i>
                    <input
                      type="text"
                      {...register("securityAnswer", {
                        required: "Security Answer is required",
                      })}
                      className={`style2-input pl-5 form-control text-grey-900 font-xsss fw-600 ${
                        errors.name ? "border-danger" : ""
                      }`}
                      placeholder="Security Answer"
                    />
                    <span className="text-danger">{errors.name?.message}</span>
                  </div>

                  <Button
                    disabled={loading}
                    type="submit"
                    className="form-control text-center style2-input text-white fw-600 bg-dark border-0 p-0"
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      "Register"
                    )}
                  </Button>

                  <h6 className="text-grey-500 font-xssss fw-500 mt-3 lh-32">
                    Already have an account?{" "}
                    <Link to="/login" className="fw-700 ml-1">
                      Login
                    </Link>
                  </h6>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
