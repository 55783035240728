import React, { useState, Fragment, useEffect } from "react";
// import { Modal, Button } from "react-bootstrap";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import {
  getApplicantByID,
  disApproveApplicant,
  approveApplicant,
} from "../services/adminServices/AdminServices";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress } from "@mui/material";

function Admincustomerview() {
  const param = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Admin | Applicants for Instructor ";

    getApplicantMethod();
  }, []);

  const [applicant, setApplicant] = useState({});
  const [location, setLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    visible: false,
    message: "",
  });
  const navigate = useNavigate();
  const handleModel = () => {
    setLocation(!location);
  };

  const getApplicantMethod = async () => {
    try {
      setLoading(true);

      const token = JSON.parse(localStorage.getItem("token"));
      const res = await getApplicantByID(token, param.id);
      // console.log(res.data)
      setApplicant(res.data);
      setLoading(false);
      // console.log(res.data)
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      await disApproveApplicant(token, param.id)
        .then((res) => {
          console.log(res.data);
          navigate("/admin-rejected-applicants");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async (data) => {
    try {
      const token = JSON.parse(localStorage.getItem("token"));
      await approveApplicant(token, param.id, data)
        .then((res) => {
          setLoading(true);
          console.log(res.data);
          navigate("/admin-approved-applicants");
          setError({
            visible: false,
            message: "",
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError({
            visible: true,
            message: err.response.data.errors[0].msg,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                  <div className="card w-100 shadow-xss rounded-lg overflow-hidden border-0 mb-3 mt-0 pb-3">
                    <div className="card-body d-block pt-4 text-center">
                      <figure className="avatar position-relative w110 z-index-1 w100 z-index-1 rounded-lg overflow-hidden ml-auto mr-auto">
                        {/* <img
                          src="assets/images/user.png"
                          alt="avater"
                          className="p-1 bg-white rounded-xl w-100 overflow-hidden"
                        /> */}
                        <AccountCircleIcon />
                      </figure>
                      <h4 className="font-sm ls-1 fw-700 text-grey-900">
                        {applicant.name}
                        <span className="d-block font-sm fw-500 mt-1 lh-3 text-grey-500">
                          {applicant.email_contact}
                        </span>
                      </h4>
                    </div>
                    <div className="card-body d-flex align-items-center ps-6 pe-6 pt-0">
                      <h4
                        className="font-xs text-center text-grey-500 fw-600 ml-2 mr-2"
                        style={{ fontSize: "20px" }}
                      >
                        Experience:{" "}
                        <b className="text-grey-900 mb-1 font-lg fw-700 d-inline-block ls-3 text-dark">
                          {" "}
                          {applicant.years_of_experience}
                        </b>
                        {/* Years of Experience */}
                      </h4>
                    </div>

                    <div className="card-body d-flex flex-column align-items-center justify-content-center p-4 m-4">
                      {applicant.status !== "approved" && (
                        <>
                          <button
                            type="button"
                            className="btn btn-success btn-md mb-3 text-uppercase fw-bold ls-1"
                            onClick={() => {
                              handleModel();
                            }}
                            // onClick={() => handleApprove()}
                          >
                            Approve Applicant
                          </button>
                          <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            show={location}
                          >
                            {/* <Button onClick={() => handleModel()} className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"></Button> */}
                            {error.visible && (
                              <Alert variant="danger">{error.message}</Alert>
                            )}
                            <Modal.Body className="text-center p-4">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="mb-4">
                                  <label
                                    htmlFor="text"
                                    className="form-label font-xs text-grey-800"
                                  >
                                    Instructor's Email
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    placeholder="Assign instructor an email"
                                    {...register("email", {
                                      required: "Please enter an Email",
                                    })}
                                  />
                                  <span className="text-danger">
                                    {errors.email?.message}
                                  </span>
                                </div>
                                <div className="mb-4">
                                  <label
                                    htmlFor="password"
                                    className="form-label font-xs text-grey-800"
                                  >
                                    Your Password
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    placeholder="Assign  password"
                                    {...register("password", {
                                      required: "Please enter a password",
                                    })}
                                  />
                                  <span className="text-danger">
                                    {errors.password?.message}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <button
                                    disabled={loading}
                                    type="submit"
                                    className="btn btn-success btn-lg text-uppercase fw-bold ls-1"
                                  >
                                    {loading ? (
                                      <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                      />
                                    ) : (
                                      "Approve & Send Verification Email"
                                    )}
                                  </button>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary me-2"
                                      onClick={() => handleModel()}
                                    >
                                      Close
                                    </button>
                                    <i className="bi bi-check-circle text-success fs-3 align-middle"></i>{" "}
                                    {/* This icon represents verification or tick */}
                                  </div>
                                </div>
                              </form>
                            </Modal.Body>
                          </Modal>

                          <button
                            type="button"
                            className="btn btn-danger btn-md text-uppercase fw-bold ls-1"
                            onClick={() => handleReject()}
                          >
                            Reject Applicant
                          </button>
                        </>
                      )}
                      {applicant.status === "approved" && (
                        <button
                          type="button"
                          className="btn btn-danger btn-md text-uppercase fw-bold ls-1"
                          onClick={() => handleReject()}
                        >
                          Inactivate Instructor
                        </button>
                      )}
                    </div>

                    {/* <div className="card-body d-block p-4">
                      <h4 className="fw-700 mb-3 font-xsss text-grey-900">
                        About
                      </h4>
                      <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi nulla dolor, ornare at commodo non, feugiat non
                        nisi. Phasellus faucibus mollis pharetra. Proin blandit
                        ac massa sed rhoncus
                      </p>
                    </div> */}
                    <div className="card-body border-top-xs d-flex align-items-center">
                      <i className="feather-lock text-grey-500 mr-3 font-lg"></i>
                      <div>
                        <h4 className="fw-700 text-grey-900 font-sm mb-1">
                          Resume
                        </h4>
                        <a
                          href={applicant.resume}
                          rel="noreferrer"
                          target="_blank"
                          className="d-block font-xs fw-500 text-primary"
                        >
                          {applicant.resume}
                        </a>
                      </div>
                    </div>
                    {applicant.github && (
                      <div className="card-body border-top-xs d-flex align-items-center">
                        <i className="feather-lock text-grey-500 mr-3 font-lg"></i>
                        <div>
                          <h4 className="fw-700 text-grey-900 font-sm mb-1">
                            Github
                          </h4>
                          <a
                            href={applicant.github}
                            rel="noreferrer"
                            target="_blank"
                            className="d-block font-xs fw-500 text-primary"
                          >
                            {applicant.github}
                          </a>
                        </div>
                      </div>
                    )}
                    {applicant.linkedin && (
                      <div className="card-body border-top-xs d-flex align-items-center">
                        <i className="feather-lock text-grey-500 mr-3 font-lg"></i>
                        <div>
                          <h4 className="fw-700 text-grey-900 font-sm mb-1">
                            Linkedin
                          </h4>
                          <a
                            href={applicant.linkedin}
                            rel="noreferrer"
                            target="_blank"
                            className="d-block font-xs fw-500 text-primary"
                          >
                            {applicant.linkedin}
                          </a>
                        </div>
                      </div>
                    )}
                    {applicant.youtube && (
                      <div className="card-body border-top-xs d-flex align-items-center">
                        <i className="feather-lock text-grey-500 mr-3 font-lg"></i>
                        <div>
                          <h4 className="fw-700 text-grey-900 font-sm mb-1">
                            YouTube
                          </h4>
                          <a
                            href={applicant.youtube}
                            rel="noreferrer"
                            target="_blank"
                            className="d-block font-xs fw-500 text-primary"
                          >
                            {applicant.youtube}
                          </a>
                        </div>
                      </div>
                    )}
                    <div className="card-body d-flex align-items-center pt-0">
                      <i className="feather-map-pin text-grey-500 mr-3 font-lg"></i>
                      <div>
                        <h4 className="fw-700 text-grey-900 font-sm mt-1">
                          {`${applicant.address}, ${applicant.country}`}
                        </h4>
                      </div>
                    </div>
                    <div className="card-body d-flex align-items-center pt-0">
                      <i className="feather-eye text-grey-500 mr-3 font-lg"></i>
                      <div>
                        <h4 className="fw-700 text-grey-900 font-sm mt-0">
                          Visible
                        </h4>
                        <span className="d-block font-xs fw-500 mt-1 lh-3 text-grey-500">
                          Anyone can find you
                        </span>
                      </div>
                    </div>

                    {/* <div className="card-body d-flex pt-0">
                      <i className="feather-users text-grey-500 mr-3 font-lg"></i>
                      <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                        Genarel Group
                      </h4>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <div className="card border-0 mt-0 rounded-lg">
                    <div className="card-body d-flex pt-4 px-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Applicant for Teacher
                      </h4>
                      {/* <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select> */}
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0" scope="col">
                                Name
                              </th>
                              <th className="border-0" scope="col">
                                Domain of Study
                              </th>
                              <th className="border-0" scope="col">
                                Application Status
                              </th>
                              <th className="border-0" scope="col">
                                Email
                              </th>
                              <th scope="col" className="text-right border-0">
                                Contact Number
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr key={applicant._id}>
                              <td>
                                <b>{applicant.name}</b>
                              </td>

                              <td>
                                <b>{applicant.domain_of_study}</b>
                              </td>
                              <td>
                                <span
                                  className={`font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 d-inline-block mr-1 alert-warning text-warning`}
                                >
                                  {applicant.status}
                                </span>
                              </td>
                              <td className="text-left">
                                {applicant.email_contact}
                              </td>
                              <td className="text-right">
                                {applicant.contact_number}
                              </td>
                              {/* <td className="product-remove text-right">
                                  <Button
                                    className="bg-transparent border-0"
                                    onClick={() => {
                                      handleModel();
                                    }}
                                  >
                                    <i className="ti-trash  font-xs text-danger"></i>
                                  </Button>
                                  <Modal
                                    size="sm"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={location}
                                  >
                                    <Button
                                      onClick={() => {
                                        handleModel();
                                      }}
                                      className="btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss"
                                    ></Button>
                                    <Modal.Body className="text-center p-4">
                                      <i className="ti-info-alt text-warning display4-size"></i>
                                      <p className="text-grey-500 font-xsss mt-3 mb-4">
                                        Are you sure you want to delete product?
                                      </p>

                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-white bg-danger font-xssss text-uppercase fw-600 ls-3"
                                      >
                                        Yes, delete!
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          handleModel();
                                        }}
                                        className="border-0 btn rounded-6 lh-2 p-3 mt-0 mb-2 text-grey-600 bg-greylight font-xssss text-uppercase fw-600 ls-3 ms-1"
                                      >
                                        No, cancle!
                                      </Button>
                                    </Modal.Body>
                                  </Modal>
                                </td> */}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0 mt-3 rounded-lg">
                    <div className="card-body d-flex pt-4 px-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Applicant Information
                      </h4>
                      <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select>
                    </div>
                    {/* <div className="card-body m-4 mb-0 bg-lightblue p-4 rounded-lg">
                      <h4 className="fw-700 mt-2 font-xss text-grey-900 d-flex mb-0">
                        Hurin Seary
                        <span className="bg-primary text-white font-xsssss fw-600 ls-3 p-2 rounded-lg ml-auto">
                          HOME
                        </span>
                      </h4>
                      <h6 className="fw-500 font-xssss text-grey-600 lh-24 mb-0">
                        Manikpur, Thakurpara PO Ital Gacha 2 no Airport Gate
                        <br /> Thakur Para United Club, Kolkata <br /> West
                        Bengal - 700079
                      </h6>
                    </div> */}

                    <div className="card-body m-4 bg-lightgreen p-4 rounded-lg">
                      <h4 className="fw-700 mt-2 font-xss text-grey-900 d-flex mb-0">
                        {applicant.name}
                        <span className="bg-success text-white font-xsssss fw-600 ls-3 p-2 rounded-lg ml-auto">
                          Cover Letter
                        </span>
                      </h4>
                      <h6 className="fw-500 font-xssss text-grey-600 lh-24 mb-0">
                        {applicant.cover_letter}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

export default Admincustomerview;
