import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} md={6} className="text-center">
          <h1 className="display-4">404 - Page Not Found</h1>
          <p className="lead">Oops! The page you're looking for doesn't exist.</p>
          <Button as={Link} to="/" variant="primary" className="mt-3">
            Go to Home
          </Button>
        </Col>
      </Row>
    </Container>
  );
};


