import httpClient from "../http-common";


export const getCategories = () => {
    return httpClient.get(`/category/getAllCategories`,{
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("token")),
          },
    });
    }


export const addCategory = (name, description, imageURL) => {
    return httpClient.post(`/category/addCategory`, {
        name,
        description,
        imageURL
    }, {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("token")),
          },
    });
}

export const deleteCategory = (id) => {
    return httpClient.delete(`/category/deleteCategory/${id}`, {
        headers: {
            "x-auth-token": JSON.parse(localStorage.getItem("token")),
          },
    });
}