import React, { Fragment, useState } from "react";
import AdminTopNav from "../components/AdminTopnav";
import Teachersidebar from "../components/Teachersidebar";
import Adminfooter from "../components/Adminfooter";
import { FaCheckCircle } from "react-icons/fa"; // This is the new import
import { TeacherProtection } from "../CustomHooks/TeacherProtection";

const TeacherCoursePlan = () => {
  TeacherProtection();
  // const [selectedOption, setSelectedOption] = useState(null);

  const [isToggled, setIsToggled] = useState(false);

  // ... other state and styles ...

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <Fragment>
      <div id="wrapper">
        <Teachersidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <AdminTopNav />

          <div
            className="container-fluid px-4 py-4"
            style={{ flex: "1 0 auto" }}
          >
            <div className="row main-row-card bg-white border-0 mt-0 rounded-10  shadow-xs overflow-hidden">
              {/* Left Column - Course Planning and Toggle Button */}
              <div className="col-md-6">
                <h1 className="page-title">Course Planning</h1>

                <div className="payment-options">
                  {isToggled ? (
                    <ul className="styled-list">
                      {/* Content for One-Time Payment */}
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Immediate full access to the course upon <br />
                          payment.
                        </span>
                      </li>
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Eliminates the hassle of tracking multiple payments.
                        </span>
                      </li>
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Simplified financial management for the <br /> course.
                        </span>
                      </li>
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Often comes with a discount for upfront <br />{" "}
                          payment.
                        </span>
                      </li>
                    </ul>
                  ) : (
                    <ul className="styled-list">
                      {/* Content for Installments */}
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Makes courses financially accessible to more students.
                        </span>
                      </li>
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Flexible payment options tailored to student needs.
                        </span>
                      </li>
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Enables budgeting by spreading the cost over time.
                        </span>
                      </li>
                      <li>
                        <FaCheckCircle className="icon" />
                        <span>
                          Increases course enrollment by reducing upfront cost.
                        </span>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="section text-center py-5 py-md-0">
                  {/* Toggle Button */}
                  <input
                    className="pricing"
                    type="checkbox"
                    id="pricing"
                    name="pricing"
                    onChange={handleToggle}
                  />
                  <label htmlFor="pricing">
                    <span className="block-diff">
                      Installment Plan
                      <span className="float-right">Single payment</span>
                    </span>
                  </label>
                </div>
                {/* ... other content ... */}
              </div>

              {/* Right Column - Cards */}
              <div className="col-md-6">
                <div className="section over-hide">
                  <div className="container">
                    <div className="row full-height justify-content-center">
                      <div className="col-12 text-center align-self-center py-5">
                        <div className="section text-center py-5 py-md-0">
                          <div className="card-3d-wrap mx-auto">
                            <div
                              className={`card-3d-wrapper ${
                                isToggled ? "flipped" : ""
                              }`}
                            >
                              <div className="card-front">
                                <div className="pricing-wrap">
                                  <h4 className="mb-5">Installment</h4>
                                  <h2 className="mb-2">
                                    <sup>$</sup>10 / month
                                  </h2>
                                  <p className="mb-4">for 4 months</p>
                                  <p className="mb-1">
                                    <i className="uil uil-location-pin-alt size-22"></i>
                                  </p>
                                  <p className="mb-4">Flexible payments</p>
                                  <a href="#0" className="link">
                                    Select{" "}
                                  </a>
                                  <div
                                    className={`img-wrap img-2 ${
                                      isToggled ? "i1" : ""
                                    }`}
                                  >
                                    <img src="./frontcard2.png" alt="" />
                                  </div>
                                  <div
                                    className={`img-wrap img-1 ${
                                      isToggled ? "i1" : ""
                                    }`}
                                  >
                                    <img src="./frontcard3 (2).png" alt="" />
                                  </div>
                                  <div
                                    className={`img-wrap img-3 ${
                                      isToggled ? "i3" : ""
                                    }`}
                                  >
                                    <img src="./frontcard1.png" alt="" />
                                  </div>
                                  <div
                                    className={`img-wrap img-6 ${
                                      isToggled ? "i6" : ""
                                    }`}
                                  >
                                    <img
                                      src="https://assets.codepen.io/1462889/Stone.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="card-back">
                                <div className="pricing-wrap">
                                  <h4 className="mb-5">One Payment</h4>
                                  <h2 className="mb-2">
                                    <sup>$</sup>100<sup></sup>
                                  </h2>
                                  <p className="mb-4">per month</p>
                                  <p className="mb-1">
                                    <i className="uil uil-location-pin-alt size-22"></i>
                                  </p>
                                  <p className="mb-4">All in one package</p>
                                  <a href="#0" className="link">
                                    Select
                                  </a>
                                  <div className="img-wrap img-2">
                                    <img
                                      src="https://assets.codepen.io/1462889/grass.png"
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className={`img-wrap img-4 ${
                                      isToggled ? "i4" : ""
                                    }`}
                                  >
                                    <img
                                      src="https://assets.codepen.io/1462889/camp.png"
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className={`img-wrap img-5 ${
                                      isToggled ? "i5" : ""
                                    }`}
                                  >
                                    <img
                                      src="https://assets.codepen.io/1462889/Ivy.png"
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className={`img-wrap img-7 ${
                                      isToggled ? "i7" : ""
                                    }`}
                                  >
                                    <img
                                      src="https://assets.codepen.io/1462889/IvyRock.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Adminfooter />
        </div>
      </div>
    </Fragment>
  );
};

export default TeacherCoursePlan;
