import React, { useState, Fragment, useEffect } from "react";

import Adminsidebar from "../components/Adminsidebar";
import AdminTopnav from "../components/AdminTopnav";
import Adminfooter from "../components/Adminfooter";
import Pagination from "../components/Pagination";
import { getAllEnrolledUsers } from "../services/adminServices/EnrollmentServices";
import { formatDate } from "../utils/formatDate";

function Adminorder() {
  const [enrolledCourses, setEnrolledCourses] = useState([]);

  const [token] = useState(JSON.parse(localStorage.getItem("token")));

  const fetchEnrolledUsers = async () => {
    try {
      const response = await getAllEnrolledUsers(token);
      console.log(response.data.enrolledCourses);
      setEnrolledCourses(response.data.enrolledCourses);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEnrolledUsers();
  }, []);
  return (
    <Fragment>
      <div id="wrapper">
        <Adminsidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopnav />

            <div className="container px-3 py-4">
              <div className="row">
                <div className="col-lg-12 mt-4">
                  <div className="card border-0 mt-2 rounded-10">
                    <div className="card-body d-flex p-4 pb-0">
                      <h4 className="font-xss text-grey-800 mt-3 fw-700">
                        Enrollment List
                      </h4>
                      {/* <select
                        className="form-select ml-auto float-right border-0 font-xssss fw-600 text-grey-700 bg-transparent"
                        aria-label="Default select example"
                      >
                        <option>Sort by latest</option>
                        <option defaultValue="1">Sort by popularity</option>
                        <option defaultValue="2">
                          Sort by price : low to high
                        </option>
                        <option defaultValue="3">
                          Sort by price : high to low
                        </option>
                      </select> */}
                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-admin mb-0">
                          <thead className="bg-greylight rounded-10 ovh">
                            <tr>
                              <th className="border-0">ID</th>
                              <th className="border-0" scope="col">
                                Name
                              </th>
                              <th className="border-0" scope="col">
                                Email
                              </th>
                              {/* <th className="border-0" scope="col">
                                Total
                              </th> */}
                              <th className="border-0" scope="col">
                                Course
                              </th>
                              <th scope="col" className="text-end border-0">
                                Category
                              </th>
                              <th className="border-0" scope="col">
                                Enrollment Date
                              </th>
                              <th className="border-0" scope="col">
                                Price (PKR)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {enrolledCourses.map((value, index) => (
                              <tr key={index}>
                                {/* <td>{value.course._id}</td> */}
                                <td>{index + 1}</td>
                                <td>
                                  <b>{value.student.name}</b>
                                </td>
                                <td>{value.student.email}</td>
                                <td> {value.course.title}</td>
                                <td className="product-remove text-end">
                                  {value.course.domain_of_study}
                                </td>
                                <td>
                                  <span
                                  // className={`badge rounded-pill font-xsssss fw-700 pr-3 pl-3 lh-24 text-uppercase rounded-3 ls-2 alert-${value.color}`}
                                  >
                                    {formatDate(value.date)}
                                  </span>
                                </td>
                                <td> {value.course.price}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Pagination divClass="pagination justify-content-center mt-5" />
                </div>
              </div>
            </div>

            <Adminfooter />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Adminorder;
