import httpClient from "../http-common";

// Sign Up
export const registerUser = (data, role) => {
  return httpClient.post(`/users/${role}`, data);
};

// Sign In
export const signInUser = (credentials) => {
  return httpClient.post("/auth", credentials);
};

// AuthorizeUser
export const authorizeUser = (token, role) => {
  return httpClient.get(`/auth/${role}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

// Forgot Password
export const getSecurityQuestionUser = ( email) => {
  return httpClient.post(`/forgot-password/getSecurityQuestionUser`, {
    email: email,
  });
};

export const changePasswordUser = (
  email,
  securityQuestion,
  securityAnswer,
  newPassword
) => {
  return httpClient.post(
    `/forgot-password/user`,
    { email ,securityQuestion, securityAnswer, newPassword },
    
  );
};
