import React, { useEffect, useState } from "react";
import { Button, Image, Ratio } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { editCourse } from "../../services/courseServices/courseServices.js";
import { Backdrop, CircularProgress } from "@mui/material";
import { Alert } from "react-bootstrap";
// import { useDispatch } from "react-redux";
// import { nullateData } from "../../Redux/courseSlice.jsx";
// import { useNavigate } from "react-router-dom";

const ThumbnailEdit = (props) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [previewImage, setPreviewImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState(false);

  useEffect(() => {
    document.title = "Create Course | Seekho | Pricing";

    setRelatedValues({
      token: JSON.parse(localStorage.getItem("token")),
    });
  }, []);

  const [success, setSuccess] = useState({
    visible: false,
    message: "",
  });
  const [error, setError] = useState({
    visible: false,
    message: "",
  });

  const handleThumbnailUpload = (e) => {
    const file = e.target.files[0];
    // Display preview of the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [relatedValues, setRelatedValues] = useState({
    user: {
      name: "No User",
      domain_of_study: "Not Logged In",
    },
    token: "",
    courseID: "",
  });

  // const handleRedirectToDashboard = async () => {
  //   dispatch(nullateData());
  //   localStorage.removeItem("courseID"); // Remove the courseID from localStorage
  //   navigate("/teacher-my-courses");
  // };
  const onSubmit = async (data) => {
    // editCourse(data.thumbnail[0]);
    setOpen(true);
    try {
      const formData = new FormData();
      formData.append("thumbnail", data.thumbnail[0]);
      formData.append("courseID", props.courseID);
      await editCourse(relatedValues.token, formData)
        .then((res) => {
          setOpen(true);
          console.log(res.data);
          setError({
            visible: false,
            message: "",
          });
          setSuccess({
            visible: true,
            message: "Thumbnail Updated Successfully!"
        });
          setOpen(false);
          setState(true);
        })
        .catch((err) => {
          // console.log(err.response.data.errors)
          console.log(err.response.data.errors[0].msg);
          setError({
            visible: true,
            message: err.response.data.errors[0].msg,
          });
          setOpen(false);
        });
    } catch (err) {
      console.log(err);
      setError({
        visible: true,
        message: "Server Error",
      });
      setOpen(false);
    }
  };

  const styles3 = {
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      padding: "20px",
      backgroundColor: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      marginBottom: "30px",
    },
    form: {
      backgroundColor: "#f4f4f4",
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      width: "100%",
    },
    backButton: {
      textAlign: "right",
    },
    uploadLabel: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
    faqLabel: {
      fontWeight: "bold",
      marginBottom: "10px",
    },
    faqItem: {
      backgroundColor: "#ffffff",
      border: "1px solid #ddd",
      padding: "10px",
      margin: "10px 0",
      borderRadius: "4px",
    },
    faqQuestion: {
      fontWeight: "bold",
    },
    faqInput: {
      padding: "8px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      marginRight: "10px",
    },
    addFaqButton: {
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "8px 16px",
      cursor: "pointer",
    },
    addFaqButtonHover: {
      backgroundColor: "#45a049",
    },
    submitButton: {
      marginTop: "20px",
      textAlign: "center",
    },
    submitButtonButton: {
      backgroundColor: "#4caf50",
      color: "white",
      border: "none",
      borderRadius: "4px",
      padding: "10px 20px",
      cursor: "pointer",
    },
    submitButtonButtonHover: {
      backgroundColor: "#45a049",
    },
  };

  return (
    <>
      {success.visible && <Alert variant="success">{success.message}</Alert>}
      {error.visible && <Alert variant="danger">{error.message}</Alert>}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
          Loading...
        </Backdrop>
        <Alert variant="warning">
          Recommended: Best Suited Dimenstions for thumbnails is 1920 x 1080
        </Alert>
        {/* <label>Upload Thumbnail Image:</label> */}
        <br />
        <Form.Group controlId="formFileSm" className="mb-3">
          <Form.Label>Select Thumbnail Image:</Form.Label>
          <Form.Control
            type="file"
            size="sm"
            accept="image/*"
            {...register("thumbnail", {
              required: "Thumnail must be included.",
            })}
            onChange={handleThumbnailUpload}
            style={{
              border: "1px solid #ced4da",
              borderRadius: "30px",
              backgroundColor: "#ffffff",
              color: "#4CAF50",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
              paddingLeft: "20%",
            }}  
          />
        </Form.Group>
        <br />
        <span className="text-danger">{errors.thumbnail?.message}</span>
        {/* Display preview of the selected image */}
        {props.thumbnail && !previewImage && (
          <div style={{ width: 660, height: 660 }}>
            <Ratio aspectRatio="16x9">
              <Image
                src={props.thumbnail}
                alt="Thumbnail Preview"
                fluid
                style={{ marginTop: "10px", border: "1px solid #ced4da" }}
              />
            </Ratio>
          </div>
        )}
        {previewImage && (
          <>
            <br />
            <br />
            <h2 className="mont-font fw-600 font-lg">Preview Thumbnail</h2>

            <div style={{ width: 660, height: 660 }}>
              <Ratio aspectRatio="16x9">
                <Image
                  src={previewImage}
                  alt="Thumbnail Preview"
                  fluid
                  style={{ marginTop: "10px", border: "1px solid #ced4da" }}
                />
              </Ratio>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                Title:{" "}
                <h3 className="mont-font fw-600 font-lg">{props.title}</h3>
              </div>
              <div className="container-fluid px-4 py-4 mt-5">
                <div
                  className="d-flex flex-column align-items-center"
                  // style={{ marginTop: "-30px" }}
                >
                  <Button
                    disabled={open || state}
                    type="submit"
                    style={styles3.submitButtonButton}
                  >
                    Save Thumbnail
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default ThumbnailEdit;
