import React, { Fragment } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import ReactStars from "react-rating-stars-component";

import Subscribe from "../components/Subscribe";
import { Accordion, Ratio, Image, Modal } from "react-bootstrap";

import { getFeaturedCoursesByID } from "../services/public/PublicServices.js";

import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { reviewByCourse } from "../services/statistics/statistics";

import { Backdrop, CircularProgress } from "@mui/material";
import { enrollCourse } from "../services/userServices/EnrollmentServices.js";
import { useSelector } from "react-redux";
import { createPayment } from "../services/userServices/stripe.js";

export const CoursePreview = () => {
  const params = useParams();
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const fetchCourses = async () => {
    try {
      const response = await getFeaturedCoursesByID(courseID);
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };

  const [courses, setCourses] = useState();
  const [open, setOpen] = useState(false);
  const [courseID] = useState(params.id);
  const [modules, setModules] = useState([]);
  const [token] = useState(JSON.parse(localStorage.getItem("token")));

  // Modal Related Logic
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0); // State variable to store active accordion index

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the active index
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpen(true);
    fetchCourses()
      .then((courses) => {
        setCourses(courses.data);
        //console.log(courses.data);
        setOpen(false);
        setModules(courses.data.modules);
        //   console.log(courses.data.modules)
        reviewByCourse(courses.data.course._id).then((response) => {
          setReviews(response.data);
          const avgRating =
            response.data.reduce((acc, review) => acc + review.rating, 0) /
              response.data.length || 0;
          setAverageRating(avgRating);
        });

        // setCurrentVideo(currentCourse.moduleData[0]?.parts[0]?.video.url)
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
      });
  }, []);

  const handleClickPurchase = () => {
    if (isuserSelector) {
      handleShow(true);
    } else if (!isuserSelector) {
      alert("You're being redirected to login page. Please login to continue.");
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };
  const handlePurchase = async () => {
    // console.log("Purchase")

    const response = await createPayment(
      {
        name: courses?.course?.title,
        description: courses?.course?.description,
        price: courses?.course?.price,
        studentID: "null",
        courseID: courseID,
        teacherID: courses?.course?.instructor?._id,
      },
      token
    );
    if(response.status === 200){
      console.log("Payment Successful");
    }else if (response.status === 203){
      alert("Already Bought the Course")
    }else{
      alert("An error occured please try again later")
    }
    //enrollIntoCourse();
    setShow(false);
  };

  const enrollIntoCourse = async () => {
    try {
      setOpen(true);
      await enrollCourse(token, courseID)
        .then((response) => {
          // console.log(response)
          setOpen(false);
          setTimeout(() => {
            navigate("/default-user-profile");
          }, 2000);

          alert("Course enrolled Successfully 🎉");
        })
        .catch((error) => {
          // console.error(error);
          alert(`${error.response.data.errors[0].msg}`);
          setOpen(false);
        });
    } catch (error) {
      console.error(error);
      alert(`Server Error`);
      setOpen(false);
    }
  };

  const isuserSelector = useSelector((state) => state.user.loggedIn);

  return (
    <Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        Loading...
      </Backdrop>

      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader />

          <div class="inner-banner">
            <div class="container">
              <div class="row">
                <div class="col-lg-8">
                  <div class="instructor-wrap border-bottom-0 m-0">
                    <div class="about-instructor align-items-center">
                      {/* <div class="abt-instructor-img">
                        <a href="instructor-profile.html"><img src="assets/img/user/user1.jpg" alt="img" class="img-fluid" /></a>
                      </div> */}
                      <div class="instructor-detail me-3">
                        <h5>
                          <a href="instructor-profile.html">
                            {courses?.course?.instructor?.name}
                          </a>
                        </h5>
                        <p>{courses?.course.domain_of_study}</p>
                      </div>
                      <div class="rating mb-0">
                        {averageRating !== 0 && (
                          <ReactStars
                            count={5}
                            value={averageRating.toFixed(1)}
                            size={24}
                            activeColor="#ffd700"
                            isHalf={true}
                            edit={false}
                          />
                        )}
                        <span class="d-inline-block average-rating">
                          <span>{averageRating}</span> ({reviews.length}{" "}
                          reviews)
                        </span>
                      </div>
                    </div>
                    <span class="web-badge mb-3">
                      {" "}
                      {courses?.course?.domain_of_study}
                    </span>
                  </div>
                  <h2> {courses?.course?.title}</h2>
                  <p>{courses?.course?.description}</p>
                  {/* <div class="course-info d-flex align-items-center border-bottom-0 m-0 p-0">
                    <div class="cou-info">
                      <img src="assets/img/icon/icon-01.svg" alt="" />
                      <p>12+ Lesson</p>
                    </div>
                    <div class="cou-info">
                      <img src="assets/img/icon/timer-icon.svg" alt="" />
                      <p>9hr 30min</p>
                    </div>
                    <div class="cou-info">
                      <img src="assets/img/icon/people.svg" alt="" />
                      <p>32 students enrolled</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="row">
              <div style={{ maxWidth: "100%" }} className="col-xl-8 col-xxl-9">
                <div className="card border-0 mb-0 rounded-lg overflow-hidden">
                  <div
                    style={{
                      width: "100%",
                      height: 460,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Ratio aspectRatio="16x9">
                      <Image
                        src={courses?.course?.thumbnail}
                        alt="Thumbnail Preview"
                        fluid
                        style={{
                          border: "1px solid #ced4da",
                        }}
                      />
                    </Ratio>
                  </div>
                </div>
                <div class="card overview-sec">
                  <div class="card-body">
                    <h5 class="subs-title">Overview</h5>
                    <h6>Course Description</h6>
                    <p> {courses?.course?.description}</p>
                    {/* <h6>What you'll learn</h6>
                    <div class="row">
                      <div class="col-md-12">
                        <ul>
                          <li style={{ listStyle: "circle" }}>
                            Create awesome animated splash screens for any Excel
                            project such as animation with password access to a
                            work book, loading animation.
                          </li>
                          <li style={{ listStyle: "circle" }}>
                            After completing this course you'll be confident to
                            create any subtle to complex animation that will
                            turn any project a professional work and surely
                            you'll become an awesome developer and designer
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h6>Requirements</h6>
                    <ul class="mb-0">
                      <li style={{ listStyle: "circle" }}>
                        {" "}
                        After creating more than a dozen courses on Microsoft
                        Access databases and programming in VBA, many students
                        have contacted me with discussions on specific problems
                        and scenarios. From these discussions.
                      </li>
                    </ul> */}
                  </div>
                </div>

                <div class="card content-sec">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-6">
                        <h5 class="subs-title">Course Content</h5>
                      </div>
                      <div class="col-sm-6 text-sm-end">
                        <h6></h6>
                      </div>
                    </div>
                    {modules.map((value, index) => (
                      <div class="course-card">
                        <h6 class="cou-title">
                          <a
                            class="collapsed"
                            data-bs-toggle="collapse"
                            href={`#collapse${index + 1}`}
                            aria-expanded="false"
                          >
                            {value?.title}
                          </a>
                        </h6>
                        <div
                          id={`collapse${index + 1}`}
                          class="card-collapse collapse"
                        >
                          <ul>
                            {value?.parts.map((part, index) => (
                              <li>
                                <p>
                                  <img
                                    src="assets/img/icon/play.svg"
                                    alt=""
                                    class="me-2"
                                  />
                                  Lecture {index + 1}: {part.title}
                                </p>
                                <div>
                                  {/* <a href="javascript:;">Preview</a> */}
                                  {/* <span>02:53</span> */}
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-xxl-3">
                <div className="card p-4 mb-4 bg-primary border-0 shadow-xss rounded-lg">
                  <div className="card-body">
                    <h2 className="text-white font-xsssss fw-700 text-uppercase ls-3 ">
                      Starter
                    </h2>
                    <h1 className="display2-size text-white fw-700">
                      PKR {courses?.course?.price}
                    </h1>
                    <h4 className="text-white fw-500 mb-4 lh-24 font-xssss">
                      For anyone validating Framer as a professional prototyping
                      tool.
                    </h4>
                    <h4 className="text-white font-xssss mb-2">
                      <i className="ti-check mr-2 text-white"></i> Unlimited
                      views
                    </h4>
                    <h4 className="text-white font-xssss mb-2">
                      <i className="ti-check mr-2 text-white"></i> Everything in
                      Free
                    </h4>
                    <h4 className="text-white font-xssss mb-4">
                      <i className="ti-check mr-2 text-white"></i> Private share
                      links
                    </h4>
                    <button
                      onClick={() => {
                        handleClickPurchase();
                      }}
                      className="btn btn-block border-0 w-100 bg-white p-3 text-primary fw-600 rounded-lg d-inline-block font-xssss btn-light"
                    >
                      Purchase this course
                    </button>
                  </div>
                </div>
                <Subscribe />

                <div className="card w-100 border-0 mt-0 mb-4 p-4 shadow-xss position-relative rounded-lg bg-white">
                  <div className="row">
                    <div className="col-5 pr-0">
                      <h2 className="display3-size lh-1 m-0 text-grey-900 fw-700">
                        {averageRating}
                      </h2>
                    </div>
                    <div className="col-7 pl-0 text-right">
                      <div className="star d-block w-100 text-right">
                        {averageRating !== 0 && (
                          <ReactStars
                            count={5}
                            value={averageRating.toFixed(1)}
                            size={24}
                            activeColor="#ffd700"
                            isHalf={true}
                            edit={false}
                          />
                        )}
                      </div>
                      <h4 className="font-xsssss text-grey-600 fw-600 mt-1">
                        Based on {reviews.length} rating
                      </h4>
                    </div>
                  </div>
                  {/* <div className="bg-greylight theme-dark-bg rounded-sm p-2 mt-3 mb-4">
                    <div className="row mt-3">
                      <div className="col-3 pr-1 mt-0">
                        <img
                          src="../assets/images/star.png"
                          alt="star"
                          className="w10 float-left"
                        />
                        <h4 className="font-xsss fw-600 text-grey-600 ml-1 float-left d-inline">
                          5
                        </h4>
                      </div>
                      <div className="col-7 pl-0 pr-2">
                        <div
                          id="bar_1"
                          data-value="45"
                          className="bar-container"
                        >
                          <div
                            className="bar-percentage"
                            style={{ Width: `70%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-2 pl-0">
                        <h4 className="font-xssss fw-600 text-grey-800">70%</h4>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3 pr-1 mt-0">
                        <img
                          src="../assets/images/star.png"
                          alt="star"
                          className="w10 float-left"
                        />
                        <h4 className="font-xsss fw-600 text-grey-600 ml-1 float-left d-inline">
                          4
                        </h4>
                      </div>
                      <div className="col-7 pl-0 pr-2">
                        <div
                          id="bar_2"
                          data-value="45"
                          className="bar-container"
                        >
                          <div
                            className="bar-percentage"
                            style={{ Width: `50%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-2 pl-0">
                        <h4 className="font-xssss fw-600 text-grey-800">50%</h4>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3 pr-1 mt-0">
                        <img
                          src="../assets/images/star.png"
                          alt="star"
                          className="w10 float-left"
                        />
                        <h4 className="font-xsss fw-600 text-grey-600 ml-1 float-left d-inline">
                          3
                        </h4>
                      </div>
                      <div className="col-7 pl-0 pr-2">
                        <div
                          id="bar_3"
                          data-value="45"
                          className="bar-container"
                        >
                          <div
                            className="bar-percentage"
                            style={{ Width: `40%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-2 pl-0">
                        <h4 className="font-xssss fw-600 text-grey-800">40%</h4>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3 pr-1 mt-0">
                        <img
                          src="../assets/images/star.png"
                          alt="star"
                          className="w10 float-left"
                        />
                        <h4 className="font-xsss fw-600 text-grey-600 ml-1 float-left d-inline">
                          2
                        </h4>
                      </div>
                      <div className="col-7 pl-0 pr-2">
                        <div
                          id="bar_4"
                          data-value="45"
                          className="bar-container"
                        >
                          <div
                            className="bar-percentage"
                            style={{ Width: `30%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-2 pl-0">
                        <h4 className="font-xssss fw-600 text-grey-800">30%</h4>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-3 pr-1 mt-0">
                        <img
                          src="../assets/images/star.png"
                          alt="star"
                          className="w10 float-left"
                        />
                        <h4 className="font-xsss fw-600 text-grey-600 ml-1 float-left d-inline">
                          1
                        </h4>
                      </div>
                      <div className="col-7 pl-0 pr-2">
                        <div
                          id="bar_5"
                          data-value="45"
                          className="bar-container"
                        >
                          <div
                            className="bar-percentage"
                            style={{ Width: `20%` }}
                          ></div>
                        </div>
                      </div>
                      <div className="col-2 pl-0">
                        <h4 className="font-xssss fw-600 text-grey-800">20%</h4>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    {/* <div className="col-2 text-left">
                      <figure className="avatar float-left mb-0">
                        <img
                          src="../assets/images/user.png"
                          alt="banner"
                          className="float-right shadow-none w40 mr-2"
                        />
                      </figure>
                    </div> */}
                    <div className="col-10 pl-4">
                      {reviews &&
                        reviews.length > 0 &&
                        reviews.map((review) => {
                          //console.log(review);
                          return (
                            <div className="content">
                              {/* <h6 className="author-name font-xssss fw-600 mb-0 text-grey-800">
                                Goria Coast
                              </h6> */}
                              <div className="col-4 text-left">
                                <figure className="avatar float-left mb-0">
                                  <img
                                    src="../assets/images/user.png"
                                    alt="banner"
                                    className="float-right shadow-none w40 mr-2"
                                  />
                                </figure>
                              </div>
                              <h6 className="d-block font-xsssss fw-500 text-grey-500 mt-2 mb-0">
                                {new Date(
                                  review.createdAt
                                ).toLocaleDateString()}
                              </h6>
                              <div className="star d-block w-100 text-left">
                                {review.rating && (
                                  <ReactStars
                                    count={5}
                                    value={review.rating.toFixed(1)}
                                    size={24}
                                    activeColor="#ffd700"
                                    isHalf={true}
                                    edit={false}
                                  />
                                )}
                              </div>
                              <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 mt-2">
                                {review.comment}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-2 text-left">
                      <figure className="avatar float-left mb-0">
                        <img
                          src="../assets/images/user.png"
                          alt="banner"
                          className="float-right shadow-none w40 mr-2"
                        />
                      </figure>
                    </div>
                    <div className="col-10 pl-4">
                      <div className="content">
                        <h6 className="author-name font-xssss fw-600 mb-0 text-grey-800">
                          Goria Coast
                        </h6>
                        <h6 className="d-block font-xsssss fw-500 text-grey-500 mt-2 mb-0">
                          July 26 at 8:20 PM
                        </h6>
                        <div className="star d-block w-100 text-left">
                          <img
                            src="../assets/images/star.png"
                            alt="star"
                            className="w10"
                          />
                          <img
                            src="../assets/images/star.png"
                            alt="star"
                            className="w10"
                          />
                          <img
                            src="../assets/images/star.png"
                            alt="star"
                            className="w10"
                          />
                          <img
                            src="../assets/images/star.png"
                            alt="star"
                            className="w10"
                          />
                          <img
                            src="assets/images/star-disable.png"
                            alt="star"
                            className="w10"
                          />
                        </div>
                        <p className="comment-text lh-24 fw-500 font-xssss text-grey-500 mt-2">
                          Enjoyed this a lot and well done. We are an early
                          stage digitally native vertical brand, making travel
                          bags.{" "}
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="row">
                    <a
                      href="/default-course-one"
                      className="d-block p-2 lh-32 w-100 text-center ml-3 mr-3 bg-greylight fw-600 font-xssss text-grey-900"
                    >
                      Add a Review
                    </a>
                  </div> */}
                </div>
                {/* <div className="card shadow-xss rounded-lg border-0 p-4 mb-4">
                  <h4 className="font-xs fw-700 text-grey-900 d-inline-block mb-3">
                    Modules information
                    <i className="ti-arrow-circle-right text-grey-500 font-xss ml-1"></i>
                  </h4>

                  <h5 className="font-xs fw-700 text-grey-900 d-inline-block mb-3 ml-3">
                    Total Modules: <span>{modules.length}</span>
                  </h5>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Purchase this course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Press the button to complete transaction</p>
          <button
            onClick={() => {
              handlePurchase();
            }}
            className="btn btn-block border-0 w-100 bg-primary p-3 text-white fw-600 rounded-lg d-inline-block font-xssss btn-light"
          >
            Purchase
          </button>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default CoursePreview;
