import httpClient from "../http-common";

export const getTeacherStats = () => {
  let token = JSON.parse(localStorage.getItem("token"));
  return httpClient.post(
    `statistics/getTeacherStats`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};
 
export const getAdminStats = () => {
  let token = JSON.parse(localStorage.getItem("token"));
  return httpClient.post(
    `statistics/getAdminStats`,
    {},
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};


export const addReviewToCourse = (courseID, rating, comment, instructorID) => {

  let token = JSON.parse(localStorage.getItem("token"));
  return httpClient.post(`statistics/addReviewToCourse`, { courseID, rating, comment, instructorID }, {
    headers: {
      "x-auth-token": token,
    },
  });
}

export const reviewByCourse = (courseID) => {
  return httpClient.get(`statistics/reviewByCourse/${courseID}`);
}

export const reviewByInstructor = (instructorID) => {
  return httpClient.get(`statistics/reviewByInstructor/${instructorID}`);
}