import React, { Fragment, useEffect, useState } from "react";
import Header from "../components/Header";
import Upperheader from "../components/Upperheader";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import SearchBarTeacher from "../components/searchBarTeachers.tsx";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import Footertwo from "../components/Footertwo";

import "../main.scss";

import { getAllFeaturedCourses } from "../services/public/PublicServices";
import { Spinner } from "react-bootstrap";
import { truncateTitle } from "../utils/utilityMethods";
import { getCategories } from "../services/categoryServices/categoryServices.js";

import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css"; //Allows for server-side rendering.
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import SearchBar from "../components/searchBar.tsx";
import CourseCard from "../components/courseCard.js";

const feedbackList = [
  {
    imageUrl: "user1.png",
    name: "Goria Coast",
    status: "Digital Marketing Executive",
    color: "bg-warning",
    icon: "ti-quote-right",
    des: "This theme is simply outstanding. The customization options and flexibility are superb. The speed is impressive. We couldn't find any criticisms. Using the theme is enjoyable, installation is easy, and it's super user-friendly. Excellent work!\"",
  },
  {
    imageUrl: "user6.png",
    name: "Goria Coast",
    status: "Digital Marketing Executive",
    color: "bg-danger",
    icon: "feather-slack",
    des: "This theme is simply outstanding. The customization options and flexibility are superb. The speed is impressive. We couldn't find any criticisms. Using the theme is enjoyable, installation is easy, and it's super user-friendly. Excellent work!\"",
  },
  {
    imageUrl: "user3.png",
    name: "Goria Coast",
    status: "Digital Marketing Executive",
    color: "bg-twitter",
    icon: "ti-twitter-alt",
    des: "This theme is simply outstanding. The customization options and flexibility are superb. The speed is impressive. We couldn't find any criticisms. Using the theme is enjoyable, installation is easy, and it's super user-friendly. Excellent work!\"",
  },
];

const priceList = [
  {
    color: "bg-primary shadow-xss",
    price: "€ 0",
    title: "Free",
    des: "For anyone validating Framer as a professional prototyping tool.",
    status: "text-white",
    btn: "btn btn-block border-0 w-100 bg-white p-3 text-primary fw-600 rounded-lg d-inline-block font-xssss btn-light",
  },
  {
    color: "bg-white shadow-lg",
    price: "€ 49",
    title: "Standard",
    des: "For anyone validating Framer as a professional prototyping tool.",
    status: "text-grey-900",
    btn: "btn btn-block border-0 w-100 bg-primary p-3 text-white fw-600 rounded-lg d-inline-block font-xssss btn-light",
  },
];

const blogList = [
  {
    imageUrl: "blog1.png",
    title: "Mastering Effective Study Habits for Academic Success",
    meta: "Study Tips",
    des: "Unlock the secrets to successful learning with our expert-backed study techniques. Maximize your academic potential and excel in your courses.",
  },
  {
    imageUrl: "blog2.png",
    title: "Navigating the Digital Classroom: Tips for Online Learning",
    meta: "Online Lec",
    des: "Adapt to the online learning environment with ease. Learn essential strategies for virtual education and make the most of your online courses.",
  },
  {
    imageUrl: "blog3.png",
    title: "The Future of Education: Trends and Innovations",
    meta: "Trends",
    des: "Stay ahead of the curve in the ever-evolving field of education. Explore the latest trends and innovations shaping the future of learning.",
  },
];

const classesList = [
  {
    imageUrl: "bootstrap.png",
    title: "Bootstrap",
    num: "32 Course",
    bg: "#fcf1eb",
  },
  {
    imageUrl: "html.png",
    title: "HTML",
    num: "54 Course",
    bg: "#fff9eb",
  },
  {
    imageUrl: "jquery.png",
    title: "Jquery",
    num: "76 Course",
    bg: "#e5f4fb",
  },
  {
    imageUrl: "sass.png",
    title: "SASS",
    num: "76 Course",
    bg: "#dcf4f8",
  },
  {
    imageUrl: "react.png",
    title: "React",
    num: "23 Course",
    bg: "#fcf1eb",
  },

  {
    imageUrl: "java.png",
    title: "JAVA",
    num: "78 Course",
    bg: "#fff9eb",
  },
  {
    imageUrl: "python.png",
    title: "Python",
    num: "65 Course",
    bg: "#e5f4fb",
  },
  {
    imageUrl: "mongo.png",
    title: "MongoDB",
    num: "11 Course",
    bg: "#fcf1eb",
  },
  {
    imageUrl: "bootstrap.png",
    title: "Bootstrap",
    num: "32 Course",
    bg: "#fcf1eb",
  },
  {
    imageUrl: "bootstrap.png",
    title: "Bootstrap",
    num: "32 Course",
    bg: "#fff9eb",
  },
];

const serviceList = [
  {
    img: "feedback.png",
    title: "Blazing Performance",
    des: "Experience unmatched speed and efficiency with our cutting-edge technology, ensuring a seamless and responsive learning environment.",
  },
  {
    img: "feedback2.png",
    title: "Best Feedback",
    des: "Benefit from comprehensive and constructive feedback, tailored to enhance your learning and development, provided by industry experts.",
  },
  {
    img: "feedback3.png",
    title: "Quality Code",
    des: "Rely on our high-quality, well-documented code, designed for both robust performance and easy customization to fit your specific needs.",
  },
];

export const HomePage = () => {
  const [courseCategories, setCourseCategories] = useState([]);
  const [instructors, setInstructors] = useState([]);

  const categorysettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 300,
    centerMode: false,
    variableWidth: true,
  };
  const feedbacksettings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    centerMode: false,
    variableWidth: false,
  }; 

  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchFeaturedCourses = async () => {
    try {
      const response = await getAllFeaturedCourses();
      return response;
    } catch (error) {
      throw new Error(`Error fetching courses: ${error.message}`);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await getCategories();
      if (response.status === 200) {
        setCourseCategories(response.data);
      } else {
        console.log("Error in fetching categories");
        alert("Error in fetching categories");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setOpen(true);
    fetchCategories();
    fetchFeaturedCourses()
      .then((courses) => {
        //console.log(courses.data.featuredCourses);
        setCourses(courses.data.featuredCourses);
        setOpen(false);
      })
      .catch((error) => {
        console.error(error);
        setOpen(false);
      });
  }, []);

  const options = {
    items: 4,
    nav: true,
    rewind: true,
    autoplay: true,
  };

  const testimonialOptions = {
    items: 1,
    nav: true,
    rewind: true,
    autoplay: true,
  };

  return (
    <Fragment>
      <div className="main-wrapper">
        <header className="header">
          <div className="header-fixed">
            <nav className="navbar navbar-expand-lg header-nav scroll-sticky">
              <div className="container">
                <div className="navbar-header">
                  <a id="mobile_btn" href="javascript:void(0);">
                    <span className="bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </a>
                  <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                    Seekho.pk
                    <span className="d-block font-xsssss ls-1 text-grey-500 open-font ">
                      Online Learning Course
                    </span>
                  </h1>
                </div>
                <div className="main-menu-wrapper">
                  <div className="menu-header">
                    <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                      Seekho.pk
                      <span className="d-block font-xsssss ls-1 text-grey-500 open-font ">
                        Online Learning Course
                      </span>
                    </h1>
                    <a
                      id="menu_close"
                      className="menu-close"
                      href="javascript:void(0);"
                    >
                      <i className="fas fa-times"></i>
                    </a>
                  </div>
                  <ul className="main-nav">
                    <li className="active">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="">
                      <Link to="/teacher-carousel">Teach on Seeko</Link>
                    </li>

                    <li className="has-submenu">
                      <Link to="/default-public-search/All/All">
                        All Courses
                      </Link>
                      {/* <ul className="submenu">
                        <li>
                          <a href="blog-list.html">Blog List</a>
                        </li>
                        <li>
                          <a href="blog-grid.html">Blog Grid</a>
                        </li>
                        <li>
                          <a href="blog-masonry.html">Blog Masonry</a>
                        </li>
                        <li>
                          <a href="blog-modern.html">Blog Modern</a>
                        </li>
                        <li>
                          <a href="blog-details.html">Blog Details</a>
                        </li>
                      </ul> */}
                    </li>
                    <li className="login-link">
                      <a href="login.html">Login / Signup</a>
                    </li>
                  </ul>
                </div>
                <ul className="nav header-navbar-rht">
                  <li className="nav-item">
                    <Link className="nav-link header-sign" to="/login">
                      Student
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link header-sign" to="/login-teacher">
                      Teacher
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <section className="home-slide d-flex align-items-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-7">
                <div className="home-slide-face aos">
                  <div className="home-slide-text ">
                    <h5>The Leader in Online Learning</h5>
                    <h1>Engaging & Accessible Online Courses For All</h1>
                    <p>Own your future learning new skills online</p>
                  </div>
                  <div className="banner-content">
                    <form
                      className="form"
                      action="https://dreamslms.dreamguystech.com/course-list.html"
                    >
                      <SearchBar public={true} />
                    </form>
                  </div>
                  <div className="trust-user">
                    <p>Trusted by over 15K Users worldwide since 2022</p>
                    <div className="trust-rating d-flex align-items-center">
                      <div className="rate-head">
                        <h2>
                          <span>1000</span>+
                        </h2>
                      </div>
                      <div className="rating d-flex align-items-center">
                        <h2 className="d-inline-block average-rating">4.4</h2>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                        <i className="fas fa-star filled"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className="girl-slide-img aos">
                  <img src="assets/img/object.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section student-course">
          <div className="container">
            <div className="course-widget">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius align-items-center aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src="assets/img/pencil-icon.svg" alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span>10</span>K
                          </h4>
                          <p>Online Courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 d-flex">
                  <div className="course-full-width">
                    <div
                      className="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div className="online-course d-flex align-items-center">
                        <div className="course-img">
                          <img src="assets/img/cources-icon.svg" alt="" />
                        </div>
                        <div className="course-inner-content">
                          <h4>
                            <span>200</span>+
                          </h4>
                          <p>Expert Tutors</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 d-flex">
                  <div class="course-full-width">
                    <div
                      class="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div class="online-course d-flex align-items-center">
                        <div class="course-img">
                          <img src="assets/img/certificate-icon.svg" alt="" />
                        </div>
                        <div class="course-inner-content">
                          <h4>
                            <span>6</span>K+
                          </h4>
                          <p>Ceritified Courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 d-flex">
                  <div class="course-full-width">
                    <div
                      class="blur-border course-radius aos"
                      data-aos="fade-up"
                    >
                      <div class="online-course d-flex align-items-center">
                        <div class="course-img">
                          <img src="assets/img/gratuate-icon.svg" alt="" />
                        </div>
                        <div class="course-inner-content">
                          <h4>
                            <span>60</span>K +
                          </h4>
                          <p>Online Students</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section how-it-works">
          <div className="container">
            <div className="section-header aos">
              <div className="section-sub-head">
                <span>Favourite Course</span>
                <h2>Top Category</h2>
              </div>
              {/* <div className="all-btn all-category d-flex align-items-center">
                <a href="job-category.html" className="btn btn-primary">
                  All Categories
                </a>
              </div> */}
            </div>
            <div className="section-text aos">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
            <OwlCarousel options={options}>
              {courseCategories.map((value, index) => {
                return (
                  <div
                    className="feature-box text-center "
                    key={index}
                    style={{
                      minHeight: "250px",
                      maxHeight: "250px",
                    }}
                  >
                    <div
                      className="feature-bg"
                      style={{
                        minHeight: "250px",
                        maxHeight: "250px",
                      }}
                    >
                      <div className="feature-header">
                        <div className="feature-icon">
                          <img src={value.imageURL} alt="" />
                        </div>
                        <div className="feature-cont">
                          <div className="feature-text">{value.name}</div>
                        </div>
                      </div>
                      {/* <p>{value.description}</p> */}
                    </div>
                  </div>
                );
              })}
              {/* <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Angular Development</div>
                      </div>
                    </div>
                    <p>40 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-01.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Docker Development</div>
                      </div>
                    </div>
                    <p>45 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-02.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Node JS Frontend</div>
                      </div>
                    </div>
                    <p>40 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-03.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Swift Development</div>
                      </div>
                    </div>
                    <p>23 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-04.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Python Development</div>
                      </div>
                    </div>
                    <p>30 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-05.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">React Native</div>
                      </div>
                    </div>
                    <p>80 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-04.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Angular Development</div>
                      </div>
                    </div>
                    <p>40 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-01.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Docker Development</div>
                      </div>
                    </div>
                    <p>45 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-02.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Node JS Frontend</div>
                      </div>
                    </div>
                    <p>40 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-03.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Swift Development</div>
                      </div>
                    </div>
                    <p>23 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-04.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Python Development</div>
                      </div>
                    </div>
                    <p>30 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-01.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Docker Development</div>
                      </div>
                    </div>
                    <p>45 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-02.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Node JS Frontend</div>
                      </div>
                    </div>
                    <p>40 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-03.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Swift Development</div>
                      </div>
                    </div>
                    <p>23 Instructors</p>
                  </div>
                </div>
              </div>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="feature-box text-center ">
                  <div className="feature-bg">
                    <div className="feature-header">
                      <div className="feature-icon">
                        <img src="assets/img/categories-icon-04.png" alt="" />
                      </div>
                      <div className="feature-cont">
                        <div className="feature-text">Python Development</div>
                      </div>
                    </div>
                    <p>30 Instructors</p>
                  </div>
                </div>
              </div> */}
            </OwlCarousel>
          </div>
        </section>

        <section className="section new-course">
          <div className="container">
            <div className="section-header aos">
              <div className="section-sub-head">
                <span>What’s New</span>
                <h2>Featured Courses</h2>
              </div>
              <div className="all-btn all-category d-flex align-items-center">
                <Link
                  to="default-public-search/All/All"
                  className="btn btn-primary"
                >
                  All Courses
                </Link>
              </div>
            </div>
            <div className="section-text aos">
              <p className="mb-0">
                This feature course section is designed to help you find the
                right course for your needs. It includes some of our most
                popular courses, including those that are relevant to your
                interests and career goals.
              </p>
            </div>
            <div className="course-feature">
              <div className="row">
                {courses.map((value, index) => (
                  <CourseCard value={value} key={index} homePage={true}/>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="section master-skill">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="section-header aos">
                  <div className="section-sub-head">
                    <span>What’s New</span>
                    <h2>Master the skills to drive your career</h2>
                  </div>
                </div>
                <div className="section-text aos">
                  <p>
                    Get certified, master modern tech skills, and level up your
                    career — whether you’re starting out or a seasoned pro. 95%
                    of eLearning learners report our hands-on content directly
                    helped their careers.
                  </p>
                </div>
                <div className="career-group aos">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img
                                src="assets/img/icon/icon-1.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <p>Stay motivated with engaging instructors</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img
                                src="assets/img/icon/icon-2.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <p>Keep up with in the latest in cloud</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img
                                src="assets/img/icon/icon-3.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <p>Get certified with 100+ certification courses</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 d-flex">
                      <div className="certified-group blur-border d-flex">
                        <div className="get-certified d-flex align-items-center">
                          <div className="blur-box">
                            <div className="certified-img ">
                              <img
                                src="assets/img/icon/icon-4.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <p>Build skills your way, from labs to courses</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 d-flex align-items-end">
                <div className="career-img aos">
                  <img src="assets/img/join.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section trend-course">
          <div className="container">
            <div className="section-header aos">
              <div className="section-sub-head">
                <span>What’s New</span>
                <h2>TRENDING COURSES</h2>
              </div>
              <div className="all-btn all-category d-flex align-items-center">
                <Link
                  to="default-public-search/All/All"
                  className="btn btn-primary"
                >
                  All Courses
                </Link>
              </div>
            </div>
            <div className="section-text aos">
              <p className="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
                aenean accumsan bibendum gravida maecenas augue elementum et
                neque. Suspendisse imperdiet.
              </p>
            </div>
            <div className="owl-carousel trending-course owl-theme aos">
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-07.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $200 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">John Michael</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        Learn JavaScript and Express to become a professional
                        JavaScript
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>13+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>10hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-08.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $300 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user2.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">John Smith</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        Responsive Web Design Essentials HTML5 CSS3 and
                        Bootstrap
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>10+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>11hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-05.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $100 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user3.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">Lavern M.</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        The Complete App Design Course - UX, UI and Design
                        Thinking
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>8+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>8hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-08.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $200 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user5.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">John Smith</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        Build Responsive Real World Websites with HTML5 and CSS3
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>13+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>10hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-07.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $300 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user2.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">John Smith</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        Responsive Web Design Essentials HTML5 CSS3 and
                        Bootstrap
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>10+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>11hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-09.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $100 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user4.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">Lavern M.</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        The Complete App Design Course - UX, UI and Design
                        Thinking
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>8+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>8hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-08.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $200 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user1.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">John Michael</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        Learn JavaScript and Express to become a professional
                        JavaScript
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>13+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>10hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="course-box trend-box">
                <div className="product trend-product">
                  <div className="product-img">
                    <a href="course-details.html">
                      <img
                        className="img-fluid"
                        alt=""
                        src="assets/img/course/course-09.jpg"
                      />
                    </a>
                    <div className="price">
                      <h3>
                        $300 <span>$99.00</span>
                      </h3>
                    </div>
                  </div>
                  <div className="product-content">
                    <div className="course-group d-flex">
                      <div className="course-group-img d-flex">
                        <a href="instructor-profile.html">
                          <img
                            src="assets/img/user/user3.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </a>
                        <div className="course-name">
                          <h4>
                            <a href="instructor-profile.html">John Smith</a>
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div className="course-share d-flex align-items-center justify-content-center">
                        <a href="#">
                          <i className="fa-regular fa-heart"></i>
                        </a>
                      </div>
                    </div>
                    <h3 className="title">
                      <a href="course-details.html">
                        Responsive Web Design Essentials HTML5 CSS3 and
                        Bootstrap
                      </a>
                    </h3>
                    <div className="course-info d-flex align-items-center">
                      <div className="rating-img d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-01.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>10+ Lesson</p>
                      </div>
                      <div className="course-view d-flex align-items-center">
                        <img
                          src="assets/img/icon/icon-02.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>11hr 30min</p>
                      </div>
                    </div>
                    <div className="rating">
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star filled"></i>
                      <i className="fas fa-star"></i>
                      <span className="d-inline-block average-rating">
                        <span>4.0</span> (15)
                      </span>
                    </div>
                    <div className="all-btn all-category d-flex align-items-center">
                      <a href="checkout.html" className="btn btn-primary">
                        BUY NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="feature-instructors">
              <div className="section-header aos">
                <div className="section-sub-head feature-head text-center">
                  <h2>Featured Instructor</h2>
                  <div className="section-text aos">
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Eget aenean accumsan bibendum gravida maecenas augue
                      elementum et neque. Suspendisse imperdiet.
                    </p>
                  </div>
                </div>
              </div>
              <SearchBarTeacher
                setTeacherListProp={setInstructors}
                homePage={true}
              />

              <OwlCarousel options={options}>
                {instructors.map((value, index) => (
                  <div className="col-xl-12 col-lg-6 col-md-6" key={index}>
                    <div className="card mb-4 d-block w-100 shadow-xss rounded-lg p-xxl-5 p-4 border-0 text-center">
                      <Link
                        to={`/default-author-profile/${value._id}`}
                        className="position-absolute right-0 mr-4 top-0 mt-3"
                      >
                        <i className="ti-more text-grey-500 font-xs"></i>
                      </Link>
                      <Link
                        to={`/default-author-profile/${value._id}`}
                        className="btn-round-xxxl rounded-full bg-lightblue ml-auto mr-auto"
                      >
                        <img
                          src={`assets/images/user.png`}
                          alt="."
                          className="p-1 w-100"
                        />
                        <AccountCircleIcon />
                      </Link>
                      <h4 className="fw-700 font-xs mt-4">{value.name}</h4>
                      <p className="fw-500 font-xssss text-grey-500 mt-3">
                        {value.domain_of_study}
                      </p>
                      <div className="clearfix"></div>
                      {value.tag1 ? (
                        <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-success d-inline-block text-success mb-1 mr-1">
                          {value.tag1}
                        </span>
                      ) : (
                        ""
                      )}
                      {value.tag2 ? (
                        <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 bg-lightblue d-inline-block text-grey-800 mb-1 mr-1">
                          {value.tag2}
                        </span>
                      ) : (
                        ""
                      )}
                      {value.tag3 ? (
                        <span className="font-xsssss fw-700 pl-3 pr-3 lh-32 text-uppercase rounded-lg ls-2 alert-info d-inline-block text-info mb-1">
                          {value.tag3}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* <button
                        className="btn btn-primary rounded-pill"
                        onClick={() => {
                          handleFollowInstructor(
                            value?._id
                          );
                        }}
                        disabled={open}
                      >
                        <span className="font-xssss fw-700 text-light ">
                          {checker(value._id) ? "Following" : "Follow"}
                        </span>
                      </button> */}
                    </div>
                  </div>
                ))}
                {/* <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img ">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user7.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">David Lee</a>
                      </h5>
                      <p>Web Developer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>50 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user8.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Daziy Millar</a>
                      </h5>
                      <p>PHP Expert</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group yellow"></i>
                        <span>50 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user9.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Patricia Mendoza</a>
                      </h5>
                      <p>Web Developer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group violet"></i>
                        <span>50 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user10.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Skyler Whites</a>
                      </h5>
                      <p>UI Designer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group orange"></i>
                        <span>50 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img ">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user7.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Patricia Mendoza</a>
                      </h5>
                      <p>Java Developer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>40 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user8.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">David Lee</a>
                      </h5>
                      <p>Web Developer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>50 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img ">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user9.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Daziy Millar</a>
                      </h5>
                      <p>PHP Expert</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>40 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img ">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user10.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Patricia Mendoza</a>
                      </h5>
                      <p>Web Developer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>20 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img ">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user7.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Skyler Whites</a>
                      </h5>
                      <p>UI Designer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>30 Students</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="instructors-widget">
                    <div className="instructors-img">
                      <a href="instructor-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/user/user8.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="instructor-profile.html">Patricia Mendoza</a>
                      </h5>
                      <p>Java Developer</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-user-group"></i>
                        <span>40 Students</span>
                      </div>
                    </div>
                  </div>
                </div> */}
              </OwlCarousel>
            </div>
          </div>
        </section>
        <section className="section lead-companies">
          <div className="container">
            <div className="section-header aos">
              <div className="section-sub-head feature-head text-center">
                <span>Trusted By</span>
                <h2>500+ Leading Universities And Companies</h2>
              </div>
            </div>
            <div className="lead-group aos">
              <div className="lead-group-slider owl-carousel owl-theme">
                <div className="item">
                  <div className="lead-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/img/lead-01.png"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="lead-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/img/lead-02.png"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="lead-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/img/lead-03.png"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="lead-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/img/lead-04.png"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="lead-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/img/lead-05.png"
                    />
                  </div>
                </div>
                <div className="item">
                  <div className="lead-img">
                    <img
                      className="img-fluid"
                      alt=""
                      src="assets/img/lead-06.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section share-knowledge">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="knowledge-img aos">
                  <img
                    src="assets/img/share.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="join-mentor aos">
                  <h2>Want to share your knowledge? Join us a Mentor</h2>
                  <p>
                    High-definition video is video of higher resolution and
                    quality than standard-definition. While there is no
                    standardized meaning for high-definition, generally any
                    video.
                  </p>
                  <ul className="course-list">
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Best Courses
                    </li>
                    <li>
                      <i className="fa-solid fa-circle-check"></i>Top rated
                      Instructors
                    </li>
                  </ul>
                  <div className="all-btn all-category d-flex align-items-center">
                    <Link to="/teacher-carousel" className="btn btn-primary">
                      Apply
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section user-love">
          <div className="container">
            <div className="section-header white-header aos">
              <div className="section-sub-head feature-head text-center">
                <span>Check out these real reviews</span>
                <h2>Users-love-us Don't take it from us.</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="testimonial-four">
          <div className="review">
            <div className="container">
              <div className="testi-quotes">
                <img src="assets/img/qute.png" alt="" />
              </div>
              <div style={{ height: "100%", maxHeight: "500px" }}>
                {/* <OwlCarousel options={testimonialOptions}>
                  <div
                    style={{ height: "540px" }}
                    className="d-flex justify-content-center"
                  >
                    <div className="testimonial-all d-flex justify-content-center">
                      <div className="testimonial-two-head text-center align-items-center d-flex">
                        <div className="testimonial-four-saying ">
                          <div className="testi-right">
                            <img src="assets/img/qute-01.png" alt="" />
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <div className="four-testimonial-founder">
                            <div className="fount-about-img">
                              <a href="instructor-profile.html">
                                <img
                                  src="assets/img/user/user1.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                            <h3>
                              <a href="instructor-profile.html">Daziy Millar</a>
                            </h3>
                            <span>Founder of Awesomeux Technology</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="testimonial-all d-flex justify-content-center">
                      <div className="testimonial-two-head text-center align-items-center d-flex">
                        <div className="testimonial-four-saying ">
                          <div className="testi-right">
                            <img src="assets/img/qute-01.png" alt="" />
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <div className="four-testimonial-founder">
                            <div className="fount-about-img">
                              <a href="instructor-profile.html">
                                <img
                                  src="assets/img/user/user3.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                            <h3>
                              <a href="instructor-profile.html">john smith</a>
                            </h3>
                            <span>Founder of Awesomeux Technology</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="testimonial-all d-flex justify-content-center">
                      <div className="testimonial-two-head text-center align-items-center d-flex">
                        <div className="testimonial-four-saying ">
                          <div className="testi-right">
                            <img src="assets/img/qute-01.png" alt="" />
                          </div>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <div className="four-testimonial-founder">
                            <div className="fount-about-img">
                              <a href="instructor-profile.html">
                                <img
                                  src="assets/img/user/user2.jpg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                            <h3>
                              <a href="instructor-profile.html">David Lee</a>
                            </h3>
                            <span>Founder of Awesomeux Technology</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </OwlCarousel> */}
              </div>
            </div>
          </div>
        </section>
        <section className="section become-instructors aos">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="student-mentor cube-instuctor ">
                  <h4>Become An Instructor</h4>
                  <div className="row">
                    <div className="col-lg-7 col-md-12">
                      <div className="top-instructors">
                        <p>
                          Top instructors from around the world teach millions
                          of students on Mentoring.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12">
                      <div className="mentor-img">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/become-02.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex">
                <div className="student-mentor yellow-mentor">
                  <h4>Transform Access To Education</h4>
                  <div className="row">
                    <div className="col-lg-8 col-md-12">
                      <div className="top-instructors">
                        <p>
                          Create an account to receive our newsletter, course
                          recommendations and promotions.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="mentor-img">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/become-01.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section latest-blog">
          <div className="container">
            <div className="section-header aos">
              <div className="section-sub-head feature-head text-center mb-0">
                <h2>Latest Blogs</h2>
                <div className="section-text aos">
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Eget aenean accumsan bibendum gravida maecenas augue
                    elementum et neque. Suspendisse imperdiet.
                  </p>
                </div>
              </div>
            </div>
            <div style={{ height: "100%", maxHeight: "500px" }}>
              <OwlCarousel options={options}>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-01.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          Attract More Attention Sales And Profits
                        </a>
                      </h5>
                      <p>Marketing</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>Jun 15, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-02.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          11 Tips to Help You Get New Clients
                        </a>
                      </h5>
                      <p>Sales Order</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>May 20, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-03.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          An Overworked Newspaper Editor
                        </a>
                      </h5>
                      <p>Design</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>May 25, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-04.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          A Solution Built for Teachers
                        </a>
                      </h5>
                      <p>Seo</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>Jul 15, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-02.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          Attract More Attention Sales And Profits
                        </a>
                      </h5>
                      <p>Marketing</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>Sep 25, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-03.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          An Overworked Newspaper Editor
                        </a>
                      </h5>
                      <p>Marketing</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>May 25, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-04.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          A Solution Built for Teachers
                        </a>
                      </h5>
                      <p>Analysis</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>May 15, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-02.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          11 Tips to Help You Get New Clients
                        </a>
                      </h5>
                      <p>Development</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>Jun 20, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-03.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          An Overworked Newspaper Editor
                        </a>
                      </h5>
                      <p>Sales</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>May 25, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    height: "100%",
                    maxHeight: "500px",
                  }}
                >
                  <div
                    className="instructors-widget blog-widget"
                    style={{ height: "500px" }}
                  >
                    <div className="instructors-img">
                      <a href="blog-list.html">
                        <img
                          className="img-fluid"
                          alt=""
                          src="assets/img/blog/blog-04.jpg"
                        />
                      </a>
                    </div>
                    <div className="instructors-content text-center">
                      <h5>
                        <a href="blog-list.html">
                          A Solution Built for Teachers
                        </a>
                      </h5>
                      <p>Marketing</p>
                      <div className="student-count d-flex justify-content-center">
                        <i className="fa-solid fa-calendar-days"></i>
                        <span>April 15, 2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
            <div className="enroll-group aos">
              <div className="row ">
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img
                          src="assets/img/icon/icon-07.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">253,085</span>
                      </h3>
                      <p>STUDENTS ENROLLED</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img
                          src="assets/img/icon/icon-08.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">1,205</span>
                      </h3>
                      <p>TOTAL COURSES</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="total-course d-flex align-items-center">
                    <div className="blur-border">
                      <div className="enroll-img ">
                        <img
                          src="assets/img/icon/icon-09.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="course-count">
                      <h3>
                        <span className="counterUp">127</span>
                      </h3>
                      <p>COUNTRIES</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lab-course">
              <div className="section-header aos">
                <div className="section-sub-head feature-head text-center">
                  <h2>
                    Unlimited access to 360+ courses and 1,600+ hands-on labs
                  </h2>
                </div>
              </div>
              <div className="icon-group aos">
                <div className="offset-lg-1 col-lg-12">
                  <div className="row">
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-09.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-10.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-16.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-12.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-13.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-14.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-15.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-16.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-17.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1 col-3">
                      <div className="total-course d-flex align-items-center">
                        <div className="blur-border">
                          <div className="enroll-img ">
                            <img
                              src="assets/img/icon/icon-18.svg"
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="footer-top aos">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="footer-widget footer-about">
                    <h1 className="fredoka-font ls-3 fw-700 text-current font-xxl">
                      Seekho.pk
                    </h1>
                    <div className="footer-about-content">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut consequat mauris Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Ut consequat mauris
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">For Instructor</h2>
                    <ul>
                      
                      <li>
                        <a href="/login-teacher">Login</a>
                      </li>
                      <li>
                        <a href="/teacher-carousel">Teach on Seekho</a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">For Student</h2>
                    <ul>
                     
                      <li>
                        <a href="/login">Login</a>
                      </li>
                      <li>
                        <a href="/register">Register</a>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="footer-widget footer-contact">
                    <h2 className="footer-title">News letter</h2>
                    <div className="news-letter">
                      <form>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email address"
                          name="email"
                        />
                      </form>
                    </div>
                    <div className="footer-contact-info">
                      <div className="footer-address">
                        <img
                          src="assets/img/icon/icon-20.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <p>
                          {" "}
                          Pakisthan
                        </p>
                      </div>
                      <p>
                        <img
                          src="assets/img/icon/icon-19.svg"
                          alt=""
                          className="img-fluid"
                        />
                        <a
                          href="cdn-cgi/l/email-protection.html"
                          className="__cf_email__"
                        >
                          contact@seekho.com
                        </a>
                      </p>
                      <p className="mb-0">
                        <img
                          src="assets/img/icon/icon-21.svg"
                          alt=""
                          className="img-fluid"
                        />
                        +92 12345-67890
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="container">
              <div className="copyright">
                <div className="row">
                  <div className="col-md-6">
                    <div className="privacy-policy">
                      <ul>
                        <li>
                          <a href="term-condition.html">Terms</a>
                        </li>
                        <li>
                          <a href="privacy-policy.html">Privacy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="copyright-text">
                      <p className="mb-0">&copy; Seekho.pk</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Fragment>
  );
};
