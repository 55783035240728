import React, { Fragment, useEffect, useState } from "react";
import Appfooter from "../components/Appfooter";
import Navheader from "../components/Navheader";
import Appheader from "../components/Appheader";
import { Image, Ratio } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getLiveCourseById } from "../services/userServices/FeaturedServices";
import CountdownTimer from "../components/CountDownTimer";
// import Profile from "../components/Profile";
// import Myclass from "../components/Myclass";
// import Subscribe from "../components/Subscribe";
import "../main.scss";

import {
  setRoomNoVar,
  setMediaConstraintsG,
} from "../videoChatComponents/webRTC/globals.ts";
import {
  handleOnCreate,
  handleOnJoin,
} from "../videoChatComponents/webRTC/action.ts";
import { useNavigate } from "react-router-dom";

const Contactinfo = () => {
  const navigate = useNavigate();

  const [liveCourse, setLiveCourse] = useState({});
  const [date, setDate] = useState();
  const params = useParams();
  const [checkStatus, setCheckStatus] = useState(false);
  const [mediaConstraints, setMediaConstraints] = useState({
    video: true,
    audio: true,
  });

  const joinButtonClicked = (roomCode) => {
    setRoomNoVar(roomCode.toString());
    handleOnJoin();
    setMediaConstraintsG({ video: true, audio: true });

    navigate(`/room`);
  };

  const fetchLiveCourses = async () => {
    try {
      const res = await getLiveCourseById(params.id);
      setLiveCourse(res.data.liveCourse);
      setDate(res.data.liveCourse.lectures[0].liveTime.startTime);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLiveCourses();
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia(mediaConstraints)
      .then((localstream) => {
        const videoCont = document.querySelector(".video-self");
        if (videoCont) {
          videoCont.srcObject = localstream;
        }
      });
  }, [mediaConstraints]);

  return (
    <Fragment>
      <div className="main-wrapper">
        <Navheader />

        <div className="main-content">
          <Appheader />

          <div className="middle-sidebar-bottom theme-dark-bg">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  {/* <h1></h1> */}
                  <CountdownTimer
                    targetDate={date}
                    setCheckStatus={setCheckStatus}
                  />
                  <div className="card d-block border-0 rounded-lg overflow-hidden dark-bg-transparent bg-transparent mt-4 pb-3">
                    <div
                      style={{
                        width: 700,
                        height: 460,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Ratio aspectRatio="16x9">
                        <Image
                          src={liveCourse?.thumbnail}
                          alt="Thumbnail Preview"
                          fluid
                          style={{
                            border: "1px solid #ced4da",
                          }}
                        />
                      </Ratio>
                    </div>
                    <div className="row">
                      <div className="col-10">
                        <h2 className="fw-700 font-md d-block lh-4 mb-2">
                          {liveCourse?.title}
                        </h2>
                      </div>
                    </div>
                    <span className="font-xssss fw-700 text-grey-900 d-inline-block ml-0 text-dark">
                      {liveCourse?.instructor?.name}
                    </span>
                    <span className="dot ml-2 mr-2 d-inline-block btn-round-xss bg-grey"></span>
                    <span className="font-xssss fw-600 text-grey-500 d-inline-block ml-1">
                      {liveCourse.domain_of_study}
                    </span>

                    <div className="card d-block border-0 rounded-lg overflow-hidden p-4 shadow-xss mt-4">
                      <h2 className="fw-700 font-sm mb-3 mt-1 pl-1 mb-3">
                        Description
                      </h2>
                      <p className="font-xssss fw-500 lh-28 text-grey-600 mb-0 pl-2">
                        {liveCourse?.description}
                      </p>
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection:"row-reverse",
                      gap: "100px",
                    }}
                  >
                    <button
                      className="btn btn-primary w-32 h-16"
                      style={{ background: checkStatus ? "" : "gray" , borderColor: checkStatus ? "" : "gray"}}
                      onClick={() => {
                        if (checkStatus) {
                          joinButtonClicked(params.id);
                        }
                      }}
                    >
                      Join Live Class
                    </button>

                    <video
                      className="video-self"
                      autoPlay
                      muted
                      style={{maxHeight: "300px", maxWidth: "700px"}}
                      playsInline
                    ></video>
                  </div>

                  {/* <div
                    className="card border-0 mb-0 rounded-3 overflow-hidden chat-wrapper bg-image-center bg-image-cover"
                    style={{
                      backgroundImage: `url("/assets/images/dl.png")`,
                    }}
                  >
                    
                    <div className="card-body position-absolute mt-0 ms-0 left-0">
                      <img
                        src="/assets/images/dl2.png"
                        alt="video-bg"
                        className="w150 h200 rounded-3 position-relative z-index-1 shadow-xss"
                      />
                    </div>
                    <div className="card-body text-center p-2 position-absolute w-100 bottom-0 bg-gradiant-bottom">
                      <a
                        href="/defaultlivestream"
                        className="btn-round-xl d-md-inline-block d-none bg-blur m-3 me-0 z-index-1"
                      >
                        <i className="feather-grid text-white font-md"></i>
                      </a>
                      <a
                        href="/defaultlivestream"
                        className="btn-round-xl d-md-inline-block d-none bg-blur m-3 z-index-1"
                      >
                        <i className="feather-mic-off text-white font-md"></i>
                      </a>
                      <a
                        href="/defaultlivestream"
                        className="btn-round-xxl bg-danger z-index-1"
                      >
                        <i className="feather-phone-off text-white font-md"></i>
                      </a>
                      <a
                        href="/defaultlivestream"
                        className="btn-round-xl d-md-inline-block d-none bg-blur m-3 z-index-1"
                      >
                        <i className="ti-video-camera text-white font-md"></i>
                      </a>
                      <a
                        href="/defaultlivestream"
                        className="btn-round-xl d-md-inline-block d-none bg-blur m-3 ms-0 z-index-1"
                      >
                        <i className="ti-settings text-white font-md"></i>
                      </a>
                      <span className="p-2 bg-blur z-index-1 text-white fw-700 font-xssss rounded-3 right-15 position-absolute mb-4 bottom-0">
                        44:00
                      </span>
                      <span className="live-tag position-absolute left-15 mt-2 bottom-0 mb-4 bg-danger p-2 z-index-1 rounded-3 text-white font-xsssss text-uppersace fw-700 ls-3">
                        LIVE
                      </span>
                    </div>
                  </div> */}
                </div>

                {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0 ps-0">
                  <div className="card w-100 d-block chat-body p-0 border-0 shadow-xss rounded-3 mb-3 position-relative">
                    <div className="messages-content chat-wrapper scroll-bar p-3">
                      <div className="message-item">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                            <div className="time">01:35 PM</div>
                          </div>
                        </div>
                        <div className="message-wrap shadow-none">
                          I'm fine, how are you
                        </div>
                      </div>

                      <div className="message-item">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                            <div className="time">
                              01:35 PM
                              <i className="ti-double-check text-info"></i>
                            </div>
                          </div>
                        </div>
                        <div className="message-wrap shadow-none">
                          I want those files for you. I want you to send 1 PDF
                          and 1 image file.
                        </div>
                      </div>

                      <div className="message-item">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                            <div className="time">01:35 PM</div>
                          </div>
                        </div>
                        <div className="message-wrap shadow-none">
                          I've found some cool photos for our travel app.
                        </div>
                      </div>

                      <div className="message-item outgoing-message">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5>You</h5>
                            <div className="time">
                              01:35 PM
                              <i className="ti-double-check text-info"></i>
                            </div>
                          </div>
                        </div>
                        <div className="message-wrap">
                          Hey mate! How are things going ?
                        </div>
                      </div>

                      <div className="message-item">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                            <div className="time">01:35 PM</div>
                          </div>
                        </div>
                        <div className="message-wrap shadow-none">
                          I'm fine, how are you.
                        </div>
                      </div>

                      <div className="message-item">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                            <div className="time">
                              01:35 PM
                              <i className="ti-double-check text-info"></i>
                            </div>
                          </div>
                        </div>
                        <div className="message-wrap shadow-none">
                          I want those files for you. I want you to send 1 PDF
                          and 1 image file.
                        </div>
                      </div>

                      <div className="message-item">
                        <div className="message-user">
                          <figure className="avatar">
                            <img src="assets/images/user.png" alt="avater" />
                          </figure>
                          <div>
                            <h5 className="font-xssss mt-2">Byrom Guittet</h5>
                            <div className="time">01:35 PM</div>
                          </div>
                        </div>
                        <div className="message-wrap shadow-none">
                          I've found some cool photos for our travel app.
                        </div>
                      </div>
                    </div>
                    <form className="chat-form position-absolute bottom-0 w-100 left-0 bg-white z-index-1 p-3 shadow-xs theme-dark-bg ">
                      <button className="bg-grey float-left">
                        <i className="ti-microphone text-white"></i>
                      </button>
                      <div className="form-group">
                        <input type="text" placeholder="Start typing.." />
                      </div>
                      <button className="bg-current">
                        <i className="ti-arrow-right text-white"></i>
                      </button>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="middle-sidebar-right scroll-bar">
              <div className="middle-sidebar-right-content">
                {/* <Profile /> */}
                {/* <Myclass /> */}
                {/* <Subscribe /> */}
              </div>
            </div>
          </div>
        </div>

        <Appfooter />
      </div>
    </Fragment>
  );
};

export default Contactinfo;
