import httpClient from "../http-common";

// Earning Services

export const getEarnings = (token) => {
  return httpClient.get(`/teacher/earning`, {
    headers: {
      'x-auth-token': token
    },
  });
}


// Sign Up
export const teacherApplicant = (data) => {
  return httpClient.post(`/applicant`, data);
};

// Sign In
export const signInTeacher = (credentials) => {
  return httpClient.post("/teacher/login", credentials);
};

// // AuthorizeUser
export const authorizeTeacher = (token) => {
  return httpClient.get(`/teacher/auth`, {
    headers: {
      'x-auth-token': token
    },
  });
};

// // Get All Courses
export const getAllCourses = (token) => {
  return httpClient.get(`/course/all-courses`, {
    headers: {
      'x-auth-token': token
    },
  });
};


// Module Services


//  Create a module
export const addNewModule = (token, formData, courseID) => {
  return httpClient.post(`/module/add-a-module/${courseID}`, formData, {
      headers: {
          'x-auth-token': token,
          // 'Content-Type': 'multipart/form-data'
      }
  });

};

// Delete part of Module 
export const deletePartOfModule = (token, moduleID,partID) => {
  return httpClient.delete(`/module/delete-part/${moduleID}/${partID}`, {
      headers: {
          'x-auth-token': token,
      }
  });

};
// Delete part of Module 
export const deleteCourse = (token, courseID) => {
  return httpClient.delete(`/course/delete-course/${courseID}`, {
      headers: {
          'x-auth-token': token,
      }
  });

};

// Delete part of Module 
export const deleteModule = (token, moduleID) => {
  return httpClient.delete(`/module/delete-module/${moduleID}`, {
      headers: {
          'x-auth-token': token,
      }
  });

};

// Delete part of Module 
export const editCourse = (token, data) => {
  return httpClient.put(`/course/edit-course`,data ,{
      headers: {
          'x-auth-token': token,
      }
  });

};

export const searchTeachers = (query, sort) => {
  return httpClient.get(`/teacher/searchTeachers/${query}`, {
   
    params: {
      query,
      sort
    }
  });
};

// Forgot Password
export const getSecurityQuestionTeacher = ( email) => {
  return httpClient.post(`/forgot-password/getSecurityQuestionTeacher`, {
    email: email,
  });
};

export const changePasswordTeacher = (
  email,
  securityQuestion,
  securityAnswer,
  newPassword
) => {
  return httpClient.post(
    `/forgot-password/teacher`,
    { email ,securityQuestion, securityAnswer, newPassword },
    
  );
};
