import React, { useEffect, useState } from "react";
import { searchTeachers } from "../services/teacherServices/TeacherServices";

export default function SearchBarTeacher(props: { setTeacherListProp: Function , homePage?: boolean}) {
  const [courseSearchQuery, setCourseSearchQuery] = useState("");
  const [sort, setSort] = useState("relevance");

  const searchTeachersList = async () => {
    try {
      if(courseSearchQuery === ""){
        await searchTeachers("a", sort)
        .then((response) => {
          //console.log(response.data);
          props.setTeacherListProp(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
      }else{

      
      await searchTeachers(courseSearchQuery, sort)
        .then((response) => {
          console.log(response.data);
          props.setTeacherListProp(response.data);
        })
        .catch((error) => {
          console.error(error);
        });}
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    searchTeachersList();
  }, [sort]);

  if(!props.homePage){

  return (
    <div className="form-group mt-lg-4 p-3 border-light border p-2 bg-white rounded-lg">
      <div className="row">
        <div className="col-md-5">
          <div className="form-group icon-input mb-0">
            <i className="ti-search font-xs text-grey-400"></i>
            <input
              type="text"
              className="style1-input bg-transparent border-0 pl-5 font-xsss mb-0 text-grey-500 fw-500"
              placeholder="Search teachers.."
              value={courseSearchQuery}
              onChange={(e) => setCourseSearchQuery(e.target.value)}
            />
          </div>
        </div>
        
        <div className="col-md-2">
          <select
            className="form-control"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="relevance">Relevance</option>
            <option value="nameAsc">Name Ascending</option>
            <option value="nameDesc">Name Descending</option>
            <option value="createdDateAsc">Created Date Ascending</option>
            <option value="createdDateDesc">Created Date Descending</option>
            <option value="updatedDateAsc">Updated Date Ascending</option>
            <option value="updatedDateDesc">Updated Date Descending</option>
          </select>
        </div>

        <div className="col-md-3">
          <button
            onClick={async () => {
              await searchTeachersList();
            }}
            className="w-100 d-block btn text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase"
            style={{ backgroundColor: "#ff875a" }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}else{
  <div></div>
}
}
