import React, { useState, Fragment } from "react";

// import RecordedCourseIcon from "./1home.png";
import { FaTimes, FaVideo, FaPlay, FaFile } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { addNewModule } from "./services/teacherServices/TeacherServices.js";
import { addNewPart } from "./services/courseServices/courseServices.js";
import { setModuleData } from "./Redux/courseSlice.jsx";
import { setCurrentModuleID } from "./Redux/courseSlice.jsx";

// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProgressComponent from "./components/ModuleComponents/ProgressComponent.jsx";

function AddModuleForm(props) {
  const [initialize, setInitialize] = useState(false);
  const [content, setContent] = useState(true);
  const [open, setOpen] = useState(false);
  const [moduleDataID, setModuleDataID] = useState(null);
  const [sections, setSections] = useState([
    { id: 1, title: "Section 1: Introduction" },
  ]);
  const [
    selectedDropdownId,
    // setSelectedDropdownId
  ] = useState(null);
  const [
    isDropdownOpen,
    //  setIsDropdownOpen
  ] = useState(true);
  const [editingDescriptionId, setEditingDescriptionId] = useState(null);
  const [descriptionText, setDescriptionText] = useState("");
  const styles = {
    courseContainer: {
      width: "100%",
    },
    sectionContainer: {
      border: "2px solid transparent",
      margin: "15px 0",
      backgroundColor: "transparent",
    },

    sectionContainer1: {
      margin: "15px 10px",
      backgroundColor: "#f9f9f9",
    },

    sectionBlock: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "15px",
    },

    editContainer: {
      display: "flex",
      alignItems: "center",
    },
    staticText: {
      marginRight: "10px",
    },
    editInput: {
      // flex: 1,
      marginRight: "10px",
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    editBtn: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    cancelBtn: {
      backgroundColor: "#ff0000",
      color: "white",
      padding: "5px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    dropdownIcon: {
      fontSize: "20px",
      cursor: "pointer",
      marginLeft: "10px",
    },
    addSectionBtn: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "10px 15px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
    icon: {
      marginLeft: "10px",
      cursor: "pointer",
    },
    dropdownButtons: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "10px",
      marginLeft: "auto",
    },
    dropdownContent: {
      padding: "10px 15px",
      backgroundColor: "#f9f9f9",
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center align the buttons
      justifyContent: "center",
    },
    dropdownContent1: {
      padding: "10px 15px",
      backgroundColor: "#f9f9f9",
      display: "flex",
      flexDirection: "row",
      alignItems: "center", // Center align the buttons
      justifyContent: "center",
    },
    button: {
      marginBottom: "0px", // Adds space between buttons
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px", // Adjust padding for smaller size
      border: "1px solid #ddd", // Elegant border
      borderRadius: "5px", // Rounded corners
      cursor: "pointer",
      width: "100%", // Adjust width as per requirement
      textAlign: "center", // Ensure text is centered
    },
    button1: {
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px", // Adjust padding for smaller size
      border: "1px solid #ddd", // Elegant border
      borderRadius: "5px", // Rounded corners
      cursor: "pointer",
      width: "32%", // Adjust width as per requirement
      textAlign: "center", // Ensure text is centered
    },
    descriptionEditContainer: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "column",
    },
    descriptionTextarea: {
      width: "100%",
      height: "100px",
      marginBottom: "10px",
      padding: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      resize: "none",
      fontFamily: "inherit", // Use the same font as the parent container
    },
    descriptionButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    descriptionButton: {
      marginRight: "5px",
      backgroundColor: "#4CAF50",
      color: "white",
      padding: "5px 10px",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  const saveDescription = () => {
    const editedSectionIndex = sections.findIndex(
      (section) => section.id === editingDescriptionId
    );
    if (editedSectionIndex !== -1) {
      const updatedSections = [...sections];
      updatedSections[editedSectionIndex].description = descriptionText;
      setSections(updatedSections);
    }
    setEditingDescriptionId(null);
  };

  const cancelEditingDescription = () => {
    setEditingDescriptionId(null);
  };

  const startEditingDescription = (id, description) => {
    setEditingDescriptionId(id);
    setDescriptionText(description);
  };
  const handleCurriculumButtonClick = (id) => {
    const updatedSections = sections.map((section) => {
      if (section.id === id) {
        return {
          ...section,
          isCurriculumButtonPressed: !section.isCurriculumButtonPressed,
        };
      }
      return section;
    });
    setSections(updatedSections);
  };
  const handleContentButtonClick = (id) => {
    setSections(
      sections.map((section) => {
        if (section.id === id) {
          return {
            ...section,
            isContentButtonPressed: !section.isContentButtonPressed,
          };
        }
        return section;
      })
    );
  };
  const contentButtonStyle = {
    ...styles.button,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20%",
    margin: "0 10px",
  };

  const buttonWidth = "700px";

  const [
    currentSection,
    //  setCurrentSection
  ] = useState(2);
  const [currentSubsection] = useState(1); // Define currentSubsection state

  //   New Additions
  const [show, setShow] = useState(false);
  const handleOpenModal = () => {
    setShow((show) => !show);
  };

  const handleClose = () => {
    setShow((show) => !show);
  };

  // const [isInitialized, setIsInitialized] = useState(false); // State to track if module is initialized

  // const handleInitializeModule = () => {
  //   setIsInitialized(true); // Set isInitialized to true when button is clicked
  // };

  return (
    <Fragment>
      <div
        className="card-body d-block p-5 pb-4 text-center bg-w "
        style={{ marginTop: "-50px", width: "1000px" }}
      >
        <PopUpVideoLecture />
        <div className="timeline-steps aos-init aos-animate" data-aos="fade-up">
          <div
            className={`timeline-step ${currentSection === 2 ? "active" : ""}`}
          ></div>
        </div>
      </div>
      <div className="container-fluid px-4 py-4">
        <div
          className="d-flex flex-column align-items-center "
          style={{ marginTop: "-30px", width: "100%" }}
        >
          {/* Content for other sections */}
          {currentSection === 2 && (
            <div>
              <div>
                <SectionModule />

                {/* Content for Subsection 2 */}
              </div>
            </div>
          )}
        </div>
      </div>
      {currentSection === 4 && (
        <button style={{ width: buttonWidth }}>Publish</button>
      )}
    </Fragment>
  );

  function SectionModule() {
    // const [addSectionDisabled, setAddSectionDisabled] = useState(true);
    // const [moduleId, setModuleId] = useState(null); // State to store the module ID
    // const [expanded, setExpanded] = useState(null);

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const dispatch = useDispatch();

    const [references] = useState({
      courseID: localStorage.getItem("courseID"),
      token: JSON.parse(localStorage.getItem("token")),
    });

    const onSubmit = async (data, event) => {
      event.preventDefault();
      setOpen(true);
      await addNewModule(references.token, data, references.courseID)
        .then((response) => {
          // console.log(response.data);
          // setModuleId(response.data._id);
          setContent(false);
          setInitialize(true);
          dispatch(setModuleData(response.data.module));
          console.log(response.data);
          dispatch(setCurrentModuleID(response.data.module._id));
          setModuleDataID(response.data.module._id);
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setOpen(false);
        });
    };

    return (
      <>
        {/* Content for Subsection 1 */}
        {currentSubsection === 1 && (
          <div style={styles.courseContainer}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
              Loading...
            </Backdrop>
            {sections.map((section) => (
              <div key={section.id} style={styles.sectionContainer}>
                <div style={styles.sectionBlock}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      {" "}
                      {errors.title && (
                        <span className="text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </div>
                    <span style={styles.staticText}>{`Section Title:`}</span>
                    <input
                      type="text"
                      // onChange={(e) => setEditedSectionText(e.target.value)}
                      {...register("title", {
                        required: "Title for Section must be included",
                      })}
                      style={styles.editInput}
                    />

                    <button
                      // disabled={initialize}
                      style={styles.editBtn}
                      className="btn btn-primary"
                      // onClick={saveEdit}
                      type="submit"
                    >
                      {/* Initialize Section */}
                      Add Section
                    </button>
                    {/* <button
                                        style={styles.cancelBtn}
                                        onClick={cancelEdit}
                                      >
                                        Cancel
                                      </button> */}
                  </form>

                  <div>
                    {/* <FaEdit
                                    style={styles.icon}
                                    onClick={() =>
                                      startEditing(section.id, section.title)
                                    }
                                  /> */}
                    {/* <FaTrashAlt
                      style={styles.icon}
                      onClick={() => deleteSection(section.id)}
                    /> */}
                    {/* <FaAngleDown
                                    style={styles.dropdownIcon}
                                    onClick={() => toggleDropdown(section.id)}
                                  /> */}
                  </div>
                </div>
                {isDropdownOpen && selectedDropdownId === section.id && (
                  <div>
                    {editingDescriptionId === section.id ? (
                      <div style={styles.descriptionEditContainer}>
                        <textarea
                          value={descriptionText}
                          onChange={(e) => setDescriptionText(e.target.value)}
                          style={styles.descriptionTextarea}
                          placeholder="Write description about the section..."
                        />
                        <div style={styles.descriptionButtonContainer}>
                          <button
                            style={{
                              ...styles.descriptionButton,
                            }}
                            onClick={saveDescription}
                          >
                            Save
                          </button>
                          <button
                            style={styles.descriptionButton}
                            onClick={cancelEditingDescription}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div style={styles.dropdownContent}>
                          <button
                            style={styles.button}
                            onClick={() =>
                              startEditingDescription(
                                section.id,
                                section.description
                              )
                            }
                          >
                            + Description
                          </button>
                          <button style={styles.button}>+ Resources</button>
                        </div>
                      </>
                    )}
                  </div>
                )}
                <div style={styles.sectionContainer1}>
                  {section.isCurriculumButtonPressed ? (
                    <div style={{ position: "relative" }}>
                      <FaTimes
                        style={{
                          position: "absolute",
                          top: "-15px",
                          right: "1px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCurriculumButtonClick(section.id)}
                      />
                      <div
                        style={{
                          ...styles.dropdownContent1,
                          border: section.isCurriculumButtonPressed
                            ? "2px solid #ccc"
                            : "none",
                        }}
                        onClick={() => handleCurriculumButtonClick(section.id)}
                      >
                        <button style={styles.button}>Lecture</button>
                        <button style={styles.button}>Quizz</button>
                        <button style={styles.button}>Exercise</button>
                        <button style={styles.button}>Assignment</button>
                        <button style={styles.button}>Practice Test</button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* Content + Button and Dropdown */}
                {/* <div style={styles.sectionContainer1}>
                  {section.isContentButtonPressed ? (
                    <div style={{ position: "relative" }}>
                      <FaTimes
                        style={{
                          position: "absolute",
                          top: "-20px",
                          right: "1px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleContentButtonClick(section.id)}
                      />
                      <div
                        style={{
                          ...styles.dropdownContent1,
                          border: section.isContentButtonPressed
                            ? "2px solid #ccc"
                            : "none",
                        }}
                      >
                        <button
                          onClick={handleOpenModal}
                          style={contentButtonStyle}
                        >
                          <FaVideo style={{ marginRight: "10px" }} /> Video
                        </button>
                        <button style={contentButtonStyle}>
                          <FaPlay style={{ marginRight: "10px" }} /> Article
                        </button>
                        <button style={contentButtonStyle}>
                          <FaFile style={{ marginRight: "10px" }} /> Other
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        style={styles.button1}
                        onClick={() => handleContentButtonClick(section.id)}
                        disabled={content}
                      >
                        + Content
                      </button>
                    </div>
                  )}
                </div> */}
              </div>
            ))}
          </div>
        )}

        {/* <button
          className="btn btn-primary"
          style={styles.button1}
          onClick={addSection}
          disabled={addSectionDisabled}
        >
          Add Section
        </button> */}

        {props.showProgress && (
          <>
            <div className="d-flex flex-column">
              <ProgressComponent
                handleLectureAdd={handleOpenModal}
                handleContentButtonClick={handleContentButtonClick}
              />
            </div>
          </>
        )}
      </>
    );
  }
  function PopUpVideoLecture() {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const dispatch = useDispatch();

    // Inside your component function
    const [videoPreview, setVideoPreview] = useState(null); // State to store video preview URL
    const [fileName, setFileName] = useState(""); // State to store the selected file name

    // const modules = useSelector((state) => state.course.moduleData[0].module._id);
    const moduleID = useSelector((state) => state.course.currentModuleID);
    const token = JSON.parse(localStorage.getItem("token"));
    const onSubmit = async (data, event) => {
      event.preventDefault();
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("video", data.video[0]);
      formData.append("type", "video");
      // console.log(formData)

      try {
        setOpen(true);
        await addNewPart(token, formData, moduleID)
          .then((response) => {
            console.log(response.data);
            dispatch(setModuleData(response.data.module));
            handleClose();
            setInitialize(false);
            setOpen(false);
          })
          .catch((error) => {
            setOpen(false);
            console.log(error);
          });
      } catch (error) {
        console.log(error.message);
        setOpen(false);
      }
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        const videoURL = URL.createObjectURL(file); // Create a URL for the file
        setVideoPreview(videoURL); // Set the video preview URL
        setFileName(file.name); // Set the selected file name
      }
    };

    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add a module</Modal.Title>
        </Modal.Header>
        <p className="m-3">Post a Video Lecture</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
              Loading...
            </Backdrop>
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">
                Lecture Title
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={`Lecture Title`}
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <span className="text-danger">{errors.title.message}</span>
              )}
            </div>
            <div className="form-group">
              <label className="mont-font fw-600 font-xsss">
                Brief Introduction of Lecture
              </label>
              <textarea
                className="form-control mb-0 p-3 bg-white lh-16"
                rows="3"
                placeholder="Brief introduction..."
                {...register("description", {
                  required: "Description is required",
                })}
              ></textarea>
              {errors.description && (
                <span className="text-danger">
                  {errors.description.message}
                </span>
              )}
            </div>
            <div className="form-group">
              {/* Video preview */}
              {videoPreview && (
                <div className="form-group">
                  <p>Video Preview:</p>
                  <video controls className="mt-2" style={{ maxWidth: "100%" }}>
                    <source src={videoPreview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <p>File Name: {fileName}</p> {/* Display file name */}
                </div>
              )}
              <label htmlFor="file" className="mont-font fw-600 font-xsss">
                Attach Video File
              </label>
              <div className="input-group">
                <div className="custom-file">
                  <input
                    type="file"
                    accept="video/*" // Only accept video files
                    onChange={handleFileChange} // Call handleFileChange on file input change
                    {...register("video", {
                      required: "Video file is required",
                    })}
                  />
                </div>
              </div>
              {/* Your existing error handling code */}
              {errors.video && (
                <span className="text-danger">{errors.video.message}</span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="primary"
                size="lg"
                type="submit"
                style={{
                  backgroundColor: "#4CAF50",
                  borderColor: "#4CAF50",
                }}
              >
                Post new Module
              </Button>
              <Button
                variant="secondary"
                size="lg"
                onClick={handleClose}
                style={{
                  backgroundColor: "#ff0000",
                  borderColor: "#ff0000",
                  opacity: "0.75",
                }}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default AddModuleForm;
